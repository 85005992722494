import { useContext } from 'react';

// Import context
import { RouteContext } from './RouteProvider';

const useRouteContext = () => {
	const context = useContext(RouteContext);

	if (!context) {
		throw new Error('RouteContext must be used within a RouteProvider');
	}
	return context;
};

export default useRouteContext;
