import React from 'react';

const Footer = () => (
	<div className="footer">
		<div>
			Copyright: <strong>proexe.co</strong>
		</div>
	</div>
);

export default Footer;
