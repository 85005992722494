import React, { useEffect } from 'react';
import { useTranslation } from 'react-i18next';
import { useParams } from 'react-router-dom';
import { Form } from 'react-final-form';
import { useSelector, useDispatch } from 'react-redux';

// Import redux actions
import {
	dictionaryPeriodUnit,
	dictionaryMain,
	dictionaryProvision
} from 'store/actions';

// Import components
import { FormTemplate } from 'components/common/templates';
import { FormButtons } from 'components/common/buttons';
import Fields from './fields/Fields';
import { defaultFieldValues } from './fields/input_fields';

// Import helpers
import { addSubscriberPacket } from '../helpers';

// Import validations
import validate from './validation';

const SubscribersPacketsForm = () => {
	const dispatch = useDispatch();
	const { t } = useTranslation();
	const { id } = useParams();

	const handleOnSubmit = async (values) => {
		addSubscriberPacket(values, id);
	};

	useEffect(() => {
		dictionaryPeriodUnit()(dispatch);
		dictionaryMain()(dispatch);
		dictionaryProvision()(dispatch);
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, []);

	const { main, provision, period_unit } = useSelector(
		({ dictionary }) => dictionary
	);

	const selectResources = {
		period_unit,
		packet_uuid: [...main, ...provision]
	};

	const formInitialValues = {
		...defaultFieldValues,
		packet_uuid: main[0]?.value
	};

	return (
		<FormTemplate>
			{() => (
				<Form
					onSubmit={handleOnSubmit}
					initialValues={formInitialValues}
					validate={validate}
					render={({ handleSubmit, submitting }) => (
						<form onSubmit={handleSubmit}>
							<Fields
								submitting={submitting}
								selectResources={selectResources}
							/>
							<FormButtons
								isButtonDisabled={submitting}
								path="subscribers"
								buttonText={t('common:buttons.submit')}
							/>
						</form>
					)}
				/>
			)}
		</FormTemplate>
	);
};

export default SubscribersPacketsForm;
