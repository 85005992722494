import i18n from 'i18next';

export const VOD_INPUT_FIELDS_DATA = {
	submitUrl: 'vod',
	fetchUrl: 'vod',
	cancelButtonPath: 'vod',
	hasMetadata: true,
	defautlFieldValues: {
		title: '',
		title_org: '',
		summary_short: '',
		summary_long: ''
	},
	inputFields: [
		{
			name: 'title',
			label: i18n.t('vod:form:fields.title'),
			type: 'text',
			required: true
		},
		{
			name: 'title_org',
			label: i18n.t('vod:form:fields.title_org'),
			type: 'text',
			required: false
		},
		{
			name: 'summary_short',
			label: i18n.t('vod:form:fields.summary_short'),
			type: 'textarea',
			required: true
		},
		{
			name: 'summary_long',
			label: i18n.t('vod:form:fields.summary_long'),
			type: 'textarea',
			required: false
		}
	]
};
