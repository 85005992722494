import services from 'services/services';
import * as types from '../types';

// Import translation
import i18n from 'i18next';

// Import utilities
import { notificationHandler } from 'components/utilities/notifications/index';

// Import helpers
import { checkFetchErrors } from '../helpers_actions/convert_helpers';

// ******************** FETCH CATEGORY TO EDIT ********************
export const fetchCategory = (id) => async (dispatch) => {
	try {
		// Dispatch a loading action
		dispatch({
			type: types.FETCH_CATEGORY_LOADING,
			payload: true
		});

		const { data } = await services.get(`/products/genres/${id}/details`);

		// Dispatch an action with data
		dispatch({
			type: types.FETCH_CATEGORY_SUCCESS,
			payload: data
		});
	} catch (error) {
		dispatch({
			type: types.FETCH_CATEGORY_ERROR,
			payload: checkFetchErrors(error)
		});
	}
};

// ****************** REVERSE MERGE CATEGORY ******************
export const reverseMergeCategory = (id) => async (dispatch, getState) => {
	try {
		const {
			categories: {
				form: {
					edit: { id: parentId }
				}
			}
		} = getState();

		await services.post('/products/genres/reverseMerge', { childId: id });

		notificationHandler(
			i18n.t('categories:notifications.reverse_merge'),
			i18n.t('categories:notifications.reverse_merge_success'),
			'success'
		);
		dispatch(fetchCategory(parentId));
	} catch (error) {
		notificationHandler(
			i18n.t('messages:notifications.error'),
			i18n.t('common:errors.common_error_message'),
			'error'
		);
	}
};
