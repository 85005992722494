import React from 'react';
import useTabsLogic from './useTabsLogic';

// Import components
import Links from './links/Links';
import Routes from './routes/Routes';
import TabsBreadcrumbs from './TabsBreadcrumbs';
import { Skeleton } from 'antd';

// Import hooks
import useAuthCheck from 'hooks/useAuthCheck';

const Tabs = ({ tabs }) => {
	const {
		url,
		path,
		loading,
		child,
		parent,
		baseParent,
		properSubtype,
		properType
	} = useTabsLogic();

	// check if user have rights to create and edit videos
	const {
		isMaterialTabVisible,
		isEncodingTabVisible,
		isPaymentsTabVisible,
		isTrackingTabVisible
	} = useAuthCheck();

	const renderBreadCrumbs = () => {
		if (properType) {
			return (
				<div className="d-flex align-items-center tabs-breadcrumbs__container">
					{!loading ? (
						<TabsBreadcrumbs
							child={child}
							parent={parent}
							baseParent={baseParent}
							properSubtype={properSubtype}
						/>
					) : (
						<div style={{ width: '400px' }}>
							<Skeleton
								active
								title
								paragraph={false}
								className="tabs-breadcrumbs__skeleton"
							/>
						</div>
					)}
				</div>
			);
		}
	};

	return (
		<>
			{renderBreadCrumbs()}
			<Links
				tabs={tabs}
				url={url}
				isMaterialTabVisible={isMaterialTabVisible}
				isEncodingTabVisible={isEncodingTabVisible}
				isPaymentsTabVisible={isPaymentsTabVisible}
				isTrackingTabVisible={isTrackingTabVisible}
			/>
			<Routes
				tabs={tabs}
				path={path}
				url={url}
				isMaterialTabVisible={isMaterialTabVisible}
				isEncodingTabVisible={isEncodingTabVisible}
				isPaymentsTabVisible={isPaymentsTabVisible}
				isTrackingTabVisible={isTrackingTabVisible}
			/>
		</>
	);
};

export default Tabs;
