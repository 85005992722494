import React from 'react';
import i18n from 'i18next';

// Import tabs component
import SubscribersForm from '../../subscribers_form/SubscribersForm';
import SubscribersDevices from '../tabs/SubscribersDevices';
import SubscribersPackets from '../../subscribers_packets/SubscribersPackets';
import Payments from 'components/views/payments/Payments';
import Trackings from 'components/views/trackings/Trackings';
import SubscribersPacketsForm from '../../subscribers_packets/subscribers_packets_form/SubscribersPacketsForm';
import AssignedPackets from 'components/views/assigned_packets/AssignedPackets';

export const subscribersTabs = [
	{
		name: i18n.t('common:tabs.metadata'),
		path: 'metadata',
		disableOnCreate: false,
		component: <SubscribersForm />
	},
	{
		name: i18n.t('common:tabs.devices'),
		path: 'devices',
		disableOnCreate: true,
		component: <SubscribersDevices />
	},
	{
		name: i18n.t('subscribers:tabs.packets'),
		path: 'packets',
		disableOnCreate: true,
		component: <SubscribersPackets />
	},
	{
		name: i18n.t('common:tabs.assign_packet'),
		path: 'assign_main',
		disableOnCreate: true,
		component: <SubscribersPacketsForm />
	},
	{
		name: i18n.t('common:tabs.assigned_packets'),
		path: 'assigned_packets',
		disableOnCreate: true,
		component: <AssignedPackets />
	},
	{
		name: i18n.t('common:tabs.payments'),
		path: 'payments',
		disableOnCreate: true,
		component: <Payments />
	},
	{
		name: i18n.t('common:tabs.tracking'),
		path: 'tracking',
		disableOnCreate: true,
		component: <Trackings />
	}
];
