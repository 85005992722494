import React from 'react';
import { Field } from 'react-final-form';
import { Select } from 'antd';

// Import components
import {
	InputLabel,
	InputLabelButtonAsync,
	DatePickerLabel,
	TextareaLabel,
	TinyTextEditor,
	MultiSelectLabel,
	SelectLabel,
	CheckboxLabel,
	InputFileLabel,
	RangePickerLabel,
	MultiselectLabelCustomChange,
	ProviderFieldMultiSelectLabel
} from 'components/common/inputs';

export const renderFields = ({
	item,
	index,
	submitting,
	selectResources,
	onSearch
}) => {
	// some fields have isVisible flag. If it's undefined, create and set flag to true
	if (!item.hasOwnProperty('isVisible')) {
		item.isVisible = true;
	}
	switch (item.type) {
		case 'textarea':
			return (
				item.isVisible && (
					<Field
						key={`${item.name}-${index}`}
						disabled={submitting}
						name={item.name}
						type="textarea"
						placeholder={item.label}
						label={item.label}
						id={item.name}
						required={item.required}
						component={TextareaLabel}
						fieldInfo={item.fieldInfo}
						labelColumn={item.labelColumn}
						inputColumn={item.inputColumn}
						parse={(value) => value}
					/>
				)
			);

		case 'editor':
			return (
				item.isVisible && (
					<Field
						key={`${item.name}-${index}`}
						disabled={submitting}
						name={item.name}
						id={item.name}
						required={item.required}
						component={TinyTextEditor}
						parse={(value) => value}
					/>
				)
			);

		case 'date':
			return (
				item.isVisible && (
					<Field
						key={`${item.name}-${index}`}
						disabled={submitting}
						name={item.name}
						type="number"
						placeholder={item.label}
						label={item.label}
						id={item.name}
						required={item.required}
						showTime={item.showTime}
						component={DatePickerLabel}
						labelColumn={item.labelColumn}
						inputColumn={item.inputColumn}
						style={item.style}
					/>
				)
			);

		case 'range':
			return (
				item.isVisible && (
					<Field
						key={`${item.name}-${index}`}
						disabled={submitting}
						name={item.name}
						type="number"
						placeholder={item.placeholder}
						label={item.label}
						id={item.name}
						required={item.required}
						showTime={item.showTime}
						component={RangePickerLabel}
						labelColumn={item.labelColumn}
						inputColumn={item.inputColumn}
						style={item.style}
						rangeFormat={item.rangeFormat}
					/>
				)
			);

		// rember to provide array attached to the same key as item.name
		// for example item.name = title, then selectResources = {title: []}
		case 'multi-select':
			return (
				item.isVisible && (
					<Field
						key={`${item.name}-${index}`}
						disabled={submitting || item.disabled}
						name={item.name}
						type="multi-select"
						mode={item.mode}
						placeholder={item.label}
						label={item.label}
						id={item.name}
						required={item.required}
						component={MultiSelectLabel}
						onSearch={onSearch}
						showSearch={item.showSearch}
						labelColumn={item.labelColumn}
						inputColumn={item.inputColumn}
					>
						{selectResources[item.name]?.map((item, index) => (
							<Select.Option key={index} value={item}>
								{item}
							</Select.Option>
						))}
					</Field>
				)
			);

		// rember to provide array attached to the same key as item.name
		// for example item.name = title, then selectResources = {title: []}
		case 'select':
			return (
				item.isVisible && (
					<Field
						key={`${item.name}-${index}`}
						disabled={submitting || item.disabled}
						name={item.name}
						type="select"
						mode={item.mode}
						placeholder={item.label}
						label={item.label}
						id={item.name}
						required={item.required}
						fieldInfo={item.fieldInfo}
						component={SelectLabel}
						labelColumn={item.labelColumn}
						inputColumn={item.inputColumn}
					>
						{selectResources[item.name].map((item, index) => (
							<option key={index} value={item.value}>
								{item.name}
							</option>
						))}
					</Field>
				)
			);

		case 'checkbox':
			return (
				item.isVisible && (
					<Field
						key={`${item.name}-${index}`}
						disabled={submitting || item.disabled}
						name={item.name}
						type="checkbox"
						placeholder={item.label}
						label={item.label}
						id={item.name}
						required={item.required}
						component={CheckboxLabel}
						labelColumn={item.labelColumn}
						inputColumn={item.inputColumn}
					/>
				)
			);

		case 'file':
			return (
				item.isVisible && (
					<Field
						key={`${item.name}-${index}`}
						disabled={submitting}
						name={item.name}
						type="file"
						placeholder={item.label}
						label={item.label}
						id={item.name}
						required={item.required}
						component={InputFileLabel}
						labelColumn={item.labelColumn}
						inputColumn={item.inputColumn}
					/>
				)
			);

		case 'text-w-button-async':
			return (
				item.isVisible && (
					<Field
						key={`${item.name}-${index}`}
						disabled={submitting || item.disabled}
						name={item.name}
						type={item.type}
						placeholder={item.label}
						label={item.label}
						id={item.name}
						required={item.required}
						buttonText={item.buttonText}
						url={item.url}
						component={InputLabelButtonAsync}
					/>
				)
			);

		// multi-select to which you can provide select resources as array of objects with name and value keyts {name: foo, value: bar}
		case 'custom-names':
			return (
				item.isVisible && (
					<Field
						key={`${item.name}-${index}`}
						disabled={submitting}
						name={item.name}
						type="multi-select"
						mode={item.mode}
						placeholder={item.label}
						label={item.label}
						id={item.name}
						required={item.required}
						component={MultiSelectLabel}
						onSearch={onSearch}
						showSearch={item.showSearch}
						allowClear={item.allowClear || false}
						labelColumn={item.labelColumn}
						inputColumn={item.inputColumn}
						onChangeCallback={item.onChangeCallback}
						filterOption={item.filterOption || true}
						optionFilterProp={item.optionFilterProp || 'value'}
					>
						{selectResources[item.name].map((item, index) => (
							<Select.Option key={index} value={item.value}>
								{item.name}
							</Select.Option>
						))}
					</Field>
				)
			);

		// this option is barely used (provider_id field in series and seasons form)
		// however it is quite generic and can be used elsewhere
		case 'multi-select-on-change':
			return (
				item.isVisible && (
					<Field
						key={`${item.name}-${index}`}
						disabled={submitting || item.disabled}
						name={item.name}
						type="multi-select"
						mode={item.mode}
						placeholder={item.label}
						label={item.label}
						id={item.name}
						required={item.required}
						component={MultiselectLabelCustomChange}
						onSearch={onSearch}
						onChangeAction={item.onChangeAction}
						showSearch={item.showSearch}
						labelColumn={item.labelColumn}
						inputColumn={item.inputColumn}
						currentValues={item.currentValues}
						clearFieldName={item.clearFieldName}
					>
						{selectResources[item.name].map((item, index) => (
							<Select.Option key={index} value={item}>
								{item}
							</Select.Option>
						))}
					</Field>
				)
			);

		// --------------------- CUSTOM FIELDS -----------------------
		// USED WITH SPECIFIC FORMS IN MIND

		// custom providers field for season and series forms
		case 'provider-field-multi-select':
			return (
				item.isVisible && (
					<Field
						key={`${item.name}-${index}`}
						disabled={submitting || item.disabled}
						name={item.name}
						type="multi-select"
						mode={item.mode}
						placeholder={item.label}
						label={item.label}
						id={item.name}
						required={item.required}
						component={ProviderFieldMultiSelectLabel}
						onSearch={onSearch}
						onChangeAction={item.onChangeAction}
						showSearch={item.showSearch}
						labelColumn={item.labelColumn}
						inputColumn={item.inputColumn}
					/>
				)
			);

		case 'multi-select-no-resources':
			return (
				item.isVisible && (
					<Field
						key={`${item.name}-${index}`}
						disabled={submitting || item.disabled}
						name={item.name}
						type="multi-select"
						mode={item.mode}
						placeholder={item.label}
						label={item.label}
						id={item.name}
						required={item.required}
						component={MultiSelectLabel}
						onSearch={onSearch}
						showSearch={item.showSearch}
						labelColumn={item.labelColumn}
						inputColumn={item.inputColumn}
						dropdownStyle={{ display: 'none' }}
					/>
				)
			);

		case 'number':
			return (
				item.isVisible && (
					<Field
						key={`${item.name}-${index}`}
						disabled={submitting || item.disabled}
						name={item.name}
						type={item.type}
						placeholder={item.label}
						label={item.label}
						id={item.name}
						required={item.required}
						component={InputLabel}
						labelColumn={item.labelColumn}
						inputColumn={item.inputColumn}
						fieldInfo={item.fieldInfo}
						parse={(value) => value}
						min={item.min ?? 0}
						step={item.step}
					/>
				)
			);

		default:
			return (
				item.isVisible && (
					<Field
						key={`${item.name}-${index}`}
						disabled={submitting || item.disabled}
						name={item.name}
						type={item.type}
						placeholder={item.label}
						label={item.label}
						id={item.name}
						required={item.required}
						component={InputLabel}
						labelColumn={item.labelColumn}
						inputColumn={item.inputColumn}
						fieldInfo={item.fieldInfo}
						parse={(value) => value}
					/>
				)
			);
	}
};
