import React from 'react';
import { Form } from 'react-final-form';
import { useSelector } from 'react-redux';
import { useLocation, useParams } from 'react-router-dom';

// Import translation
import { useTranslation } from 'react-i18next';

// Validate
import validate from './validation';

// Import components
import { FormTemplate } from 'components/common/templates';
import { FormButtons } from 'components/common/buttons';

// Import fields
import Fields from './fields/Fields';

// Import default values
import { defaultFieldsValues } from './fields/input_fields';

// Import hooks
import useVodSegments from '../useVodSegments';

// Import variables
import { NEXT_EPISODE } from 'components/helpers/variables';
import { NEXT_EPISODE_DURATION } from './helpers';

const VodSegmentsForm = () => {
	const { t } = useTranslation();

	const { id, vodId, segmentId } = useParams();
	const { pathname } = useLocation();

	const isEdit = pathname.match(/edit/i);

	const { pathname: prevPath } = useSelector(
		({ previous_location }) => previous_location
	);

	const redirectPath = prevPath.slice(7);

	const {
		data,
		submitVodSegment,
		editVodSegment,
		segmentsTypes
	} = useVodSegments({
		vodId,
		videoId: id
	});

	const selectResources = { type: segmentsTypes };

	const editedIndex = isEdit && data?.findIndex(({ id }) => id === +segmentId);

	const editedValues = {
		...data?.[editedIndex],
		start_time_in_seconds: data?.[
			editedIndex
		]?.start_time_in_seconds.toString(),
		end_time_in_seconds: data?.[editedIndex]?.end_time_in_seconds.toString()
	};

	const initialValues = isEdit ? editedValues : defaultFieldsValues;

	const handleOnSubmit = () => async (values) => {
		const resources = {
			...values,
			end_time_in_seconds:
				values.type === NEXT_EPISODE
					? `${parseInt(values.start_time_in_seconds) + NEXT_EPISODE_DURATION}`
					: values.end_time_in_seconds,
			video_id: id
		};

		if (isEdit) {
			await editVodSegment.mutateAsync({
				vodId: vodId,
				videoId: id,
				segmentId: values.id,
				resources
			});
		} else {
			await submitVodSegment.mutateAsync({
				vodId: vodId,
				videoId: id,
				resources
			});
		}
	};

	return (
		<FormTemplate>
			{({ isEdit, itemID }) => (
				<Form
					initialValues={{ ...initialValues }}
					validate={validate}
					onSubmit={handleOnSubmit({ isEdit, itemID })}
					render={({ handleSubmit, submitting, values: { type } }) => (
						<form onSubmit={handleSubmit}>
							<Fields
								submitting={submitting}
								selectResources={selectResources}
								type={type}
							/>

							<FormButtons
								isButtonDisabled={submitting}
								path={redirectPath}
								buttonText={t('common:buttons.submit')}
							/>
						</form>
					)}
				/>
			)}
		</FormTemplate>
	);
};

export default VodSegmentsForm;
