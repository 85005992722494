import React from 'react';

// Import translation
import i18n from 'i18next';

// Import components
import EditCell from './EditCell';
import DeleteCell from './DeleteCell';

// Import variables
import { NEXT_EPISODE } from 'components/helpers/variables';

export const createColumns = (handleSelectItem, canEditVideo) => [
	{
		title: i18n.t('vod_segment:table_columns.start_time_in_seconds'),
		dataIndex: 'start_time_in_seconds',
		key: 'start_time_in_seconds'
	},
	{
		title: i18n.t('vod_segment:table_columns.end_time_in_seconds'),
		dataIndex: 'end_time_in_seconds',
		key: 'end_time_in_seconds',
		render: (text, { type, end_time_in_seconds }) =>
			type !== NEXT_EPISODE && <span>{end_time_in_seconds}</span>
	},
	{
		title: i18n.t('vod_segment:table_columns.type'),
		dataIndex: 'type',
		key: 'type'
	},
	{
		title: i18n.t('common:table_columns.edit'),
		dataIndex: 'delete',
		key: 'delete',
		width: 70,
		align: 'center',
		render: (text, { id, video_id }) =>
			canEditVideo && <EditCell videoId={video_id} segmentId={id} />
	},
	{
		title: i18n.t('common:table_columns.delete'),
		dataIndex: 'delete',
		key: 'delete',
		width: 70,
		align: 'center',
		render: (text, { id }) =>
			canEditVideo && <DeleteCell handleSelectItem={handleSelectItem} id={id} />
	}
];
