import * as types from 'store/actions/types';
import produce from 'immer';

const INITIAL_STATE = {
	authenticate: null,
	errorMessage: false,
	isAuthenticated: true,
	signOut: {
		loading: false,
		error: false
	},
	signOutRedirect: false,
	resetPassword: {
		checkHash: {
			isHashValid: false,
			loading: true
		},
		sendResetEmail: {
			loading: false,
			isSuccess: false,
			error: false
		},
		setNewPassword: {
			isSuccess: false,
			loading: false
		}
	}
};

export default (state = INITIAL_STATE, action) =>
	produce(state, (draft) => {
		switch (action.type) {
			// ------------ Authenticate user ------------
			case types.AUTH_USER_SUCCESS:
				draft.authenticate = action.payload;
				draft.errorMessage = false;
				draft.signOutRedirect = false;
				draft.isAuthenticated = true;
				return;

			case types.AUTH_USER_ERROR:
				draft.errorMessage = action.payload;
				return;

			// ------------ Sign out ------------
			case types.SIGN_OUT_LOADING:
				draft.signOut.loading = action.payload;
				return;

			case types.SIGN_OUT_SUCCESS:
				draft.signOut.loading = false;
				draft.authenticate = action.payload;
				draft.isAuthenticated = false;
				return;

			case types.SIGN_OUT_ERROR:
				draft.signOut.loading = false;
				draft.signOut.error = action.payload;
				return;

			// ------------ Send reset mail ------------
			case types.SEND_RESET_EMAIL_LOADING:
				draft.resetPassword.sendResetEmail.loading = true;
				draft.resetPassword.sendResetEmail.error = false;
				draft.resetPassword.sendResetEmail.isSuccess = false;
				break;
			case types.SEND_RESET_EMAIL_SUCCESS:
				draft.resetPassword.sendResetEmail.loading = false;
				draft.resetPassword.sendResetEmail.error = false;
				draft.resetPassword.sendResetEmail.isSuccess = true;
				break;
			case types.SEND_RESET_EMAIL_ERROR:
				draft.resetPassword.sendResetEmail.loading = false;
				draft.resetPassword.sendResetEmail.error = true;
				draft.resetPassword.sendResetEmail.isSuccess = false;
				break;

			// ------------ Check reset password hash ------------
			case types.CHECK_RESET_PASSWORD_HASH_LOADING:
				draft.resetPassword.checkHash.loading = true;
				draft.resetPassword.checkHash.isHashValid = false;
				break;
			case types.CHECK_RESET_PASSWORD_HASH_SUCCESS:
				draft.resetPassword.checkHash.loading = false;
				draft.resetPassword.checkHash.isHashValid = true;
				break;
			case types.CHECK_RESET_PASSWORD_HASH_ERROR:
				draft.resetPassword.checkHash.loading = false;
				draft.resetPassword.checkHash.isHashValid = false;
				break;

			// ------------ Send new passwords ------------
			case types.SEND_NEW_PASSWORDS_LOADING:
				draft.resetPassword.setNewPassword.loading = true;
				draft.resetPassword.setNewPassword.isSuccess = false;
				break;
			case types.SEND_NEW_PASSWORDS_SUCCESS:
				draft.resetPassword.setNewPassword.loading = false;
				draft.resetPassword.setNewPassword.isSuccess = true;
				break;
			case types.SEND_NEW_PASSWORDS_ERROR:
				draft.resetPassword.setNewPassword.loading = false;
				draft.resetPassword.setNewPassword.isSuccess = false;
				break;

			default:
				return state;
		}
	});
