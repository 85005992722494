import React from 'react';
import PropTypes from 'prop-types';
import { compose } from 'redux';
import { connect } from 'react-redux';
import { withRouter } from 'react-router-dom';
import { fetchSubscriberPackets } from 'store/actions';

// Import columns
import {
	createProvisioningColumns,
	createSubscriptionsColumns
} from './subscribers_packets_columns';

// Import translation
import { useTranslation } from 'react-i18next';

// Import logic
import useSubscribersPackets from './useSubscribersPackets';

// Import components
import { TableTemplate } from 'components/common/templates';
import { Button } from 'reactstrap';

// Import helpers
import { removeSubscriberPacket } from 'components/helpers/packets';

const SubscribersPackets = ({
	error,
	fetchSubscriberPackets,
	resources,
	match: {
		params: { id }
	}
}) => {
	const { t } = useTranslation();

	const {
		clearPermissions,
		showClearPermissionsButton
	} = useSubscribersPackets({ id });

	const filteredSubscribersResources = {
		...resources,
		data: resources.data.filter((item) => item.subtype === 'main')
	};
	const filteredProvisioningsResources = {
		...resources,
		data: resources.data.filter((item) => item.subtype === 'provision')
	};

	const createColumnsSubscriptionsVersions = () =>
		createSubscriptionsColumns({
			subscriberId: id
		});

	return (
		<>
			{showClearPermissionsButton && (
				<Button
					style={{ marginLeft: 'auto', display: 'block' }}
					onClick={clearPermissions}
				>
					{t('subscribers:buttons.refresh_permissions')}
				</Button>
			)}
			{/* SUBSCRIPTIONS (MAIN) TABLE */}
			<TableTemplate
				itemId={id}
				error={error}
				resources={filteredSubscribersResources}
				columns={createColumnsSubscriptionsVersions}
				deleteItemAction={removeSubscriberPacket}
				fetchResourcesAction={fetchSubscriberPackets}
			/>

			{/* PROVISIONING TABLE */}
			<TableTemplate
				itemId={id}
				error={error}
				resources={filteredProvisioningsResources}
				columns={createProvisioningColumns}
				fetchResourcesAction={fetchSubscriberPackets}
			/>
		</>
	);
};

SubscribersPackets.propTypes = {
	error: PropTypes.bool,
	resources: PropTypes.object.isRequired,
	subscriberData: PropTypes.object.isRequired,
	fetchSubscriberPackets: PropTypes.func.isRequired,
	fetchSubscriberSubscriptions: PropTypes.func.isRequired,
	match: PropTypes.shape({
		params: PropTypes.shape({ id: PropTypes.string.isRequired })
	})
};

const mapStateToProps = ({ subscribers: { packets, form } }) => ({
	error: packets.error,
	resources: packets.provisioning,
	subscriberData: form.edit
});

export default compose(
	connect(mapStateToProps, {
		fetchSubscriberPackets
	}),
	withRouter
)(SubscribersPackets);
