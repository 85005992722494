import { combineReducers } from 'redux';

// Import reducers
import table from './events_live_panel_reducer';
import form from './events_live_form_reducer';
import availability from './events_live_availabilty/events_live_availability_reducer';
import materials from './events_live_materials_reducer';
import files from './events_live_files_reducer';

export default combineReducers({
	table,
	form,
	availability,
	materials,
	files
});
