import services from 'services/services';
import * as types from '../../types';

// Import helpers
import {
	checkFetchErrors,
	queryParams
} from '../../helpers_actions/convert_helpers';

// Import variables
import { TABLE_LENGTH } from 'components/helpers/table';

// ******************** FETCH PACKETS AVAILABILITY - TABLE DATA ********************
export const fetchPacketAvailability = (options, itemId) => async (
	dispatch,
	getState
) => {
	try {
		// Dispatch a loading action
		dispatch({
			type: types.FETCH_PACKET_AVAILABILITY_TABLE_LOADING,
			payload: true
		});

		const {
			packets: {
				availability: {
					table: {
						columns,
						options: { startIndex: startTableIndex, page }
					}
				}
			}
		} = getState();

		const startIndex =
			options.page === page ? startTableIndex : options.startIndex;

		const { data } = await services.get(
			`/timetables/availability/listByProduct/packet/${itemId}?${queryParams(
				options,
				TABLE_LENGTH,
				columns,
				startIndex
			)}`
		);

		// Calculate pages
		const pages = Math.ceil(data.recordsFiltered / TABLE_LENGTH);

		// // Dispatch an action with data
		dispatch({
			type: types.FETCH_PACKET_AVAILABILITY_TABLE_SUCCESS,
			payload: {
				...data,
				pages,
				page: options.page,
				startIndex
			}
		});
	} catch (error) {
		dispatch({
			type: types.FETCH_PACKET_AVAILABILITY_TABLE_ERROR,
			payload: checkFetchErrors(error)
		});
	}
};

// ******************** DELETE PACKET AVAILABILITY ********************
export const deletePacketsAvailability = (id) => async (dispatch) => {
	try {
		// Dispatch a loading action
		dispatch({
			type: types.DELETE_PACKET_AVAILABILITY_LOADING,
			payload: true
		});

		await services.delete(`/timetables/availability/${id}`);
		// Dispatch an action with data
		dispatch({
			type: types.DELETE_PACKET_AVAILABILITY_SUCCESS,
			payload: true
		});
	} catch (error) {
		// Dispatch an action with error set to true
		dispatch({
			type: types.DELETE_PACKET_AVAILABILITY_ERROR,
			payload: checkFetchErrors(error)
		});
	}
};
