// Import translation
import i18n from 'i18next';

export default ({ title, description, short_description, order, url }) => {
	const errors = {};

	// ------------------ Title ------------------
	if (!title) {
		errors.title = i18n.t('main:validation.title');
	} else if (title.length < 2 || title.length > 200) {
		errors.title = i18n.t('main:validation.title_length');
	}
	// ------------------ Description ------------------
	if (!description) {
		errors.description = i18n.t('main:validation.description');
	}
	// ------------------ Description short ------------------
	if (!short_description) {
		errors.short_description = i18n.t('main:validation.short_description');
	}
	// ------------------ Order ------------------
	if (!order) {
		errors.order = i18n.t('main:validation.order');
	}
	// ------------------ Url ------------------
	if (
		url &&
		!/^(?:http(s)?:\/\/)?[\w.-]+(?:\.[\w.-]+)+[\w\-._~:/?#[\]@!$&'()*+,;=.]+$/.test(
			url
		)
	) {
		errors.url = i18n.t('main:validation.url_error');
	}
	return errors;
};
