import React from 'react';
import PropTypes from 'prop-types';
import { Col, FormGroup, Label } from 'reactstrap';
import { DatePicker } from 'antd';

const { RangePicker } = DatePicker;

const RangePickerLabel = ({
	input,
	id,
	label,
	placeholder,
	labelColumn = 2,
	inputColumn = 10,
	showTime = false,
	format = 'YYYY-MM-DD',
	rangeFormat = null,
	meta: { touched, error, submitError },
	...inputProps
}) => {
	return (
		<FormGroup row className={`${touched && error ? 'has-error' : ''} `}>
			<Label
				for={id}
				sm={labelColumn}
				className="col-form-label col-form-label-lg"
			>
				{label}
			</Label>
			<Col sm={inputColumn}>
				<RangePicker
					{...input}
					{...inputProps}
					value={input.value || null}
					id={id}
					placeholder={placeholder}
					showTime={showTime}
					format={rangeFormat || format}
				/>
				{touched && (error || submitError) && (
					<div>
						<span
							data-testid={`input-error-${input.name}`}
							className="input-error"
						>
							{error || submitError}
						</span>
					</div>
				)}
			</Col>
		</FormGroup>
	);
};

RangePickerLabel.propTypes = {
	input: PropTypes.object.isRequired,
	label: PropTypes.string.isRequired,
	id: PropTypes.string.isRequired,
	placeholder: PropTypes.array.isRequired,
	inputColumn: PropTypes.number,
	labelColumn: PropTypes.number,
	showTime: PropTypes.oneOfType([PropTypes.bool, PropTypes.object]),
	format: PropTypes.string,
	rangeFormat: PropTypes.string,
	meta: PropTypes.object
};

export default RangePickerLabel;
