// -------------------- CONTENT_TRANSLATIONS FORM --------------------
export const FETCH_CONTENT_TRANSLATIONS_LOADING =
	'FETCH_CONTENT_TRANSLATIONS_LOADING';
export const FETCH_CONTENT_TRANSLATIONS_SUCCESS =
	'FETCH_CONTENT_TRANSLATIONS_SUCCESS';
export const FETCH_CONTENT_TRANSLATIONS_ERROR =
	'FETCH_CONTENT_TRANSLATIONS_ERROR';
// -------------------- FETCH LANGUAGE LIST --------------------
export const FETCH_CONTENT_TRANSLATIONS_LANGUAGES_LOADING =
	'FETCH_CONTENT_TRANSLATIONS_LANGUAGES_LOADING';
export const FETCH_CONTENT_TRANSLATIONS_LANGUAGES_SUCCESS =
	'FETCH_CONTENT_TRANSLATIONS_LANGUAGES_SUCCESS';
export const FETCH_CONTENT_TRANSLATIONS_LANGUAGES_ERROR =
	'FETCH_CONTENT_TRANSLATIONS_LANGUAGES_ERROR';
