import React, { useState } from 'react';
import PropTypes from 'prop-types';
import { Select } from 'antd';

const { Option } = Select;

/**
 * Function component that select vod subtype or vod status and dispatches action to redux store
 * with selected value
 * @param {array} options - values passed to select component
 * @param {string} label - label next to select component
 * @param {func} action - action that will be dispatched in component
 * @param {string} defaultValue - default selected value
 * @param {number} width - width of select
 */
const FilterSelect = ({ options, label, action, defaultValue, width }) => {
	const [value, setValue] = useState(defaultValue);

	// dispatch action
	const handleChange = (value) => {
		setValue(value);
		action(value);
	};

	return (
		<>
			<span className="m-r-sm">{label}</span>
			<Select
				onChange={handleChange}
				className="m-r-sm"
				style={{ width }}
				value={value}
			>
				{options.map(({ name, value }, index) => (
					<Option key={index} value={value}>
						{name}
					</Option>
				))}
			</Select>
		</>
	);
};

FilterSelect.defaultProps = {
	width: 200
};

FilterSelect.propTypes = {
	options: PropTypes.array.isRequired,
	label: PropTypes.string.isRequired,
	action: PropTypes.func.isRequired,
	defaultValue: PropTypes.string.isRequired,
	width: PropTypes.number
};

export default FilterSelect;
