// ******************** VOD DATA TABLE ********************
export const FETCH_VOD_LOADING = 'FETCH_VOD_LOADING';
export const FETCH_VOD_SUCCESS = 'FETCH_VOD_SUCCESS';
export const FETCH_VOD_ERROR = 'FETCH_VOD_ERROR';
// -------------------- VOD DATA TABLE - DELETE VOD SERIES --------------------
export const DELETE_VOD_SERIES_LOADING = 'DELETE_VOD_SERIES_LOADING';
export const DELETE_VOD_SERIES_SUCCESS = 'DELETE_VOD_SERIES_SUCCESS';
export const DELETE_VOD_SERIES_ERROR = 'DELETE_VOD_SERIES_ERROR';
// -------------------- VOD DATA TABLE - DELETE VOD SEASON --------------------
export const DELETE_VOD_SEASON_LOADING = 'DELETE_VOD_SEASON_LOADING';
export const DELETE_VOD_SEASON_SUCCESS = 'DELETE_VOD_SEASON_SUCCESS';
export const DELETE_VOD_SEASON_ERROR = 'DELETE_VOD_SEASON_ERROR';
// -------------------- VOD DATA TABLE - DELETE VOD --------------------
export const DELETE_VOD_LOADING = 'DELETE_VOD_LOADING';
export const DELETE_VOD_SUCCESS = 'DELETE_VOD_SUCCESS';
export const DELETE_VOD_ERROR = 'DELETE_VOD_ERROR';

// ******************** SET SEARCH QUERY ********************
export const SET_VOD_SEARCH_QUERY = 'SET_VOD_SEARCH_QUERY';

// ******************** SAVE SORTING OPTIONS  ********************
export const SAVE_VOD_TABLE_SORTING = 'SAVE_VOD_TABLE_SORTING';

// ******************** SET VOD SUBTYPE ********************
export const SET_VOD_SUBTYPE = 'SET_VOD_SUBTYPE';

// ******************** SET VOD STATUS ********************
export const SET_VOD_STATUS = 'SET_VOD_STATUS';

// ******************** FILES ********************
// -------------------- COVER --------------------
export const FETCH_VOD_COVER_FILES_LOADING = 'FETCH_VOD_COVER_FILES_LOADING';
export const FETCH_VOD_COVER_FILES_SUCCESS = 'FETCH_VOD_COVER_FILES_SUCCESS';
export const FETCH_VOD_COVER_FILES_ERROR = 'FETCH_VOD_COVER_FILES_ERROR';

// -------------------- COVER SMALL ------------------
export const FETCH_VOD_COVER_SMALL_FILES_LOADING =
	'FETCH_VOD_COVER_SMALL_FILES_LOADING';
export const FETCH_VOD_COVER_SMALL_FILES_SUCCESS =
	'FETCH_VOD_COVER_SMALL_FILES_SUCCESS';
export const FETCH_VOD_COVER_SMALL_FILES_ERROR =
	'FETCH_VOD_COVER_SMALL_FILES_ERROR';

// -------------------- POSTER --------------------
export const FETCH_VOD_POSTER_FILES_LOADING = 'FETCH_VOD_POSTER_FILES_LOADING';
export const FETCH_VOD_POSTER_FILES_SUCCESS = 'FETCH_VOD_POSTER_FILES_SUCCESS';
export const FETCH_VOD_POSTER_FILES_ERROR = 'FETCH_VOD_POSTER_FILES_ERROR';

// -------------------- GALLERY --------------------
export const FETCH_VOD_GALLERY_FILES_LOADING =
	'FETCH_VOD_GALLERY_FILES_LOADING';
export const FETCH_VOD_GALLERY_FILES_SUCCESS =
	'FETCH_VOD_GALLERY_FILES_SUCCESS';
export const FETCH_VOD_GALLERY_FILES_ERROR = 'FETCH_VOD_GALLERY_FILES_ERROR';

// -------------------- TITLE --------------------
export const FETCH_VOD_TITLE_FILES_LOADING = 'FETCH_VOD_TITLE_FILES_LOADING';
export const FETCH_VOD_TITLE_FILES_SUCCESS = 'FETCH_VOD_TITLE_FILES_SUCCESS';
export const FETCH_VOD_TITLE_FILES_ERROR = 'FETCH_VOD_TITLE_FILES_ERROR';
// ******************** VOD DETAILS ********************
export const FETCH_VOD_DETAILS_LOADING = 'FETCH_VOD_DETAILS_LOADING';
export const FETCH_VOD_DETAILS_SUCCESS = 'FETCH_VOD_DETAILS_SUCCESS';
export const FETCH_VOD_DETAILS_ERROR = 'FETCH_VOD_DETAILS_ERROR';

// ******************** MATERIALS ********************
// -------------------- FETCH --------------------
export const FETCH_VOD_MATERIALS_LOADING = 'FETCH_VOD_MATERIALS_LOADING';
export const FETCH_VOD_MATERIALS_SUCCESS = 'FETCH_VOD_MATERIALS_SUCCESS';
export const FETCH_VOD_MATERIALS_ERROR = 'FETCH_VOD_MATERIALS_ERROR';
// -------------------- UPDATE --------------------
export const UPDATE_VOD_MATERIALS = 'UPDATE_VOD_MATERIALS';
export const UPDATE_VOD_MATERIALS_ERROR = 'UPDATE_VOD_MATERIALS_ERROR';
// -------------------- DELETE --------------------
export const DELETE_VOD_MATERIALS = 'DELETE_VOD_MATERIALS';
export const DELETE_VOD_MATERIALS_ERROR = 'DELETE_VOD_MATERIALS_ERROR';
// -------------------- ADD VIDEO TO VOD --------------------
export const ADD_VIDEO_TO_VOD_LOADING = 'ADD_VIDEO_TO_VOD_LOADING';
export const ADD_VIDEO_TO_VOD_SUCCESS = 'ADD_VIDEO_TO_VOD_SUCCESS';
export const ADD_VIDEO_TO_VOD_ERROR = 'ADD_VIDEO_TO_VOD_ERROR';

// ******************** UPLOAD MATERIALS ********************
// -------------------- GET UPLOAD MATERIALS URL --------------------
export const GET_UPLOAD_MATERIALS_URL_LOADING =
	'GET_UPLOAD_MATERIALS_URL_LOADING';
export const GET_UPLOAD_MATERIALS_URL_SUCCESS =
	'GET_UPLOAD_MATERIALS_URL_SUCCESS';
export const GET_UPLOAD_MATERIALS_URL_ERROR = 'GET_UPLOAD_MATERIALS_URL_ERROR';
// -------------------- GET UPLOADED MATERIALS --------------------
export const GET_UPLOADED_MATERIALS_LOADING = 'GET_UPLOADED_MATERIALS_LOADING';
export const GET_UPLOADED_MATERIALS_SUCCESS = 'GET_UPLOADED_MATERIALS_SUCCESS';
export const GET_UPLOADED_MATERIALS_ERROR = 'GET_UPLOADED_MATERIALS_ERROR';
// -------------------- CLEAR UPLOADED MATERIALS TABLE --------------------
export const CLEAR_UPLOAD_MATERIALS_TABLE = 'CLEAR_UPLOAD_MATERIALS_TABLE';

// ******************** SERIES ********************
// -------------------- SERIES FORM --------------------
export const FETCH_SERIES_LOADING = 'FETCH_SERIES_LOADING';
export const FETCH_SERIES_SUCCESS = 'FETCH_SERIES_SUCCESS';
export const FETCH_SERIES_ERROR = 'FETCH_SERIES_ERROR';

// ******************** SEASONS ********************
// -------------------- SEASONS DATA TABLE --------------------
export const FETCH_SEASONS_LOADING = 'FETCH_SEASONS_LOADING';
export const FETCH_SEASONS_SUCCESS = 'FETCH_SEASONS_SUCCESS';
export const FETCH_SEASONS_ERROR = 'FETCH_SEASONS_ERROR';

// -------------------- DELETE --------------------
export const DELETE_SEASON_LOADING = 'DELETE_SEASON_LOADING';
export const DELETE_SEASON_SUCCESS = 'DELETE_SEASON_SUCCESS';
export const DELETE_SEASON_ERROR = 'DELETE_SEASON_ERROR';

// -------------------- SEASONS SET QUERY --------------------
export const SET_SEASONS_SEARCH_QUERY = 'SET_SEASONS_SEARCH_QUERY';

// -------------------- SEASONS SAVE SORTING OPTIONS  --------------------
export const SAVE_SEASONS_TABLE_SORTING = 'SAVE_SEASONS_TABLE_SORTING';

// -------------------- SEASON FORM --------------------
export const FETCH_SEASON_LOADING = 'FETCH_SEASON_LOADING';
export const FETCH_SEASON_SUCCESS = 'FETCH_SEASON_SUCCESS';
export const FETCH_SEASON_ERROR = 'FETCH_SEASON_ERROR';

// ******************** EPISODES ********************
// -------------------- EPISODES DATA TABLE --------------------
export const FETCH_EPISODES_LOADING = 'FETCH_EPISODES_LOADING';
export const FETCH_EPISODES_SUCCESS = 'FETCH_EPISODES_SUCCESS';
export const FETCH_EPISODES_ERROR = 'FETCH_EPISODES_ERROR';

// -------------------- DETACH EPISODES --------------------
export const DETACH_EPISODE_LOADING = 'DETACH_EPISODE_LOADING';
export const DETACH_EPISODE_SUCCESS = 'DETACH_EPISODE_SUCCESS';
export const DETACH_EPISODE_ERROR = 'DETACH_EPISODE_ERROR';

// -------------------- EPISODE FORM --------------------
export const FETCH_EPISODE_LOADING = 'FETCH_EPISODE_LOADING';
export const FETCH_EPISODE_SUCCESS = 'FETCH_EPISODE_SUCCESS';
export const FETCH_EPISODE_ERROR = 'FETCH_EPISODE_ERROR';

// -------------------- EPISODES SET QUERY --------------------
export const SET_EPISODES_SEARCH_QUERY = 'SET_EPISODES_SEARCH_QUERY';

// -------------------- EPISODES SAVE SORTING OPTIONS  --------------------
export const SAVE_EPISODES_TABLE_SORTING = 'SAVE_EPISODES_TABLE_SORTING';

// -------------------- SET PARENT SEASON ID --------------------
export const SET_PARENT_SEASON_ID = 'SET_PARENT_SEASON_ID';

// ******************** AVAILABILITY ********************
// -------------------- TABLE --------------------
// -------------------- FETCH --------------------
export const FETCH_VOD_AVAILABILITY_TABLE_LOADING =
	'FETCH_VOD_AVAILABILITY_TABLE_LOADING';
export const FETCH_VOD_AVAILABILITY_TABLE_SUCCESS =
	'FETCH_VOD_AVAILABILITY_TABLE_SUCCESS';
export const FETCH_VOD_AVAILABILITY_TABLE_ERROR =
	'FETCH_VOD_AVAILABILITY_TABLE_ERROR';

// -------------------- DELETE --------------------
export const DELETE_VOD_AVAILABILITY_LOADING =
	'DELETE_VOD_AVAILABILITY_LOADING';
export const DELETE_VOD_AVAILABILITY_SUCCESS =
	'DELETE_VOD_AVAILABILITY_SUCCESS';
export const DELETE_VOD_AVAILABILITY_ERROR = 'DELETE_VOD_AVAILABILITY_ERROR';

// -------------------- FORM --------------------
// -------------------- FETCH --------------------
export const FETCH_VOD_AVAILABILITY_FORM_LOADING =
	'FETCH_VOD_AVAILABILITY_FORM_LOADING';
export const FETCH_VOD_AVAILABILITY_FORM_SUCCESS =
	'FETCH_VOD_AVAILABILITY_FORM_SUCCESS';
export const FETCH_VOD_AVAILABILITY_FORM_ERROR =
	'FETCH_VOD_AVAILABILITY_FORM_ERROR';

// ******************** PRICES ********************
// -------------------- FETCH --------------------
export const FETCH_VOD_PRICES_LOADING = 'FETCH_VOD_PRICES_LOADING';
export const FETCH_VOD_PRICES_SUCCESS = 'FETCH_VOD_PRICES_SUCCESS';
export const FETCH_VOD_PRICES_ERROR = 'FETCH_VOD_PRICES_ERROR';

// -------------------- DELETE --------------------
export const DELETE_VOD_PRICE_LOADING = 'DELETE_VOD_PRICE_LOADING';
export const DELETE_VOD_PRICE_SUCCESS = 'DELETE_VOD_PRICE_SUCCESS';
export const DELETE_VOD_PRICE_ERROR = 'DELETE_VOD_PRICE_ERROR';

// ******************** SUBTITLES ********************
// -------------------- FETCH --------------------
export const FETCH_VOD_SUBTITLES_LOADING = 'FETCH_VOD_SUBTITLES_LOADING';
export const FETCH_VOD_SUBTITLES_SUCCESS = 'FETCH_VOD_SUBTITLES_SUCCESS';
export const FETCH_VOD_SUBTITLES_ERROR = 'FETCH_VOD_SUBTITLES_ERROR';

// -------------------- DELETE --------------------
export const DELETE_VOD_SUBTITLE_LOADING = 'DELETE_VOD_SUBTITLE_LOADING';
export const DELETE_VOD_SUBTITLE_SUCCESS = 'DELETE_VOD_SUBTITLE_SUCCESS';
export const DELETE_VOD_SUBTITLE_ERROR = 'DELETE_VOD_SUBTITLE_ERROR';

// -------------------- SET SEARCH QUERY --------------------
export const SET_VOD_SUBTITLES_SEARCH_QUERY = 'SET_VOD_SUBTITLES_SEARCH_QUERY';

// ******************** TYPE ********************
// -------------------- SET TYPE --------------------
export const SET_VOD_TYPE = 'SET_VOD_TYPE';

// ******************** ENCODING ********************
// -------------------- FETCH VOD ENCODING --------------------
export const FETCH_VOD_ENCODING_LOADING = 'FETCH_VOD_ENCODING_LOADING';
export const FETCH_VOD_ENCODING_SUCCESS = 'FETCH_VOD_ENCODING_SUCCESS';
export const FETCH_VOD_ENCODING_ERROR = 'FETCH_VOD_ENCODING_ERROR';

// ******************** PACKETS ASSIGNED ********************
// -------------------- FETCH PACKETS ASSIGNED - TABLE DATA --------------------
export const FETCH_VOD_PACKETS_ASSIGNED_LOADING =
	'FETCH_VOD_PACKETS_ASSIGNED_LOADING';
export const FETCH_VOD_PACKETS_ASSIGNED_SUCCESS =
	'FETCH_VOD_PACKETS_ASSIGNED_SUCCESS';
export const FETCH_VOD_PACKETS_ASSIGNED_ERROR =
	'FETCH_VOD_PACKETS_ASSIGNED_ERROR';
export const FETCH_VOD_PACKETS_ASSIGNED_PAYMENT_MODEL_TYPE_ERROR =
	'FETCH_VOD_PACKETS_ASSIGNED_PAYMENT_MODEL_TYPE_ERROR';

// -------------------- SET SEARCH QUERY --------------------
export const SET_VOD_PACKETS_ASSIGNED_SEARCH_QUERY =
	'SET_VOD_PACKETS_ASSIGNED_SEARCH_QUERY';
