import React from 'react';
import PropTypes from 'prop-types';

// Import hooks
import useAssetStatus from '../asset_status/useAssetStatus';

const TranscodeButton = ({ transcodeAsset, record, videoType }) => {
	const { clearUploadedMaterialsTable } = useAssetStatus(record);

	// send transcode request and update asset status
	const handleTranscode = async () => {
		await transcodeAsset(record.id, videoType);
		clearUploadedMaterialsTable();
	};

	return (
		<>
			<button onClick={handleTranscode} className="btn btn-xs btn-primary">
				<i className="fa fa-exchange" />
			</button>
		</>
	);
};

TranscodeButton.propTypes = {
	transcodeAsset: PropTypes.func.isRequired,
	record: PropTypes.object.isRequired,
	videoType: PropTypes.string.isRequired
};

export default TranscodeButton;
