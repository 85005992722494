import React from 'react';
import i18n from 'i18next';

// Import components
import SeasonsForm from '../../seasons_form/SeasonForm';
import Episodes from '../../../vod_episodes/episodes_table/Episodes';
import VodPrices from 'components/views/vod/vod_prices/VodPrices';
import VodAvailability from 'components/views/vod/vod_availability/VodAvailability';
import Payments from 'components/views/payments/Payments';
import SeasonsImages from 'components/views/vod/vod_file/SeasonsImages';
import VodPacketsAssigned from 'components/views/vod/vod_packets_assigned/VodPacketsAssigned';
import VodMaterials from 'components/views/vod/common/materials/VodMaterials';
import ProductGeoblockForm from 'components/views/geoblock_groups/ProductGeoblockForm/ProductGeoblockForm';

// Import utilities
import { generateContentTranslationsTabs } from 'components/utilities/content_translation/tabs/content_translations_tabs';

// import content translation fields
import { COMMON_VOD_INPUT_FIELDS_DATA } from 'components/utilities/content_translation/input_fields';

// Validate
import { commonVodTranslationValidate } from 'components/utilities/content_translation/validation';

export const seasonsBasicTabs = [
	{
		name: i18n.t(`common:tabs.metadata`),
		path: 'metadata',
		disableOnCreate: false,
		component: <SeasonsForm />
	},
	{
		name: i18n.t(`common:tabs.pictures`),
		path: 'pictures',
		disableOnCreate: true,
		component: <SeasonsImages />
	},
	{
		name: i18n.t('common:tabs.materials'),
		path: 'materials',
		disableOnCreate: true,
		component: <VodMaterials />
	},
	{
		name: i18n.t(`common:tabs.availability`),
		path: 'availability',
		disableOnCreate: true,
		component: <VodAvailability />
	},
	{
		name: i18n.t(`common:tabs.prices`),
		path: 'prices',
		disableOnCreate: true,
		component: <VodPrices />
	},
	{
		name: i18n.t(`common:tabs.episodes`),
		path: 'episodes',
		disableOnCreate: true,
		component: <Episodes />
	},
	{
		name: i18n.t(`common:tabs.payments`),
		path: 'payments',
		disableOnCreate: true,
		component: <Payments />
	},
	{
		name: i18n.t('common:tabs.packets_assigned'),
		path: 'packets_assigned',
		disableOnCreate: true,
		component: <VodPacketsAssigned />
	},
	{
		name: i18n.t('common:tabs.geoblock'),
		path: 'geoblock',
		disableOnCreate: true,
		component: <ProductGeoblockForm />
	}
];

export const seasonsTabs = (contentTranslationLanguages) => [
	...seasonsBasicTabs,
	...generateContentTranslationsTabs(
		COMMON_VOD_INPUT_FIELDS_DATA,
		contentTranslationLanguages,
		commonVodTranslationValidate
	)
];
