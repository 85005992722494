import services from 'services/services';
import * as types from '../types';
import { setRequestUrl } from 'store/actions';

// Import helpers
import {
	checkFetchErrors,
	queryParams
} from '../helpers_actions/convert_helpers';

// Import variables
import { TABLE_LENGTH } from 'components/helpers/table';

// ******************** FETCH ASSIGNED PACKETS - TABLE DATA ********************
export const fetchAssignedPackets = (options, id = null) => async (
	dispatch,
	getState
) => {
	try {
		// Dispatch a loading action
		dispatch({
			type: types.FETCH_ASSIGNED_PACKETS_LOADING,
			payload: true
		});

		const {
			assigned_packets: {
				table: {
					columns,
					options: {
						filters: { filterUrlQuery },
						startIndex: startTableIndex,
						page
					}
				}
			}
		} = getState();

		const startIndex =
			options.page === page ? startTableIndex : options.startIndex;

		// request url
		let url = `/timetables/products/list?${filterUrlQuery}${queryParams(
			options,
			TABLE_LENGTH,
			columns,
			startIndex
		)}`;

		// Because in table of assigned packets we need always free payment methods
		url += '&payment_method=free';

		if (id) {
			url += `&user_id=${id}`;
		}

		const { data } = await services.get(url);

		// Calculate pages
		const pages = Math.ceil(data.recordsFiltered / TABLE_LENGTH);

		// Dispatch an action with data
		dispatch({
			type: types.FETCH_ASSIGNED_PACKETS_SUCCESS,
			payload: { ...data, pages, page: options.page, startIndex }
		});

		// save request url in redux
		setRequestUrl(url)(dispatch);
	} catch (error) {
		dispatch({
			type: types.FETCH_ASSIGNED_PACKETS_ERROR,
			payload: checkFetchErrors(error)
		});
	}
};

// ******************** SET SEARCH QUERY ********************
export const setAssignedPacketsQuery = (query) => ({
	type: types.SET_ASSIGNED_PACKETS_SEARCH_QUERY,
	payload: query
});
