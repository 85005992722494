import services from 'services/services';
import * as types from '../types';

// Import helpers
import { checkFetchErrors } from '../helpers_actions/convert_helpers';

// ******************** FETCH SECTION ALGORITHMS ********************
export const fetchSectionAlgorithms = () => async (dispatch) => {
	try {
		// Dispatch a loading action
		dispatch({
			type: types.FETCH_SECTION_ALGORITHMS_LOADING,
			payload: true
		});

		const response = await services.get('/section/algorithms');

		// Dispatch an action with data
		dispatch({
			type: types.FETCH_SECTION_ALGORITHMS_SUCCESS,
			payload: response.data
		});
	} catch (error) {
		dispatch({
			type: types.FETCH_SECTION_ALGORITHMS_ERROR,
			payload: checkFetchErrors(error)
		});
	}
};

// ******************** FETCH SECTION TO EDIT ********************
export const fetchSection = (id) => async (dispatch) => {
	try {
		// Dispatch a loading action
		dispatch({
			type: types.FETCH_SECTION_LOADING,
			payload: true
		});

		const { data } = await services.get(`/section/${id}`);

		// Dispatch an action with data
		dispatch({
			type: types.FETCH_SECTION_SUCCESS,
			payload: data
		});
	} catch (error) {
		dispatch({
			type: types.FETCH_SECTION_ERROR,
			payload: checkFetchErrors(error)
		});
	}
};
