import React from 'react';
import PropTypes from 'prop-types';

// Import utilites
import { renderFields } from 'components/utilities/form/renderFields';

// Import fields
import { BASE_FIELDS } from './input_fields';

const Fields = ({ platforms, submitting, isEdit }) => {
	const selectResources = {
		platform: platforms
	};

	return (
		<React.Fragment>
			{BASE_FIELDS(isEdit).map((item, index) =>
				renderFields({ item, index, submitting, selectResources })
			)}
		</React.Fragment>
	);
};

Fields.propTypes = {
	platforms: PropTypes.array,
	submitting: PropTypes.bool.isRequired,
	isEdit: PropTypes.bool.isRequired
};

export default Fields;
