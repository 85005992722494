import React from 'react';
import Tabs from 'components/utilities/tabs/Tabs';
import { useSelector } from 'react-redux';
import { useLocation } from 'react-router-dom';

// Import helpers
import { promotionsTabs } from './helpers/index';

const PromotionsTabs = () => {
	// check if creating or editing form
	// create form should not display any tabs other than metadata
	const location = useLocation();
	const isCreateTab = location.pathname.split('/').includes('create');

	// promotion data is needed to conditionally render promotion tabs
	const promotionAvailableTabs = useSelector(
		(state) => state.promotions.form.tabs
	);

	return <Tabs tabs={promotionsTabs(promotionAvailableTabs, isCreateTab)} />;
};

export default PromotionsTabs;
