import i18n from 'i18next';

export const INGESTER_ASSETS_FILTER_FIELDS = [
	{
		name: 'title',
		label: i18n.t('filters:fields.title'),
		type: 'text',
		required: false,
		labelColumn: 3,
		inputColumn: 9
	},
	{
		name: 'original_file_name',
		label: i18n.t('filters:fields.original_file_name'),
		type: 'text',
		required: false,
		labelColumn: 3,
		inputColumn: 9
	},
	{
		name: 'type',
		label: i18n.t('filters:fields.type'),
		type: 'multi-select',
		required: false,
		mode: '',
		showSearch: true,
		labelColumn: 3,
		inputColumn: 9
	}
];
