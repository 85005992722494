// ******************** GEOBLOCK_GROUPS DATA TABLE ********************
export const FETCH_GEOBLOCK_GROUPS_LOADING = 'FETCH_GEOBLOCK_GROUPS_LOADING';
export const FETCH_GEOBLOCK_GROUPS_SUCCESS = 'FETCH_GEOBLOCK_GROUPS_SUCCESS';
export const FETCH_GEOBLOCK_GROUPS_ERROR = 'FETCH_GEOBLOCK_GROUPS_ERROR';

// ******************** SET SEARCH QUERY ********************
export const SET_GEOBLOCK_GROUPS_SEARCH_QUERY =
	'SET_GEOBLOCK_GROUPS_SEARCH_QUERY';

// ******************** SAVE SORTING OPTIONS  ********************
export const SAVE_GEOBLOCK_GROUPS_TABLE_SORTING =
	'SAVE_GEOBLOCK_GROUPS_TABLE_SORTING';

// ******************** DELETE GEOBLOCK_GROUPS_ITEM ********************
export const DELETE_GEOBLOCK_GROUPS_ITEM_LOADING =
	'DELETE_GEOBLOCK_GROUPS_ITEM_LOADING';
export const DELETE_GEOBLOCK_GROUPS_ITEM_SUCCESS =
	'DELETE_GEOBLOCK_GROUPS_ITEM_SUCCESS';
export const DELETE_GEOBLOCK_GROUPS_ITEM_ERROR =
	'DELETE_GEOBLOCK_GROUPS_ITEM_ERROR';

// ******************** GEOBLOCK_GROUPS_ITEM ********************
export const FETCH_GEOBLOCK_GROUPS_ITEM_LOADING =
	'FETCH_GEOBLOCK_GROUPS_ITEM_LOADING';
export const FETCH_GEOBLOCK_GROUPS_ITEM_SUCCESS =
	'FETCH_GEOBLOCK_GROUPS_ITEM_SUCCESS';
export const FETCH_GEOBLOCK_GROUPS_ITEM_ERROR =
	'FETCH_GEOBLOCK_GROUPS_ITEM_ERROR';
