import React, { useEffect } from 'react';
import PropTypes from 'prop-types';
import { Form } from 'react-final-form';
import { compose } from 'redux';
import { connect } from 'react-redux';
import { withRouter } from 'react-router-dom';
import { fetchEventLive, fetchCategories } from 'store/actions';

// Import defaultValues
import { defaultFieldValues } from './fields/input_fields';

// Import translation
import { useTranslation } from 'react-i18next';

// Import utilities
import { submitForm } from 'components/utilities/form';

// Validate
import validate from './validation';

// Import components
import { FormTemplate } from 'components/common/templates';
import { FormButtons } from 'components/common/buttons';
import Fields from './fields/Fields';

// Import helpers
import {
	convertGenresToNames,
	convertToIds,
	vodCategoriesOptions
} from 'components/helpers/genres_helpers/helpers';

const EventsLiveForm = ({
	history,
	fetchEventLive,
	error,
	isLoaded,
	initialValues,
	genresList = [],
	paymentsModelsList,
	fetchCategories
}) => {
	const { t } = useTranslation();

	const { genres, data, rating } = initialValues;

	const { actors, writers, directors, producers } = data;

	const handleOnSubmit = ({ isEdit, itemID }) => async (values) => {
		const { genres, rating } = values;

		// selected genres mapped to array of ids
		const genres_ids = convertToIds(genresList, genres);

		const resources = {
			...values,
			genres_ids,
			rating: parseInt(rating, 10)
		};

		// Submit the form with field values
		await submitForm({
			history,
			isEdit,
			itemID,
			resources,
			api: 'products/liveevents',
			redirectPath: 'events_live'
		});
	};

	// array of genres names that will be passed to multi-select
	// both initial values and data for combobox
	const genresNames = convertGenresToNames(genres);
	const genresListNames = convertGenresToNames(genresList);

	const formInitialValues = {
		...defaultFieldValues,
		...initialValues,
		data: {
			...defaultFieldValues.data,
			...data,
			short_summary: '',
			title_org: ''
		},
		genres: genresNames,
		rating: rating ? '' + rating : '0' //fast way to convert value to string
	};

	useEffect(() => {
		fetchCategories(vodCategoriesOptions, null, null);
		// eslint-disable-next-line
	}, []);

	return (
		// Dispatch fetchEventLive actions in in events_live_form
		<FormTemplate
			fetchResourceToEdit={fetchEventLive}
			error={error}
			isLoaded={isLoaded}
		>
			{({ isEdit, itemID, checkedPermissions, decorators }) => (
				<Form
					initialValues={formInitialValues}
					validate={validate}
					decorators={decorators}
					onSubmit={handleOnSubmit({ isEdit, itemID })}
					render={({ handleSubmit, submitting }) => (
						<form onSubmit={handleSubmit}>
							<Fields
								submitting={submitting}
								isEdit={isEdit}
								checkedPermissions={checkedPermissions}
								genres={genresListNames}
								actors={actors || []}
								writers={writers || []}
								directors={directors || []}
								producers={producers || []}
								paymentsModelsList={paymentsModelsList}
							/>

							<FormButtons
								isButtonDisabled={submitting || error}
								path="events_live"
								buttonText={t('common:buttons.submit')}
							/>
						</form>
					)}
				/>
			)}
		</FormTemplate>
	);
};

EventsLiveForm.propTypes = {
	history: PropTypes.object.isRequired,
	fetchEventLive: PropTypes.func.isRequired,
	fetchCategories: PropTypes.func.isRequired,
	initialValues: PropTypes.object.isRequired,
	error: PropTypes.bool.isRequired,
	isLoaded: PropTypes.bool.isRequired,
	genresList: PropTypes.array.isRequired,
	paymentsModelsList: PropTypes.array.isRequired
};

const mapStateToProps = ({
	events_live: { form },
	categories: {
		table: { data }
	},
	dictionary
}) => {
	return {
		initialValues: form.edit,
		error: form.error,
		isLoaded: form.isLoaded,
		genresList: data,
		paymentsModelsList: dictionary.paymentModels
	};
};

export default compose(
	connect(mapStateToProps, {
		fetchEventLive,
		fetchCategories
	}),
	withRouter
)(EventsLiveForm);
