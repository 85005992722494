import React from 'react';

// Import translation
import i18n from 'i18next';

// Import services
import services from 'services/services';

export const info_txt = {
	cover: <div className="text-center">{i18n.t('packets:info.cover')}</div>,
	cover_large: (
		<div className="text-center">{i18n.t('packets:info.cover_large')}</div>
	),
	cover_small: (
		<div className="text-center">{i18n.t('packets:info.cover_small')}</div>
	),
	cover_detail: (
		<div className="text-center">{i18n.t('packets:info.cover_detail')}</div>
	),
	cover_detail_large: (
		<div className="text-center">
			{i18n.t('packets:info.cover_detail_large')}
		</div>
	)
};

// PACKETS COLLECTIONS OF IMAGES
export const COVER_EN_SUBSCRIBED = 'cover';
export const COVER_EN_NOT_SUBSCRIBED = 'cover_large';
export const COVER_AR_SUBSCRIBED = 'cover_small';
export const COVER_AR_NOT_SUBSCRIBED = 'cover_detail';
export const COVER_EN_IQ_SUBSCRIBED = 'cover_iraq';
export const COVER_EN_IQ_NOT_SUBSCRIBED = 'cover_large_iraq';
export const COVER_AR_IQ_SUBSCRIBED = 'cover_small_iraq';
export const COVER_AR_IQ_NOT_SUBSCRIBED = 'cover_detail_iraq';

export const getValues = ({ collection, url }) => {
	const values = {};

	switch (collection) {
		case COVER_EN_NOT_SUBSCRIBED:
		case COVER_AR_NOT_SUBSCRIBED:
			values.not_subscribed_image_link = url;
			break;

		case COVER_EN_IQ_NOT_SUBSCRIBED:
		case COVER_AR_IQ_NOT_SUBSCRIBED:
			values.not_subscribed_image_link_iraq = url;
			break;

		case COVER_EN_IQ_SUBSCRIBED:
		case COVER_AR_IQ_SUBSCRIBED:
			values.subscribed_image_link_iraq = url;
			break;

		default:
			values.subscribed_image_link = url;
			break;
	}

	return values;
};

export const getContentLanguage = (collection) => {
	switch (collection) {
		case COVER_AR_SUBSCRIBED:
		case COVER_AR_NOT_SUBSCRIBED:
		case COVER_AR_IQ_SUBSCRIBED:
		case COVER_AR_IQ_NOT_SUBSCRIBED:
			return 'ar';

		default:
			return 'en';
	}
};

export const getTranslationsData = async ({ id, contentLanguage }) => {
	try {
		const { data } = await services.get(
			`packet/${id}?language=${contentLanguage}`
		);

		return data;
	} catch (error) {
		return null;
	}
};
