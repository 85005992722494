// Import translation
import i18n from 'i18next';

export const documentsTranslationValidate = ({ title }) => {
	const errors = {};

	// ------------------ Tytuł ------------------
	if (!title) {
		errors.title = i18n.t('documents:validation.title_required');
	}

	return errors;
};
