import React from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import {
	setCategoriesQuery,
	fetchCategories,
	deleteCategory
} from 'store/actions';

// Import translation
import { useTranslation } from 'react-i18next';

// Import columns
import { createColumns } from './categories_columns';

// Import hooks
import useAuthCheck from 'hooks/useAuthCheck';

// Import utilities
import Search from 'components/utilities/search/Search';

// Import components
import { PanelPageTemplate } from 'components/common/templates';
import CategoryFilters from './category_filters/CategoryFilters';
import FilterButton from 'components/common/buttons/FilterButton';
import CategoriesAddButton from './categories_add_button/CategoriesAddButton';

const Categories = ({
	error,
	setCategoriesQuery,
	fetchCategories,
	deleteCategory,
	resources
}) => {
	const { columns } = resources;
	const { t } = useTranslation();
	// check if user have rights to create and edit forms
	const { isReadOnly } = useAuthCheck();

	const type = resources.options.filters.type;

	return (
		// Dispatch fetchCategories action in categories_table
		<PanelPageTemplate
			title="Lista"
			error={error}
			type="categories"
			resources={resources}
			columns={(handleSelectItem) =>
				createColumns(handleSelectItem, type, isReadOnly)
			}
			fetchResourcesAction={fetchCategories}
			deleteItemAction={deleteCategory}
			notificationDeleteSuccessTxt={t('categories:notification.delete_success')}
			notificationDeleteErrorTxt={t('categories:notification.delete_error')}
		>
			<React.Fragment>
				<CategoriesAddButton type={type} />
				<CategoryFilters />
				<div className="d-flex justify-content-end">
					<Search
						setQueryValue={setCategoriesQuery}
						value={resources.options.filters.query}
					/>
					<FilterButton tableColumns={columns} />
				</div>
			</React.Fragment>
		</PanelPageTemplate>
	);
};

Categories.propTypes = {
	error: PropTypes.bool.isRequired,
	setCategoriesQuery: PropTypes.func.isRequired,
	resources: PropTypes.object.isRequired,
	fetchCategories: PropTypes.func.isRequired
};

const mapStateToProps = ({ categories }) => ({
	error: categories.table.error,
	resources: categories.table
});

export default connect(mapStateToProps, {
	setCategoriesQuery,
	fetchCategories,
	deleteCategory
})(Categories);
