import React from 'react';
import i18n from 'i18next';

// Import components
import MainForm from '../../main_form/MainForm';
import MainImages from '../../main_file/MainImages';
import MainProducts from '../../main_products/MainProducts';
import MainAvailability from 'components/views/main/main_availability/MainAvailability';
import MainPrices from 'components/views/main/main_prices/MainPrices';

// Import utilities
import { generateContentTranslationsTabs } from 'components/utilities/content_translation/tabs/content_translations_tabs';

// import content translation fields
import { MAIN_INPUT_FIELDS_DATA } from 'components/utilities/content_translation/input_fields';

// Validate
import { mainTranslationValidate } from 'components/utilities/content_translation/validation';

const mainBasicTabs = [
	{
		name: i18n.t('common:tabs.metadata'),
		path: 'metadata',
		disableOnCreate: false,
		component: <MainForm />
	},
	{
		name: i18n.t('common:tabs.pictures'),
		path: 'pictures',
		disableOnCreate: true,
		component: <MainImages />
	},
	{
		name: i18n.t('common:tabs.products'),
		path: 'products',
		disableOnCreate: true,
		component: <MainProducts />
	},
	{
		name: i18n.t('common:tabs.availability'),
		path: 'availability',
		disableOnCreate: true,
		component: <MainAvailability />
	},
	{
		name: i18n.t('common:tabs.prices'),
		path: 'prices',
		disableOnCreate: true,
		component: <MainPrices />
	}
];

export const mainTabs = (contentTranslationLanguages) => [
	...mainBasicTabs,
	...generateContentTranslationsTabs(
		MAIN_INPUT_FIELDS_DATA,
		contentTranslationLanguages,
		mainTranslationValidate
	)
];
