import React from 'react';

// Import components
import SignOut from './SignOut';
import ChangeLanguage from 'components/utilities/translation/ChangeLanguage';

const TopHeader = () => (
	<div className="row border-bottom">
		<nav
			className="navbar navbar-static-top"
			role="navigation"
			style={{ marginBottom: 0 }}
		>
			<img src="/images/logo.png" alt="Logo" style={{ height: 20 }} />
			&nbsp; {process.env.REACT_APP_PROJECT_NAME} &nbsp;{' '}
			<strong>{process.env.REACT_APP_ENV}</strong>
			<div className="d-flex justify-content-end navbar-buttons">
				<ChangeLanguage />
				<SignOut />
			</div>
		</nav>
	</div>
);

export default TopHeader;
