import React from 'react';
import PropTypes from 'prop-types';
import { Alert } from 'antd';
import { Row, Col } from 'reactstrap';

// Import translation
import { useTranslation } from 'react-i18next';

const Wraper = ({
	children,
	error,
	lg = '12',
	md = '12',
	sm = '12',
	xs = '12'
}) => {
	const { t } = useTranslation();
	const checkError = () => (
		<Alert
			showIcon
			message={t('common:error')}
			type="error"
			description={t('common:errors.data_retrieve')}
			className="m-b-md animated fadeIn"
		/>
	);
	return (
		<div className="wrapper wrapper-content ">
			{error && checkError()}

			<Row>
				<Col lg={lg} md={md} sm={sm} xs={xs}>
					{children}
				</Col>
			</Row>
		</div>
	);
};

Wraper.propTypes = {
	error: PropTypes.bool.isRequired,
	children: PropTypes.object.isRequired,
	lg: PropTypes.string,
	md: PropTypes.string,
	sm: PropTypes.string,
	xs: PropTypes.string
};

export default Wraper;
