import React from 'react';
import PropTypes from 'prop-types';
import { useTranslation } from 'react-i18next';

// Import components
import { Modal } from 'antd';
import message from 'antd/es/message';

const DeleteCell = ({ handleSelectItem, id }) => {
	const { t } = useTranslation();

	// shows modal to confirm delete. On OK it calls function to delete that is in Context
	const confirmModal = () =>
		Modal.confirm({
			title: t(`common:delete`),
			content: `${t(`messages:modal.delete_confirm_message`)} ${t(
				`names:notification_names.segment`
			)}`,
			okText: t('common:buttons.ok'),
			cancelText: t('common:buttons.cancel'),
			onOk: async () => await handleSelectItem.mutateAsync(id),
			onCancel() {
				message.info(t('messages:messages.subtitle_delete_canceled'));
			}
		});

	return (
		<button onClick={confirmModal} className="btn btn-xs btn-primary">
			<i className="fa fa-trash" />
		</button>
	);
};

DeleteCell.propTypes = {
	handleSelectItem: PropTypes.func.isRequired,
	id: PropTypes.number.isRequired
};

export default DeleteCell;
