import React, { useEffect } from 'react';
import PropTypes from 'prop-types';
import { Form } from 'react-final-form';
import { compose } from 'redux';
import { connect } from 'react-redux';
import { withRouter } from 'react-router-dom';
import {
	fetchEpisode,
	fetchCategories,
	fetchProviders,
	dictionaryPlatformsTypes
} from 'store/actions';

// Import defaultValues
import { defaultFieldValues } from './fields/input_fields';

// Import translation
import { useTranslation } from 'react-i18next';

// Import utilities
import { submitForm } from 'components/utilities/form';

// Validate
import validate from './validation';

// Import components
import { FormTemplate } from 'components/common/templates';
import { FormButtons } from 'components/common/buttons';
import Fields from './fields/Fields';
import ProductizationNote from 'components/utilities/info/ProductizationNote';

// Import helpers
import {
	vodCategoriesOptions,
	convertGenresToNames,
	convertToIds
} from 'components/helpers/genres_helpers/helpers';
import {
	convertProvidersToNames,
	convertProvidersToId
} from 'components/views/vod/helpers/helpers';

const EpisodeForm = ({
	history,
	fetchEpisode,
	fetchCategories,
	fetchProviders,
	error,
	isLoaded,
	initialValues,
	genresList,
	providersList,
	paymentsModelsList,
	dictionaryPlatformsTypes,
	platformsList
}) => {
	useEffect(() => {
		// Fetch categories with default params to feed genres combobox
		fetchCategories(vodCategoriesOptions, null, null);
		fetchProviders();
		// eslint-disable-next-line
	}, []);

	const { t } = useTranslation();

	let seasonId = localStorage.getItem('season');
	const redirectPath = seasonId
		? `vod/season/edit/${seasonId}/episodes`
		: 'vod';

	const handleOnSubmit = ({ isEdit, itemID }) => async (values) => {
		const {
			type,
			genres,
			number,
			active,
			provider,
			provider_id,
			excluded_from_subscription,
			platforms,
			...metadata
		} = values;

		// selected genres mapped to array of ids
		const genresId = convertToIds(genresList, genres);

		// resources to submit
		const resources = {
			...initialValues,
			metadata,
			type,
			genres: genresId,
			number,
			active,
			provider,
			provider_id,
			excluded_from_subscription,
			platforms
		};

		// Submit the form with field values
		return await submitForm({
			history,
			isEdit,
			itemID,
			resources,
			notificationName: 'episode',
			api: 'vod/episodes',
			apiSlug: 'update',
			redirectPath
		});
	};

	const {
		metadata,
		type,
		genres,
		number,
		active,
		status,
		subtype,
		provider,
		provider_id,
		payment_models,
		excluded_from_subscription,
		platforms
	} = initialValues;
	const { actors, writers, directors } = metadata;

	// array of genres names that will be passed to multi-select
	// both initial values and data for combobox
	const genresNames = convertGenresToNames(genres);
	const genresListNames = convertGenresToNames(genresList);

	// arrays of provider names and id's passed to suggestion combobox for form fields
	const providersNames = convertProvidersToNames(providersList);
	const providersId = convertProvidersToId(providersList);

	// form initial values
	const formInitialValues = {
		...defaultFieldValues,
		...metadata,
		type,
		genres: genresNames,
		number,
		active,
		provider,
		provider_id,
		payment_models,
		excluded_from_subscription,
		platforms
	};

	return (
		// Dispatch fetchEpisode actions in episode_form
		<FormTemplate
			fetchResourceToEdit={fetchEpisode}
			fetchFormResources={[dictionaryPlatformsTypes]}
			error={error}
			isLoaded={isLoaded}
		>
			{({ isEdit, itemID, checkedPermissions }) => (
				<>
					<ProductizationNote />
					<Form
						initialValues={formInitialValues}
						validate={validate}
						onSubmit={handleOnSubmit({ isEdit, itemID })}
						render={({ handleSubmit, submitting }) => (
							<form onSubmit={handleSubmit}>
								<Fields
									isEdit={isEdit}
									submitting={submitting}
									actors={actors}
									writers={writers}
									directors={directors}
									type={type}
									genres={genresListNames}
									status={status}
									subtype={subtype}
									providersNames={providersNames}
									providersId={providersId}
									paymentsModelsList={paymentsModelsList}
									checkedPermissions={checkedPermissions}
									platformsList={platformsList}
								/>
								<FormButtons
									isButtonDisabled={submitting || error}
									path={redirectPath}
									buttonText={t('common:buttons.submit')}
								/>
							</form>
						)}
					/>
				</>
			)}
		</FormTemplate>
	);
};

EpisodeForm.propTypes = {
	history: PropTypes.object.isRequired,
	initialValues: PropTypes.object.isRequired,
	fetchEpisode: PropTypes.func.isRequired,
	fetchCategories: PropTypes.func.isRequired,
	fetchProviders: PropTypes.func.isRequired,
	error: PropTypes.bool.isRequired,
	isLoaded: PropTypes.bool.isRequired,
	genresList: PropTypes.array.isRequired,
	providersList: PropTypes.array.isRequired,
	paymentsModelsList: PropTypes.array.isRequired,
	dictionaryPlatformsTypes: PropTypes.func.isRequired,
	platformsList: PropTypes.array.isRequired
};

const mapStateToProps = ({
	vod: { episode_form },
	categories: {
		table: { data }
	},
	providers,
	dictionary
}) => ({
	initialValues: episode_form.edit,
	error: episode_form.error,
	isLoaded: episode_form.isLoaded,
	genresList: data,
	providersList: providers.table.data,
	paymentsModelsList: dictionary.paymentModels,
	platformsList: dictionary.platformTypes
});

export default compose(
	connect(mapStateToProps, {
		fetchEpisode,
		fetchCategories,
		fetchProviders,
		dictionaryPlatformsTypes
	}),
	withRouter
)(EpisodeForm);
