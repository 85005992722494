import { combineReducers } from 'redux';
// Import reducers
import table from './subscribers_panel_reducer';
import form from './subscribers_form_reducer';
import devices from './subscriber_devices_reducer';
import packets from './subscribers_packets_reducer';

export default combineReducers({
	table,
	form,
	devices,
	packets
});
