import React from 'react';
import PropTypes from 'prop-types';

// Import components
import {
	Col,
	FormGroup,
	Label,
	Input,
	InputGroupAddon,
	InputGroup,
	Button,
	Spinner
} from 'reactstrap';

// Import hooks
import useInputLabelButtonAsyncLogic from './utils/useInputLabelButtonAsyncLogic';

const InputLabelButtonAsync = ({
	input,
	id,
	label,
	labelColumn = 2,
	inputColumn = 10,
	children,
	meta: { touched, error, submitError },
	buttonText,
	url = '',
	...inputProps
}) => {
	const { loading, buttonError, handleClick } = useInputLabelButtonAsyncLogic(
		url,
		id
	);
	return (
		<FormGroup
			data-testid={`form-group-${input.name}`}
			row
			className={`${touched && error && 'has-error'}`}
		>
			<Label
				for={id}
				sm={labelColumn}
				className="col-form-label col-form-label-lg"
			>
				{label}
			</Label>
			<Col sm={inputColumn}>
				<InputGroup>
					<InputGroupAddon addonType="prepend">
						<Button onClick={handleClick} style={{ height: '100%' }}>
							{loading ? <Spinner color="light" /> : buttonText}
						</Button>
					</InputGroupAddon>
					<Input
						{...input}
						{...inputProps}
						invalid={touched && (error || submitError) ? true : false}
						id={id}
						value={input.value}
					/>
				</InputGroup>
				{(error || submitError || buttonError) && (
					<span
						data-testid={`input-error-${input.name}`}
						className="input-error"
					>
						{error || submitError || buttonError}
					</span>
				)}
			</Col>
			{children}
		</FormGroup>
	);
};

InputLabelButtonAsync.propTypes = {
	input: PropTypes.object.isRequired,
	label: PropTypes.string.isRequired,
	id: PropTypes.string.isRequired,
	labelColumn: PropTypes.number,
	inputColumn: PropTypes.number,
	children: PropTypes.object,
	meta: PropTypes.object,
	url: PropTypes.string,
	buttonText: PropTypes.string.isRequired
};

export default InputLabelButtonAsync;
