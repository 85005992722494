export const select_types = [
	{
		name: 'LOGIN'
	},
	{
		name: 'PAYMENT'
	},
	{
		name: 'SUBSCRIBER'
	},
	{
		name: 'REGISTRATION'
	}
];
