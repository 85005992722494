import React from 'react';
import { Link } from 'react-router-dom';

// Import translation
import i18n from 'i18next';

//Import components
import { Toggle } from 'components/utilities';
import MergeForm from './categories_merge/MergeForm';

const renderMergeCell = (id, name, isReadOnly) => (
	<Toggle>
		{({ on, toggle }) => (
			<>
				{!isReadOnly && (
					<button onClick={toggle} className="btn btn-xs btn-primary">
						<i className="fa fa-object-ungroup" />
					</button>
				)}

				<MergeForm childId={id} childName={name} visible={on} toggle={toggle} />
			</>
		)}
	</Toggle>
);

export const createColumns = (handleSelectItem, type, isReadOnly) => {
	const columns = [
		{
			Header: i18n.t('categories:table_columns.order'),
			accessor: 'order',
			width: 100
		},
		{
			Header: i18n.t('categories:table_columns.category'),
			accessor: 'name',
			Cell: ({
				row: {
					_original: { id, name }
				}
			}) => <Link to={`/panel/categories/edit/${id}/metadata`}>{name}</Link>
		},
		{
			Header: i18n.t('categories:table_columns.slug'),
			accessor: 'slug'
		},
		{
			Header: i18n.t('categories:table_columns.visible'),
			accessor: 'public',
			sortable: false,
			Cell: ({
				row: {
					_original: { public: visible }
				}
			}) => <span>{visible ? i18n.t('common:yes') : i18n.t('common:no')}</span>
		},
		{
			Header: i18n.t('categories:table_columns.children'),
			accessor: 'children',
			sortable: false,
			Cell: ({
				row: {
					_original: { children }
				}
			}) =>
				children ? (
					<span>{children.map(({ name }) => name).join(', ')}</span>
				) : (
					<span>---</span>
				)
		},
		{
			Header: i18n.t('common:table_columns.merge'),
			accessor: 'merge',
			width: 60,
			className: 'text-center',
			sortable: false,
			Cell: ({
				row: {
					_original: { id, name }
				}
			}) => renderMergeCell(id, name, isReadOnly)
		}
	];

	const deleteColumn = {
		Header: i18n.t('common:table_columns.delete'),
		accessor: 'delete',
		width: 60,
		className: 'text-center',
		sortable: false,
		Cell: ({
			row: {
				_original: { id }
			}
		}) =>
			!isReadOnly && (
				<button
					onClick={() => handleSelectItem(id)}
					className="btn btn-xs btn-primary"
				>
					<i className="fa fa-trash" />
				</button>
			)
	};

	if (type === 1) {
		columns.push(deleteColumn);
	}

	return columns;
};
