import React from 'react';
import i18n from 'i18next';

// Import components
import EventsLiveForm from 'components/views/events_live/events_live_form/EventsLiveForm';
import EventsLiveAvailability from 'components/views/events_live/events_live_availability/EventsLiveAvailability';
import ProductVisibility from 'components/utilities/product_visibility/table/ProductVisibility';
import EventsLiveMaterials from 'components/views/events_live/events_live_materials/EventsLiveMaterials';
import EventsLiveImages from 'components/views/events_live/events_live_file/EventsLiveImages';
import ProductGeoblockForm from 'components/views/geoblock_groups/ProductGeoblockForm/ProductGeoblockForm';

// Import utilities
import { generateContentTranslationsTabs } from 'components/utilities/content_translation/tabs/content_translations_tabs';

// import content translation fields
import { EVENTS_LIVE_INPUT_FIELDS_DATA } from 'components/utilities/content_translation/input_fields';

// Validate
import { eventsLiveTranslationValidate } from 'components/utilities/content_translation/validation';

// Import variables
import { EVENT_LIVE } from 'components/helpers/variables';

const eventsLiveBasicTabs = [
	{
		name: i18n.t('common:tabs.metadata'),
		path: 'metadata',
		disableOnCreate: false,
		component: <EventsLiveForm />
	},
	{
		name: i18n.t('common:tabs.pictures'),
		path: 'pictures',
		disableOnCreate: true,
		component: <EventsLiveImages />
	},
	{
		name: i18n.t('common:tabs.materials'),
		path: 'materials',
		disableOnCreate: true,
		component: <EventsLiveMaterials />
	},
	{
		name: i18n.t('common:tabs.availability'),
		path: 'availability',
		disableOnCreate: true,
		component: <EventsLiveAvailability />
	},
	{
		name: i18n.t('common:tabs.visibility'),
		path: 'visibility',
		disableOnCreate: true,
		component: <ProductVisibility productType={EVENT_LIVE} />
	},
	{
		name: i18n.t('common:tabs.geoblock'),
		path: 'geoblock',
		disableOnCreate: true,
		component: <ProductGeoblockForm />
	}
];

export const eventsLiveTabs = (contentTranslationLanguages) => [
	...eventsLiveBasicTabs,
	...generateContentTranslationsTabs(
		EVENTS_LIVE_INPUT_FIELDS_DATA,
		contentTranslationLanguages,
		eventsLiveTranslationValidate
	)
];
