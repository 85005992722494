import React from 'react';
import PropTypes from 'prop-types';
import { Link } from 'react-router-dom';

// Import utilities
import { messageConfirmDeleteHandler } from 'components/utilities/message';

// Import hooks
import useAuthCheck from 'hooks/useAuthCheck';

// Import components
import {
	Ibox,
	IboxTitle,
	IboxTools,
	IboxContentBase,
	Wraper
} from 'components/common/layout';
import { ReactServerSideTable } from 'components/utilities/table';

const TableTemplate = React.memo((props) => {
	// check if user have rights to create and edit forms
	const { isReadOnly } = useAuthCheck();
	// Handle select item to delete
	const handleDeleteItem = (id) =>
		// Show confirmation dialog
		// Dispatch a delete action
		messageConfirmDeleteHandler(
			props.typeText || props.type,
			id,
			props.deleteItemAction,
			props.resourcesForDeleteAction
		);

	return (
		<Wraper error={props.error}>
			<Ibox error={false} className="m-b-lg">
				{props.addLinkText && !isReadOnly && (
					<IboxTitle>
						<IboxTools>
							<Link
								data-testid={`create-${props.type}-link`}
								className="btn__link"
								to={`/panel/${props.type}/create/metadata`}
							>
								{props.addLinkText}
							</Link>
						</IboxTools>
					</IboxTitle>
				)}
				<IboxContentBase>
					<React.Fragment>
						{props.children}
						{props.isReactTableVisible && (
							<ReactServerSideTable
								itemId={props.itemId}
								isShow={true}
								resources={props.resources}
								columns={props.columns(handleDeleteItem, isReadOnly)}
								fetchResourcesAction={props.fetchResourcesAction}
								resourcesForFetchAction={props.resourcesForFetchAction}
								notificationDeleteSuccessTxt={
									props.notificationDeleteSuccessTxt
								}
								notificationDeleteErrorTxt={props.notificationDeleteErrorTxt}
								getTrProps={props.getTrProps}
								tableType={props.tableType}
							/>
						)}
					</React.Fragment>
				</IboxContentBase>
			</Ibox>
		</Wraper>
	);
});

TableTemplate.defaultProps = {
	deleteItemAction: () => {},
	notificationDeleteSuccessTxt: '',
	notificationDeleteErrorTxt: '',
	isReactTableVisible: true,
	itemId: null,
	typeText: null,
	getTrProps: () => ({})
};

TableTemplate.propTypes = {
	error: PropTypes.bool.isRequired,
	children: PropTypes.object,
	type: PropTypes.string,
	typeText: PropTypes.string,
	addLinkText: PropTypes.string,
	resources: PropTypes.object.isRequired,
	columns: PropTypes.func.isRequired,
	fetchResourcesAction: PropTypes.func.isRequired,
	deleteItemAction: PropTypes.func.isRequired,
	notificationDeleteSuccessTxt: PropTypes.string,
	notificationDeleteErrorTxt: PropTypes.string,
	isReactTableVisible: PropTypes.bool,
	getTrProps: PropTypes.func,
	tableType: PropTypes.string
};

export default TableTemplate;
