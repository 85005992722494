import React from 'react';
import { Route, Redirect } from 'react-router-dom';
import { connect } from 'react-redux';

const PrivateRoute = ({ authenticate, component: Component, ...rest }) => (
	<Route
		{...rest}
		render={(props) =>
			!authenticate ? <Redirect to="/" /> : <Component {...props} />
		}
	/>
);

const mapStateToProps = (state) => {
	return { authenticate: state.auth.isAuthenticated };
};

export default connect(mapStateToProps)(PrivateRoute);
