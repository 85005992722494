import React from 'react';
import { shape, string } from 'prop-types';
import { withRouter } from 'react-router-dom';

// Import translation
import { useTranslation } from 'react-i18next';

// Import components
import {
	Wraper,
	Ibox,
	IboxTitle,
	IboxContentBase
} from 'components/common/layout';
import CardsList from 'components/utilities/products_select/cards_list/CardsList';
import SectionsPlatformsFilters from './SectionsPlatformsFilters';

// Import custom hook
import useSectionsPreview from './useSectionsPreview';

const SectionsPreview = ({
	match: {
		params: { id: itemId }
	}
}) => {
	const { t } = useTranslation();

	const {
		products,
		loading,
		fetchError,
		onChangePlatformType,
		currentPlatformType,
		platformTypes
	} = useSectionsPreview(itemId);

	return (
		<>
			<Wraper error={fetchError}>
				<Ibox>
					<IboxTitle title={t('preview:title')} />
					<IboxContentBase isLoaded={!loading}>
						{platformTypes?.length && (
							<SectionsPlatformsFilters
								onChangePlatformType={onChangePlatformType}
								currentPlatformType={currentPlatformType}
								platformTypes={platformTypes}
							/>
						)}

						<CardsList data={products} />
					</IboxContentBase>
				</Ibox>
			</Wraper>
		</>
	);
};

SectionsPreview.propTypes = {
	match: shape({
		params: shape({ id: string.isRequired })
	})
};

export default withRouter(SectionsPreview);
