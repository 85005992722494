import React from 'react';
import i18n from 'i18next';

// Import utilities
import { generateContentTranslationsTabs } from 'components/utilities/content_translation/tabs/content_translations_tabs';

// import content translation fields
import { SECTIONS_INPUT_FIELDS_DATA } from 'components/utilities/content_translation/input_fields';

// Import components
import SectionsForm from '../../sections_form/SectionsForm';
import SectionsProducts from '../../sections_products/SectionsProducts';
import SectionsPreview from '../../sections_preview/SectionsPreview';
import SectionsPages from '../../sections_pages/SectionsPages';
import SectionsImages from '../../sections_file/SectionsImages';

export const basicSectionsTabs = [
	{
		name: i18n.t('common:tabs.metadata'),
		path: 'metadata',
		disableOnCreate: false,
		component: <SectionsForm />
	},
	{
		name: i18n.t('common:tabs.preview'),
		path: 'preview',
		disableOnCreate: false,
		component: <SectionsPreview />
	},
	{
		name: i18n.t('common:tabs.pictures'),
		path: 'pictures',
		disableOnCreate: true,
		component: <SectionsImages />
	},
	{
		name: i18n.t('common:tabs.static_elements'),
		path: 'static-elements',
		disableOnCreate: true,
		component: <SectionsProducts />
	},
	{
		name: i18n.t('common:tabs.on_pages'),
		path: 'pages',
		disableOnCreate: true,
		component: <SectionsPages />
	}
];

export const sectionsTabs = (contentTranslationLanguages) => [
	...basicSectionsTabs,
	...generateContentTranslationsTabs(
		SECTIONS_INPUT_FIELDS_DATA,
		contentTranslationLanguages
	)
];
