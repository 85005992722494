import { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useParams } from 'react-router-dom';

// Import actions
import { fetchSection } from 'store/actions';

// Import products tabs
import {
	vod,
	channel,
	banners,
	collections,
	liveEvents
} from 'components/utilities/products_select/helpers/tabs';

// Import variables
import { SECTION_TYPES } from 'components/helpers/variables';

const useSectionsProductsTypes = () => {
	const dispatch = useDispatch();
	const { id } = useParams();

	const [tabs, setTabs] = useState([]);
	const { type: sectionType } = useSelector(
		({ sections }) => sections.form.edit
	);

	useEffect(() => {
		fetchSection(id)(dispatch);
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [id]);

	useEffect(() => {
		if (sectionType) {
			if (sectionType === SECTION_TYPES.BANNER) {
				return setTabs([banners]);
			}

			return setTabs([vod, channel, banners, collections, liveEvents]);
		}
	}, [sectionType]);

	const createSubmitData = (selectedData) => {
		const selection = { products: [] };

		selection.products = selectedData.map((item, index) => ({
			uuid: item.uuid,
			order: index + 1
		}));

		return selection;
	};

	return { tabs, createSubmitData };
};

export default useSectionsProductsTypes;
