import React from 'react';
import PropTypes from 'prop-types';
import { Field } from 'react-final-form';

// Import translation
import { useTranslation } from 'react-i18next';

// Import select options
import { select_packet_types as type } from 'components/helpers/variables';

// Import components
import {
	InputLabel,
	TextareaLabel,
	SelectLabel,
	CheckboxLabel
} from 'components/common/inputs';

const Fields = ({ submitting, isEdit, checkedPermissions }) => {
	const { t } = useTranslation();

	const DEFAULT_PACKET_TYPE_SELECT = 'collection';

	const selectResources = {
		type
	};

	return (
		<React.Fragment>
			<Field
				disabled={true}
				name="type"
				type="select"
				placeholder={t('packets:fields.type')}
				label={t('packets:fields.type')}
				id="type"
				defaultValue={
					selectResources['type'].find(
						(option) => option.value === DEFAULT_PACKET_TYPE_SELECT
					).value
				}
				required={true}
				component={SelectLabel}
			>
				{selectResources['type'].map((item, index) => (
					<option key={index} value={item.value}>
						{item.name}
					</option>
				))}
			</Field>
			<Field
				disabled={submitting}
				name="external_ids"
				type="text"
				placeholder={t('packets:fields.external_id')}
				label={t('packets:fields.external_id')}
				id="external_ids"
				required={true}
				component={InputLabel}
			/>
			<Field
				disabled={submitting}
				name="title"
				type="text"
				placeholder={t('packets:fields.title')}
				label={t('packets:fields.title')}
				id="title"
				required={true}
				component={InputLabel}
			/>
			<Field
				disabled={submitting}
				name="description"
				type="textarea"
				placeholder={t('packets:fields.description')}
				label={t('packets:fields.description')}
				id="description"
				required={true}
				component={TextareaLabel}
			/>
			<Field
				disabled={submitting}
				name="short_description"
				type="textarea"
				placeholder={t('packets:fields.short_description')}
				label={t('packets:fields.short_description')}
				id="short_description"
				required={true}
				component={TextareaLabel}
			/>
			<Field
				disabled={submitting}
				name="order"
				type="number"
				placeholder={t('packets:fields.order')}
				label={t('packets:fields.order')}
				id="order"
				required={true}
				component={InputLabel}
			/>
			<Field
				disabled={submitting || !checkedPermissions.canActivate}
				name="active"
				type="checkbox"
				label={t('packets:fields.active')}
				id="active"
				required={false}
				component={CheckboxLabel}
			/>

			{!isEdit && (
				<>
					<Field
						disabled={true}
						name="created_at"
						type="text"
						placeholder={t('packets:fields.created_at')}
						label={t('packets:fields.created_at')}
						id="created_at"
						required={false}
						component={InputLabel}
					/>
					<Field
						disabled={true}
						name="updated_at"
						type="text"
						placeholder={t('packets:fields.updated_at')}
						label={t('packets:fields.updated_at')}
						id="updated_at"
						required={false}
						component={InputLabel}
					/>
				</>
			)}
		</React.Fragment>
	);
};

Fields.propTypes = {
	submitting: PropTypes.bool.isRequired,
	isEdit: PropTypes.bool.isRequired,
	checkedPermissions: PropTypes.object.isRequired
};

export default Fields;
