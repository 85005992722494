import services from 'services/services';
import { setRequestUrl } from 'store/actions';

// Import helpers
import {
	checkFetchErrors,
	queryParams
} from 'store/actions/helpers_actions/convert_helpers';
import { setActionType } from './helpers';
import { convertProductType } from 'components/common/templates/helpers';

// Import variables
import { TABLE_LENGTH } from 'components/helpers/table';

// ******************** FETCH OPERATORS - TABLE DATA ********************
export const fetchProductVisibility = (options, itemId, resources) => async (
	dispatch
) => {
	const { productType, columns, startIndex, page } = resources;

	const { tableLoading, tableSuccess, tableError } = setActionType(productType);
	try {
		// Dispatch a loading action
		dispatch({
			type: tableLoading,
			payload: true
		});

		const startTableIndex =
			options.page === page ? startIndex : options.startIndex;

		const mappedProductType = convertProductType(productType);

		const url = `/timetables/visibility/listByProduct/${mappedProductType}/${itemId}?${queryParams(
			options,
			TABLE_LENGTH,
			columns,
			startTableIndex
		)}`;

		const { data } = await services.get(url);

		// Calculate pages
		const pages = Math.ceil(data.recordsFiltered / TABLE_LENGTH);

		// // Dispatch an action with data
		dispatch({
			type: tableSuccess,
			payload: {
				...data,
				pages,
				page: options.page,
				startIndex
			}
		});

		// save request url in redux
		setRequestUrl(url)(dispatch);
	} catch (error) {
		dispatch({
			type: tableError,
			payload: checkFetchErrors(error)
		});
	}
};

// ******************** DELETE OPERATOR ********************
export const deleteProductVisibility = (id, resources) => async (dispatch) => {
	const { productType } = resources;
	const { deleteLoading, deleteSuccess, deleteError } = setActionType(
		productType
	);
	try {
		// Dispatch a loading action
		dispatch({
			type: deleteLoading,
			payload: true
		});

		await services.delete(`/timetables/visibility/${id}`);

		// Dispatch an action with data
		dispatch({
			type: deleteSuccess,
			payload: true
		});
	} catch (error) {
		// Dispatch an action with error set to true
		dispatch({
			type: deleteError,
			payload: checkFetchErrors(error)
		});
	}
};
