import * as types from 'store/actions/types';
import produce from 'immer';

export const INITIAL_STATE = {
	edit: {
		metadata: {
			actors: [],
			writers: [],
			directors: []
		},
		genres: [],
		type: 'vod',
		subtype: 'series'
	},
	isLoaded: true,
	error: false
};

export default (state = INITIAL_STATE, action) =>
	produce(state, (draft) => {
		switch (action.type) {
			// ****************** CLEAR SERIES FORM STATE ******************
			case types.CLEAR_STATE:
				return { ...INITIAL_STATE };

			// ****************** FETCH SERIES ******************
			case types.FETCH_SERIES_LOADING:
				draft.isLoaded = false;
				return;

			case types.FETCH_SERIES_SUCCESS:
				draft.edit = action.payload;
				draft.isLoaded = true;
				return;

			case types.FETCH_SERIES_ERROR:
				draft.isLoaded = true;
				draft.error = true;
				return;

			default:
				return state;
		}
	});
