import React from 'react';
import PropTypes from 'prop-types';
import { Field } from 'react-final-form';

// Import translation
import { useTranslation } from 'react-i18next';

// Import components
import { SelectLabel } from 'components/common/inputs';

const Fields = ({ submitting, categories: selectResources, childId }) => {
	const { t } = useTranslation();

	return (
		<React.Fragment>
			<Field
				disabled={submitting}
				name="parentId"
				type="select"
				labelColumn={3}
				inputColumn={9}
				placeholder={t('categories:fields.parentId')}
				label={t('categories:fields.parentId')}
				id="parentId"
				required={true}
				component={SelectLabel}
			>
				<option value="" disabled>
					{t('categories:fields.parentId')}
				</option>
				{selectResources
					.sort((a, b) => (a.name > b.name ? 1 : b.name > a.name ? -1 : 0))
					.map((item, index) =>
						item.id !== childId ? (
							<option key={index} value={item.id}>
								{item.name}
							</option>
						) : (
							''
						)
					)}
			</Field>
		</React.Fragment>
	);
};

Fields.propTypes = {
	submitting: PropTypes.bool.isRequired,
	categories: PropTypes.array.isRequired,
	childId: PropTypes.string.isRequired
};

export default Fields;
