import * as types from 'store/actions/types';
import produce from 'immer';

export const INITIAL_STATE = {
	tabs: {
		productOutSpecification: null,
		productInSpecification: null,
		usersSpecification: null
	},
	edit: {},
	isLoaded: true,
	error: false
};

export default (state = INITIAL_STATE, action) =>
	produce(state, (draft) => {
		switch (action.type) {
			// ****************** CLEAR STATE ******************
			case types.CLEAR_STATE:
				return {
					...INITIAL_STATE,
					tabs: {
						productOutSpecification: state.tabs.productOutSpecification,
						productInSpecification: state.tabs.productInSpecification,
						usersSpecification: state.tabs.usersSpecification
					}
				};
			// ****************** FETCH PROMOTION ******************
			case types.FETCH_PROMOTION_LOADING:
				draft.isLoaded = false;
				draft.error = false;
				draft.edit = {};
				return;

			case types.FETCH_PROMOTION_SUCCESS:
				draft.edit = action.payload;
				draft.tabs.productOutSpecification =
					action.payload.products_specification;
				draft.tabs.usersSpecification = action.payload.users_specification;
				if (action.payload.entry_condition) {
					draft.tabs.productInSpecification =
						action.payload.entry_condition.products_specification;
				}
				draft.isLoaded = true;
				return;

			case types.FETCH_PROMOTION_ERROR:
				draft.isLoaded = true;
				draft.error = true;
				return;

			default:
				return state;
		}
	});
