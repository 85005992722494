import React from 'react';
import { Link } from 'react-router-dom';

// Import translation
import i18n from 'i18next';

export const createColumns = (
	handleSelectItem,
	agreement_id,
	handleActivateItem,
	isReadOnly
) => [
	{
		Header: i18n.t('agreements_versions:table_columns.applicable_from'),
		accessor: 'applicable_from',
		sortable: false,
		Cell: ({
			row: {
				_original: { id, applicable_from }
			}
		}) => (
			<React.Fragment>
				<Link
					to={`/panel/agreements/${agreement_id}/versions/edit/${id}/metadata`}
				>
					{applicable_from}
				</Link>
			</React.Fragment>
		)
	},
	{
		Header: i18n.t('agreements_versions:table_columns.active'),
		accessor: 'active',
		width: 80,
		sortable: false,
		headerClassName: 'text-center',
		className: 'text-center',
		Cell: ({
			row: {
				_original: { active }
			}
		}) => (
			<i
				className="fa fa-check"
				style={{
					fontSize: '16px',
					color: `${active ? '#13ce66' : '#676a6c'}`
				}}
				aria-hidden="true"
			></i>
		)
	},
	{
		Header: i18n.t('agreements_versions:table_columns.description'),
		accessor: 'description',
		sortable: false
	},
	{
		Header: i18n.t('agreements_versions:table_columns.action'),
		accessor: 'action',
		sortable: false
	},
	{
		Header: i18n.t('agreements_versions:table_columns.activate'),
		accessor: 'activate',
		width: 76,
		className: 'text-center',
		sortable: false,
		Cell: ({
			row: {
				_original: { id, applicable_from, description, action }
			}
		}) => {
			const options = {
				agreement_id,
				applicable_from,
				description,
				action
			};
			const handleActive = () => handleActivateItem(id, options);
			return (
				!isReadOnly && (
					<button onClick={handleActive} className="btn btn-xs btn-primary">
						<i className="fa fa-lightbulb-o" />
					</button>
				)
			);
		}
	},
	{
		Header: i18n.t('common:table_columns.delete'),
		accessor: 'delete',
		width: 60,
		className: 'text-center',
		sortable: false,
		Cell: ({
			row: {
				_original: { id }
			}
		}) =>
			!isReadOnly && (
				<button
					onClick={() => handleSelectItem(id)}
					className="btn btn-xs btn-primary"
				>
					<i className="fa fa-trash" />
				</button>
			)
	}
];
