import i18n from 'i18next';

export const CHANNELS_INPUT_FIELDS_DATA = {
	submitUrl: 'products/channels',
	fetchUrl: 'products/channels',
	cancelButtonPath: 'channels',
	hasMetadata: false,
	defautlFieldValues: {
		title: '',
		description: ''
	},
	inputFields: [
		{
			name: 'title',
			label: i18n.t('channels:form:fields.title'),
			type: 'text',
			required: true
		},
		{
			name: 'summary_long',
			label: i18n.t('channels:form:fields.summary_long'),
			type: 'textarea',
			required: false
		},
		{
			name: 'summary_short',
			label: i18n.t('channels:form:fields.summary_short'),
			type: 'textarea',
			required: false
		}
	]
};
