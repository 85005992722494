import i18n from 'i18next';
import { initReactI18next } from 'react-i18next';

// Import variables
import { LOCAL_STORAGE_LANGUAGE } from 'components/helpers/variables';

// Import translations
import { default as resources } from './translations/index';

let userLang = null;
const localStorageLang = localStorage.getItem(LOCAL_STORAGE_LANGUAGE);

// if there isn't language value in local storage set default val to polish
if (!localStorageLang) {
	localStorage.setItem(LOCAL_STORAGE_LANGUAGE, 'pl');
	userLang = 'pl';
}

// assign local storage language if present
if (localStorageLang) {
	userLang = localStorageLang;
}

i18n
	.use(initReactI18next)
	// init i18next
	.init({
		resources,
		lng: userLang || 'eng',
		debug: false,

		interpolation: {
			escapeValue: false // not needed for react as it escapes by default
		}
	});

export default i18n;
