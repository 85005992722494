import React from 'react';
import PropTypes from 'prop-types';
import { Col, FormGroup, Label, Input } from 'reactstrap';

const SelectLabel = ({
	input,
	id,
	label,
	labelColumn = 2,
	inputColumn = 10,
	children,
	meta: { touched, error, submitError },
	fieldInfo = null,
	...inputProps
}) => {
	return (
		<FormGroup row className={`${touched && error ? 'has-error' : ''} `}>
			<Label
				for={id}
				sm={labelColumn}
				className="col-form-label col-form-label-lg"
			>
				{label}
			</Label>
			<Col sm={inputColumn}>
				<Input
					invalid={touched && (error || submitError) ? true : false}
					type="select"
					{...input}
					{...inputProps}
				>
					{children}
				</Input>
				{touched && (error || submitError) && (
					<span className="input-error">{error || submitError}</span>
				)}
				{fieldInfo && (
					<span className="input-info">
						<i className="fa fa-info-circle m-r-sm"></i>
						{fieldInfo}
					</span>
				)}
			</Col>
		</FormGroup>
	);
};

SelectLabel.propTypes = {
	input: PropTypes.object.isRequired,
	label: PropTypes.string.isRequired,
	id: PropTypes.string.isRequired,
	inputColumn: PropTypes.number,
	labelColumn: PropTypes.number,
	meta: PropTypes.object
};

export default SelectLabel;
