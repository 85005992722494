import React from 'react';
import i18n from 'i18next';

// Import components
import CategoriesForm from '../../categories_form/CategoriesForm';
import CategoriesImages from 'components/views/categories/categories_file/CategoriesImages';
import CategoriesChildrenTable from 'components/views/categories/categories_children_table/CategoriesChildrenTable';

// Import utilities
import { generateContentTranslationsTabs } from 'components/utilities/content_translation/tabs/content_translations_tabs';

// import content translation fields
import { CATEGORIES_INPUT_FIELDS_DATA } from 'components/utilities/content_translation/input_fields';

// Validate
import { categoriesTranslationValidate } from 'components/utilities/content_translation/validation';

export const categoriesBasicTabs = (isFormDataAvailable) => [
	{
		name: i18n.t('common:tabs.metadata'),
		path: 'metadata',
		disableOnCreate: false,
		component: (
			<>
				<CategoriesForm />
				{isFormDataAvailable && <CategoriesChildrenTable />}
			</>
		)
	},
	{
		name: i18n.t('common:tabs.pictures'),
		path: 'pictures',
		disableOnCreate: true,
		component: <CategoriesImages />
	}
];

export const categoriesTabs = (
	isFormDataAvailable,
	contentTranslationLanguages
) => [
	...categoriesBasicTabs(isFormDataAvailable),
	...generateContentTranslationsTabs(
		CATEGORIES_INPUT_FIELDS_DATA,
		contentTranslationLanguages,
		categoriesTranslationValidate
	)
];
