export const replaceStrDots = (str) => str.replace(/\./g, '|');
export const replaceStr = (str, replace = ' ') => str.replace(/\|/g, replace);

export const convertFormData = (values) =>
	Object.entries(values).reduce(
		(prev, [name, value]) => {
			const key = replaceStr(name, '.');
			prev.items.push({ key, value });
			return prev;
		},
		{ items: [] }
	);
