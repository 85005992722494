// Import helpers
import { convertPrice, convertPriceToCents } from 'components/helpers/convert';

// convert promotion value to proper unit (percentage | zł) in promotion table
export const convertPromotionValue = (promotionType, value) => {
	switch (promotionType) {
		case 'percentage':
			return value + ' %';

		case 'discount':
			return convertPrice(value) + ' zł';

		case 'static_price':
			return convertPrice(value) + ' zł';

		default:
			return value;
	}
};

// convert promotion form values to int (percent) and to grosz (discount | static_price)
// before sending request
export const convertFormSubmitValues = (promotionType, value) => {
	switch (promotionType) {
		case 'percentage':
			return parseInt(value, 10);

		case 'discount':
			return convertPriceToCents(value);

		case 'static_price':
			return convertPriceToCents(value);

		default:
			return value;
	}
};

// convert promotion values in form initial values
// if somehow value is equal to 0 return null
export const convertFormInitialValues = (promotionType, value) => {
	if (promotionType !== 'percentage') {
		const convertedPrice = convertPrice(value);
		return convertedPrice ? convertedPrice : 0;
	}
	return value ? value : 0;
};
