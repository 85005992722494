import React, { useContext } from 'react';
import { Field } from 'react-final-form';
import PropTypes from 'prop-types';

// Import context
import { MaterialsContext } from '../materials_context/MaterialsContext';

// Import translation
import { useTranslation } from 'react-i18next';

// Import select options
import { select_types, security_type } from './select_type_options';

// Import components
import {
	InputLabel,
	SelectLabel,
	TextareaLabel
} from 'components/common/inputs';

const Fields = ({ drmKey, platformTypes }) => {
	const { t } = useTranslation();

	const { isEdit } = useContext(MaterialsContext);

	// display drm key as placeholder if we are editing material
	const drmPlaceholder = drmKey
		? drmKey
		: t('common:materials_table.form.drm_key_placeholder');

	return (
		<React.Fragment>
			<Field
				name="format"
				type="select"
				label={t('common:materials_table.form.format_label')}
				id="format"
				disabled={isEdit}
				required={true}
				component={SelectLabel}
				labelColumn={3}
				inputColumn={9}
			>
				{select_types.map(({ name }, index) => (
					<option key={index} value={name}>
						{name}
					</option>
				))}
			</Field>
			<Field
				name="platform"
				type="select"
				label={t('common:materials_table.form.platform_label')}
				id="platform"
				required={false}
				component={SelectLabel}
				labelColumn={3}
				inputColumn={9}
				allowNull={true}
			>
				{platformTypes.map(({ name, value }) => (
					<option key={`${name}`} value={value}>
						{name}
					</option>
				))}
			</Field>
			<Field
				name="security_type"
				type="select"
				label={t('common:materials_table.form.security_type')}
				id="security_type"
				required={false}
				component={SelectLabel}
				labelColumn={3}
				inputColumn={9}
				allowNull={true}
			>
				{security_type.map(({ name, value }) => (
					<option key={`${name}`} value={value}>
						{name}
					</option>
				))}
			</Field>
			<Field
				name="asset_url"
				type="text"
				placeholder={t('common:materials_table.form.asset_url_placeholder')}
				label={t('common:materials_table.form.asset_url_label')}
				id="asset_url"
				required={true}
				component={InputLabel}
				labelColumn={3}
				inputColumn={9}
			/>
			<Field
				name="drm_key"
				type="text"
				placeholder={drmPlaceholder}
				label={t('common:materials_table.form.drm_key_label')}
				id="drm_key"
				required={false}
				component={InputLabel}
				labelColumn={3}
				inputColumn={9}
			/>
			<Field
				name="iv"
				type="textarea"
				placeholder={t('common:materials_table.form.iv_label_placeholder')}
				label={t('common:materials_table.form.iv_label')}
				id="iv"
				required={false}
				component={TextareaLabel}
				labelColumn={3}
				inputColumn={9}
			/>
		</React.Fragment>
	);
};

Fields.propTypes = {
	// videoAssetsFormats: PropTypes.array.isRequired,
	drmKey: PropTypes.string,
	platformTypes: PropTypes.array
};

export default Fields;
