import React from 'react';
import PropTypes from 'prop-types';
import { Modal } from 'antd';
import { Toggle } from 'components/utilities';
import message from 'antd/es/message';
import { useDispatch } from 'react-redux';

// Import translation
import i18n from 'i18next';

// Import helpers
import { removeSubscriberPacket } from 'components/helpers/packets';

const DeletePacket = ({ subscriberId, packetUuid }) => {
	const dispatch = useDispatch();

	// shows modal to confirm delete. On OK it calls function to delete that is in Context
	const confirmModal = () =>
		Modal.confirm({
			title: i18n.t(`common:delete`),
			content: `${i18n.t(`messages:modal.delete_confirm_message`)} ${i18n.t(
				`names:notification_names.packet`
			)}`,
			okText: i18n.t('common:buttons.ok'),
			cancelText: i18n.t('common:buttons.cancel'),
			onOk: () =>
				removeSubscriberPacket({ subscriberId, packetUuid, dispatch }),
			onCancel() {
				message.info(i18n.t('messages:messages.operation_canceled'));
			}
		});

	return (
		<Toggle>
			{() => (
				<button onClick={confirmModal} className="btn btn-xs btn-primary">
					<i className="fa fa-trash" />
				</button>
			)}
		</Toggle>
	);
};

DeletePacket.propTypes = {
	subscriberId: PropTypes.string.isRequired,
	packetUuid: PropTypes.string.isRequired
};

export default DeletePacket;
