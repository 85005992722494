import React from 'react';

export const RouteContext = React.createContext(null);

const RouteProvider = ({ permissions, children }) => {
	return (
		<RouteContext.Provider value={{ permissions }}>
			{children}
		</RouteContext.Provider>
	);
};

export default RouteProvider;
