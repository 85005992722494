import React from 'react';
import { bool, func, object } from 'prop-types';
import { connect } from 'react-redux';
import {
	setEventsLiveQuery,
	fetchEventsLive,
	deleteEventLive
} from 'store/actions';
import { useTranslation } from 'react-i18next';

// Import columns
import { createColumns } from './events_live_columns';

// Import utilities
import Search from 'components/utilities/search/Search';

// Import components
import { PanelPageTemplate } from 'components/common/templates';
import FilterButton from 'components/common/buttons/FilterButton';

const EventsLive = ({
	error,
	setEventsLiveQuery,
	fetchEventsLive,
	deleteEventLive,
	resources
}) => {
	const { t } = useTranslation();

	const { columns } = resources;

	return (
		// Dispatch fetchEventsLive, deleteEventLive action in events_live_panel
		<PanelPageTemplate
			title="Lista"
			error={error}
			type="events_live"
			addLinkText={t('events_live:buttons.add')}
			resources={resources}
			columns={createColumns}
			fetchResourcesAction={fetchEventsLive}
			deleteItemAction={deleteEventLive}
			notificationDeleteSuccessTxt={t(
				'events_live:notification.delete_success'
			)}
			notificationDeleteErrorTxt={t('events_live:notification.delete_error')}
		>
			<div className="d-flex justify-content-end">
				<Search
					setQueryValue={setEventsLiveQuery}
					value={resources.options.filters.query}
				/>
				<FilterButton tableColumns={columns} />
			</div>
		</PanelPageTemplate>
	);
};

EventsLive.propTypes = {
	error: bool.isRequired,
	setEventsLiveQuery: func.isRequired,
	resources: object.isRequired,
	fetchEventsLive: func.isRequired,
	deleteEventLive: func.isRequired
};

const mapStateToProps = ({ events_live }) => ({
	error: events_live.table.error,
	resources: events_live.table
});

export default connect(mapStateToProps, {
	setEventsLiveQuery,
	fetchEventsLive,
	deleteEventLive
})(EventsLive);
