import i18n from 'i18next';

export const defaultFieldValues = {
	parent_uuid: null,
	title: '',
	summary_long: '',
	summary_short: '',
	rating: '0',
	data: {
		hide_title: false,
		country: '',
		actors: [],
		actors_display: '',
		writers: [],
		writers_display: '',
		directors: [],
		directors_display: '',
		scene: '',
		duration: 0,
		release_description: '',
		release_year: 0,
		producers: [],
		producers_display: ''
	},
	genres: [],
	active: false,
	type: 'live_event',
	subtype: 'live_event'
};

export const BASE_FIELDS = ({ isEdit, checkedPermissions }) => [
	{
		name: 'type',
		label: i18n.t('events_live:fields.type'),
		type: 'select',
		required: true,
		disabled: true
	},
	{
		name: 'title',
		label: i18n.t('events_live:fields.title'),
		type: 'text',
		required: true
	},
	{
		name: 'summary_short',
		label: i18n.t('events_live:fields.summary_short'),
		type: 'textarea',
		required: true
	},
	{
		name: 'summary_long',
		label: i18n.t('events_live:fields.summary_long'),
		type: 'textarea',
		required: false
	},
	{
		name: 'active',
		label: i18n.t('events_live:fields.active'),
		type: 'checkbox',
		disabled: !checkedPermissions.canActivate,
		fieldInfo: i18n.t('vod:form:fields_info.active')
	},
	{
		name: 'data.scene',
		label: i18n.t('events_live:fields.scene'),
		type: 'text'
	},
	{
		name: 'data.duration',
		label: i18n.t('events_live:fields.duration'),
		type: 'text',
		required: true
	},
	{
		name: 'data.release_description',
		label: i18n.t('events_live:fields.release_description'),
		type: 'textarea'
	},
	{
		name: 'data.release_year',
		label: i18n.t('events_live:fields.release_year'),
		type: 'number',
		required: true
	},
	{
		name: 'rating',
		label: i18n.t('events_live:fields.rating'),
		type: 'number',
		required: true
	},
	{
		name: 'data.country',
		label: i18n.t('events_live:fields.country'),
		type: 'text'
	},
	{
		name: 'data.actors',
		label: i18n.t('events_live:fields.actors'),
		type: 'multi-select',
		mode: 'tags',
		fieldInfo: i18n.t('common:form:fields_info.people_list')
	},
	{
		name: 'data.writers',
		label: i18n.t('events_live:fields.writers'),
		type: 'multi-select',
		mode: 'tags',
		fieldInfo: i18n.t('common:form:fields_info.people_list')
	},
	{
		name: 'data.directors',
		label: i18n.t('events_live:fields.directors'),
		type: 'multi-select',
		mode: 'tags',
		fieldInfo: i18n.t('common:form:fields_info.people_list')
	},
	{
		name: 'data.producers',
		label: i18n.t('events_live:fields.producers'),
		type: 'multi-select',
		mode: 'tags',
		fieldInfo: i18n.t('common:form:fields_info.people_list')
	},
	{
		name: 'genres',
		label: i18n.t('events_live:fields.genres'),
		type: 'multi-select',
		mode: 'multiple'
	},
	{
		name: 'payment_models',
		label: i18n.t('events_live:fields.payment_models'),
		type: 'multi-select',
		mode: 'multiple',
		disabled: true
	},
	{
		name: 'data.hide_title',
		label: i18n.t('events_live:fields.hide_title'),
		type: 'checkbox'
	},

	{
		name: 'data.created_at',
		label: i18n.t('events_live:fields.created_at'),
		type: 'text',
		disabled: true,
		isVisible: !isEdit
	},
	{
		name: 'data.updated_at',
		label: i18n.t('events_live:fields.updated_at'),
		type: 'text',
		disabled: true,
		isVisible: !isEdit
	}
];
