import _ from 'lodash';

// Import translation
import i18n from 'i18next';

export default ({ group_ids }) => {
	const errors = {};

	const isInitialValue = _.isArray(group_ids) && group_ids.length === 0;

	// ------------------ group_ids ------------------
	if (isInitialValue) {
		errors.group_ids = i18n.t('geoblock_groups:validation.group_ids');
	}

	return errors;
};
