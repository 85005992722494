import React from 'react';
import PropTypes from 'prop-types';
import { withRouter } from 'react-router-dom';
import { Alert, Spin } from 'antd';

// Import components
import useFetchConfig from './useFetchConfig';
import ShakaPlayer from './ShakaPlayer';

// Import translation
import { useTranslation } from 'react-i18next';

// Import helpers
import { getProductType } from './helpers';

const MaterialPlayer = ({
	match: {
		params: { id }
	},
	location: { pathname },
	format,
	videoId,
	platform
}) => {
	const { t } = useTranslation();

	const productType = getProductType(pathname);

	const { playlist, isSuccess, isError } = useFetchConfig({
		id,
		productType,
		format,
		videoId,
		platform
	});

	return (
		<div className="material_player h-300">
			{isError && (
				<Alert
					showIcon
					type="error"
					description={t('common:errors.data_retrieve')}
					className="m-b-md animated fadeIn"
				/>
			)}
			{isSuccess ? (
				<ShakaPlayer playlist={playlist} videoFormat={format.toUpperCase()} />
			) : (
				<div className="text-center">
					<Spin size="large" />
				</div>
			)}
		</div>
	);
};

MaterialPlayer.propTypes = {
	match: PropTypes.shape({
		params: PropTypes.shape({ id: PropTypes.string.isRequired })
	}),
	location: PropTypes.shape({
		hash: PropTypes.string.isRequired
	}),
	format: PropTypes.string.isRequired,
	videoId: PropTypes.number.isRequired
};

export default withRouter(MaterialPlayer);
