import { combineReducers } from 'redux';
// Import reducers
import table from './operators_panel_reducer';
import form from './operators_form_reducer';
import operatorRolesNames from './operators_roles_reducer';

export default combineReducers({
	table,
	form,
	operatorRolesNames
});
