export const DICTIONARY_VIDEO_FORMATS = 'DICTIONARY_VIDEO_FORMATS';
export const DICTIONARY_PRODUCT_TYPES = 'DICTIONARY_PRODUCT_TYPES';
export const DICTIONARY_VIDEO_TYPES = 'DICTIONARY_VIDEO_TYPES';
export const DICTIONARY_ASSETS_COLLECTION_TYPES =
	'DICTIONARY_ASSETS_COLLECTION_TYPES';
export const DICTIONARY_COUNTER_TYPES = 'DICTIONARY_COUNTER_TYPES';
export const DICTIONARY_IP_RANGE_TYPES = 'DICTIONARY_IP_RANGE_TYPES';
export const DICTIONARY_PACKETS_TYPES = 'DICTIONARY_PACKETS_TYPES';
export const DICTIONARY_PARTENTAL_CONTROL_RATING =
	'DICTIONARY_PARTENTAL_CONTROL_RATING';
export const DICTIONARY_PAYMENT_METHODS = 'DICTIONARY_PAYMENT_METHODS';
export const DICTIONARY_PLAY_MODES = 'DICTIONARY_PLAY_MODES';
export const DICTIONARY_REMINDER_TYPES = 'DICTIONARY_REMINDER_TYPES';
export const DICTIONARY_SECTION_TYPES = 'DICTIONARY_SECTION_TYPES';
export const DICTIONARY_AGREEMENTS_TYPES = 'DICTIONARY_AGREEMENTS_TYPES';
export const DICTIONARY_PLATFORMS_TYPES = 'DICTIONARY_PLATFORMS_TYPES';
export const DICTIONARY_PAYMENT_MODELS = 'DICTIONARY_PAYMENT_MODELS';
export const DICTIONARY_CAST_TYPES = 'DICTIONARY_CAST_TYPES';
export const DICTIONARY_ASSET_TYPES = 'DICTIONARY_ASSET_TYPES';
export const DICTIONARY_VOD_SUBSTATUS = 'DICTIONARY_VOD_SUBSTATUS';
export const DICTIONARY_SUBSCRIBER_SOURCE = 'DICTIONARY_SUBSCRIBER_SOURCE';
export const DICTIONARY_PERIOD_UNIT = 'DICTIONARY_PERIOD_UNIT';
export const DICTIONARY_MAIN = 'DICTIONARY_MAIN';
export const DICTIONARY_SEGMENTS_TYPES = 'DICTIONARY_SEGMENTS_TYPES';
export const DICTIONARY_GEOBLOCK_GROUPS_LIST =
	'DICTIONARY_GEOBLOCK_GROUPS_LIST';
export const DICTIONARY_COUNTRIES_WITH_CODES =
	'DICTIONARY_COUNTRIES_WITH_CODES';
export const DICTIONARY_PROVISION = 'DICTIONARY_PROVISION';
