// ******************** OPERATORS DATA TABLE ********************
export const FETCH_OPERATORS_LOADING = 'FETCH_OPERATORS_LOADING';
export const FETCH_OPERATORS_SUCCESS = 'FETCH_OPERATORS_SUCCESS';
export const FETCH_OPERATORS_ERROR = 'FETCH_OPERATORS_ERROR';

// ******************** SET SEARCH QUERY ********************
export const SET_OPERATORS_SEARCH_QUERY = 'SET_OPERATORS_SEARCH_QUERY';

// ******************** SAVE SORTING OPTIONS  ********************
export const SAVE_OPERATORS_TABLE_SORTING = 'SAVE_OPERATORS_TABLE_SORTING';

// ******************** DELETE OPERATOR ********************
export const DELETE_OPERATOR_LOADING = 'DELETE_OPERATOR_LOADING';
export const DELETE_OPERATOR_SUCCESS = 'DELETE_OPERATOR_SUCCESS';
export const DELETE_OPERATOR_ERROR = 'DELETE_OPERATOR_ERROR';

// ******************** OPERATOR ROLES ********************
export const FETCH_OPERATORS_ROLES_LOADING = 'FETCH_OPERATORS_ROLES_LOADING';
export const FETCH_OPERATORS_ROLES_SUCCESS = 'FETCH_OPERATORS_ROLES_SUCCESS';
export const FETCH_OPERATORS_ROLES_ERROR = 'FETCH_OPERATORS_ROLES_ERROR';

// ******************** OPERATOR ********************
export const FETCH_OPERATOR_LOADING = 'FETCH_OPERATOR_LOADING';
export const FETCH_OPERATOR_SUCCESS = 'FETCH_OPERATOR_SUCCESS';
export const FETCH_OPERATOR_ERROR = 'FETCH_OPERATOR_ERROR';

// ******************** FETCH OPERATOR ROLES ********************
export const FETCH_OPERATOR_ROLES = 'FETCH_OPERATOR_ROLES';
