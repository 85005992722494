import { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';

// Import services
import services from 'services/services';

// Import redux actions
import { dictionaryPlatformsTypes } from 'store/actions';

// Import helpers
import { checkFetchErrors } from 'components/helpers/error';
import { DEFAULT_PLATFORM_TYPE } from './helpers';

const useSectionsPreview = (itemId) => {
	const dispatch = useDispatch();

	const [loading, setLoading] = useState(false);
	const [products, setProducts] = useState([]);
	const [fetchError, setFetchError] = useState(false);
	const [currentPlatformType, setCurrentPlatformType] = useState(
		DEFAULT_PLATFORM_TYPE
	);

	const { platformTypes } = useSelector(({ dictionary }) => dictionary);

	const fetchSectionsPreview = async (currentPlatformType) => {
		try {
			setLoading(true);

			const { data } = await services.get(
				`/section/${itemId}/content?platform=${currentPlatformType}`
			);

			setProducts(data?.data);
			setLoading(false);
		} catch (error) {
			setFetchError(checkFetchErrors(error));
		}
	};

	const handleChangePlatformType = ({ target: { value } }) => {
		setCurrentPlatformType(value);
	};

	useEffect(() => {
		fetchSectionsPreview(currentPlatformType);
		// eslint-disable-next-line
	}, [currentPlatformType]);

	useEffect(() => {
		dictionaryPlatformsTypes()(dispatch);
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, []);

	return {
		products,
		loading,
		fetchError,
		fetchSectionsPreview,
		onChangePlatformType: handleChangePlatformType,
		currentPlatformType,
		platformTypes
	};
};

export default useSectionsPreview;
