import React from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';

// Import components
import SignInForm from './form/SignInForm';
import SignInTop from './SignInTop';
import ChangeLanguage from 'components/utilities/translation/ChangeLanguage';
import SignInAlert from './SignInAlert';

// Import translation
import i18n from 'i18next';

class SignIn extends React.Component {
	static propTypes = {
		isError: PropTypes.bool.isRequired
	};

	render() {
		const { isError } = this.props;
		return (
			<div className="login gray-bg">
				<div className="login__bg" />
				{isError && (
					<SignInAlert
						title={i18n.t('login:error.title')}
						message={i18n.t('login:error.message')}
					/>
				)}
				<div className="sk-loading text-center login__screen animated">
					<div className="login__content">
						<ChangeLanguage isMain={false} />
						<SignInTop />
						<SignInForm />
					</div>
				</div>
			</div>
		);
	}
}

const mapStateToProps = (state) => {
	return {
		isError: state.auth.errorMessage
	};
};

export default connect(mapStateToProps)(SignIn);
