import React from 'react';
import PropTypes from 'prop-types';
import { Col, FormGroup, Label, Input } from 'reactstrap';

const InputLabel = ({
	input,
	id,
	label,
	labelColumn = 2,
	inputColumn = 10,
	children,
	fieldInfo = null,
	meta: { touched, error, submitError },
	...inputProps
}) => {
	// Render field info if there aren't any errors
	const renderFieldInfo = () => {
		if (touched && (error || submitError)) return null;
		if (fieldInfo) {
			return (
				<span className="input-info">
					<i className="fa fa-info-circle m-r-sm"></i>
					{fieldInfo}
				</span>
			);
		}
	};

	return (
		<FormGroup
			data-testid={`form-group-${input.name}`}
			row
			className={`${touched && error && 'has-error'}`}
		>
			<Label
				for={id}
				sm={labelColumn}
				className="col-form-label col-form-label-lg"
			>
				{label}
			</Label>
			<Col sm={inputColumn}>
				<Input
					invalid={touched && (error || submitError) ? true : false}
					id={id}
					{...input}
					{...inputProps}
				/>
				{touched && (error || submitError) && (
					<span
						data-testid={`input-error-${input.name}`}
						className="input-error"
					>
						{error || submitError}
					</span>
				)}
				{renderFieldInfo()}
			</Col>
			{children}
		</FormGroup>
	);
};

InputLabel.propTypes = {
	input: PropTypes.object.isRequired,
	label: PropTypes.string,
	id: PropTypes.string.isRequired,
	labelColumn: PropTypes.number,
	inputColumn: PropTypes.number,
	children: PropTypes.object,
	meta: PropTypes.object,
	fieldInfo: PropTypes.string
};

export default InputLabel;
