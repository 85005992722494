import React from 'react';
import i18n from 'i18next';

// Import components
import AgreementsVersionsForm from '../../agreements_versions_form/AgreementsVersionsForm';

// Import utilities
import { generateContentTranslationsTabs } from 'components/utilities/content_translation/tabs/content_translations_tabs';

// import content translation fields
import { createAgreementsVersionsInputData } from 'components/utilities/content_translation/input_fields';

// Validate
import { agreementsVersionsTranslationValidate } from 'components/utilities/content_translation/validation';

const agreementsVersionsBasicTabs = [
	{
		name: i18n.t('common:tabs.metadata'),
		path: 'metadata',
		disableOnCreate: false,
		component: <AgreementsVersionsForm />
	}
];

export const agreementsVersionsTabs = (
	contentTranslationLanguages,
	agreementId
) => [
	...agreementsVersionsBasicTabs,
	...generateContentTranslationsTabs(
		createAgreementsVersionsInputData(agreementId),
		contentTranslationLanguages,
		agreementsVersionsTranslationValidate
	)
];
