import React from 'react';
import { Link } from 'react-router-dom';

// Import translation
import i18n from 'i18next';

// Import components
import DeletePacket from 'components/utilities/delete_packet/DeletePacket';

export const createProvisioningColumns = () => [
	{
		Header: i18n.t('subscribers:table_columns.provisioning_title'),
		accessor: 'title',
		sortable: false,
		Cell: ({
			row: {
				_original: { uuid, title, subtype }
			}
		}) => (
			<>
				{subtype === 'provision' && (
					<Link to={`/panel/provisioning/edit/${uuid}/metadata`}>{title}</Link>
				)}
			</>
		)
	}
];

export const createSubscriptionsColumns = ({ subscriberId }) => {
	return [
		{
			Header: i18n.t('subscribers:table_columns.subscriptions_title'),
			accessor: 'title',
			sortable: false,
			Cell: ({
				row: {
					_original: { uuid, title, subtype }
				}
			}) => (
				<>
					{subtype === 'main' && (
						<Link to={`/panel/main/edit/${uuid}/metadata`}>{title}</Link>
					)}
				</>
			)
		},
		{
			dataIndex: 'delete',
			key: 'delete',
			align: 'center',
			width: 70,
			Cell: ({
				row: {
					_original: { uuid }
				}
			}) => <DeletePacket packetUuid={uuid} subscriberId={subscriberId} />
		}
	];
};
