import React from 'react';
import PropTypes from 'prop-types';
import { Field } from 'react-final-form';

// Import translation
import { useTranslation } from 'react-i18next';

// Import select options
import { select_types } from './select_type_options';

// Import components
import {
	InputLabel,
	SelectLabel,
	CheckboxLabel
} from 'components/common/inputs';

const Fields = ({ submitting }) => {
	const { t } = useTranslation();

	return (
		<React.Fragment>
			<Field
				disabled={submitting}
				name="name"
				type="text"
				placeholder={t('agreements:fields.name_placeholder')}
				label={t('agreements:fields.name_label')}
				id="name"
				required={true}
				component={InputLabel}
			/>
			<div className="hr-line-dashed">&nbsp;</div>
			<Field
				disabled={submitting}
				name="rank"
				type="number"
				placeholder={t('agreements:fields.rank_placeholder')}
				label={t('agreements:fields.rank_label')}
				id="rank"
				required={true}
				component={InputLabel}
			/>
			<div className="hr-line-dashed">&nbsp;</div>
			<Field
				disabled={submitting}
				name="type"
				type="select"
				placeholder={t('agreements:fields.type_placeholder')}
				label={t('agreements:fields.type_label')}
				id="type"
				required={true}
				component={SelectLabel}
			>
				{select_types.map((type, index) => (
					<option key={index} value={type.name}>
						{type.name}
					</option>
				))}
			</Field>
			<div className="hr-line-dashed">&nbsp;</div>
			<Field
				disabled={submitting}
				name="obligatory"
				type="checkbox"
				placeholder={t('agreements:fields.required_placeholder')}
				label={t('agreements:fields.required_label')}
				id="required"
				component={CheckboxLabel}
			/>
			<div className="hr-line-dashed">&nbsp;</div>
		</React.Fragment>
	);
};

Fields.propTypes = {
	submitting: PropTypes.bool.isRequired
};

export default Fields;
