import axios from 'axios';

// Import variables
import { LOCALSTORAGE_USER } from 'components/helpers/variables';

const myAxiosFactory = () => () => {
	// Get token
	let token = JSON.parse(localStorage.getItem(LOCALSTORAGE_USER)) || null;

	let headers = {
		'Content-Type': 'application/json',
		Accept: 'application/json'
	};

	if (token) {
		headers.Authorization = `Bearer ${token.access_token}`;
	}

	return axios.create({
		baseURL: `${process.env.REACT_APP_API_URL}`,
		headers
	});
};

const myAxios = myAxiosFactory();
// Export `myAxios`
export default myAxios;
