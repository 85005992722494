import React from 'react';
import { array, bool } from 'prop-types';

// Import fields
import { BASE_FIELDS } from './input_fields';

// Import utilites
import { renderFields } from 'components/utilities/form/renderFields';

// Import select options
import { select_event_live_types as type } from 'components/helpers/variables';

const Fields = ({
	actors,
	writers,
	directors,
	submitting,
	genres,
	paymentsModelsList,
	checkedPermissions,
	producers,
	isEdit
}) => {
	const selectResources = {
		type,
		genres,
		'data.actors': actors,
		'data.writers': writers,
		'data.directors': directors,
		'data.producers': producers,
		payment_models: paymentsModelsList
	};

	return (
		<React.Fragment>
			{BASE_FIELDS({
				isEdit,
				checkedPermissions
			}).map((item, index) =>
				renderFields({ item, index, submitting, selectResources })
			)}
		</React.Fragment>
	);
};

Fields.propTypes = {
	submitting: bool.isRequired,
	isEdit: bool.isRequired,
	genres: array,
	actors: array,
	writers: array,
	directors: array,
	produced_by: array,
	paymentsModelsList: array
};

export default Fields;
