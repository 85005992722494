import React, { useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useTranslation } from 'react-i18next';

// Import actions
import { dictionaryAssetTypes, fetchSettingsVodLogo } from 'store/actions';

// Import components
import Image from 'components/utilities/images/Image';
import InfoTitle from 'components/utilities/info/InfoTitle';
import { Spin } from 'antd';

// Import helpers
import { VOD_LOGO_TYPE } from './helpers';
import {
	IMAGE_SIZE_INFO,
	LOGO_VOD_IMAGE_INFO
} from 'components/helpers/info_helpers/info_texts';

const VodLogoSettings = () => {
	const { t } = useTranslation();
	const dispatch = useDispatch();

	const { assetTypes } = useSelector(({ dictionary }) => dictionary);

	const vodLogoCustomId = assetTypes[VOD_LOGO_TYPE];

	useEffect(() => {
		// Dispatch  action in channels_files
		dictionaryAssetTypes()(dispatch);
		// eslint-disable-next-line
	}, []);

	return (
		<>
			<InfoTitle title={`${IMAGE_SIZE_INFO} ${LOGO_VOD_IMAGE_INFO}`} />

			{Object.entries(assetTypes).length ? (
				<Image
					title={t('common:images.vodLogo')}
					fetchResourcesAction={(id) =>
						fetchSettingsVodLogo(id, VOD_LOGO_TYPE)(dispatch)
					}
					collectionType="logo"
					mediaType={VOD_LOGO_TYPE}
					storePathSelector="settings"
					customId={vodLogoCustomId}
					isDarkBackground
					customFileType={VOD_LOGO_TYPE}
				/>
			) : (
				<div className="d-flex justify-content-center">
					<Spin size="large" />
				</div>
			)}
		</>
	);
};

export default VodLogoSettings;
