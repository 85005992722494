// Import translation
import i18n from 'i18next';

export const bannersTranslationValidate = ({ title, description }) => {
	const errors = {};

	// ------------------ title ------------------
	if (!title) {
		errors.title = i18n.t('banners:validation.title');
	}

	// ------------------ description ------------------
	if (!description) {
		errors.description = i18n.t('banners:validation.description');
	}

	return errors;
};
