import React from 'react';
import PropTypes from 'prop-types';
import { Form } from 'react-final-form';
// Import translation
import { useTranslation } from 'react-i18next';

// Import defaultValues
import { defaultFieldValues } from './fields/input_fields';

// Validate
import validate from './validation';

// Import components
import { FormButtons } from 'components/common/buttons';
import Fields from './fields/Fields';

const MetadataForm = ({
	initialValues,
	isEdit,
	itemID,
	handleOnSubmit,
	path,
	genres,
	casts,
	platforms,
	checkedPermissions
}) => {
	const { t } = useTranslation();
	return (
		<Form
			initialValues={{ ...defaultFieldValues, ...initialValues }}
			onSubmit={handleOnSubmit({ isEdit, itemID })}
			validate={validate}
			render={({ handleSubmit, submitting }) => (
				<form onSubmit={handleSubmit}>
					<Fields
						genres={genres}
						casts={casts}
						platforms={platforms}
						submitting={submitting}
						checkedPermissions={checkedPermissions}
					/>

					<FormButtons
						isButtonDisabled={submitting}
						path={path}
						buttonText={t('common:buttons.submit')}
					/>
				</form>
			)}
		/>
	);
};

MetadataForm.propTypes = {
	initialValues: PropTypes.object.isRequired,
	isEdit: PropTypes.bool.isRequired,
	itemID: PropTypes.string,
	handleOnSubmit: PropTypes.func.isRequired,
	path: PropTypes.string.isRequired,
	genres: PropTypes.array.isRequired,
	casts: PropTypes.array.isRequired,
	platforms: PropTypes.array.isRequired,
	checkedPermissions: PropTypes.object.isRequired
};

export default MetadataForm;
