import React from 'react';

// Import components
import { Toggle } from 'components/utilities';

// Import translation
import useDeleteAssetButton from './useDeleteAssetButton';

const DeleteAssetButton = ({ record }) => {
	const { confirmModal } = useDeleteAssetButton(record);

	return (
		<Toggle>
			{() => (
				<button onClick={confirmModal} className="btn btn-xs btn-primary">
					<i className="fa fa-trash" />
				</button>
			)}
		</Toggle>
	);
};

export default DeleteAssetButton;
