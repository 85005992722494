import * as types from '../types';
import services from 'services/services';

// Import variables
import { LOCALSTORAGE_USER } from 'components/helpers/variables';
// import { mockRoles } from 'components/helpers/roles_variables'; // mock user roles to test application

// ******************** LOGIN ********************
export const signIn = (values) => async (dispatch) => {
	try {
		// Get login response
		const { data } = await services.post('/auth/login', values);
		// Convert data
		const convertedData = {
			...data,
			// roles: mockRoles,
			isAuthenticated: true
		};
		// Add to Local storage
		localStorage.setItem(LOCALSTORAGE_USER, JSON.stringify(convertedData));

		// Dispatch auth action
		dispatch({
			type: types.AUTH_USER_SUCCESS,
			payload: convertedData
		});
	} catch (error) {
		// if there is an err, dispatch error action
		dispatch({
			type: types.AUTH_USER_ERROR,
			payload: true
		});
	}
};

// ******************** SIGNOUT ********************
export const signOut = () => async (dispatch) => {
	try {
		// Dispatch signOut action
		dispatch({
			type: types.SIGN_OUT_LOADING,
			payload: true
		});

		// sign out user
		// await services.post('/backend/api/logout');

		console.log('Action signOut token expired');

		// Remove from local storage
		localStorage.removeItem(LOCALSTORAGE_USER);

		// Dispatch signOut action
		dispatch({
			type: types.SIGN_OUT_SUCCESS,
			payload: null
		});
	} catch (error) {
		// if there is an err, dispatch error action
		dispatch({
			type: types.SIGN_OUT_ERROR,
			payload: true
		});
	}
};

// ******************** REDIRECT SIGNOUT ********************
export const redirectSignOut = () => async (dispatch) => {
	// Remove from local storage
	localStorage.removeItem(LOCALSTORAGE_USER);

	// Dispatch signOut action
	dispatch({
		type: types.SIGN_OUT_SUCCESS,
		payload: null
	});
};

// ******************** SEND RESET EMAIL ********************
export const sendResetEmail = (email) => async (dispatch) => {
	try {
		dispatch({
			type: types.SEND_RESET_EMAIL_LOADING
		});

		const url = '/auth/reset/password';
		await services.post(url, email);

		dispatch({
			type: types.SEND_RESET_EMAIL_SUCCESS
		});
	} catch (error) {
		dispatch({
			type: types.SEND_RESET_EMAIL_ERROR
		});
	}
};

// ******************** CHECK RESET PASSWORD HASH ********************
export const checkResetPasswordHash = (hash) => async (dispatch) => {
	try {
		dispatch({
			type: types.CHECK_RESET_PASSWORD_HASH_LOADING
		});

		const url = `/auth/reset/password/validateHash/${hash}`;
		await services.post(url);

		dispatch({
			type: types.CHECK_RESET_PASSWORD_HASH_SUCCESS
		});
	} catch (error) {
		dispatch({
			type: types.CHECK_RESET_PASSWORD_HASH_ERROR
		});
	}
};

// ******************** SEND NEW PASSWORDS ********************
export const sendNewPasswords = (passwords, hash) => async (dispatch) => {
	try {
		dispatch({
			type: types.SEND_NEW_PASSWORDS_LOADING
		});

		const url = `/auth/reset/password/${hash}`;
		await services.put(url, passwords);

		dispatch({
			type: types.SEND_NEW_PASSWORDS_SUCCESS
		});
	} catch (error) {
		dispatch({
			type: types.SEND_NEW_PASSWORDS_ERROR
		});
	}
};
