import React from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';

// Import helpers
import NAV_LINKS from './helpers/nav_links';
import { isNavItemRendered } from './helpers/helpers';

// Import components
import NavLinkItem from './NavLinkItem';

const Navigation = ({ userRoles }) => (
	<nav
		className="navbar-default navbar-static-side navigation"
		role="navigation"
	>
		<ul className="nav metismenu" id="side-menu">
			<li className="nav-header">
				<div className="logo-element">BLUE ONLINE</div>
			</li>

			{NAV_LINKS.map((link, index) => {
				if (isNavItemRendered(userRoles, link.permissions)) {
					return (
						<React.Fragment key={index}>
							{link.title && (
								<li className="nav__item nav__item--section">
									{' '}
									<h2 className="heading__nav">{link.title}</h2>
								</li>
							)}

							<NavLinkItem link={link} />
						</React.Fragment>
					);
				}
				return null;
			})}
		</ul>
	</nav>
);

const mapStateToProps = ({ auth: { authenticate } }) => ({
	userRoles: authenticate.roles
});

Navigation.propTypes = {
	user: PropTypes.shape({
		name: PropTypes.string,
		avatar: PropTypes.string,
		roles: PropTypes.object
	})
};

export default connect(mapStateToProps, null, undefined, { pure: false })(
	Navigation
);
