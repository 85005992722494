import React from 'react';
import { Link } from 'react-router-dom';

// Import translation
import i18n from 'i18next';

export const createColumns = () => [
	{
		Header: i18n.t('subscribers:table_columns.login'),
		accessor: 'login',
		width: 250,

		Cell: ({
			row: {
				_original: { id, login }
			}
		}) => <Link to={`/panel/subscribers/edit/${id}/metadata`}>{login}</Link>
	},
	{
		Header: i18n.t('subscribers:table_columns.provider'),
		accessor: 'provider',
		width: 200
	},
	{
		Header: i18n.t('subscribers:table_columns.source'),
		accessor: 'source',
		width: 200
	},
	{
		Header: i18n.t('subscribers:table_columns.email'),
		accessor: 'email',
		width: 200
	},
	{
		Header: i18n.t('subscribers:table_columns.phone_number'),
		accessor: 'phone_number',
		width: 200
	},
	{
		Header: i18n.t('subscribers:table_columns.last_login'),
		accessor: 'last_login',
		width: 200
	},
	{
		Header: i18n.t('subscribers:table_columns.created_at'),
		accessor: 'created_at',
		width: 200
	},
	{
		Header: i18n.t('subscribers:table_columns.updated_at'),
		accessor: 'updated_at',
		width: 200
	}
];
