import React from 'react';
import { useDispatch } from 'react-redux';
import { useTranslation } from 'react-i18next';

// Import actions
import {
	fetchEventLiveCover,
	fetchEventLivePoster,
	fetchEventLiveTitle
} from 'store/actions';

// Import components
import Image from 'components/utilities/images/Image';

const EventsLiveImages = () => {
	const { t } = useTranslation();
	const dispatch = useDispatch();

	return (
		<>
			<Image
				title={t('common:images.cover')}
				fetchResourcesAction={(id) => fetchEventLiveCover(id)(dispatch)}
				collectionType="cover"
				mediaType="live_event"
				storePathSelector="events_live"
				productSubtype="events_live"
			/>
			<Image
				title={t('common:images.portrait')}
				fetchResourcesAction={(id) => fetchEventLivePoster(id)(dispatch)}
				collectionType="poster"
				mediaType="live_event"
				storePathSelector="events_live"
				productSubtype="events_live"
			/>
			<Image
				title={t('common:images.title')}
				fetchResourcesAction={(id) => fetchEventLiveTitle(id)(dispatch)}
				collectionType="title"
				mediaType="live_event"
				storePathSelector="events_live"
				productSubtype="events_live"
			/>
		</>
	);
};

export default EventsLiveImages;
