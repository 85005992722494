import React from 'react';
import { useDispatch } from 'react-redux';

// Import actions
import { fetchMainItemCover } from 'store/actions';

// Import components
import Image from 'components/utilities/images/Image';
import InfoTitleCollapse from 'components/utilities/info/InfoTitleCollapse';

// Import helpers
import {
	PACKETS_IMAGE_SIZE_INFO,
	MAIN_IMAGES_REQUIREMENTS
} from 'components/helpers/info_helpers/info_texts';

// Import translation
import { useTranslation } from 'react-i18next';

// Import variables
import {
	COVER_AR_SUBSCRIBED,
	COVER_AR_NOT_SUBSCRIBED,
	COVER_EN_SUBSCRIBED,
	COVER_EN_NOT_SUBSCRIBED,
	COVER_AR_IQ_SUBSCRIBED,
	COVER_AR_IQ_NOT_SUBSCRIBED,
	COVER_EN_IQ_SUBSCRIBED,
	COVER_EN_IQ_NOT_SUBSCRIBED
} from './helpers';

// Import hooks
import useImageTranslations from './useImageTranslations';

const MainImages = () => {
	const { t } = useTranslation();
	const dispatch = useDispatch();

	const { updateImageTranslations } = useImageTranslations();

	return (
		<>
			<InfoTitleCollapse
				title={PACKETS_IMAGE_SIZE_INFO}
				requirements={MAIN_IMAGES_REQUIREMENTS}
			/>
			<Image
				title={t('main:files.cover_en_subscribed')}
				fetchResourcesAction={(id) =>
					fetchMainItemCover(id, COVER_EN_SUBSCRIBED)(dispatch)
				}
				collectionType={COVER_EN_SUBSCRIBED}
				mediaType="packet"
				storePathSelector="main"
				updateImageTranslations={updateImageTranslations}
			/>
			<Image
				title={t('main:files.cover_en_not_subscribed')}
				fetchResourcesAction={(id) =>
					fetchMainItemCover(id, COVER_EN_NOT_SUBSCRIBED)(dispatch)
				}
				collectionType={COVER_EN_NOT_SUBSCRIBED}
				mediaType="packet"
				storePathSelector="main"
				updateImageTranslations={updateImageTranslations}
			/>
			<Image
				title={t('main:files.cover_ar_subscribed')}
				fetchResourcesAction={(id) =>
					fetchMainItemCover(id, COVER_AR_SUBSCRIBED)(dispatch)
				}
				collectionType={COVER_AR_SUBSCRIBED}
				mediaType="packet"
				storePathSelector="main"
				updateImageTranslations={updateImageTranslations}
			/>
			<Image
				title={t('main:files.cover_ar_not_subscribed')}
				fetchResourcesAction={(id) =>
					fetchMainItemCover(id, COVER_AR_NOT_SUBSCRIBED)(dispatch)
				}
				collectionType={COVER_AR_NOT_SUBSCRIBED}
				mediaType="packet"
				storePathSelector="main"
				updateImageTranslations={updateImageTranslations}
			/>
			<Image
				title={t('main:files.cover_en_subscribed_iq')}
				fetchResourcesAction={(id) =>
					fetchMainItemCover(id, COVER_EN_IQ_SUBSCRIBED)(dispatch)
				}
				collectionType={COVER_EN_IQ_SUBSCRIBED}
				mediaType="packet"
				storePathSelector="main"
				updateImageTranslations={updateImageTranslations}
			/>
			<Image
				title={t('main:files.cover_en_not_subscribed_iq')}
				fetchResourcesAction={(id) =>
					fetchMainItemCover(id, COVER_EN_IQ_NOT_SUBSCRIBED)(dispatch)
				}
				collectionType={COVER_EN_IQ_NOT_SUBSCRIBED}
				mediaType="packet"
				storePathSelector="main"
				updateImageTranslations={updateImageTranslations}
			/>
			<Image
				title={t('main:files.cover_ar_subscribed_iq')}
				fetchResourcesAction={(id) =>
					fetchMainItemCover(id, COVER_AR_IQ_SUBSCRIBED)(dispatch)
				}
				collectionType={COVER_AR_IQ_SUBSCRIBED}
				mediaType="packet"
				storePathSelector="main"
				updateImageTranslations={updateImageTranslations}
			/>
			<Image
				title={t('main:files.cover_ar_not_subscribed_iq')}
				fetchResourcesAction={(id) =>
					fetchMainItemCover(id, COVER_AR_IQ_NOT_SUBSCRIBED)(dispatch)
				}
				collectionType={COVER_AR_IQ_NOT_SUBSCRIBED}
				mediaType="packet"
				storePathSelector="main"
				updateImageTranslations={updateImageTranslations}
			/>
		</>
	);
};

export default MainImages;
