import { useEffect } from 'react';
import { useMutation, useQuery } from 'react-query';
import { useHistory, useLocation } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import { useDispatch, useSelector } from 'react-redux';

// Import services
import services from 'services/services';

// Import utilities
import { notificationHandler } from 'components/utilities/notifications/index';

// Import helpers
import { replaceStringChar } from 'components/helpers/convert';
import {
	ADD_SEGMENT_API,
	DELETE_SEGMENT_API,
	GET_SEGMENTS_API,
	UPDATE_SEGMENT_API
} from 'components/helpers/api';

// Import redux actions
import { dictionarySegmentsTypes } from 'store/actions';

const useVodSegments = ({ vodId, videoId }) => {
	const { t } = useTranslation();
	const history = useHistory();
	const { state } = useLocation();

	const dispatch = useDispatch();

	const { segmentsTypes } = useSelector(({ dictionary }) => dictionary);

	const prevPath = state?.fromPath || '/panel/vod';
	const redirectToPrevPath = () => history.push(prevPath);

	const QUERY_SEGMENT_KEY = ['segment', vodId, videoId];

	// ACTIONS

	const handleFetchSegments = async () => {
		const url = replaceStringChar(GET_SEGMENTS_API, {
			':vodId': vodId,
			':videoId': videoId
		});

		return await services.get(url);
	};

	const handleDeleteMutation = async (segmentId) => {
		const url = replaceStringChar(DELETE_SEGMENT_API, {
			':vodId': vodId,
			':videoId': videoId,
			':segmentId': segmentId
		});

		return await services.delete(url);
	};

	const handleDeleteSuccess = () => {
		refetchSegments();

		notificationHandler(
			t('messages:notifications.deleted'),
			`${t('messages:notifications.successfuly_deleted')} ${t(
				`names:notification_names.segment`
			)}`
		);
	};

	const handleError = (error) => {
		const errorMessage =
			error?.response?.data?.message || t('mass_changes:fetch_error');

		notificationHandler(t('common:error'), errorMessage, 'error');
	};

	const handleSubmitMutation = async ({ vodId, videoId, resources }) => {
		const url = replaceStringChar(ADD_SEGMENT_API, {
			':vodId': vodId,
			':videoId': videoId
		});

		return await services.post(url, resources);
	};

	const handleSubmitSuccess = () => {
		notificationHandler(
			t('messages:notifications.created'),
			`${t('messages:notifications.successfuly_created')} ${t(
				`names:notification_names.segment`
			)}`
		);

		redirectToPrevPath();
	};

	const handleEditMutation = async ({
		vodId,
		videoId,
		segmentId,
		resources
	}) => {
		const url = replaceStringChar(UPDATE_SEGMENT_API, {
			':vodId': vodId,
			':videoId': videoId,
			':segmentId': segmentId
		});

		return await services.put(url, resources);
	};

	const handleEditSuccess = () => {
		notificationHandler(
			t('messages:notifications.edited'),
			`${t('messages:notifications.successfuly_edited')} ${t(
				`names:notification_names.segment`
			)}`
		);

		redirectToPrevPath();
	};

	// QUERIES

	const { data, isLoading, isError, refetch: refetchSegments } = useQuery({
		queryKey: QUERY_SEGMENT_KEY,
		queryFn: handleFetchSegments
	});

	const deleteVodSegment = useMutation({
		mutationFn: handleDeleteMutation,
		onSuccess: handleDeleteSuccess,
		onError: handleError
	});

	const submitVodSegment = useMutation({
		mutationFn: handleSubmitMutation,
		onSuccess: handleSubmitSuccess,
		onError: handleError
	});

	const editVodSegment = useMutation({
		mutationFn: handleEditMutation,
		onSuccess: handleEditSuccess,
		onError: handleError
	});

	useEffect(() => {
		!segmentsTypes.length && dictionarySegmentsTypes()(dispatch);
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, []);

	return {
		data: data?.data,
		deleteVodSegment,
		submitVodSegment,
		editVodSegment,
		isAddButtonDisabled: isLoading,
		isError,
		segmentsTypes
	};
};

export default useVodSegments;
