import React from 'react';
import { useSelector } from 'react-redux';
import { fetchContentTranslations } from 'store/actions';
import { connect } from 'react-redux';
import { useHistory, useParams } from 'react-router-dom';

// Import translation
import { useTranslation } from 'react-i18next';

// Import utilities
import { submitForm } from 'components/utilities/form';

// Import components
import { FormTemplate } from 'components/common/templates';
import { FormButtons } from 'components/common/buttons';
import { Form } from 'react-final-form';
import Fields from './fields/Fields';

const ContentTranslationForm = ({
	contentData,
	selectResources = {},
	contentLanguage,
	validate,
	fetchContentTranslations
}) => {
	const { t } = useTranslation();
	const history = useHistory();
	const { agreementId } = useParams();
	const {
		submitUrl,
		fetchUrl,
		fetchUrlSlug,
		cancelButtonPath,
		defaultFieldValues,
		inputFields,
		hasMetadata = false
	} = contentData;

	const translationSubmitUrl = `${submitUrl}/translations/${contentLanguage}`;

	// STORE
	const { error, isLoaded, data, translationsData } = useSelector(
		({ content_translations }) => content_translations.content_translations_data
	);

	// SUBMIT DATA
	const handleOnSubmit = ({ isEdit, itemID }) => async (values) => {
		const resources = hasMetadata
			? {
					...data,
					metadata: { ...data.metada, ...values }
			  }
			: { ...translationsData, ...values };

		// for agreements versions
		if (agreementId) {
			hasMetadata
				? (resources.metadata.agreement_id = agreementId)
				: (resources.agreement_id = agreementId);
		}

		return await submitForm({
			history,
			isEdit,
			itemID,
			resources,
			api: translationSubmitUrl,
			notificationName: 'translations',
			apiSlug: 'update'
		});
	};

	// FORM INITIAL VALUES
	const formInitialValues = {
		...defaultFieldValues,
		...translationsData
	};

	return (
		<FormTemplate
			fetchResourceToEdit={fetchContentTranslations}
			fetchActionResources={{
				fetchUrl,
				fetchUrlSlug,
				contentLanguage,
				hasMetadata
			}}
			error={error}
			isLoaded={isLoaded}
			iboxContentTitle={t('content_translations:form_title')}
		>
			{({ isEdit, itemID }) => (
				<Form
					initialValues={formInitialValues}
					validate={validate}
					onSubmit={handleOnSubmit({ isEdit, itemID })}
					render={({ handleSubmit, submitting }) => (
						<form onSubmit={handleSubmit}>
							<Fields
								submitting={submitting}
								selectResources={selectResources}
								contentFields={inputFields}
							/>
							<FormButtons
								isButtonDisabled={submitting || error}
								path={cancelButtonPath}
								buttonText={t('common:buttons.submit')}
							/>
						</form>
					)}
				/>
			)}
		</FormTemplate>
	);
};

export default connect(null, { fetchContentTranslations })(
	ContentTranslationForm
);
