import React from 'react';
import i18n from 'i18next';

// Import components
import EpgForm from 'components/views/channels/channels_tabs/epg/epg_form/EpgForm';

export const basicEpgTabs = [
	{
		name: i18n.t('common:tabs.metadata'),
		path: 'metadata',
		disableOnCreate: false,
		component: <EpgForm />
	}
];
