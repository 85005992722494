import * as types from 'store/actions/types';
import produce from 'immer';

export const INITIAL_STATE = {
	logo: {
		data: [],
		isLoaded: false,
		error: false
	},
	landingPageBrowser: {
		data: [],
		isLoaded: false,
		error: false
	},
	landingPageMobile: {
		data: [],
		isLoaded: false,
		error: false
	},
	landingPageBrowserRwd: {
		data: [],
		isLoaded: false,
		error: false
	},
	loginPageTV: {
		data: [],
		isLoaded: false,
		error: false
	},
	loginPageNoSubscriptionAr: {
		data: [],
		isLoaded: false,
		error: false
	},
	loginPageNoSubscriptionEn: {
		data: [],
		isLoaded: false,
		error: false
	},
	loginPageNoSubscriptionMobileAr: {
		data: [],
		isLoaded: false,
		error: false
	},
	loginPageNoSubscriptionMobileEn: {
		data: [],
		isLoaded: false,
		error: false
	},
	vodLogo: { data: [], isLoaded: false, error: false }
};

export default (state = INITIAL_STATE, action) =>
	produce(state, (draft) => {
		switch (action.type) {
			// ****************** FETCH SETTINGS'S LOGO FILES ******************
			case types.FETCH_SETTINGS_LOGO_FILES_LOADING:
				draft.logo.data = [];
				draft.logo.isLoaded = false;
				draft.logo.error = false;
				return;

			case types.FETCH_SETTINGS_LOGO_FILES_SUCCESS:
				draft.logo.data = action.payload.map((file) => ({
					...file,
					uid: file.id
				}));
				draft.logo.isLoaded = true;
				return;

			case types.FETCH_SETTINGS_LOGO_FILES_ERROR:
				draft.logo.isLoaded = true;
				draft.logo.error = true;
				return;

			// ****************** FETCH SETTINGS'S LANDING PAGE FILES ******************
			case types.FETCH_LANDING_PAGE_FILES_LOADING:
				draft[action.payload.modelType].data = [];
				draft[action.payload.modelType].isLoaded = false;
				draft[action.payload.modelType].error = false;
				return;

			case types.FETCH_LANDING_PAGE_FILES_SUCCESS:
				draft[action.payload.modelType].data = action.payload.mappedData;
				draft[action.payload.modelType].isLoaded = true;
				return;

			case types.FETCH_LANDING_PAGE_FILES_ERROR:
				draft[action.payload.modelType].isLoaded = true;
				draft[action.payload.modelType].error = true;
				return;

			// ****************** FETCH SETTINGS'S NO SUBSCRIPTION PAGE ******************
			case types.FETCH_NO_SUBSCRIPTION_PAGE_FILES_LOADING:
				draft[action.payload.modelType].data = [];
				draft[action.payload.modelType].isLoaded = false;
				draft[action.payload.modelType].error = false;
				return;

			case types.FETCH_NO_SUBSCRIPTION_PAGE_FILES_SUCCESS:
				draft[action.payload.modelType].data = action.payload.mappedData;
				draft[action.payload.modelType].isLoaded = true;
				return;

			case types.FETCH_NO_SUBSCRIPTION_PAGE_FILES_ERROR:
				draft[action.payload.modelType].isLoaded = true;
				draft[action.payload.modelType].error = true;
				return;

			// ****************** FETCH SETTINGS'S VOD LOGO FILES ******************
			case types.FETCH_SETTINGS_VOD_LOGO_FILES_LOADING:
				draft.vodLogo.data = [];
				draft.vodLogo.isLoaded = false;
				draft.vodLogo.error = false;
				return;

			case types.FETCH_SETTINGS_VOD_LOGO_FILES_SUCCESS:
				draft.vodLogo.data = action.payload.map((file) => ({
					...file,
					uid: file.id
				}));
				draft.vodLogo.isLoaded = true;
				return;

			case types.FETCH_SETTINGS_VOD_LOGO_FILES_ERROR:
				draft.vodLogo.isLoaded = true;
				draft.vodLogo.error = true;
				return;

			default:
				return state;
		}
	});
