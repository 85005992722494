import React from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import {
	setSubscribersQuery,
	fetchSubscribers,
	createFiltersSuggestions,
	setSubscribersType
} from 'store/actions';

// Import hooks
import useFilterLogic from 'hooks/useFilterLogic';

// Import translation
import { useTranslation } from 'react-i18next';

// Import columns
import { createColumns } from './subscriber_columns';

// Import utilities
import Search from 'components/utilities/search/Search';
import FilterSelect from '../../utilities/filter_select/FilterSelect';

// Import components
import { PanelPageTemplate } from 'components/common/templates';
import FilterButton from 'components/common/buttons/FilterButton';

// Import helpers
import { SUBSCRIBERS_TYPES, SUBSCRIBER_SOURCE_TYPES } from './helpers';

const Subscribers = ({
	error,
	setSubscribersQuery,
	fetchSubscribers,
	resources,
	createFiltersSuggestions,
	setSubscribersType
}) => {
	const { t } = useTranslation();

	const { columns } = resources;

	// execute filters logic
	useFilterLogic({
		createFiltersSuggestions,
		filters: {
			source: SUBSCRIBER_SOURCE_TYPES
		}
	});

	return (
		// Dispatch fetchSubscribers, deleteSubscriber action in subscribers_panel
		<PanelPageTemplate
			title="Lista"
			error={error}
			type="subscribers"
			resources={resources}
			columns={createColumns}
			fetchResourcesAction={fetchSubscribers}
			notificationDeleteSuccessTxt={t(
				'subscribers:notification.delete_success'
			)}
			notificationDeleteErrorTxt={t('subscribers:notification.delete_success')}
		>
			<div>
				<FilterSelect
					options={SUBSCRIBERS_TYPES}
					label={t('subscribers:label.subscribers_type')}
					action={setSubscribersType}
					defaultValue={SUBSCRIBERS_TYPES[0].value}
					width={250}
				/>
				<div className="d-flex justify-content-end">
					<Search
						setQueryValue={setSubscribersQuery}
						value={resources.options.filters.query}
					/>
					<FilterButton tableColumns={columns} />
				</div>
			</div>
		</PanelPageTemplate>
	);
};

Subscribers.propTypes = {
	error: PropTypes.bool.isRequired,
	setSubscribersQuery: PropTypes.func.isRequired,
	resources: PropTypes.object.isRequired,
	fetchSubscribers: PropTypes.func.isRequired,
	createFiltersSuggestions: PropTypes.func.isRequired,
	setSubscribersType: PropTypes.func.isRequired
};

const mapStateToProps = ({ subscribers }) => {
	return {
		error: subscribers.table.error,
		resources: subscribers.table
	};
};

export default connect(mapStateToProps, {
	setSubscribersQuery,
	fetchSubscribers,
	createFiltersSuggestions,
	setSubscribersType
})(Subscribers);
