import React from 'react';

// Import styles
import { FrameWrapper } from './styles';

const Reports = () => {
	return (
		<FrameWrapper>
			<iframe
				title="widekhaliji_reports_iframe"
				style={{
					width: '100%',
					height: '100%',
					border: 'none'
				}}
				src={`https://datastudio.google.com/embed/reporting/${process.env.REACT_APP_DATASTUDIO_REPORT_UUID}`}
				allowFullScreen
				sandbox="allow-scripts allow-same-origin"
			/>
		</FrameWrapper>
	);
};

export default Reports;
