import React from 'react';
import { Route, Switch, Redirect } from 'react-router-dom';
import { useSelector } from 'react-redux';

// Import routes
import ROUTES_PATHS from './helpers/route_paths';

// Import helpers
import { generateRedirectPath } from './helpers/helpers';

// Import context
import RouteProvider from './context/RouteProvider';

const Routes = () => {
	const { roles: userRoles } = useSelector((state) => state.auth.authenticate);
	return (
		<Switch>
			<Redirect exact from="/panel" to={generateRedirectPath(userRoles)} />
			{ROUTES_PATHS.map((route, index) => (
				<Route
					key={index}
					path={route.path}
					render={() => (
						<RouteProvider permissions={route.permissions}>
							<route.component />
						</RouteProvider>
					)}
				/>
			))}
			<Route render={() => <Redirect to={generateRedirectPath(userRoles)} />} />
		</Switch>
	);
};

export default Routes;
