import React from 'react';
import { array, bool } from 'prop-types';

// Import utilites
import { renderFields } from 'components/utilities/form/renderFields';

// Import fields
import { BASE_FIELDS } from './input_fields';

const Fields = ({ submitting, geoblockGroups }) => {
	const selectResources = {
		group_ids: geoblockGroups
	};

	return (
		<>
			{BASE_FIELDS.map((item, index) =>
				renderFields({ item, index, submitting, selectResources })
			)}
		</>
	);
};

Fields.propTypes = {
	submitting: bool.isRequired,
	geoblockGroups: array.isRequired
};

export default Fields;
