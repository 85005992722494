import React from 'react';
import { Link } from 'react-router-dom';

// Import translation
import i18n from 'i18next';

// Import convert
import { convertPrice } from 'components/helpers/convert';

// Import helpers
import { getPacketSubtypePath } from './helpers';

// Import components
import DeletePacket from 'components/utilities/delete_packet/DeletePacket';

export const createColumns = () => [
	{
		Header: i18n.t('assigned_packets:table_columns.title'),
		accessor: 'title',
		Cell: ({
			row: {
				_original: { product_uuid, product_title, product_subtype }
			}
		}) => {
			const path = `/panel/${getPacketSubtypePath(
				product_subtype
			)}/edit/${product_uuid}/metadata`;

			return <Link to={path}>{product_title}</Link>;
		}
	},
	{
		Header: i18n.t('assigned_packets:table_columns.provider'),
		accessor: 'provider',
		width: 200
	},
	{
		Header: i18n.t('assigned_packets:table_columns.price'),
		accessor: 'price',
		width: 100,
		Cell: ({
			row: {
				_original: { price }
			}
		}) => <span>{convertPrice(price)}</span>
	},
	{
		Header: i18n.t('assigned_packets:table_columns.payment_method'),
		accessor: 'payment_method',
		width: 120
	},
	{
		Header: i18n.t('assigned_packets:table_columns.period'),
		accessor: 'period',
		width: 150,
		Cell: ({
			row: {
				_original: { period }
			}
		}) => (
			<span>
				{parseInt(period) === -1 ? i18n.t('prices:recurring_payment') : period}
			</span>
		)
	},
	{
		Header: i18n.t('assigned_packets:table_columns.period_unit'),
		accessor: 'period_unit',
		width: 140
	},
	{
		Header: i18n.t('assigned_packets:table_columns.created_at'),
		accessor: 'created_at',
		width: 150
	},
	{
		Header: i18n.t('assigned_packets:table_columns.user_login'),
		accessor: 'user_login',
		Cell: ({
			row: {
				_original: { user_id, user_login }
			}
		}) => (
			<Link to={`/panel/subscribers/edit/${user_id}/metadata`}>
				{user_login}
			</Link>
		)
	},
	{
		Header: i18n.t('assigned_packets:table_columns.email'),
		accessor: 'email'
	},
	{
		Header: i18n.t('assigned_packets:table_columns.phone_number'),
		accessor: 'phone_number',
		width: 130
	},
	{
		Header: i18n.t('assigned_packets:table_columns.action'),
		key: 'delete',
		align: 'center',
		width: 60,
		Cell: ({
			row: {
				_original: { product_uuid, user_id }
			}
		}) => <DeletePacket subscriberId={user_id} packetUuid={product_uuid} />
	}
];
