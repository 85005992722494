import React, { useEffect } from 'react';
import { withRouter } from 'react-router-dom';
import PropTypes from 'prop-types';
import { Button } from 'antd';

// Import ProductsProvider
import ProductsProvider from './ProductsProvider';

// Import hooks
import useAuthCheck from 'hooks/useAuthCheck';

// Import components
import {
	Wraper,
	Ibox,
	IboxTitle,
	IboxTools,
	IboxContentBase
} from 'components/common/layout';
import { Toggle } from 'components/utilities';
import ProductsModal from './products_modal/ProductsModal';
import SortableCardsProduct from './sortable_products/SortableCardsProduct';
import SortableListSection from './sortable_sections/SortableListSection';
import ProductsButtons from './products_buttons/ProductsButtons';
import ProvidersList from './providers_list/ProvidersList';

const ProductsSelect = (props) => {
	const {
		match: {
			params: { id }
		},
		api,
		apiSlug,
		title,
		openModalText,
		tabs,
		buttonSubmitText,
		notificationName,
		sortableType,
		initialData,
		error,
		isLoaded,
		fetchResourcesAction,
		createSubmitData,
		productsTitle = ''
	} = props;
	// check if user have rights to create and edit forms
	const { isReadOnly } = useAuthCheck();

	const renderAddProducts = ({ on, toggle }) =>
		!isReadOnly && (
			<>
				<Button onClick={toggle} type="primary" htmlType="button">
					{openModalText}
				</Button>

				<ProductsModal visible={on} toggle={toggle} />
			</>
		);

	useEffect(() => {
		fetchResourcesAction(id);
		// eslint-disable-next-line
	}, []);

	return (
		<ProductsProvider
			id={id}
			api={api}
			apiSlug={apiSlug}
			initialData={initialData}
			title={title}
			tabs={tabs}
			openModalText={openModalText}
			buttonSubmitText={buttonSubmitText}
			notificationName={notificationName}
			sortableType={sortableType}
			createSubmitData={createSubmitData}
			productsTitle={productsTitle}
		>
			<Wraper error={error}>
				<Ibox error={false}>
					{openModalText && (
						<IboxTitle title={title}>
							<IboxTools>
								<Toggle>{renderAddProducts}</Toggle>
							</IboxTools>
						</IboxTitle>
					)}
					<IboxContentBase isLoaded={isLoaded}>
						<React.Fragment>
							{sortableType === 'list' ? (
								<SortableListSection />
							) : (
								<>
									<SortableCardsProduct />
									<ProvidersList />
								</>
							)}
							<ProductsButtons />
						</React.Fragment>
					</IboxContentBase>
				</Ibox>
			</Wraper>
		</ProductsProvider>
	);
};

ProductsSelect.defaultProps = {
	apiSlug: null,
	notificationName: '',
	sortableType: 'products'
};

ProductsSelect.propTypes = {
	match: PropTypes.shape({
		params: PropTypes.shape({ id: PropTypes.string.isRequired })
	}),
	title: PropTypes.string.isRequired,
	tabs: PropTypes.array.isRequired,
	openModalText: PropTypes.string.isRequired,
	buttonSubmitText: PropTypes.string.isRequired,
	notificationName: PropTypes.string,
	initialData: PropTypes.array.isRequired,
	error: PropTypes.bool.isRequired,
	isLoaded: PropTypes.bool.isRequired
};

export default withRouter(ProductsSelect);
