// ******************** EVENTS_LIVE DATA TABLE ********************
export const FETCH_EVENTS_LIVE_LOADING = 'FETCH_EVENTS_LIVE_LOADING';
export const FETCH_EVENTS_LIVE_SUCCESS = 'FETCH_EVENTS_LIVE_SUCCESS';
export const FETCH_EVENTS_LIVE_ERROR = 'FETCH_EVENTS_LIVE_ERROR';

// ******************** SET SEARCH QUERY ********************
export const SET_EVENTS_LIVE_SEARCH_QUERY = 'SET_EVENTS_LIVE_SEARCH_QUERY';

// ******************** SAVE SORTING OPTIONS  ********************
export const SAVE_EVENTS_LIVE_TABLE_SORTING = 'SAVE_EVENTS_LIVE_TABLE_SORTING';

// ******************** DELETE EVENT_LIVE ********************
export const DELETE_EVENT_LIVE_LOADING = 'DELETE_EVENT_LIVE_LOADING';
export const DELETE_EVENT_LIVE_SUCCESS = 'DELETE_EVENT_LIVE_SUCCESS';
export const DELETE_EVENT_LIVE_ERROR = 'DELETE_EVENT_LIVE_ERROR';

// ******************** EVENT_LIVE ********************
export const FETCH_EVENT_LIVE_LOADING = 'FETCH_EVENT_LIVE_LOADING';
export const FETCH_EVENT_LIVE_SUCCESS = 'FETCH_EVENT_LIVE_SUCCESS';
export const FETCH_EVENT_LIVE_ERROR = 'FETCH_EVENT_LIVE_ERROR';

// ******************** FILES ********************
// -------------------- COVER --------------------
export const FETCH_EVENT_LIVE_COVER_FILES_LOADING =
	'FETCH_EVENT_LIVE_COVER_FILES_LOADING';
export const FETCH_EVENT_LIVE_COVER_FILES_SUCCESS =
	'FETCH_EVENT_LIVE_COVER_FILES_SUCCESS';
export const FETCH_EVENT_LIVE_COVER_FILES_ERROR =
	'FETCH_EVENT_LIVE_COVER_FILES_ERROR';

// -------------------- POSTER --------------------
export const FETCH_EVENT_LIVE_POSTER_FILES_LOADING =
	'FETCH_EVENT_LIVE_POSTER_FILES_LOADING';
export const FETCH_EVENT_LIVE_POSTER_FILES_SUCCESS =
	'FETCH_EVENT_LIVE_POSTER_FILES_SUCCESS';
export const FETCH_EVENT_LIVE_POSTER_FILES_ERROR =
	'FETCH_EVENT_LIVE_POSTER_FILES_ERROR';

// -------------------- GALLERY --------------------
export const FETCH_EVENT_LIVE_GALLERY_FILES_LOADING =
	'FETCH_EVENT_LIVE_GALLERY_FILES_LOADING';
export const FETCH_EVENT_LIVE_GALLERY_FILES_SUCCESS =
	'FETCH_EVENT_LIVE_GALLERY_FILES_SUCCESS';
export const FETCH_EVENT_LIVE_GALLERY_FILES_ERROR =
	'FETCH_EVENT_LIVE_GALLERY_FILES_ERROR';

// -------------------- TITLE --------------------
export const FETCH_EVENT_LIVE_TITLE_FILES_LOADING =
	'FETCH_EVENT_LIVE_TITLE_FILES_LOADING';
export const FETCH_EVENT_LIVE_TITLE_FILES_SUCCESS =
	'FETCH_EVENT_LIVE_TITLE_FILES_SUCCESS';
export const FETCH_EVENT_LIVE_TITLE_FILES_ERROR =
	'FETCH_EVENT_LIVE_TITLE_FILES_ERROR';

// -------------------- ASSET GROUP --------------------
export const FETCH_EVENT_LIVE_ASSET_GROUP_LOADING =
	'FETCH_EVENT_LIVE_ASSET_GROUP_LOADING';
export const FETCH_EVENT_LIVE_ASSET_GROUP_SUCCESS =
	'FETCH_EVENT_LIVE_ASSET_GROUP_SUCCESS';
export const FETCH_EVENT_LIVE_ASSET_GROUP_ERROR =
	'FETCH_EVENT_LIVE_ASSET_GROUP_ERROR';

// ******************** MATERIALS ********************
// -------------------- FETCH --------------------
export const FETCH_EVENT_LIVE_MATERIALS_LOADING =
	'FETCH_EVENT_LIVE_MATERIALS_LOADING';
export const FETCH_EVENT_LIVE_MATERIALS_SUCCESS =
	'FETCH_EVENT_LIVE_MATERIALS_SUCCESS';
export const FETCH_EVENT_LIVE_MATERIALS_ERROR =
	'FETCH_EVENT_LIVE_MATERIALS_ERROR';
// -------------------- UPDATE --------------------
export const UPDATE_EVENT_LIVE_MATERIALS = 'UPDATE_EVENT_LIVE_MATERIALS';
export const UPDATE_EVENT_LIVE_MATERIALS_ERROR =
	'UPDATE_EVENT_LIVE_MATERIALS_ERROR';
// -------------------- UPDATE --------------------
export const DELETE_EVENT_LIVE_MATERIALS = 'DELETE_EVENT_LIVE_MATERIALS';
export const DELETE_EVENT_LIVE_MATERIALS_ERROR =
	'DELETE_EVENT_LIVE_MATERIALS_ERROR';
// -------------------- ADD VIDEO TO EVENT_LIVE --------------------
export const ADD_VIDEO_TO_EVENT_LIVE_LOADING =
	'ADD_VIDEO_TO_EVENT_LIVE_LOADING';
export const ADD_VIDEO_TO_EVENT_LIVE_SUCCESS =
	'ADD_VIDEO_TO_EVENT_LIVE_SUCCESS';
export const ADD_VIDEO_TO_EVENT_LIVE_ERROR = 'ADD_VIDEO_TO_EVENT_LIVE_ERROR';

// ******************** AVAILABILITY ********************
// -------------------- TABLE --------------------
// -------------------- FETCH --------------------
export const FETCH_EVENT_LIVE_AVAILABILITY_TABLE_LOADING =
	'FETCH_EVENT_LIVE_AVAILABILITY_TABLE_LOADING';
export const FETCH_EVENT_LIVE_AVAILABILITY_TABLE_SUCCESS =
	'FETCH_EVENT_LIVE_AVAILABILITY_TABLE_SUCCESS';
export const FETCH_EVENT_LIVE_AVAILABILITY_TABLE_ERROR =
	'FETCH_EVENT_LIVE_AVAILABILITY_TABLE_ERROR';

// -------------------- DELETE --------------------
export const DELETE_EVENT_LIVE_AVAILABILITY_LOADING =
	'DELETE_EVENT_LIVE_AVAILABILITY_LOADING';
export const DELETE_EVENT_LIVE_AVAILABILITY_SUCCESS =
	'DELETE_EVENT_LIVE_AVAILABILITY_SUCCESS';
export const DELETE_EVENT_LIVE_AVAILABILITY_ERROR =
	'DELETE_EVENT_LIVE_AVAILABILITY_ERROR';

// -------------------- FORM --------------------
// -------------------- FETCH --------------------
export const FETCH_EVENT_LIVE_AVAILABILITY_FORM_LOADING =
	'FETCH_EVENT_LIVE_AVAILABILITY_FORM_LOADING';
export const FETCH_EVENT_LIVE_AVAILABILITY_FORM_SUCCESS =
	'FETCH_EVENT_LIVE_AVAILABILITY_FORM_SUCCESS';
export const FETCH_EVENT_LIVE_AVAILABILITY_FORM_ERROR =
	'FETCH_EVENT_LIVE_AVAILABILITY_FORM_ERROR';

// ******************** PRICES ********************
// -------------------- FETCH --------------------
export const FETCH_EVENT_LIVE_PRICES_LOADING =
	'FETCH_EVENT_LIVE_PRICES_LOADING';
export const FETCH_EVENT_LIVE_PRICES_SUCCESS =
	'FETCH_EVENT_LIVE_PRICES_SUCCESS';
export const FETCH_EVENT_LIVE_PRICES_ERROR = 'FETCH_EVENT_LIVE_PRICES_ERROR';

// -------------------- DELETE --------------------
export const DELETE_EVENT_LIVE_PRICE_LOADING =
	'DELETE_EVENT_LIVE_PRICE_LOADING';
export const DELETE_EVENT_LIVE_PRICE_SUCCESS =
	'DELETE_EVENT_LIVE_PRICE_SUCCESS';
export const DELETE_EVENT_LIVE_PRICE_ERROR = 'DELETE_EVENT_LIVE_PRICE_ERROR';

// ******************** PACKETS ASSIGNED ********************
// -------------------- FETCH PACKETS ASSIGNED - TABLE DATA --------------------
export const FETCH_EVENT_LIVE_PACKETS_ASSIGNED_LOADING =
	'FETCH_EVENT_LIVE_PACKETS_ASSIGNED_LOADING';
export const FETCH_EVENT_LIVE_PACKETS_ASSIGNED_SUCCESS =
	'FETCH_EVENT_LIVE_PACKETS_ASSIGNED_SUCCESS';
export const FETCH_EVENT_LIVE_PACKETS_ASSIGNED_ERROR =
	'FETCH_EVENT_LIVE_PACKETS_ASSIGNED_ERROR';
export const FETCH_EVENT_LIVE_PACKETS_ASSIGNED_PAYMENT_MODEL_TYPE_ERROR =
	'FETCH_EVENT_LIVE_PACKETS_ASSIGNED_PAYMENT_MODEL_TYPE_ERROR';

// -------------------- SET SEARCH QUERY --------------------
export const SET_EVENT_LIVE_PACKETS_ASSIGNED_SEARCH_QUERY =
	'SET_EVENT_LIVE_PACKETS_ASSIGNED_SEARCH_QUERY';
