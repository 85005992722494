import i18n from 'i18next';

export const SECTIONS_GROUPS_INPUT_FIELDS_DATA = {
	submitUrl: 'section/group',
	fetchUrl: 'section/group',
	cancelButtonPath: 'sections_groups',
	hasMetadata: false,
	defautlFieldValues: {
		name: ''
	},
	inputFields: [
		{
			name: 'name',
			label: i18n.t('sections:fields.name'),
			type: 'text',
			required: true
		}
	]
};
