import services from 'services/services';
import * as types from '../types';

// Import helpers
import { checkFetchErrors } from '../helpers_actions/convert_helpers';

// ******************** FETCH DOCUMENT TO EDIT ********************
export const fetchContentTranslations = (
	id,
	{ fetchUrl, fetchUrlSlug, contentLanguage, hasMetadata }
) => async (dispatch) => {
	try {
		// Dispatch a loading action
		dispatch({
			type: types.FETCH_CONTENT_TRANSLATIONS_LOADING,
			payload: true
		});

		const slug = fetchUrlSlug ? `/${fetchUrlSlug}` : '';

		const url = `${fetchUrl}/${id}${slug}?language=${contentLanguage}`;

		const response = await services.get(url);

		// Dispatch an action with data
		dispatch({
			type: types.FETCH_CONTENT_TRANSLATIONS_SUCCESS,
			payload: response.data,
			hasMetadata
		});
	} catch (error) {
		dispatch({
			type: types.FETCH_CONTENT_TRANSLATIONS_ERROR,
			payload: checkFetchErrors(error)
		});
	}
};

// ******************** FETCH LANGUAGE LIST ********************
export const fetchContentTranslationLanguages = () => async (dispatch) => {
	try {
		dispatch({
			type: types.FETCH_CONTENT_TRANSLATIONS_LANGUAGES_LOADING
		});
		const url = '/configuration/languages';
		const { data } = await services.get(url);
		dispatch({
			type: types.FETCH_CONTENT_TRANSLATIONS_LANGUAGES_SUCCESS,
			payload: data
		});
	} catch (error) {
		dispatch({
			type: types.FETCH_CONTENT_TRANSLATIONS_LANGUAGES_ERROR,
			payload: checkFetchErrors(error)
		});
	}
};
