import React, { useEffect } from 'react';
import { bool, object, func, shape, string, array } from 'prop-types';
import { compose } from 'redux';
import { connect, useDispatch } from 'react-redux';
import { withRouter } from 'react-router-dom';
import { fetchMainPrices, deleteMainPrice } from 'store/actions';

// Import translation
import { useTranslation } from 'react-i18next';

// Import columns
import { createColumns } from './main_prices_columns';

// Import components
import { TableTemplate } from 'components/common/templates';

// Import actions
import { dictionaryGeoblockGroups } from 'store/actions';

const MainPrices = ({
	error,
	fetchMainPrices,
	deleteMainPrice,
	resources,
	match: {
		params: { id }
	},
	geoblockGroups
}) => {
	const { t } = useTranslation();
	const dispatch = useDispatch();

	useEffect(() => {
		dispatch(dictionaryGeoblockGroups());
		// eslint-disable-next-line
	}, []);

	return (
		// Dispatch fetchMainPrices, deleteMainPrice action in main_prices
		<TableTemplate
			itemId={id}
			error={error}
			type={`main/${id}/price`}
			typeText="prices"
			addLinkText={t('prices:buttons.add')}
			resources={resources}
			columns={(handleSelectItem, isReadOnly) =>
				createColumns(handleSelectItem, isReadOnly, geoblockGroups)
			}
			fetchResourcesAction={fetchMainPrices}
			deleteItemAction={deleteMainPrice}
			notificationDeleteSuccessTxt={t('prices:notification.delete_success')}
			notificationDeleteErrorTxt={t('prices:notification.delete_error')}
		></TableTemplate>
	);
};

MainPrices.propTypes = {
	error: bool.isRequired,
	resources: object.isRequired,
	fetchMainPrices: func.isRequired,
	deleteMainPrice: func.isRequired,
	match: shape({
		params: shape({ id: string.isRequired })
	}),
	geoblockGroups: array.isRequired
};

const mapStateToProps = ({
	main: { prices },
	dictionary: { geoblockGroups }
}) => ({
	error: prices.error,
	resources: prices,
	geoblockGroups
});

export default compose(
	connect(mapStateToProps, { fetchMainPrices, deleteMainPrice }),
	withRouter
)(MainPrices);
