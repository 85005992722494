import React from 'react';
import PropTypes from 'prop-types';

// Import translation
import { useTranslation } from 'react-i18next';

// Import helpers
import { translateSubtypes } from './helpers/helpers';

// Import utils
import getSlug from 'speakingurl';

function TabsWebLink({ id, title, subtype }) {
	const { t } = useTranslation();

	const url = `${process.env.REACT_APP_WEBSITE_URL}/vod/${translateSubtypes(
		subtype
	)}/item/${id}/${getSlug(title)}`;

	return (
		<span className="tabs-breadcrumbs__name">
			(
			<a
				className="tabs-breadcrumbs__web-link"
				target="_blank"
				rel="noopener noreferrer"
				href={url}
			>
				{t('common:tabs_breadcrumbs.web_link')}
			</a>
			)
		</span>
	);
}

TabsWebLink.propTypes = {
	id: PropTypes.string.isRequired,
	title: PropTypes.string.isRequired,
	subtype: PropTypes.string.isRequired
};

export default TabsWebLink;
