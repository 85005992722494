// Import helpers
import { getColumnId } from 'store/actions/helpers_actions/convert_helpers';

// Import variables
import { SUBSCRIBER_TYPES } from 'components/helpers/variables';

const {
	ACTIVE,
	NEW,
	RECURRING,
	INACTIVE,
	CANCELLED,
	UNABLE
} = SUBSCRIBER_TYPES;

export const getSubscribersTypeQuery = ({ type, columns }) => {
	const userProductsUrl = `columns[${getColumnId(
		columns,
		'has_active_products'
	)}][search][value]=`;

	const activeProductsQuery = `${userProductsUrl}1&`;
	const inActiveProductsQuery = `${userProductsUrl}0&`;

	const subscriptionTrialUrl = `columns[${getColumnId(
		columns,
		'subscription_free_trial'
	)}][search][value]=`;

	const newQuery = `${subscriptionTrialUrl}1&`;
	const recurringQuery = `${subscriptionTrialUrl}0&`;

	const subscriptionExpirationIntentUrl = `columns[${getColumnId(
		columns,
		'subscription_expiration_intent'
	)}][search][value]=`;

	const cancelledQuery = `${subscriptionExpirationIntentUrl}canceled&`;
	const unableQuery = `${subscriptionExpirationIntentUrl}biling_error&`;

	switch (type) {
		case ACTIVE:
			return activeProductsQuery;

		case NEW:
			return `${activeProductsQuery}${newQuery}`;

		case RECURRING:
			return `${activeProductsQuery}${recurringQuery}`;

		case INACTIVE:
			return inActiveProductsQuery;

		case CANCELLED:
			return `${inActiveProductsQuery}${cancelledQuery}`;

		case UNABLE:
			return `${inActiveProductsQuery}${unableQuery}`;

		default:
			return '';
	}
};
