// Import translation
import i18n from 'i18next';

export const commonVodTranslationValidate = ({ title, summary_short }) => {
	const errors = {};

	// ------------------ Title ------------------
	if (!title) {
		errors.title = i18n.t('vod:form:validation.title');
	}

	// ------------------ Summary short ------------------
	if (!summary_short) {
		errors.summary_short = i18n.t('vod:form:validation.summary_short');
	}

	return errors;
};
