import React from 'react';
import PropTypes from 'prop-types';
import { Form } from 'react-final-form';
import { compose } from 'redux';
import { connect } from 'react-redux';
import { withRouter } from 'react-router-dom';
import { fetchProvisioningItem, dictionaryProductTypes } from 'store/actions';

// Import translation
import { useTranslation } from 'react-i18next';

// Import utilities
import { submitForm } from 'components/utilities/form';

// Validate
import validate from './validation';

// Import components
import { FormTemplate } from 'components/common/templates';
import { FormButtons } from 'components/common/buttons';
import Fields from './fields/Fields';

const ProvisioningForm = ({
	history,
	fetchProvisioningItem,
	dictionaryProductTypes,
	productTypes,
	error,
	isLoaded,
	initialValues
}) => {
	const { t } = useTranslation();

	const handleOnSubmit = ({ isEdit, itemID }) => async (values) => {
		const { all_products, product_type } = values;
		// external_id is not required
		delete values.external_id;
		const resources = values;

		if (!resources.external_ids) resources.external_ids = [];

		if (!all_products) {
			resources.product_type = null;
		} else if (!product_type) {
			resources.product_type = productTypes[0];
		}

		// Submit the form with field values
		return await submitForm({
			history,
			isEdit,
			itemID,
			resources,
			api: 'packet',
			redirectPath: 'provisioning'
		});
	};

	return (
		// Dispatch fetchProvisioningItem actions in in provisioning_form
		<FormTemplate
			fetchResourceToEdit={fetchProvisioningItem}
			fetchFormResources={[dictionaryProductTypes]}
			error={error}
			isLoaded={isLoaded}
		>
			{({ isEdit, itemID, checkedPermissions }) => (
				<Form
					initialValues={initialValues}
					validate={validate}
					onSubmit={handleOnSubmit({ isEdit, itemID })}
					render={({ handleSubmit, submitting, values }) => (
						<form onSubmit={handleSubmit}>
							<Fields
								submitting={submitting}
								isEdit={isEdit}
								checkedPermissions={checkedPermissions}
								currentValues={values}
								productTypes={productTypes}
							/>

							<FormButtons
								isButtonDisabled={submitting || error}
								path="provisioning"
								buttonText={t('common:buttons.submit')}
							/>
						</form>
					)}
				/>
			)}
		</FormTemplate>
	);
};

ProvisioningForm.propTypes = {
	history: PropTypes.object.isRequired,
	initialValues: PropTypes.object.isRequired,
	fetchProvisioningItem: PropTypes.func.isRequired,
	dictionaryProductTypes: PropTypes.func.isRequired,
	productTypes: PropTypes.array,
	error: PropTypes.bool.isRequired,
	isLoaded: PropTypes.bool.isRequired
};

const mapStateToProps = ({
	provisioning: { form },
	dictionary: { productTypes }
}) => {
	return {
		initialValues: form.edit,
		error: form.error,
		isLoaded: form.isLoaded,
		productTypes
	};
};

export default compose(
	connect(mapStateToProps, { fetchProvisioningItem, dictionaryProductTypes }),
	withRouter
)(ProvisioningForm);
