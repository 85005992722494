import store from 'store/store';

// Import variables
import { LOCALSTORAGE_USER } from 'components/helpers/variables';

export const checkRefreshToken = (jwt) => {
	// Get local storage data
	let localStorageData =
		JSON.parse(localStorage.getItem(LOCALSTORAGE_USER)) || null;
	// Check if there is jwt header and local storage is not equal to null
	if (jwt && localStorageData) {
		// Create new local storage data with new token
		const newLocalStorageData = {
			...localStorageData,
			meta: {
				...localStorageData.meta,
				access_token: jwt
			}
		};
		// Update local storage
		localStorage.setItem('gepard', JSON.stringify(newLocalStorageData));
	}
};

/**
 * Function that takes original request url and adds user id to it.
 * @param {string} url - request url
 */
export const addUserIDtoUrl = (url) => {
	const {
		auth: { authenticate }
	} = store.getState();
	const userId = authenticate?.user?.id;

	let requestUrl = url;

	if (userId) {
		const userSlug = `op=${userId}`;
		const type = !/\?/i.test(url) ? '?' : '&';
		requestUrl = `${url}${type}${userSlug}`;
	}

	return requestUrl;
};
