export const convertDictionaryCountriesCodes = (array) =>
	Object.entries(array).map((item) => ({
		value: item[0],
		name: item[1]
	}));

export const convertInitialValues = ({ initialValues, geoblockGroups }) => {
	const { countries, cities } = initialValues;

	const initialCountries = countries.map(({ code }) => code);
	const initialCities = cities.map(({ name }) => name);

	/*
		TODO: the following code converts an array of countries into an array of groups

		const initalGeoblockGroups = geoblockGroups
			.filter((group) => group.id !== id)
			.filter(({ countries }) =>
				countries.every((code) => initalCountries.includes(code))
			)
			.map(({ id }) => id);
	*/

	return {
		initialCountries,
		initialCities,
		initialGeoblockGroups: []
	};
};
