// ******************** SUBSCRIBERS DATA TABLE ********************
export const FETCH_SUBSCRIBERS_LOADING = 'FETCH_SUBSCRIBERS_LOADING';
export const FETCH_SUBSCRIBERS_SUCCESS = 'FETCH_SUBSCRIBERS_SUCCESS';
export const FETCH_SUBSCRIBERS_ERROR = 'FETCH_SUBSCRIBERS_ERROR';

// ******************** SET SEARCH QUERY ********************
export const SET_SUBSCRIBERS_SEARCH_QUERY = 'SET_SUBSCRIBERS_SEARCH_QUERY';

// ******************** SET SUBSCRIBERS TYPE ********************
export const SET_SUBSCRIBERS_TYPE = 'SET_SUBSCRIBERS_TYPE';

// ******************** SAVE SORTING OPTIONS  ********************
export const SAVE_SUBSCRIBERS_TABLE_SORTING = 'SAVE_SUBSCRIBERS_TABLE_SORTING';

// ******************** DELETE SUBSCRIBER ********************
export const DELETE_SUBSCRIBER_LOADING = 'DELETE_SUBSCRIBER_LOADING';
export const DELETE_SUBSCRIBER_SUCCESS = 'DELETE_SUBSCRIBER_SUCCESS';
export const DELETE_SUBSCRIBER_ERROR = 'DELETE_SUBSCRIBER_ERROR';

// ******************** SUBSCRIBER DEVICES ********************
export const FETCH_SUBSCRIBER_DEVICES_LOADING =
	'FETCH_SUBSCRIBER_DEVICES_LOADING';
export const FETCH_SUBSCRIBER_DEVICES_SUCCESS =
	'FETCH_SUBSCRIBER_DEVICES_SUCCESS';
export const FETCH_SUBSCRIBER_DEVICES_ERROR = 'FETCH_SUBSCRIBER_DEVICES_ERROR';
// --- Save sorting options ---
export const SAVE_SUBSCRIBER_DEVICES_TABLE_SORTING =
	'SAVE_SUBSCRIBER_DEVICES_TABLE_SORTING';
// --- Deactivate devices ---
export const DEACTIVATE_DEVICE_LOADING = 'DEACTIVATE_DEVICE_LOADING';
export const DEACTIVATE_DEVICE_SUCCESS = 'DEACTIVATE_DEVICE_SUCCESS';
export const DEACTIVATE_DEVICE_ERROR = 'DEACTIVATE_DEVICE_ERROR';
// --- Reset limits ---
export const RESET_SUBSCRIBER_LIMITS_LOADING =
	'RESET_SUBSCRIBER_LIMITS_LOADING';
export const RESET_SUBSCRIBER_LIMITS_SUCCESS =
	'RESET_SUBSCRIBER_LIMITS_SUCCESS';
export const RESET_SUBSCRIBER_LIMITS_ERROR = 'RESET_SUBSCRIBER_LIMITS_ERROR';
// --- Fetch limits data ---
export const GET_SUBSCRIBER_LIMITS_DATA_LOADING =
	'GET_SUBSCRIBER_LIMITS_DATA_LOADING';
export const GET_SUBSCRIBER_LIMITS_DATA_SUCCESS =
	'GET_SUBSCRIBER_LIMITS_DATA_SUCCESS';
export const GET_SUBSCRIBER_LIMITS_DATA_ERROR = 'GET_SUBSCRIBER_LIMITS_ERROR';

// ******************** SUBSCRIBER DATA FORM ********************
export const FETCH_SUBSCRIBER_LOADING = 'FETCH_SUBSCRIBER_LOADING';
export const FETCH_SUBSCRIBER_SUCCESS = 'FETCH_SUBSCRIBER_SUCCESS';
export const FETCH_SUBSCRIBER_ERROR = 'FETCH_SUBSCRIBER_ERROR';

// --- Packets ---
export const FETCH_SUBSCRIBER_PACKETS_LOADING =
	'FETCH_SUBSCRIBER_PACKETS_LOADING';
export const FETCH_SUBSCRIBER_PACKETS_SUCCESS =
	'FETCH_SUBSCRIBER_PACKETS_SUCCESS';
export const FETCH_SUBSCRIBER_PACKETS_ERROR = 'FETCH_SUBSCRIBER_PACKETS_ERROR';
// --- Subscriptions (MAIN) ---
export const FETCH_SUBSCRIBER_SUBSCRIPTIONS_LOADING =
	'FETCH_SUBSCRIBER_SUBSCRIPTIONS_LOADING';
export const FETCH_SUBSCRIBER_SUBSCRIPTIONS_SUCCESS =
	'FETCH_SUBSCRIBER_SUBSCRIPTIONS_SUCCESS';
export const FETCH_SUBSCRIBER_SUBSCRIPTIONS_ERROR =
	'FETCH_SUBSCRIBER_SUBSCRIPTIONS_ERROR';
