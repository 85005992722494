import React from 'react';
import PropTypes from 'prop-types';

// Import components
import { Button } from 'reactstrap';

/**
 * Functional component that returns button able to add new video to channel (main)
 * @param {string} uuid - id of channel
 * @param {bool} isMainDisabled - this prop can disable add main video button
 * @param {func} addVideoToChannel - dispatches action creator (POST add video to product)
 * @param {bool} isLoaded - disables button if table content is not yet loaded
 */
const ChannelsAddMaterialsButtons = ({
	uuid,
	isDisabled = false,
	addVideoToChannel,
	isLoaded,
	videoType,
	buttonText = ''
}) => {
	// dispatch action
	const handleAddVideo = () => addVideoToChannel(uuid, videoType);

	return (
		<>
			<Button
				onClick={handleAddVideo}
				disabled={isDisabled || !isLoaded}
				className="m-b-sm m-r-sm"
			>
				{buttonText}
			</Button>
		</>
	);
};
ChannelsAddMaterialsButtons.propTypes = {
	uuid: PropTypes.string.isRequired,
	isDisabled: PropTypes.bool.isRequired,
	addVideoToChannel: PropTypes.func.isRequired,
	isLoaded: PropTypes.bool.isRequired,
	videoType: PropTypes.string.isRequired,
	buttonText: PropTypes.string.isRequired
};

export default ChannelsAddMaterialsButtons;
