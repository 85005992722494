// Import translation
import i18n from 'i18next';

export default ({ period, period_unit, packet_uuid }) => {
	const errors = {};

	// ------------------ Period ------------------
	if (!period) {
		errors.period = i18n.t('subscribers:validation.period');
	}
	// ------------------ Period unit ------------------
	if (!period_unit) {
		errors.period_unit = i18n.t('subscribers:validation.period_unit');
	}
	// ------------------ Packet uuid ------------------
	if (!packet_uuid) {
		errors.packet_uuid = i18n.t('subscribers:validation.packet_uuid');
	}

	return errors;
};
