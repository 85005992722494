// -----------------  Login authorization -----------------
export const AUTH_USER_LOADING = 'AUTH_USER_LOADING';
export const AUTH_USER_SUCCESS = 'AUTH_USER_SUCCESS';
export const AUTH_USER_ERROR = 'AUTH_USER_ERROR';

// -----------------  Sign out -----------------
export const SIGN_OUT_LOADING = 'SIGN_OUT_LOADING ';
export const SIGN_OUT_SUCCESS = 'SIGN_OUT_SUCCESS';
export const SIGN_OUT_ERROR = 'SIGN_OUT_ERROR';

export const SIGNOUT_REDIRECT = 'SIGNOUT_REDIRECT';

// -----------------  Reset e-mail -----------------
export const SEND_RESET_EMAIL_LOADING = 'SEND_RESET_EMAIL_LOADING';
export const SEND_RESET_EMAIL_SUCCESS = 'SEND_RESET_EMAIL_SUCCESS';
export const SEND_RESET_EMAIL_ERROR = 'SEND_RESET_EMAIL_ERROR';

// -----------------  Check reset password hash -----------------
export const CHECK_RESET_PASSWORD_HASH_LOADING =
	'CHECK_RESET_PASSWORD_HASH_LOADING';
export const CHECK_RESET_PASSWORD_HASH_SUCCESS =
	'CHECK_RESET_PASSWORD_HASH_SUCCESS';
export const CHECK_RESET_PASSWORD_HASH_ERROR =
	'CHECK_RESET_PASSWORD_HASH_ERROR';

// ------------ Send new passwords ------------
export const SEND_NEW_PASSWORDS_LOADING = 'SEND_NEW_PASSWORDS_LOADING';
export const SEND_NEW_PASSWORDS_SUCCESS = 'SEND_NEW_PASSWORDS_SUCCESS';
export const SEND_NEW_PASSWORDS_ERROR = 'SEND_NEW_PASSWORDS_ERROR';
