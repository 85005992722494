import React, { useEffect } from 'react';
import { connect } from 'react-redux';
import PropTypes from 'prop-types';
import { Form } from 'react-final-form';
import { Modal } from 'antd';
import Loader from 'components/common/loaders/Loader';
import { fetchAllCategories, removeCategoryItem } from 'store/actions';

// Import translation
import { useTranslation } from 'react-i18next';

// Import utilities
import { submitModalForm } from 'components/utilities/form';
import { notificationHandler } from 'components/utilities/notifications/index';
import { messageConfirmHandler } from 'components/utilities/message/confirm_message';

// Validate
import validate from './validation';

// Import components
import { ModalFormButtons } from 'components/common/buttons';
import Fields from './fields/Fields';

const MargeForm = (props) => {
	const {
		visible,
		toggle,
		fetchAllCategories,
		removeCategoryItem,
		childId,
		childName,
		loading,
		error,
		data,
		type
	} = props;

	const { t } = useTranslation();

	useEffect(() => {
		// Dispatch fetchAllCategories action in categories_merge
		if (visible) fetchAllCategories(type);
		// eslint-disable-next-line
	}, [visible]);

	const handleOnSubmit = async ({ parentId }) =>
		await submitModalForm({
			resources: { parentId, childId },
			redirect: false,
			redirectToPath: true,
			api: 'products/genres/merge',
			notification: handleNotification,
			callbackAction: handleSubmitSuccess
		});

	const handleNotification = () =>
		notificationHandler(
			t('messages:notifications.merged'),
			t('categories:merge_form.success')
		);

	const handleSubmitSuccess = () => {
		removeCategoryItem(childId);
		toggle();
	};

	const showDeleteConfirm = (values) => {
		messageConfirmHandler(
			t('categories:merge_form.confirm.title'),
			t('categories:merge_form.confirm.text'),
			values,
			handleOnSubmit,
			'confirm'
		);
	};

	return (
		<Modal
			title={childName}
			visible={visible}
			onOk={toggle}
			onCancel={toggle}
			footer={false}
			destroyOnClose={true}
		>
			<Loader data-testid="loader" isLoaded={!loading}>
				<Form
					validate={validate}
					onSubmit={showDeleteConfirm}
					render={({ handleSubmit, submitting }) => (
						<form onSubmit={handleSubmit}>
							<Fields
								isButtonDisabled={submitting || error}
								submitting={submitting}
								categories={data}
								childId={childId}
							/>
							<ModalFormButtons
								isButtonDisabled={submitting}
								modalCloseAction={toggle}
							/>
						</form>
					)}
				/>
			</Loader>
		</Modal>
	);
};

MargeForm.propTypes = {
	childId: PropTypes.number.isRequired,
	childName: PropTypes.string.isRequired,
	visible: PropTypes.bool.isRequired,
	toggle: PropTypes.func.isRequired,
	fetchAllCategories: PropTypes.func.isRequired,
	removeCategoryItem: PropTypes.func.isRequired,
	loading: PropTypes.bool.isRequired,
	error: PropTypes.bool.isRequired,
	data: PropTypes.array.isRequired,
	type: PropTypes.number.isRequired
};

const mapStateToProps = ({
	categories: {
		merge,
		table: {
			options: {
				filters: { type }
			}
		}
	}
}) => ({
	loading: merge.loading,
	error: merge.error,
	data: merge.data,
	type
});

export default connect(mapStateToProps, {
	fetchAllCategories,
	removeCategoryItem
})(MargeForm);
