export const LANDING_PAGE_MODEL_TYPES = [
	'landingPageMobile',
	'landingPageBrowser',
	'landingPageBrowserRwd',
	'loginPageTV'
];

export const NO_SUBSCRIPTION_PAGE_MODEL_TYPES = [
	'loginPageNoSubscriptionAr',
	'loginPageNoSubscriptionEn',
	'loginPageNoSubscriptionMobileAr',
	'loginPageNoSubscriptionMobileEn'
];

export const VOD_LOGO_TYPE = 'vodLogo';
