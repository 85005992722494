import React from 'react';
import PropTypes from 'prop-types';
import { Field } from 'react-final-form';

// Import translation
import { useTranslation } from 'react-i18next';

// Import components
import { InputLabel, TinyTextEditor } from 'components/common/inputs';

const Fields = ({ submitting, values }) => {
	const { t } = useTranslation();

	return (
		<React.Fragment>
			<Field
				disabled={submitting}
				name="alias"
				type="text"
				placeholder={t('documents:fields.alias_placeholder')}
				label={t('documents:fields.alias_label')}
				id="alias"
				required={true}
				component={InputLabel}
			/>
			<div className="hr-line-dashed">&nbsp;</div>

			<Field
				disabled={submitting}
				name="title"
				type="text"
				placeholder={t('documents:fields.title_placeholder')}
				label={t('documents:fields.title_label')}
				id="title"
				required={true}
				component={InputLabel}
			/>
			<div className="hr-line-dashed">&nbsp;</div>

			<Field
				values={values}
				name="content"
				id="content"
				component={TinyTextEditor}
			/>
			<div className="hr-line-dashed">&nbsp;</div>
		</React.Fragment>
	);
};

Fields.propTypes = {
	submitting: PropTypes.bool.isRequired
};

export default Fields;
