// Import translation
import i18n from 'i18next';

// Import helpers
import { checkIsInt } from 'components/helpers/convert';

export default ({
	promotion_type,
	promotion_value,
	name,
	description,
	entry_condition,
	entry_condition_purchases_threshold,
	entry_condition_in_ttl,
	entry_condition_limit,
	promotion_period,
	limit
}) => {
	const errors = {};

	// ------------------ Name ------------------
	if (!name) {
		errors.name = i18n.t('promotions:form:validation.name_required');
	}

	// ------------------ Promotion Period ------------------
	if (!promotion_period || promotion_period.length === 0) {
		errors.promotion_period = i18n.t(
			'promotions:form:validation.promotion_period_required'
		);
	}

	// ------------------ Promotion value ------------------
	if (promotion_value === null || promotion_value === undefined) {
		errors.promotion_value = i18n.t(
			'promotions:form:validation.promotion_value_required'
		);
	} else if (promotion_value < 0) {
		errors.promotion_value = i18n.t(
			'promotions:form:validation.negative_value'
		);
	} else if (promotion_type === 'percentage') {
		if (promotion_value > 100) {
			errors.promotion_value = i18n.t(
				'promotions:form:validation.over_percent'
			);
		}

		if (!checkIsInt(promotion_value)) {
			errors.promotion_value = i18n.t(
				'promotions:form:validation.fraction_percent'
			);
		}
	}

	// ------------------ Limit ------------------
	if (limit <= 0 && limit !== null) {
		errors.limit = i18n.t('promotions:form:validation.negative_value_or_zero');
	} else if (!checkIsInt(limit) && limit) {
		errors.limit = i18n.t('promotions:form:validation.fraction_invalid');
	}

	// ------------------ Entry conditions ------------------
	if (entry_condition) {
		// ------------------ Entry condition purchases threshold ------------------
		if (!entry_condition_purchases_threshold) {
			errors.entry_condition_purchases_threshold = i18n.t(
				'promotions:form:validation.entry_condition_purchases_threshold_required'
			);
		} else if (entry_condition_purchases_threshold <= 0) {
			errors.entry_condition_purchases_threshold = i18n.t(
				'promotions:form:validation.negative_value_or_zero'
			);
		} else if (!checkIsInt(entry_condition_purchases_threshold)) {
			errors.entry_condition_purchases_threshold = i18n.t(
				'promotions:form:validation.fraction_invalid'
			);
		}

		// ------------------ Entry condition in ttl ------------------
		if (entry_condition_in_ttl <= 0 && entry_condition_in_ttl !== null) {
			errors.entry_condition_in_ttl = i18n.t(
				'promotions:form:validation.negative_value_or_zero'
			);
		} else if (!checkIsInt(entry_condition_in_ttl) && entry_condition_in_ttl) {
			errors.entry_condition_in_ttl = i18n.t(
				'promotions:form:validation.fraction_invalid'
			);
		}

		// ------------------ Entry condition limit ------------------
		if (entry_condition_limit <= 0 && entry_condition_limit !== null) {
			errors.entry_condition_limit = i18n.t(
				'promotions:form:validation.negative_value_or_zero'
			);
		} else if (!checkIsInt(entry_condition_limit) && entry_condition_limit) {
			errors.entry_condition_limit = i18n.t(
				'promotions:form:validation.fraction_invalid'
			);
		}
	}

	return errors;
};
