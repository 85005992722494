import React from 'react';
import PropTypes from 'prop-types';
import { Col, FormGroup, Label, Input } from 'reactstrap';

const TextareaLabel = ({
	input,
	id,
	label,
	rows,
	labelColumn = 2,
	inputColumn = 10,
	resize = 'vertical',
	meta: { touched, error, submitError },
	fieldInfo = null,
	...inputProps
}) => {
	return (
		<FormGroup row className={`${touched && error ? 'has-error' : ''} `}>
			<Label
				for={id}
				sm={labelColumn}
				className="col-form-label col-form-label-lg"
			>
				{label}
			</Label>
			<Col sm={inputColumn}>
				<Input
					invalid={touched && (error || submitError) ? true : false}
					rows={rows}
					id={id}
					{...input}
					{...inputProps}
					style={{ resize }}
					type="textarea"
				/>
				{touched && (error || submitError) && (
					<span
						data-testid={`textarea-error-${input.name}`}
						className="input-error"
					>
						{error || submitError}
					</span>
				)}
				{fieldInfo && (
					<span className="input-info">
						<i className="fa fa-info-circle m-r-sm"></i>
						{fieldInfo}
					</span>
				)}
			</Col>
		</FormGroup>
	);
};

TextareaLabel.propTypes = {
	input: PropTypes.object.isRequired,
	label: PropTypes.string.isRequired,
	id: PropTypes.string.isRequired,
	rows: PropTypes.string,
	labelColumn: PropTypes.number,
	inputColumn: PropTypes.number,
	resize: PropTypes.string,
	meta: PropTypes.object,
	fieldInfo: PropTypes.string
};

export default TextareaLabel;
