import React from 'react';
import { useSelector } from 'react-redux';

// Import components
import NotAuthorized from 'components/layouts/NotAuthorized';

const PermissionsAuthorization = ({
	allowedRoles,
	component: Component,
	routerProps
}) => {
	const { roles } = useSelector(({ auth }) => auth.authenticate);
	// Check if user's role is included in allowedRole array
	const isAccessAllowed = roles.some((role) => allowedRoles.includes(role));

	return isAccessAllowed ? <Component {...routerProps} /> : <NotAuthorized />;
};
export default PermissionsAuthorization;
