import React from 'react';
import PropTypes from 'prop-types';
import { Field } from 'react-final-form';

// Import translation
import { useTranslation } from 'react-i18next';

// Import select options
import { select_packet_types as type } from 'components/helpers/variables';

// Import components
import {
	InputLabel,
	TextareaLabel,
	CheckboxLabel,
	SelectLabel,
	MultiSelectLabel
} from 'components/common/inputs';

// Import utilites
import { renderFields } from 'components/utilities/form/renderFields';

// Import fields
import { VISIBLE_FIELDS } from './input_fields';

const Fields = ({
	submitting,
	isEdit,
	checkedPermissions,
	currentValues,
	productTypes
}) => {
	const { t } = useTranslation();

	const DEFAULT_PACKET_TYPE_SELECT = 'provision';

	const selectResources = {
		type,
		product_type: !currentValues.all_products ? [] : productTypes
	};

	return (
		<React.Fragment>
			<Field
				disabled={true}
				name="type"
				type="select"
				placeholder={t('provisioning:fields.type')}
				label={t('provisioning:fields.type')}
				id="type"
				defaultValue={
					selectResources['type'].find(
						(option) => option.value === DEFAULT_PACKET_TYPE_SELECT
					).value
				}
				required={true}
				component={SelectLabel}
			>
				{selectResources['type'].map((item, index) => (
					<option key={index} value={item.value}>
						{item.name}
					</option>
				))}
			</Field>
			<Field
				disabled={submitting}
				name="external_ids"
				type="multi-select"
				placeholder={t('provisioning:fields.external_id')}
				label={t('provisioning:fields.external_id')}
				id="external_ids"
				required={false}
				mode="tags"
				dropdownStyle={{ display: 'none' }}
				component={MultiSelectLabel}
			/>
			{!isEdit && (
				<Field
					disabled={true}
					name="slug"
					type="text"
					placeholder={t('provisioning:fields.slug')}
					label={t('provisioning:fields.slug')}
					id="slug"
					required={false}
					component={InputLabel}
				/>
			)}
			<Field
				disabled={submitting}
				name="title"
				type="text"
				placeholder={t('provisioning:fields.title')}
				label={t('provisioning:fields.title')}
				id="title"
				required={true}
				component={InputLabel}
			/>
			<Field
				disabled={submitting}
				name="description"
				type="textarea"
				placeholder={t('provisioning:fields.description')}
				label={t('provisioning:fields.description')}
				id="description"
				required={true}
				component={TextareaLabel}
			/>
			<Field
				disabled={submitting}
				name="short_description"
				type="textarea"
				placeholder={t('provisioning:fields.short_description')}
				label={t('provisioning:fields.short_description')}
				id="short_description"
				required={true}
				component={TextareaLabel}
			/>
			{checkedPermissions.canActivate && (
				<Field
					disabled={submitting}
					name="active"
					type="checkbox"
					label={t('provisioning:fields.active')}
					id="active"
					required={false}
					component={CheckboxLabel}
				/>
			)}
			<Field
				disabled={submitting}
				name="free_vod"
				type="checkbox"
				label={t('provisioning:fields.free_vod')}
				id="free_vod"
				required={false}
				component={CheckboxLabel}
			/>
			<Field
				disabled={submitting}
				name="all_products"
				type="checkbox"
				label={t('provisioning:fields.all_products')}
				id="all_products"
				required={false}
				component={CheckboxLabel}
			/>
			<Field
				disabled={!currentValues.all_products}
				name="product_type"
				type="select"
				placeholder={t('provisioning:fields.product_type')}
				label={t('provisioning:fields.product_type')}
				id="product_type"
				required={false}
				component={SelectLabel}
			>
				{selectResources['product_type'].map((item, index) => (
					<option key={index} value={item}>
						{item}
					</option>
				))}
			</Field>
			{VISIBLE_FIELDS.map((item, index) =>
				renderFields({ item, index, submitting, selectResources })
			)}
			{!isEdit && (
				<>
					<Field
						disabled={true}
						name="created_at"
						type="text"
						placeholder={t('provisioning:fields.created_at')}
						label={t('provisioning:fields.created_at')}
						id="created_at"
						required={false}
						component={InputLabel}
					/>
					<Field
						disabled={true}
						name="updated_at"
						type="text"
						placeholder={t('provisioning:fields.updated_at')}
						label={t('provisioning:fields.updated_at')}
						id="updated_at"
						required={false}
						component={InputLabel}
					/>
				</>
			)}
		</React.Fragment>
	);
};

Fields.propTypes = {
	submitting: PropTypes.bool.isRequired,
	isEdit: PropTypes.bool.isRequired,
	checkedPermissions: PropTypes.object.isRequired,
	currentValues: PropTypes.object.isRequired,
	productTypes: PropTypes.array.isRequired
};

export default Fields;
