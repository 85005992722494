import * as types from 'store/actions/types';
import produce from 'immer';

const INITIAL_STATE = {
	loading: true,
	error: false,
	assets: { total: 0, in_progress: 0, queued: 0, finished: 0 },
	encoding: { total: 0, in_progress: 0, queued: 0, finished: 0 }
};

export default (state = INITIAL_STATE, action) =>
	produce(state, (draft) => {
		switch (action.type) {
			// ****************** CLEAR DATA ******************
			case types.CLEAR_STATE:
				return { ...INITIAL_STATE };

			// *************** FETCH INGESTER STATS ***************
			case types.FETCH_INGESTER_STATS_LOADING:
				draft.loading = true;
				draft.error = false;
				return;

			case types.FETCH_INGESTER_STATS_SUCCESS:
				draft.loading = false;
				draft.assets = action.payload.assets;
				draft.encoding = action.payload.encoding;
				return;

			case types.FETCH_INGESTER_STATS_ERROR:
				draft.error = action.payload;
				return;

			default:
				return state;
		}
	});
