// Import translation
import i18n from 'i18next';

// Import store and actions
import store from 'store/store';
import { saveTableSorting } from 'store/actions';

const { dispatch } = store;

// Table length
export const TABLE_LENGTH = 30;

// Locale data for antd tables to show 'no data' properly
export const locale = { emptyText: i18n.t('common:no_data') };

// Convert sort table option
const convertSortOption = (state, sorted) => {
	const values = Object.values(state.sorted[0]);
	const sortOption = values[1] ? 'desc' : 'asc';
	sorted.type = sortOption;
	// add unconverted data for saving table sorting
	sorted.id = values[0];
	sorted.desc = values[1];
	return sorted;
};

// if there is sorting saved for table replace default data with it
export const prepareDefaultSortedData = (defaultSorted, reduxSorted) => {
	// promotions table has 2 default values. Tricky way to handle this exeption
	// reduxSorted should be array to properly handle cases like this (like default array)
	const isPromotionException =
		defaultSorted.length > 1 &&
		reduxSorted?.id === 'active' &&
		reduxSorted?.desc === true;

	// promotion table behaves differently (2 default sorting values)
	if (isPromotionException) {
		return defaultSorted;
	}
	// if reduxSorted data available return default sorting base on its values
	// in proper react table format (array of objects with id and desc [{id, desc}])
	else if (reduxSorted?.id && reduxSorted?.desc !== undefined) {
		const { id, desc } = reduxSorted;
		return [{ id, desc }];
	} else if (defaultSorted) {
		return defaultSorted;
	} else return [];
};

// Create table options
export const createTableOptions = (state, reduxSorted, tableType) => {
	// save genereted sorting to redux (only when table type is provided)
	const handleSaveAction = (sorted, tableType) => {
		if (tableType) {
			saveTableSorting(sorted, tableType)(dispatch);
		}
	};

	let sorted = {};
	// Check sorted values
	if (state.sorted.length > 0) {
		// Convert sorted option
		sorted = convertSortOption(state, sorted);
		// Find sorted column index
		sorted.columnIndex = state.columns.findIndex(
			(column) => column.accessor === state.sorted[0].id
		);

		handleSaveAction(sorted, tableType);
	}
	// apply previous sorting if present in redux store
	else if (reduxSorted) {
		sorted = reduxSorted;
	} else {
		// Return default sorted values
		sorted = { columnIndex: 0, type: 'asc' };

		handleSaveAction(sorted, tableType);
	}

	// Calculate start index
	const startIndex = TABLE_LENGTH * state.page;
	// Get column index
	const { columnIndex } = sorted;
	// Get accessor name
	const { accessor } = state.columns[columnIndex];
	return {
		pageSize: state.pageSize,
		page: state.page,
		startIndex,
		sorted,
		filtered: { ...state.filtered },
		accessor,
		defaultSorted: state.defaultSorted,
		currentlySorted: state.sorted
	};
};

// Add class in row if device is active
export const handleCheckActiveDevice = (state, rowInfo, column) => {
	if (rowInfo) {
		const {
			original: { active },
			index
		} = rowInfo;
		return (
			active &&
			(index % 2 !== 0
				? { className: '-active-device' }
				: { className: '-active-device-dark' })
		);
	}

	return {};
};

// map table type names to meet action types requirements
export const mapTableTypesNames = (tableType) => {
	switch (tableType) {
		case 'vod/series':
			return 'vod';
		case 'vod/season':
			return 'seasons';
		case 'vod/episode':
			return 'episodes';

		default:
			return tableType;
	}
};
