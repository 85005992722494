import { useState } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import { useParams } from 'react-router-dom';
import {
	getUploadUrl,
	confirmUpload,
	clearUploadedMaterialsTable
} from 'store/actions';

const useUpload = () => {
	const { id } = useParams();

	// STATE
	const [files, setFiles] = useState([]);
	const [fileName, setFileName] = useState('');
	const [isPromptVisible, setIsPromptVisible] = useState(false);

	// REDUX
	const dispatch = useDispatch();
	const { status, assetId, uploadUrl } = useSelector(
		({ vod }) => vod.upload_materials.get_url
	);

	const handleGetUploadUrl = async () => await getUploadUrl(id)(dispatch);

	const handleUploadComplete = async (error) => {
		if (error) {
			return null;
		} else {
			await confirmUpload(assetId, fileName);
			setIsPromptVisible(false);
			clearUploadedMaterialsTable()(dispatch);
		}
	};

	const handleOnUpdateFiles = (files) => {
		setFiles(files);
		setFileName(files[0].filename);
		setIsPromptVisible(true);
	};

	return {
		files,
		uploadComplete: handleUploadComplete,
		getUploadUrl: handleGetUploadUrl,
		updateFiles: handleOnUpdateFiles,
		status,
		uploadUrl,
		isPromptVisible
	};
};

export default useUpload;
