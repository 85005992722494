import React from 'react';
import { bool, array } from 'prop-types';

// Import variables
import { PAYMENT_TYPES } from 'components/helpers/variables';

// Import utilities
import { renderFields } from 'components/utilities/form/renderFields';

// Import fields
import { BASE_FIELDS, GEOBLOCK_FIELDS } from './input_fields';

const Fields = ({ submitting, periodUnits, geoblockGroups = [], isPacket }) => {
	const paymentMethods = Object.values(PAYMENT_TYPES).map((value) => ({
		name: value.toUpperCase(),
		value
	}));

	const baseSelectResources = {
		periodUnit: periodUnits,
		paymentMethod: paymentMethods
	};

	return (
		<>
			{BASE_FIELDS.map((item, index) =>
				renderFields({
					item,
					index,
					submitting,
					selectResources: baseSelectResources
				})
			)}

			{isPacket &&
				GEOBLOCK_FIELDS.map((item, index) =>
					renderFields({
						item,
						index,
						submitting,
						selectResources: { geoblock_group_ids: geoblockGroups }
					})
				)}
		</>
	);
};

Fields.propTypes = {
	submitting: bool.isRequired,
	periodUnits: array.isRequired,
	geoblockGroups: array,
	isPacket: bool.isRequired
};

export default Fields;
