import React from 'react';

// Import components
import { Toggle } from 'components/utilities';
import { Modal } from 'antd';
import message from 'antd/es/message';
import MaterialPlayer from 'components/utilities/material_player/MaterialPlayer';

// Import translation
import i18n from 'i18next';

// Import variables
import { MATERIAL_PLATFORM_TYPES } from './helpers/index';

// renders cell with delete action, contains modal to confirm delete
const renderCell = (deleteAsset, type, canEditVideo) => (
	text,
	record,
	index
) => {
	// shows modal to confirm delete. On OK it calls function to delete that is in Context
	const confirmModal = () =>
		Modal.confirm({
			title: i18n.t(`common:delete`),
			content: `${i18n.t(`messages:modal.delete_confirm_message`)} ${i18n.t(
				`names:notification_names.asset`
			)}`,
			okText: i18n.t('common:buttons.ok'),
			cancelText: i18n.t('common:buttons.cancel'),
			onOk: () => deleteAsset(record.id, type),
			onCancel() {
				message.info(i18n.t('messages:messages.asset_delete_canceled'));
			}
		});

	return (
		canEditVideo && (
			<Toggle>
				{() => (
					<button onClick={confirmModal} className="btn btn-xs btn-primary">
						<i className="fa fa-trash" />
					</button>
				)}
			</Toggle>
		)
	);
};

const renderPlayerModalCell = () => (text, record, index) => (
	<Toggle>
		{({ on, toggle }) => {
			const { format, video_id, platform } = record;
			return (
				<>
					<button onClick={toggle} className="btn btn-xs btn-primary">
						<i className="fa fa-play" />
					</button>
					<Modal
						visible={on}
						onCancel={toggle}
						footer={null}
						destroyOnClose={true}
						title={i18n.t('common:materials_table.modal_player_title')}
						width={'60%'}
					>
						<MaterialPlayer
							format={format}
							videoId={video_id}
							platform={platform}
						/>
					</Modal>
				</>
			);
		}}
	</Toggle>
);

const renderMappedPlatforms = () => (text, record, index) => {
	const platform = record.platform;
	if (platform === null) return MATERIAL_PLATFORM_TYPES.default;
	else return platform;
};

export const createColumns = (deleteAsset, type, editAsset, canEditVideo) => {
	return [
		{
			title: i18n.t('common:materials_table.table_columns.player'),
			dataIndex: 'player',
			key: 'player',
			align: 'center',
			width: 70,
			render: renderPlayerModalCell()
		},
		{
			title: i18n.t('common:materials_table.table_columns.format'),
			dataIndex: 'format',
			key: 'format',
			width: 100
		},
		{
			title: i18n.t('common:materials_table.table_columns.platform'),
			dataIndex: 'platform',
			key: 'platform',
			width: 150,
			render: renderMappedPlatforms()
		},
		{
			title: i18n.t('common:materials_table.table_columns.security_type'),
			dataIndex: 'security_type',
			key: 'security_type',
			width: 150
		},
		{
			title: i18n.t('common:materials_table.table_columns.url'),
			dataIndex: 'src',
			key: 'src'
		},

		{
			title: i18n.t('common:materials_table.table_columns.edit'),
			dataIndex: 'edit',
			key: 'edit',
			align: 'center',
			width: 70,
			render: (text, record, index) =>
				canEditVideo && (
					<React.Fragment>
						<button
							onClick={() => editAsset(record.id)}
							className="btn btn-xs btn-primary"
						>
							<i className="fa fa-gear" />
						</button>
					</React.Fragment>
				)
		},
		{
			title: i18n.t('common:materials_table.table_columns.delete'),
			dataIndex: 'delete',
			key: 'delete',
			align: 'center',
			width: 70,
			render: canEditVideo && renderCell(deleteAsset, type, canEditVideo)
		}
	];
};
