import React from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { compose } from 'redux';
import { withRouter } from 'react-router-dom';
import {
	setProvisioningItemProductsQuery,
	fetchProvisioningItemProducts,
	deleteProvisioningItemProduct,
	clearPanelState,
	fetchCategories,
	createFiltersSuggestions,
	fetchProviders,
	dictionaryParentalControlRating,
	dictionaryProductTypes
} from 'store/actions';

// Import translation
import { useTranslation } from 'react-i18next';

// Import context
import ProvisioningProductsProvider from './provisioning_products_select/ProvisioningProductsProvider';

// Import hooks
import useAuthCheck from 'hooks/useAuthCheck';
import useFilterLogic from 'hooks/useFilterLogic';

// Import columns
import { createColumns } from './provisioning_products_columns';

// Import utilities
import Search from 'components/utilities/search/Search';

// Import helpers
import { vodCategoriesOptions } from 'components/helpers/genres_helpers/helpers';

// Import products tabs
import {
	vod,
	channel,
	provisioning
} from './provisioning_products_select/helpers/tabs';

// Import components
import { PanelPageTemplate } from 'components/common/templates';
import FilterButton from 'components/common/buttons/FilterButton';
import { Button } from 'antd';
import ProductsModal from './provisioning_products_select/ProductsModal';
import { Toggle } from 'components/utilities';

const ProvisioningProductsTable = ({
	error,
	setProvisioningItemProductsQuery,
	fetchProvisioningItemProducts,
	deleteProvisioningItemProduct,
	fetchCategories,
	createFiltersSuggestions,
	fetchProviders,
	dictionaryParentalControlRating,
	dictionaryProductTypes,
	clearPanelState,
	resources,
	match: {
		params: { id }
	},
	filters
}) => {
	const { t } = useTranslation();
	const { columns } = resources;
	const tabs = [vod, channel, provisioning];
	// check if user have rights to create and edit forms
	const { isReadOnly } = useAuthCheck();

	// Actions to fetch data for filters
	const filterActions = {
		fetchCategories,
		fetchProviders,
		dictionaryParentalControlRating,
		dictionaryProductTypes
	};

	// execute filters logic
	useFilterLogic({
		filterActions,
		categoriesOptions: vodCategoriesOptions,
		createFiltersSuggestions,
		filters
	});

	const createSubmitData = (data) => {
		// get values from data object, flatten it to one array and return object
		// with structure like: {uuid, order: 0}
		const convertedData = Object.values(data)
			.flat()
			.map(({ uuid }) => ({ uuid, order: 0 }));

		// return data formatted accordingly to API needs
		const selection = { add: convertedData, del: [] };

		return selection;
	};

	const renderAddProducts = ({ on, toggle }) =>
		!isReadOnly && (
			<>
				<Button onClick={toggle} type="primary" htmlType="button">
					{t('common:products.add_products')}
				</Button>

				<ProductsModal visible={on} toggle={toggle} />
			</>
		);
	return (
		<ProvisioningProductsProvider
			id={id}
			api={'packet'}
			apiSlug={'addDelProducts'}
			title={t('common:tabs.products')}
			tabs={tabs}
			openModalText={t('common:products.add_products')}
			buttonSubmitText={t('provisioning:buttons.assign_products')}
			notificationName="products"
			createSubmitData={createSubmitData}
			clearPanelState={clearPanelState}
		>
			<PanelPageTemplate
				itemId={id}
				title="Lista"
				error={error}
				type="provisioning"
				resources={resources}
				columns={createColumns}
				fetchResourcesAction={fetchProvisioningItemProducts}
				deleteItemAction={deleteProvisioningItemProduct}
				typeText={'provisioning_product'}
			>
				<div className="d-flex ">
					<Toggle>{renderAddProducts}</Toggle>
					<div style={{ marginLeft: 'auto' }} className="d-flex">
						<Search
							setQueryValue={setProvisioningItemProductsQuery}
							value={resources.options.filters.query}
						/>
						<FilterButton tableColumns={columns} />
					</div>
				</div>
			</PanelPageTemplate>
		</ProvisioningProductsProvider>
	);
};

ProvisioningProductsTable.propTypes = {
	error: PropTypes.bool.isRequired,
	setProvisioningItemProductsQuery: PropTypes.func.isRequired,
	resources: PropTypes.object.isRequired,
	fetchProvisioningItemProducts: PropTypes.func.isRequired,
	deleteProvisioningItemProduct: PropTypes.func.isRequired,
	fetchCategories: PropTypes.func.isRequired,
	createFiltersSuggestions: PropTypes.func.isRequired,
	clearPanelState: PropTypes.func.isRequired,
	fetchProviders: PropTypes.func.isRequired,
	dictionaryParentalControlRating: PropTypes.func.isRequired,
	dictionaryProductTypes: PropTypes.func.isRequired,
	match: PropTypes.shape({
		params: PropTypes.shape({ id: PropTypes.string.isRequired })
	}),
	filters: PropTypes.object.isRequired
};

const mapStateToProps = (state) => {
	return {
		error: state.provisioning.products.error,
		resources: state.provisioning.products,
		filters: {
			genres: state.categories.table.data,
			providers: state.providers.table.data,
			rating: state.dictionary.parentalControlRating,
			subtype: state.dictionary.productTypes,
			status: state.dictionary.status
		}
	};
};

export default compose(
	connect(mapStateToProps, {
		setProvisioningItemProductsQuery,
		fetchProvisioningItemProducts,
		deleteProvisioningItemProduct,
		fetchCategories,
		createFiltersSuggestions,
		clearPanelState,
		fetchProviders,
		dictionaryParentalControlRating,
		dictionaryProductTypes
	}),
	withRouter
)(ProvisioningProductsTable);
