import React from 'react';
import { withRouter } from 'react-router-dom';
import { compose } from 'redux';
import { connect } from 'react-redux';
import PropTypes from 'prop-types';
import {
	fetchSubscriberDevices,
	resetSubscriberLimits,
	deactivateDevice
} from 'store/actions';

// Import columns
import { createDeviceColumns } from './subscriber_devices_columns';

// Import translation
import { useTranslation } from 'react-i18next';

// Import hooks
import useAuthCheck from 'hooks/useAuthCheck';

// Import utilities
import { messageConfirmDeactivateHandler } from 'components/utilities/message';

// Import components
import {
	Ibox,
	IboxTitle,
	IboxContentBase,
	Wraper
} from 'components/common/layout';
import { ReactServerSideTable } from 'components/utilities/table';
import { Button } from 'antd';
import SubscribersDevicesInfo from './SubscribersDevicesInfo';

const SubscribersDevices = ({
	error,
	resources,
	fetchSubscriberDevices,
	resetSubscriberLimits,
	deactivateDevice,
	match: {
		params: { id: userId }
	}
}) => {
	const { t } = useTranslation();
	// check if user have rights to create and edit forms
	const { isReadOnly, canClearSubscriberLimits } = useAuthCheck();

	const handleDeviceDeactivation = (deviceId) => {
		messageConfirmDeactivateHandler(userId, deviceId, deactivateDevice);
	};

	return (
		<Wraper lg="12" error={error}>
			<Ibox error={false} className="m-b-lg">
				<IboxTitle title="Urządzenia" isLoaded={false}>
					{!isReadOnly && (
						<div className="d-flex justify-content-end">
							{canClearSubscriberLimits && (
								<Button
									type="primary"
									htmlType="button"
									onClick={() => resetSubscriberLimits(userId)}
									className="m-r-sm"
								>
									{t('subscribers:buttons.reset_limits')}
								</Button>
							)}
							<Button
								type="primary"
								htmlType="button"
								onClick={() => handleDeviceDeactivation(null)}
							>
								{t('subscribers:buttons.deactivate_all_devices')}
							</Button>
						</div>
					)}
				</IboxTitle>

				<IboxContentBase>
					<>
						<SubscribersDevicesInfo
							subscriberId={userId}
							tableResources={resources.data}
						/>
						<ReactServerSideTable
							resources={resources}
							columns={createDeviceColumns(
								handleDeviceDeactivation,
								isReadOnly
							)}
							itemId={userId}
							fetchResourcesAction={fetchSubscriberDevices}
							defaultSorted={[{ id: 'last_login_date', desc: true }]}
							tableType="subscriber_devices"
						/>
					</>
				</IboxContentBase>
			</Ibox>
		</Wraper>
	);
};

SubscribersDevices.propTypes = {
	error: PropTypes.bool.isRequired,
	resources: PropTypes.object.isRequired,
	fetchSubscriberDevices: PropTypes.func.isRequired,
	resetSubscriberLimits: PropTypes.func.isRequired,
	deactivateDevice: PropTypes.func.isRequired,
	match: PropTypes.shape({ params: PropTypes.shape({ id: PropTypes.string }) })
};

const mapStateToProps = ({ subscribers: { devices } }) => ({
	error: devices.error,
	resources: devices
});

export default compose(
	connect(mapStateToProps, {
		fetchSubscriberDevices,
		resetSubscriberLimits,
		deactivateDevice
	}),
	withRouter
)(SubscribersDevices);
