import React from 'react';
import PropTypes from 'prop-types';
import { Row, Col } from 'reactstrap';
import { useTranslation } from 'react-i18next';

// Import fields
import {
	BASE_FIELDS,
	BOX_FIELDS,
	SECONDARY_SCREENS_FIELDS
} from './input_fields';

// Import utilities
import { renderFields } from 'components/utilities/form/renderFields';

const Fields = ({ photos, submitting, checkedPermissions }) => {
	const { t } = useTranslation();

	return (
		<>
			{BASE_FIELDS(checkedPermissions).map((item, index) =>
				renderFields({ item, index, submitting })
			)}

			<h3>{t('epg:system:field_groups.primary_fields')}</h3>
			<div className="hr-line-solid">&nbsp;</div>
			{BOX_FIELDS.map((item, index) =>
				renderFields({ item, index, submitting })
			)}

			<h3>{t('epg:system:field_groups.secondary_fields')}</h3>
			<div className="hr-line-solid">&nbsp;</div>
			{SECONDARY_SCREENS_FIELDS.map((item, index) =>
				renderFields({ item, index, submitting })
			)}

			{photos && (
				<Row>
					{photos.map(({ url, type }, index) => (
						<Col key={`${type}-${index}`} xl={2} lg={3} md={4} sm={4} xs={6}>
							<img
								className="img-fluid"
								src={url}
								alt="Preview"
								style={{ marginBottom: '1rem' }}
							/>
						</Col>
					))}
				</Row>
			)}
		</>
	);
};

Fields.propTypes = {
	submitting: PropTypes.bool.isRequired,
	photos: PropTypes.array,
	checkedPermissions: PropTypes.object.isRequired
};

export default Fields;
