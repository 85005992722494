import Store from 'store/store';
import { signOut } from 'store/actions';
import myAxios from './myAxiosFactory';
// Import helpers
import { checkRefreshToken, addUserIDtoUrl } from './helpers/helpers';

const servicesFactory = (myAxios) => ({
	post: (url, payload = {}, progress = {}) => {
		const axios = myAxios();

		axios.interceptors.response.use(
			function(response) {
				checkRefreshToken(response.headers.jwt);
				return response;
			},
			function(error) {
				if (401 === error.response.status) {
					// Dispatch an signOut action in auth folder
					Store.dispatch(signOut());
					return Promise.reject(error);
				} else {
					return Promise.reject(error);
				}
			}
		);
		return axios.post(addUserIDtoUrl(url), payload, progress);
	},
	put: (url, payload = {}) => {
		const axios = myAxios();

		axios.interceptors.response.use(
			function(response) {
				checkRefreshToken(response.headers.jwt);
				return response;
			},
			function(error) {
				if (401 === error.response.status) {
					// Dispatch an signOut action in auth folder
					Store.dispatch(signOut());
					return Promise.reject(error);
				} else {
					return Promise.reject(error);
				}
			}
		);
		return axios.put(addUserIDtoUrl(url), payload);
	},
	patch: (url, payload = {}) => {
		const axios = myAxios();

		axios.interceptors.response.use(
			function(response) {
				checkRefreshToken(response.headers.jwt);
				return response;
			},
			function(error) {
				if (401 === error.response.status) {
					// Dispatch an signOut action in auth folder
					Store.dispatch(signOut());
					return Promise.reject(error);
				} else {
					return Promise.reject(error);
				}
			}
		);
		return axios.patch(addUserIDtoUrl(url), payload);
	},
	delete: (url, payload = {}) => {
		const axios = myAxios();

		axios.interceptors.response.use(
			function(response) {
				checkRefreshToken(response.headers.jwt);
				return response;
			},
			function(error) {
				if (401 === error.response.status) {
					// Dispatch an signOut action in auth folder
					Store.dispatch(signOut());
					return Promise.reject(error);
				} else {
					return Promise.reject(error);
				}
			}
		);
		return axios.delete(addUserIDtoUrl(url), payload);
	},
	get: (url) => {
		const axios = myAxios();

		axios.interceptors.response.use(
			function(response) {
				checkRefreshToken(response.headers.jwt);
				return response;
			},
			function(error) {
				if (401 === error.response.status) {
					// Dispatch an signOut action in auth folder
					Store.dispatch(signOut());
					return Promise.reject(error);
				} else {
					return Promise.reject(error);
				}
			}
		);
		return axios.get(addUserIDtoUrl(url));
	}
});

const services = servicesFactory(myAxios);
export default services;
