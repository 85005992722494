// Import helpers
import { getLocationType } from 'components/helpers/convert';
import { CHANNEL, EVENT_LIVE, VOD } from 'components/helpers/variables';

// fetch data required to configure shaka player
export const fetchPlaylistData = async (playlist) => {
	try {
		const playlistData = await fetch(playlist).then((res) => res.json());
		const certificate = await fetch(
			playlistData.drm.FAIRPLAY.cert
		).then((res) => res.arrayBuffer());

		return { playlistData, certificate };
	} catch (error) {}
};

// player configuration
export const getPlayerConfiguration = (drm, certificate) => {
	return {
		preferredAudioLanguage: 'en',
		preferredTextLanguage: 'en',
		streaming: {
			alwaysStreamText: true,
			bufferBehind: 10,
			bufferingGoal: 10,
			rebufferingGoal: 5
		},
		drm: {
			servers: {
				'com.widevine.alpha': drm.WIDEVINE,
				'com.microsoft.playready': drm.PLAYREADY,
				'com.apple.fps.1_0': drm.FAIRPLAY.src
			},
			advanced: {
				'com.apple.fps.1_0': {
					serverCertificate: new Uint8Array(certificate)
				},
				'com.widevine.alpha': {
					videoRobustness: 'SW_SECURE_CRYPTO',
					audioRobustness: 'SW_SECURE_CRYPTO'
				}
			}
		},
		abr: {
			enabled: true
		}
	};
};

export const getProductType = (pathname) => {
	const locationType = getLocationType(pathname);

	switch (locationType) {
		case 'channels':
			return CHANNEL;

		case 'events_live':
			return EVENT_LIVE;

		default:
			return VOD;
	}
};
