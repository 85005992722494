import React from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { setLimitsQuery, fetchLimits, deleteLimit } from 'store/actions';

// Import components
import { PanelPageTemplate } from 'components/common/templates';
import FilterButton from 'components/common/buttons/FilterButton';

// Import columns
import { createColumns } from './limits_columns';

// Import utilities
import Search from 'components/utilities/search/Search';

// Import translation
import { useTranslation } from 'react-i18next';

const Limits = ({
	error,
	resources,
	setLimitsQuery,
	fetchLimits,
	deleteLimit
}) => {
	const { t } = useTranslation();

	const { columns } = resources;

	return (
		<PanelPageTemplate
			title={t('common:list')}
			error={error}
			type="limits"
			addLinkText={t('limits:buttons.add')}
			resources={resources}
			columns={createColumns}
			fetchResourcesAction={fetchLimits}
			deleteItemAction={deleteLimit}
			notificationDeleteSuccessTxt={t('limits:notification.delete_success')}
			notificationDeleteErrorTxt={t('limits:notification.delete_error')}
		>
			<div className="d-flex justify-content-end">
				<Search setQueryValue={setLimitsQuery} />
				<FilterButton tableColumns={columns} />
			</div>
		</PanelPageTemplate>
	);
};

Limits.propTypes = {
	error: PropTypes.bool.isRequired,
	resources: PropTypes.object.isRequired,
	setLimitsQuery: PropTypes.func.isRequired,
	fetchLimits: PropTypes.func.isRequired,
	deleteLimit: PropTypes.func.isRequired
};

const mapStateToProps = ({ limits: { table } }) => ({
	error: table.error,
	resources: table
});

export default connect(mapStateToProps, {
	setLimitsQuery,
	fetchLimits,
	deleteLimit
})(Limits);
