// ******************** ASSIGNED PACKETS DATA TABLE ********************
export const FETCH_ASSIGNED_PACKETS_LOADING = 'FETCH_ASSIGNED_PACKETS_LOADING';
export const FETCH_ASSIGNED_PACKETS_SUCCESS = 'FETCH_ASSIGNED_PACKETS_SUCCESS';
export const FETCH_ASSIGNED_PACKETS_ERROR = 'FETCH_ASSIGNED_PACKETS_ERROR';

// ******************** SET SEARCH QUERY ********************
export const SET_ASSIGNED_PACKETS_SEARCH_QUERY =
	'SET_ASSIGNED_PACKETS_SEARCH_QUERY';

// ******************** SAVE SORTING OPTIONS  ********************
export const SAVE_ASSIGNED_PACKETS_TABLE_SORTING =
	'SAVE_ASSIGNED_PACKETS_TABLE_SORTING';
