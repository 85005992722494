// Import nav links
import NAV_LINKS from 'components/layouts/nav/helpers/nav_links';

export const generateRedirectPath = (userRoles) => {
	// default path
	let generatedPath = '/panel/channels';

	if (userRoles) {
		const navLink = NAV_LINKS.find(({ permissions }) =>
			permissions.some((permission) => userRoles.includes(permission))
		);

		if (navLink) {
			generatedPath = navLink.path;
		}
	}

	return generatedPath;
};
