import * as types from 'store/actions/types';
import produce from 'immer';

export const INITIAL_STATE = {
	main: {
		data: []
	},
	preview: {
		data: []
	},
	isLoaded: false,
	error: false
};

// helper function that accepts array of assets and sorts them alphabetically
const sortAssets = (assetsArray) => {
	// array with sorted assets
	const sortedArray = [...assetsArray].sort((a, b) => {
		const formatA = a.format.toUpperCase();
		const formatB = b.format.toUpperCase();

		if (formatA < formatB) return -1;
		if (formatA > formatB) return 1;
		return 0;
	});
	return sortedArray;
};

export default (state = INITIAL_STATE, action) =>
	produce(state, (draft) => {
		switch (action.type) {
			// ****************** FETCH VOD'S MATERIALS ******************
			case types.FETCH_VOD_MATERIALS_LOADING:
				draft.main.data = [];
				draft.isLoaded = false;
				draft.error = false;
				return;

			case types.FETCH_VOD_MATERIALS_SUCCESS:
				// MAIN DATA
				const filteredMainData = action.payload.filter(
					({ type }) => type === 'main'
				);
				// main data with sorted assets
				const sortedMainData = filteredMainData.map((data) => {
					const { assets } = data;
					if (assets && assets.length) {
						data.assets = sortAssets(assets);
					}
					return data;
				});

				draft.main.data = sortedMainData;

				// PREVIEW DATA
				const filteredPreviewData = action.payload.filter(
					({ type }) => type === 'preview'
				);
				// preview data with sorted assets
				const sortedPreviewData = filteredPreviewData.map((data) => {
					const { assets } = data;
					if (assets && assets.length) {
						data.assets = sortAssets(assets);
					}
					return data;
				});

				draft.preview.data = sortedPreviewData;

				draft.isLoaded = true;
				return;

			case types.FETCH_VOD_MATERIALS_ERROR:
				draft.main.isLoaded = true;
				draft.error = true;
				return;

			// ****************** UPDATE / DELETE VOD'S MATERIALS ******************
			case types.UPDATE_VOD_MATERIALS:
				draft.error = false;
				// gets type data and productId
				const { data, type, productId } = action.payload;
				const assetId = data.id;
				const assets = draft[type].data.filter(({ id }) => id === productId)[0]
					.assets;

				// select asset which data will be modified
				const selectAsset = assets.find((asset) => asset.id === assetId);

				// if there is an specified asset in product asset then modify its data
				// else add new asset to product
				if (selectAsset) {
					selectAsset.src = data.src;
					selectAsset.drm_key = data.drm_key;
					selectAsset.format = data.format;
					selectAsset.params = data.params;
					selectAsset.platform = data.platform;
				} else {
					assets.push(data);
				}

				return;

			case types.UPDATE_VOD_MATERIALS_ERROR:
				draft.error = true;
				return;

			case types.DELETE_VOD_MATERIALS:
				draft.error = false;
				draft[action.payload.type].data.map(
					(product) =>
						(product.assets = product.assets.filter(
							(asset) => asset.id !== action.payload.id
						))
				);
				return;

			case types.DELETE_VOD_MATERIALS_ERROR:
				draft.error = true;
				return;

			// ******************** ADD VIDEO TO VOD ********************
			case types.ADD_VIDEO_TO_VOD_LOADING:
				draft.error = false;
				return;

			case types.ADD_VIDEO_TO_VOD_SUCCESS:
				draft[action.payload.videoType].data.push(action.payload.data);
				return;

			case types.ADD_VIDEO_TO_VOD_ERROR:
				draft.error = true;
				return;

			default:
				return state;
		}
	});
