import React from 'react';
import PropTypes from 'prop-types';
import { compose } from 'redux';
import { connect } from 'react-redux';
import { withRouter } from 'react-router-dom';
import { fetchPacketPrices, deletePacketPrice } from 'store/actions';

// Import translation
import { useTranslation } from 'react-i18next';

// Import columns
import { createColumns } from './packets_prices_columns';

// Import components
import { TableTemplate } from 'components/common/templates';

const PacketsPrices = ({
	error,
	fetchPacketPrices,
	deletePacketPrice,
	resources,
	match: {
		params: { id }
	}
}) => {
	const { t } = useTranslation();

	return (
		// Dispatch fetchPacketPrices, deletePacketPrice action in packet_prices
		<TableTemplate
			itemId={id}
			error={error}
			type={`packets/${id}/price`}
			typeText="prices"
			addLinkText={t('prices:buttons.add')}
			resources={resources}
			columns={createColumns}
			fetchResourcesAction={fetchPacketPrices}
			deleteItemAction={deletePacketPrice}
			notificationDeleteSuccessTxt={t('prices:notification.delete_success')}
			notificationDeleteErrorTxt={t('prices:notification.delete_error')}
		></TableTemplate>
	);
};

PacketsPrices.propTypes = {
	error: PropTypes.bool.isRequired,
	resources: PropTypes.object.isRequired,
	fetchPacketPrices: PropTypes.func.isRequired,
	deletePacketPrice: PropTypes.func.isRequired,
	match: PropTypes.shape({
		params: PropTypes.shape({ id: PropTypes.string.isRequired })
	})
};

const mapStateToProps = ({ packets: { prices } }) => ({
	error: prices.error,
	resources: prices
});

export default compose(
	connect(mapStateToProps, { fetchPacketPrices, deletePacketPrice }),
	withRouter
)(PacketsPrices);
