import { message } from 'antd';
// Import translation
import i18n from 'i18next';

// Import utilities
import { notificationHandler } from 'components/utilities/notifications/index';

// Import services
import services from 'services/services';

// Import redux actions
import { clearPanelState } from 'store/actions';

export const removeSubscriberPacket = async ({
	subscriberId,
	packetUuid,
	dispatch
}) => {
	try {
		await services.post('timetables/user/packet/free/detach', {
			subscriber_id: subscriberId,
			packet_uuid: packetUuid
		});

		dispatch(clearPanelState());

		notificationHandler(
			i18n.t('messages:notifications.deleted'),
			`${i18n.t('messages:notifications.successfuly_deleted')} ${i18n.t(
				'names:notification_names.packets'
			)} `
		);
	} catch (error) {
		message.error(i18n.t('common:errors.common_error_message'));
	}
};
