import React from 'react';
import { useSelector } from 'react-redux';
import Tabs from 'components/utilities/tabs/Tabs';

// Import helrpes
import { seasonsTabs } from './helpers/index';

const SeasonsTabs = () => {
	const { languages } = useSelector(
		({ content_translations }) => content_translations.language_list
	);

	return <Tabs tabs={seasonsTabs(languages)} />;
};

export default SeasonsTabs;
