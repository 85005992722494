import React from 'react';
import { object, func, array, bool } from 'prop-types';
import { Form } from 'react-final-form';
import { compose } from 'redux';
import { connect } from 'react-redux';
import { withRouter } from 'react-router-dom';

// Import redux actions
import {
	fetchGeoblockGroupsItem,
	dictionaryGeoblockGroups,
	dictionaryCountriesWithCodes
} from 'store/actions';

// Import translation
import { useTranslation } from 'react-i18next';

// Import utilities
import { submitForm } from 'components/utilities/form';

// Validate
import validate from './validation';

// Import components
import { FormTemplate } from 'components/common/templates';
import { FormButtons } from 'components/common/buttons';
import Fields from './fields/Fields';
import InfoTitle from 'components/utilities/info/InfoTitle';

// Import helpers
import {
	convertDictionaryCountriesCodes,
	convertInitialValues
} from './helpers';

const GeoblockGroupForm = ({
	history,
	fetchGeoblockGroupsItem,
	error,
	isLoaded,
	initialValues,
	dictionaryGeoblockGroups,
	dictionaryCountriesWithCodes,
	geoblockGroups,
	countriesWithCodes
}) => {
	const { t } = useTranslation();

	const handleOnSubmit = ({ isEdit, itemID }) => async ({
		countries,
		name,
		cities
	}) => {
		// // Submit the form with field values
		await submitForm({
			history,
			isEdit,
			itemID,
			resources: { countries, name, cities },
			api: 'geoblocking',
			redirectPath: 'geoblock_groups',
			notificationName: 'geoblock/groups'
		});
	};

	const convertedCountriesCodes = convertDictionaryCountriesCodes(
		countriesWithCodes
	);

	const {
		initialCountries,
		initialCities,
		initialGeoblockGroups
	} = convertInitialValues({
		initialValues,
		geoblockGroups
	});

	const formInitialValues = {
		...initialValues,
		countries: initialCountries,
		cities: initialCities,
		geoblock_groups: initialGeoblockGroups
	};

	return (
		// Dispatch fetchGeoblockGroupsItem actions in in geoblock_groups
		<div style={{ paddingBottom: 120 }}>
			<FormTemplate
				fetchResourceToEdit={fetchGeoblockGroupsItem}
				fetchFormResources={[
					dictionaryGeoblockGroups,
					dictionaryCountriesWithCodes
				]}
				error={error}
				isLoaded={isLoaded}
			>
				{({ isEdit, itemID, checkedPermissions, decorators }) => (
					<Form
						initialValues={formInitialValues}
						validate={validate}
						decorators={decorators}
						onSubmit={handleOnSubmit({ isEdit, itemID })}
						render={({ handleSubmit, submitting }) => (
							<form onSubmit={handleSubmit}>
								<InfoTitle title={t('geoblock_groups:info_text')} />
								<Fields
									isEdit={isEdit}
									checkedPermissions={checkedPermissions}
									geoblockGroups={geoblockGroups}
									countries={convertedCountriesCodes}
								/>

								<FormButtons
									isButtonDisabled={submitting || error}
									path="geoblock_groups"
									buttonText={t('common:buttons.submit')}
								/>
							</form>
						)}
					/>
				)}
			</FormTemplate>
		</div>
	);
};

GeoblockGroupForm.propTypes = {
	history: object.isRequired,
	initialValues: object.isRequired,
	fetchGeoblockGroupsItem: func.isRequired,
	error: bool.isRequired,
	isLoaded: bool.isRequired,
	dictionaryGeoblockGroups: func.isRequired,
	dictionaryCountriesWithCodes: func.isRequired,
	geoblockGroups: array.isRequired,
	countriesWithCodes: object.isRequired
};

const mapStateToProps = ({
	geoblock_groups: { form },
	dictionary: { geoblockGroups, countriesWithCodes }
}) => ({
	initialValues: form.edit,
	error: form.error,
	isLoaded: form.isLoaded,
	geoblockGroups,
	countriesWithCodes
});

export default compose(
	connect(mapStateToProps, {
		fetchGeoblockGroupsItem,
		dictionaryGeoblockGroups,
		dictionaryCountriesWithCodes
	}),
	withRouter
)(GeoblockGroupForm);
