import React, { createContext, useContext, useEffect, useReducer } from 'react';
import services from 'services/services';
import { useLocation, useHistory } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import PropTypes from 'prop-types';

// import variables
import { PRODUCT_SELECT_VOD_LIST_LENGTH } from 'components/helpers/variables';

// import helpers
import { initialState, massChangesReducer, updateState } from './helpers';
import { convertErrors } from 'components/helpers/error';
import { capitalizeText } from 'components/helpers/convert';

// Import utilities
import { notificationHandler } from 'components/utilities/notifications/index';

const MassChangesContext = createContext();

export const useMassChangesContext = () => useContext(MassChangesContext);

export const MassChangesProvider = (props) => {
	const { productsTypes, createSubmitData } = props;
	const [state, dispatch] = useReducer(massChangesReducer, initialState);
	const { state: locationState } = useLocation();
	const { t } = useTranslation();
	const history = useHistory();

	const handleProductTypeChange = (selectedType) => {
		const item = productsTypes.find(({ type }) => type === selectedType);

		updateState({
			selectedType: item.type,
			selectedItemData: {},
			selectedItem: '',
			data: [],
			...item
		})(dispatch);
	};

	const setDefaultProductType = () => {
		const [firstType] = productsTypes;

		updateState({
			selectedType: firstType.type,
			...firstType
		})(dispatch);
	};

	const handleErrors = (error) => {
		// Convert Errors
		const errors = convertErrors(error.response.data.validator.errors);
		// CreateNotification
		Object.entries(errors).forEach(([key, value]) => {
			notificationHandler(capitalizeText(key), value, 'error', 8);
		});
		// Throw new errors
		return { ...errors };
	};

	const handleOnSubmit = async () => {
		try {
			const { actionType, notificationTxt, notificationTitle } = props;
			const {
				api,
				submitApiSlug,
				selectedItemData,
				idSelector,
				selection,
				selectedType
			} = state;

			const id = selectedItemData[idSelector];

			const isPacket = api === 'packet';

			const addDelData = createSubmitData({
				selection,
				actionType,
				id,
				api
			});

			const url = isPacket
				? `${api}/${id}/${submitApiSlug}`
				: `${api}/${submitApiSlug}`;

			updateState({ submitting: true })(dispatch);

			await services.post(url, addDelData);

			updateState({ submitting: false })(dispatch);

			notificationHandler(
				t(`messages:notifications.${notificationTitle}`),
				`${notificationTxt} ${t(
					`mass_changes:actions_products_types.${selectedType}`
				)}`
			);
		} catch (error) {
			updateState({ submitting: false })(dispatch);
			return handleErrors(error);
		}
	};

	const fetchSelectAction = async (value) => {
		try {
			if (value.length >= 2) {
				const { lastFetchId, selectedType, apiUrl } = state;
				updateState({
					data: [],
					fetching: true,
					error: false,
					lastFetchId: lastFetchId + 1
				})(dispatch);

				const fetchId = lastFetchId;

				// choose proper url based on selectedType -> change this variable to switch when more options are needed
				const url =
					selectedType === 'vod'
						? `${apiUrl}order[0][column]=4&order[0][dir]=asc&start=0&length=${PRODUCT_SELECT_VOD_LIST_LENGTH}&columns[4][search][value]=${value}`
						: `${apiUrl}order[0][column]=1&order[0][dir]=asc&start=0&length=30&search[value]=${value}`;

				// Get data from server and add them to select list
				let {
					data: { data }
				} = await services.get(url);

				if (fetchId !== lastFetchId) return;

				// Add uuid, type, title to provider items
				if (selectedType === 'provider') {
					data.forEach((item) => {
						item.title = item.provider;
						item.uuid = item.provider_id;
						item.type = 'provider';
					});
				}

				// Remove not active products
				if (selectedType === 'vod') {
					data = data.filter(({ active }) => parseInt(active, 10) !== 0);
				}

				updateState({ data, fetching: false })(dispatch);
			}
		} catch (error) {
			updateState({ data: [], fetching: false, error: true })(dispatch);
		}
	};

	const handleSelectChange = (selectedItem) => {
		const { data } = state;
		const { key } = selectedItem;

		const selectedItemData = data.find(
			({ uuid, id }) => uuid === key || id === key
		);

		updateState({
			selectedItem: key,
			fetching: false,
			selectedItemData
		})(dispatch);
	};

	const handleRemoveItem = (id) => {
		const { selection, idSelector } = state;
		const newSelection = selection.filter((item) => item[idSelector] !== id);
		updateState({ selection: newSelection })(dispatch);
	};

	const handleRedirect = (redirectPath) => history.push(redirectPath);

	useEffect(() => {
		setDefaultProductType();
		const selection = locationState?.selection || [];
		updateState({ selection })(dispatch);
		// eslint-disable-next-line
	}, [locationState]);

	return (
		<MassChangesContext.Provider
			value={{
				...props,
				...state,
				fetchSelectAction,
				handleProductTypeChange,
				handleSelectChange,
				handleOnSubmit,
				handleRemoveItem,
				handleOnCancel: handleRedirect
			}}
		>
			{props.children({ ...props, ...state, handleRemoveItem })}
		</MassChangesContext.Provider>
	);
};

MassChangesProvider.propTypes = {
	actionType: PropTypes.string.isRequired,
	buttonSubmitText: PropTypes.string.isRequired,
	createSubmitData: PropTypes.func.isRequired,
	labelSelector: PropTypes.string.isRequired,
	notificationTxt: PropTypes.string.isRequired,
	productSelectLabel: PropTypes.string.isRequired,
	productsTitle: PropTypes.string.isRequired,
	productsTypes: PropTypes.array.isRequired,
	typeSelectLabel: PropTypes.string.isRequired,
	notificationTitle: PropTypes.string.isRequired
};
