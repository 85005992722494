import React from 'react';
import PropTypes from 'prop-types';

// Import components
import { Drawer } from 'antd';
import FiltersDrawerForm from 'components/utilities/filters/filters_drawer/filters_drawer_form/FiltersDrawerForm';

/**
 * Functional component with filters drawer containing filters form
 * @param {bool} on - boolean value determining that drawer is visible or not
 * @param {func} toggle - toggle function switches drawer visibility
 * @returns {*}
 */
const FiltersDrawer = ({ on, toggle }) => {
	return (
		<Drawer visible={on} onClose={toggle} width={600}>
			<FiltersDrawerForm toggle={toggle} />
		</Drawer>
	);
};

FiltersDrawer.propTypes = {
	on: PropTypes.bool.isRequired,
	toggle: PropTypes.func.isRequired
};

export default FiltersDrawer;
