import * as types from 'store/actions/types';
import produce from 'immer';

//  Import helpers
import { PLAYER_TYPES } from 'components/helpers/section_groups_helpers/helpers';

const INITIAL_STATE = {
	loading: true,
	error: false,
	data: [], // filtered data. Name "data" is used because panel page template requires this name to function properly
	allTypesData: [], // all data cached to use in type filter (all types in list are here)
	columns: [],
	deleteItem: {
		success: false,
		error: false
	},
	options: {
		pages: 0,
		page: 0,
		total_results: 0,
		startIndex: 0,
		sorted: undefined,
		filters: {
			query: ''
		}
	}
};

export default (state = INITIAL_STATE, action) =>
	produce(state, (draft) => {
		switch (action.type) {
			// ****************** CLEAR DATA ******************
			case types.CLEAR_STATE:
				return { ...INITIAL_STATE };

			// *************** FETCH SECTION GROUPS ***************
			case types.FETCH_SECTIONS_GROUPS_LOADING:
				draft.loading = true;
				draft.error = false;
				return;

			case types.FETCH_SECTIONS_GROUPS_SUCCESS:
				draft.loading = false;
				draft.data = action.payload.data;
				draft.allTypesData = action.payload.data;
				draft.columns = action.payload.input.columns;
				draft.options.pages = action.payload.pages;
				draft.options.total_results = action.payload.recordsFiltered;
				draft.options.startIndex = action.payload.startIndex;
				draft.options.page = action.payload.page;
				return;

			case types.FETCH_SECTIONS_GROUPS_ERROR:
				draft.error = action.payload;
				return;

			// *************** SAVE SORTING OPTIONS ***************
			case types.SAVE_SECTIONS_GROUPS_TABLE_SORTING:
				draft.options.sorted = action.payload;
				break;

			// ******************** SET SECTIONS GROUPS TYPE ********************
			case types.SET_SECTIONS_GROUPS_TYPE:
				const playerType = action.payload.playerType;
				const isPlayerAllTypes = playerType === PLAYER_TYPES.all;

				// filtered types based on action payload
				const filteredPlayerData = isPlayerAllTypes
					? draft.allTypesData
					: draft.allTypesData.filter(({ label }) =>
							label.includes(playerType)
					  );

				// if array of filtered data is empty return all types
				const typesData = filteredPlayerData.length ? filteredPlayerData : [];

				draft.data = typesData;
				return;

			default:
				return state;
		}
	});
