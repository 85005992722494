import * as types from 'store/actions/types';
import produce from 'immer';

export const INITIAL_STATE = {
	cover: {
		data: [],
		isLoaded: false,
		error: false
	},
	cover_large: {
		data: [],
		isLoaded: false,
		error: false
	},
	cover_small: {
		data: [],
		isLoaded: false,
		error: false
	},
	cover_detail: {
		data: [],
		isLoaded: false,
		error: false
	},
	cover_iraq: {
		data: [],
		isLoaded: false,
		error: false
	},
	cover_large_iraq: {
		data: [],
		isLoaded: false,
		error: false
	},
	cover_small_iraq: {
		data: [],
		isLoaded: false,
		error: false
	},
	cover_detail_iraq: {
		data: [],
		isLoaded: false,
		error: false
	}
};

export default (state = INITIAL_STATE, action) =>
	produce(state, (draft) => {
		switch (action.type) {
			// ****************** FETCH MAIN_ITEM COVER FILES ******************
			case types.FETCH_MAIN_ITEM_COVER_FILES_LOADING:
				draft[action.payload.collection].data = [];
				draft[action.payload.collection].isLoaded = false;
				draft[action.payload.collection].error = false;

				return;

			case types.FETCH_MAIN_ITEM_COVER_FILES_SUCCESS:
				draft[action.payload.collection].data = action.payload.data.map(
					(file) => ({
						...file,
						uid: file.id
					})
				);
				draft[action.payload.collection].isLoaded = true;

				return;

			case types.FETCH_MAIN_ITEM_COVER_FILES_ERROR:
				draft[action.payload.collection].isLoaded = true;
				draft[action.payload.collection].error = true;

				return;

			default:
				return state;
		}
	});
