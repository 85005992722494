import services from 'services/services';
import * as types from '../types';

export const fetchLandingPageFiles = (id, modelType) => async (dispatch) => {
	try {
		dispatch({
			type: types.FETCH_LANDING_PAGE_FILES_LOADING,
			payload: { modelType }
		});

		const { data } = await services.get(
			`/assets/${id}/list?model_type=${modelType}&collection=cover`
		);

		const mappedData = data.map((file) => ({
			...file,
			uid: file.id
		}));

		dispatch({
			type: types.FETCH_LANDING_PAGE_FILES_SUCCESS,
			payload: { mappedData, modelType }
		});
	} catch (error) {
		dispatch({
			type: types.FETCH_LANDING_PAGE_FILES_ERROR,
			payload: { modelType }
		});
	}
};
