import React from 'react';
import i18n from 'i18next';

// Import components
import PacketsForm from '../../packets_form/PacketsForm';
import PacketsImages from 'components/views/packets/packets_file/PacketsImages';
import PacketsProducts from '../../packets_products/PacketsProducts';
import PacketsPrices from 'components/views/packets/packets_prices/PacketsPrices';
import PacketsAvailability from 'components/views/packets/packets_availability/PacketsAvailability';

export const packetsTabs = [
	{
		name: i18n.t('common:tabs.metadata'),
		path: 'metadata',
		disableOnCreate: false,
		component: <PacketsForm />
	},
	{
		name: i18n.t('common:tabs.pictures'),
		path: 'pictures',
		disableOnCreate: true,
		component: <PacketsImages />
	},
	{
		name: i18n.t('common:tabs.products'),
		path: 'products',
		disableOnCreate: true,
		component: <PacketsProducts />
	},
	{
		name: i18n.t('common:tabs.availability'),
		path: 'availability',
		disableOnCreate: true,
		component: <PacketsAvailability />
	},
	{
		name: i18n.t('common:tabs.prices'),
		path: 'prices',
		disableOnCreate: true,
		component: <PacketsPrices />
	}
];
