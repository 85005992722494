import React, { useEffect } from 'react';
import PropTypes from 'prop-types';
import { compose } from 'redux';
import { connect } from 'react-redux';
import { withRouter } from 'react-router-dom';

// Import redux actions
import {
	fetchChannel,
	fetchCategories,
	dictionaryCastTypes,
	dictionaryPlatformsTypes
} from 'store/actions';

// Import utilities
import { submitForm } from 'components/utilities/form';

// Import components
import { FormTemplate } from 'components/common/templates';
import MetadataForm from '../channels_common/metadata_form/MetadataForm';

// Import helpers
import {
	liveCategoriesOptions,
	convertGenresToNames,
	convertToIds
} from 'components/helpers/genres_helpers/helpers';

const ChannelsForm = ({
	history,
	fetchChannel,
	fetchCategories,
	dictionaryPlatformsTypes,
	dictionaryCastTypes,
	error,
	isLoaded,
	initialValues,
	genresList,
	castList,
	platformList
}) => {
	useEffect(() => {
		dictionaryCastTypes();
		dictionaryPlatformsTypes();
		fetchCategories(liveCategoriesOptions, null, null);
		// eslint-disable-next-line
	}, []);

	const handleOnSubmit = ({ isEdit, itemID }) => async (values) => {
		const { genres } = values;

		// selected genres mapped to array of ids
		const genresId = convertToIds(genresList, genres);

		const resources = {
			...values,
			genres: genresId,
			genres_ids: genresId
		};

		// Submit the form with field values
		return await submitForm({
			history,
			isEdit,
			itemID,
			resources,
			api: 'products/channels',
			redirectPath: 'channels'
		});
	};

	const { genres } = initialValues;

	// convert genres objects of currently edited channel to array of genres names
	const genresNames = convertGenresToNames(genres);
	const genresListNames = convertGenresToNames(genresList);

	// form initial values
	const formInitialValues = {
		...initialValues,
		parent_uuid: null,
		genres: genresNames,
		genres_ids: genresNames
	};

	return (
		// Dispatch fetchChannelCategories, fetchChannel actions in in channels_form
		<FormTemplate
			fetchResourceToEdit={fetchChannel}
			error={error}
			isLoaded={isLoaded}
		>
			{({ isEdit, itemID, checkedPermissions }) => (
				<MetadataForm
					genres={genresListNames}
					casts={castList}
					platforms={platformList}
					isEdit={isEdit}
					itemID={itemID}
					initialValues={formInitialValues}
					handleOnSubmit={handleOnSubmit}
					path="channels"
					checkedPermissions={checkedPermissions}
				/>
			)}
		</FormTemplate>
	);
};

ChannelsForm.propTypes = {
	history: PropTypes.object.isRequired,
	initialValues: PropTypes.object.isRequired,
	fetchCategories: PropTypes.func.isRequired,
	fetchChannel: PropTypes.func.isRequired,
	error: PropTypes.bool.isRequired,
	isLoaded: PropTypes.bool.isRequired,
	genresList: PropTypes.array.isRequired,
	castList: PropTypes.array.isRequired,
	dictionaryPlatformsTypes: PropTypes.func.isRequired,
	platformList: PropTypes.array.isRequired,
	dictionaryCastTypes: PropTypes.func.isRequired
};

const mapStateToProps = ({
	channels: { form },
	categories: {
		table: { data }
	},
	dictionary: { castTypes, platformTypes }
}) => ({
	initialValues: form.edit,
	error: form.error,
	isLoaded: form.isLoaded,
	genresList: data,
	castList: castTypes,
	platformList: platformTypes
});

export default compose(
	connect(mapStateToProps, {
		fetchCategories,
		fetchChannel,
		dictionaryCastTypes,
		dictionaryPlatformsTypes
	}),
	withRouter
)(ChannelsForm);
