import React from 'react';
import { useHistory, useLocation } from 'react-router-dom';
import PropTypes from 'prop-types';

// Import translation
import { useTranslation } from 'react-i18next';

// Import helpers
import { replaceStringChar } from 'components/helpers/convert';
import { CREATE_SEGMENT_PATH } from './helpers';

const SegmentsHeader = ({
	vodId,
	videoId,
	canEditVideo,
	isAddButtonDisabled
}) => {
	const { t } = useTranslation();
	const { pathname } = useLocation();
	const history = useHistory();

	const handleButtonClick = () =>
		history.push({
			pathname: replaceStringChar(CREATE_SEGMENT_PATH, {
				':vodId': vodId,
				':videoId': videoId
			}),
			state: { fromPath: pathname }
		});

	return (
		<div className="d-flex align-items-center justify-content-between">
			<h5 style={{ fontSize: '14px', margin: 0 }}>
				{t('vod_segment:table_title')}
			</h5>
			{canEditVideo && (
				<button
					onClick={handleButtonClick}
					className={'ant-btn btn__link'}
					disabled={isAddButtonDisabled}
				>
					<i className={`fa fa-plus`} />
				</button>
			)}
		</div>
	);
};

SegmentsHeader.propTypes = {
	vodId: PropTypes.string.isRequired,
	videoId: PropTypes.number.isRequired,
	canEditVideo: PropTypes.bool.isRequired,
	isAddButtonDisabled: PropTypes.bool.isRequired
};

export default SegmentsHeader;
