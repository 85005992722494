import React from 'react';
import PropTypes from 'prop-types';
import { useDispatch } from 'react-redux';
import { fetchProviderNamesById } from 'store/actions';

// Import select options
import {
	select_vod_types as type,
	select_vod_subtypes as subtype
} from './select_type_options';

// Import utilites
import { renderFields } from 'components/utilities/form/renderFields';

// Import fields
import { BASE_FIELDS } from './input_fields';

const Fields = ({
	actors,
	writers,
	directors,
	submitting,
	genres,
	providersNames,
	providersId,
	paymentsModelsList,
	isEdit,
	currentValues,
	checkedPermissions,
	platformsList
}) => {
	const dispatch = useDispatch();

	// Fetch provider names based on provider id change
	const handleProviderIdChange = (value) =>
		fetchProviderNamesById(value)(dispatch);

	const selectResources = {
		actors,
		writers,
		directors,
		type,
		genres,
		provider: providersNames,
		provider_id: providersId,
		payment_models: paymentsModelsList,
		subtype,
		platforms: platformsList
	};

	return (
		<React.Fragment>
			<div className="hr-line-solid">&nbsp;</div>
			{BASE_FIELDS({
				isEdit,
				handleProviderIdChange,
				currentValues,
				checkedPermissions
			}).map((item, index) =>
				renderFields({ item, index, submitting, selectResources })
			)}
		</React.Fragment>
	);
};

Fields.propTypes = {
	actors: PropTypes.array,
	writers: PropTypes.array,
	directors: PropTypes.array,
	genres: PropTypes.array,
	submitting: PropTypes.bool.isRequired,
	providersNames: PropTypes.array,
	providersId: PropTypes.array,
	paymentsModelsList: PropTypes.array,
	isEdit: PropTypes.bool.isRequired,
	currentValues: PropTypes.object.isRequired,
	checkedPermissions: PropTypes.object.isRequired,
	platformsList: PropTypes.array.isRequired
};

export default Fields;
