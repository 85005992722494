import React from 'react';
import PropTypes from 'prop-types';
import { Field } from 'react-final-form';

// Import translation
import { useTranslation } from 'react-i18next';

// Import components
import { InputLabel, InputFileLabel } from 'components/common/inputs';

const Fields = ({ submitting }) => {
	const { t } = useTranslation();

	return (
		<>
			<Field
				disabled={submitting}
				name="file"
				type="file"
				placeholder={t('vod_subtitles:fields.file')}
				label={t('vod_subtitles:fields.file')}
				id="file"
				required={true}
				component={InputFileLabel}
			/>
			<div className="hr-line-dashed">&nbsp;</div>
			<Field
				disabled={submitting}
				name="language"
				type="text"
				placeholder={t('vod_subtitles:fields.language')}
				label={t('vod_subtitles:fields.language')}
				id="language"
				required={true}
				component={InputLabel}
			/>
			<div className="hr-line-dashed">&nbsp;</div>
		</>
	);
};

Fields.propTypes = {
	submitting: PropTypes.bool.isRequired
};

export default Fields;
