import React from 'react';
import PropTypes from 'prop-types';

const IboxTools = ({ children, className = '' }) => (
	<div className={`ibox-tools ${className}`}>{children}</div>
);

IboxTools.propTypes = {
	children: PropTypes.object,
	className: PropTypes.string
};

export default IboxTools;
