import services from 'services/services';
import { useParams } from 'react-router-dom';

// Import utilities
import { messageConfirmHandler } from 'components/utilities/message/confirm_message';
import { notificationHandler } from 'components/utilities/notifications';

// Import translation
import { useTranslation } from 'react-i18next';

const useSubscribersButtons = (subscriberData) => {
	const { t } = useTranslation();
	const { id: subscriberId } = useParams();

	const { login } = subscriberData;

	// SUSPEND SUBSCRIBER
	const suspendTitle = t('subscribers:modal.suspend_confirm.title');
	const suspendText = t('subscribers:modal.suspend_confirm.text');

	const handleSuspendSubscriber = () => {
		messageConfirmHandler(
			suspendTitle,
			suspendText,
			subscriberId,
			suspendSubscriber
		);
	};

	const suspendSubscriber = async (id) => {
		try {
			// clear permissions request
			await services.post(`pureott/subscriber/suspend/${id}`);

			// show success notification
			notificationHandler(
				t('messages:notifications.commissioned'),
				t('messages:notifications.subscriber_suspended_success')
			);
		} catch (error) {
			// show error notification
			notificationHandler(
				t('messages:notifications.error'),
				t('common:errors.common_error_message'),
				'error'
			);
		}
	};

	// RESEND RESET PASSWORD
	const resetPasswordTitle = t('subscribers:modal.resend_reset_password.title');
	const resetPasswordText = t('subscribers:modal.resend_reset_password.text');

	const handleResendResetPassword = () => {
		messageConfirmHandler(
			resetPasswordTitle,
			resetPasswordText,
			subscriberId,
			resendResetPassword,
			'warning',
			{ email: login }
		);
	};

	// id will never be used here but it must be preserved because of messageConfirmClearHandler function structure
	const resendResetPassword = async (id, data) => {
		try {
			await services.post(`/pureott/subscriber/send/resetPasswordEmail`, data);

			// show success notification
			notificationHandler(
				t('messages:notifications.commissioned'),
				t('messages:notifications.email_send_success')
			);
		} catch (error) {
			// show error notification
			notificationHandler(
				t('messages:notifications.error'),
				t('common:errors.common_error_message'),
				'error'
			);
		}
	};

	// RESEND APPROVAL EMAIL
	const approvalEmailTitle = t('subscribers:modal.resend_email_approval.title');
	const approvalEmailText = t('subscribers:modal.resend_email_approval.text');

	const handleResendApprovalEmail = () => {
		messageConfirmHandler(
			approvalEmailTitle,
			approvalEmailText,
			subscriberId,
			resendApprovalEmail,
			'warning',
			{ email: login }
		);
	};

	// id will never be used here but it must be preserved because of messageConfirmClearHandler function structure
	const resendApprovalEmail = async (id, data) => {
		try {
			await services.post(`/pureott/subscriber/send/emailApprovalEmail`, data);

			// show success notification
			notificationHandler(
				t('messages:notifications.commissioned'),
				t('messages:notifications.email_send_success')
			);
		} catch (error) {
			// show error notification
			notificationHandler(
				t('messages:notifications.error'),
				t('common:errors.common_error_message'),
				'error'
			);
		}
	};

	return {
		suspendSubscriber: handleSuspendSubscriber,
		resendResetPassword: handleResendResetPassword,
		resendApprovalEmail: handleResendApprovalEmail
	};
};

export default useSubscribersButtons;
