import React from 'react';
import i18n from 'i18next';

// Import components
import BannersForm from '../../banners_form/BannersForm';
import BannersImages from 'components/views/banners/banners_file/BannersImages';

// Import utilities
import { generateContentTranslationsTabs } from 'components/utilities/content_translation/tabs/content_translations_tabs';

// import content translation fields
import { BANNERS_INPUT_FIELDS_DATA } from 'components/utilities/content_translation/input_fields';

// Validate
import { bannersTranslationValidate } from 'components/utilities/content_translation/validation';

const bannersBasicTabs = [
	{
		name: i18n.t('common:tabs.metadata'),
		path: 'metadata',
		disableOnCreate: false,
		component: <BannersForm />
	},
	{
		name: i18n.t('common:tabs.pictures'),
		path: 'pictures',
		disableOnCreate: true,
		component: <BannersImages />
	}
];

export const bannersTabs = (contentTranslationLanguages) => [
	...bannersBasicTabs,
	...generateContentTranslationsTabs(
		BANNERS_INPUT_FIELDS_DATA,
		contentTranslationLanguages,
		bannersTranslationValidate
	)
];
