import React from 'react';
import PropTypes from 'prop-types';
import { compose } from 'redux';
import { connect } from 'react-redux';
import { withRouter } from 'react-router-dom';

// Import redux actions
import {
	fetchEventLiveAvailability,
	deleteEventLiveAvailability
} from 'store/actions';

// Import translation
import { useTranslation } from 'react-i18next';

// Import columns
import { createColumns } from './events_live_availability_columns';

// Import components
import { TableTemplate } from 'components/common/templates';

const EventsLiveAvailability = ({
	error,
	fetchEventLiveAvailability,
	deleteEventLiveAvailability,
	resources,
	match: {
		params: { id }
	}
}) => {
	const { t } = useTranslation();

	return (
		// Dispatch fetchEventLiveAvailability, deleteEventLiveAvailability action in events_live_availability
		<TableTemplate
			itemId={id}
			error={error}
			type={`events_live/${id}/availability`}
			typeText="availability"
			addLinkText={t('availability:buttons.add')}
			resources={resources}
			columns={createColumns}
			fetchResourcesAction={fetchEventLiveAvailability}
			deleteItemAction={deleteEventLiveAvailability}
			notificationDeleteSuccessTxt={t(
				'availability:notification.delete_success'
			)}
			notificationDeleteErrorTxt={t('availability:notification.delete_error')}
		/>
	);
};

EventsLiveAvailability.propTypes = {
	error: PropTypes.bool.isRequired,
	resources: PropTypes.object.isRequired,
	fetchEventLiveAvailability: PropTypes.func.isRequired,
	deleteEventLiveAvailability: PropTypes.func.isRequired,
	match: PropTypes.shape({
		params: PropTypes.shape({ id: PropTypes.string.isRequired })
	})
};

const mapStateToProps = ({ events_live: { availability } }) => ({
	error: availability.table.error,
	resources: availability.table
});

export default compose(
	connect(mapStateToProps, {
		fetchEventLiveAvailability,
		deleteEventLiveAvailability
	}),
	withRouter
)(EventsLiveAvailability);
