const getMatchedGoblocks = ({ geoblockGroups, geoblockGroupIds }) =>
	geoblockGroups.reduce((acc, group) => {
		geoblockGroupIds.includes(group.id) && acc.push(group.name);

		return acc;
	}, []);

export const getGeoblockNames = ({ geoblockGroups, geoblockGroupIds }) => {
	const geoblockNames = getMatchedGoblocks({
		geoblockGroups,
		geoblockGroupIds
	});

	return geoblockNames.length ? geoblockNames.join(', ') : '';
};
