import React from 'react';

// Import components
import { Link } from 'react-router-dom';
import ActiveIcon from 'components/utilities/table/render_icons/ActiveIcon';

// Import translation
import i18n from 'i18next';

export const createColumns = (handleSelectItem, isReadOnly) => [
	{
		Header: i18n.t('availability:table_columns.active'),
		accessor: 'active',
		width: 75,
		className: 'text-center',
		Cell: ({
			row: {
				_original: { active }
			}
		}) => <ActiveIcon active={active} />
	},
	{
		Header: i18n.t('availability:table_columns.type'),
		accessor: 'type',
		width: 75,
		className: 'text-center',
		Cell: ({
			row: {
				_original: { type }
			}
		}) => <ActiveIcon active={type} />
	},
	{
		Header: i18n.t('availability:table_columns.since'),
		accessor: 'since'
	},
	{
		Header: i18n.t('availability:table_columns.till'),
		accessor: 'till'
	},
	{
		Header: i18n.t('availability:table_columns.created_at'),
		accessor: 'created_at'
	},
	{
		Header: i18n.t('sections:table_columns.edit'),
		width: 80,
		className: 'text-center',
		sortable: false,
		Cell: ({
			row: {
				_original: { product_type, product_uuid, id }
			}
		}) =>
			!isReadOnly && (
				<Link
					to={`/panel/${product_type}/${product_uuid}/availability/edit/${id}`}
					className="btn btn-xs btn-primary"
				>
					<i className="fa fa-gear" />
				</Link>
			)
	},
	{
		Header: i18n.t('common:table_columns.delete'),
		accessor: 'delete',
		width: 60,
		className: 'text-center',
		sortable: false,
		Cell: ({
			row: {
				_original: { id, products }
			}
		}) =>
			!isReadOnly && (
				<button
					onClick={() => handleSelectItem(id)}
					className="btn btn-xs btn-primary"
					disabled={products === 0 ? true : false}
				>
					<i className="fa fa-trash" />
				</button>
			)
	}
];
