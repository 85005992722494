import React from 'react';
import PropTypes from 'prop-types';
import { Field } from 'react-final-form';

// Import translation
import { useTranslation } from 'react-i18next';

// Import components
import { InputLabel, CheckboxLabel } from 'components/common/inputs';

const Fields = ({ submitting }) => {
	const { t } = useTranslation();

	return (
		<React.Fragment>
			<Field
				disabled={true}
				name="login"
				type="text"
				placeholder={t('subscribers:fields.login')}
				label={t('subscribers:fields.login')}
				id="login"
				required={true}
				component={InputLabel}
			/>
			<div className="hr-line-dashed">&nbsp;</div>

			<Field
				disabled={submitting}
				name="payment_pin"
				type="text"
				placeholder={t('subscribers:fields.payment_pin')}
				label={t('subscribers:fields.payment_pin')}
				id="payment_pin"
				required={true}
				component={InputLabel}
				fieldInfo={t('subscribers:fields_info.payment_pin')}
			/>
			<div className="hr-line-dashed">&nbsp;</div>

			<Field
				disabled={submitting}
				name="parental_control_pin"
				type="text"
				placeholder={t('subscribers:fields.parental_control_pin')}
				label={t('subscribers:fields.parental_control_pin')}
				id="parental_control_pin"
				required={true}
				component={InputLabel}
				fieldInfo={t('subscribers:fields_info.parental_control_pin')}
			/>
			<div className="hr-line-dashed">&nbsp;</div>

			<Field
				disabled={submitting}
				name="account_pin"
				type="text"
				placeholder={t('subscribers:fields.account_pin')}
				label={t('subscribers:fields.account_pin')}
				id="account_pin"
				required={true}
				component={InputLabel}
				fieldInfo={t('subscribers:fields_info.account_pin')}
			/>
			<div className="hr-line-dashed">&nbsp;</div>

			<Field
				disabled={submitting}
				name="adult_access_pin"
				type="text"
				placeholder={t('subscribers:fields.adult_access_pin')}
				label={t('subscribers:fields.adult_access_pin')}
				id="adult_access_pin"
				required={true}
				component={InputLabel}
				fieldInfo={t('subscribers:fields_info.adult_access_pin')}
			/>
			<div className="hr-line-dashed">&nbsp;</div>

			<Field
				disabled={true}
				name="credits_limit"
				type="number"
				placeholder={t('subscribers:fields.credits_limit')}
				label={t('subscribers:fields.credits_limit')}
				id="credits_limit"
				required={true}
				component={InputLabel}
			/>
			<div className="hr-line-dashed">&nbsp;</div>

			<Field
				disabled={submitting}
				name="npvr_storage_limit"
				type="number"
				placeholder={t('subscribers:fields.npvr_storage_limit')}
				label={t('subscribers:fields.npvr_storage_limit')}
				id="npvr_storage_limit"
				required={true}
				component={InputLabel}
			/>
			<div className="hr-line-dashed">&nbsp;</div>

			<Field
				disabled={submitting}
				name="hide_unavailable_products"
				type="checkbox"
				placeholder={t('subscribers:fields.hide_unavailable_products')}
				label={t('subscribers:fields.hide_unavailable_products')}
				id="hide_unavailable_products"
				component={CheckboxLabel}
			/>
			<div className="hr-line-dashed">&nbsp;</div>
		</React.Fragment>
	);
};

Fields.propTypes = {
	submitting: PropTypes.bool.isRequired
};

export default Fields;
