import React from 'react';
import Modal from 'antd/es/modal';

export const messageInfo = ({ type = 'success', message, title }) => {
	Modal[type]({
		title: title,
		content: <p>{message}</p>,
		onOk() {}
	});
};
