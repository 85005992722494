import React from 'react';
import PropTypes from 'prop-types';
import { Form } from 'react-final-form';
import { compose } from 'redux';
import { connect } from 'react-redux';
import { withRouter } from 'react-router-dom';
import { fetchCategory } from 'store/actions';

// Import translation
import { useTranslation } from 'react-i18next';

// Import utilities
import { submitForm } from 'components/utilities/form';

// Import helpers
import { convertTypeToNumber } from 'components/helpers/genres_helpers/helpers';

// Validate
import validate from './validation';

// Import components
import { FormTemplate } from 'components/common/templates';
import { FormButtons } from 'components/common/buttons';
import Fields from './fields/Fields';

const CategoriesForm = ({
	history,
	fetchCategory,
	error,
	isLoaded,
	initialValues,
	match: {
		params: { type }
	}
}) => {
	const { t } = useTranslation();

	const handleOnSubmit = ({ isEdit, itemID }) => async (values) => {
		const resources = {
			type: convertTypeToNumber(type), //needed for create form. Overwrite data with ...values when using edit mode
			...values
		};

		// Submit the form with field values
		return await submitForm({
			history,
			isEdit,
			itemID,
			resources,
			api: 'products/genres',
			redirectPath: 'categories'
		});
	};

	return (
		// Dispatch fetchCategory actions in sections_form
		<FormTemplate
			fetchResourceToEdit={fetchCategory}
			error={error}
			isLoaded={isLoaded}
		>
			{({ isEdit, itemID }) => (
				<Form
					initialValues={{ public: false, ...initialValues }}
					validate={validate}
					onSubmit={handleOnSubmit({ isEdit, itemID })}
					render={({ handleSubmit, submitting }) => (
						<form onSubmit={handleSubmit}>
							<Fields submitting={submitting} />

							<FormButtons
								isButtonDisabled={submitting || error}
								path="categories"
								buttonText={t('common:buttons.submit')}
							/>
						</form>
					)}
				/>
			)}
		</FormTemplate>
	);
};

CategoriesForm.propTypes = {
	history: PropTypes.object.isRequired,
	initialValues: PropTypes.object.isRequired,
	fetchCategory: PropTypes.func.isRequired,
	error: PropTypes.bool.isRequired,
	isLoaded: PropTypes.bool.isRequired,
	match: PropTypes.shape({
		params: PropTypes.shape({ id: PropTypes.string })
	})
};

const mapStateToProps = ({ categories: { form } }) => {
	return {
		initialValues: form.edit,
		error: form.error,
		isLoaded: form.isLoaded
	};
};

export default compose(
	connect(mapStateToProps, { fetchCategory }),
	withRouter
)(CategoriesForm);
