import React from 'react';

// Import translation
import i18n from 'i18next';

export const createDeviceColumns = (handleSelectItem, isReadOnly) => [
	{
		Header: i18n.t('subscribers:devices.table_columns.active'),
		accessor: 'active',
		width: 75,
		sortable: false,
		headerClassName: 'text-center',
		className: 'text-center',
		Cell: ({
			row: {
				_original: { active }
			}
		}) => (
			<i
				className="fa fa-check"
				style={{
					fontSize: '16px',
					color: `${active ? '#13ce66' : '#676a6c'}`
				}}
				aria-hidden="true"
			></i>
		)
	},
	{
		Header: i18n.t('subscribers:devices.table_columns.device_id'),
		accessor: 'device_id',
		className: 'scroll-cell'
	},
	{
		Header: i18n.t('subscribers:devices.table_columns.device_name'),
		accessor: 'device_name',
		className: 'scroll-cell'
	},
	{
		Header: i18n.t('subscribers:devices.table_columns.agent'),
		accessor: 'agent',
		className: 'scroll-cell',
		Cell: ({
			row: {
				_original: { agent, system, agent_version, app_version, os_version }
			}
		}) => (
			<span>
				{agent} {agent_version} {app_version} / {system} {os_version}
			</span>
		)
	},
	{
		Header: i18n.t('subscribers:devices.table_columns.producer'),
		accessor: 'producer',
		width: 100,
		className: 'scroll-cell'
	},
	{
		Header: i18n.t('subscribers:devices.table_columns.version_name'),
		accessor: 'version_name',
		width: 120,
		className: 'scroll-cell'
	},
	{
		Header: i18n.t('subscribers:devices.table_columns.created_at'),
		accessor: 'created_at',
		width: 160
	},
	{
		Header: i18n.t('subscribers:devices.table_columns.platform'),
		accessor: 'platform',
		width: 110
	},
	{
		Header: i18n.t('subscribers:devices.table_columns.last_login_date'),
		accessor: 'last_login_date',
		width: 160
	},
	{
		Header: i18n.t('subscribers:devices.table_columns.last_login_ip'),
		accessor: 'last_login_ip',
		className: 'scroll-cell',
		width: 130
	},
	{
		Header: i18n.t('subscribers:devices.table_columns.deactivated_at'),
		accessor: 'deactivated_at',
		width: 160
	},
	{
		Header: i18n.t('subscribers:devices.table_columns.deactivate'),
		accessor: 'deactivate',
		width: 90,
		className: 'text-center',
		sortable: false,
		Cell: ({
			row: {
				_original: { device_id }
			}
		}) =>
			!isReadOnly && (
				<button
					onClick={() => handleSelectItem(device_id)}
					className="btn btn-xs btn-primary"
				>
					<i className="fa fa-times" />
				</button>
			)
	}
];
