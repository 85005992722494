import React from 'react';
import PropTypes from 'prop-types';
import { Button } from 'antd';
import { Col, FormGroup } from 'reactstrap';

// Import translation
import { useTranslation } from 'react-i18next';

const ModalFormButtons = ({
	buttonText,
	isButtonDisabled = false,
	closeButton = true,
	className = '',
	modalCloseAction,
	modalOkAction = null
}) => {
	const { t } = useTranslation();

	return (
		<FormGroup row className={className}>
			<Col
				className="d-flex justify-content-end m-t-md"
				sm={{ size: '10', offset: 2 }}
			>
				{closeButton && (
					<Button
						className="m-r-md"
						type="default"
						htmlType="button"
						onClick={modalCloseAction}
					>
						{t('common:buttons.cancel')}
					</Button>
				)}
				<Button
					disabled={isButtonDisabled}
					type="primary"
					htmlType="submit"
					loading={isButtonDisabled}
					onClick={modalOkAction}
				>
					{buttonText || t('common:buttons.submit')}
				</Button>
			</Col>
		</FormGroup>
	);
};

ModalFormButtons.propTypes = {
	path: PropTypes.string,
	isButtonDisabled: PropTypes.bool,
	closeButton: PropTypes.bool,
	buttonText: PropTypes.string,
	className: PropTypes.string,
	modalCloseAction: PropTypes.func.isRequired
};

export default ModalFormButtons;
