import { useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { dictionaryAssetTypes } from 'store/actions';

const usePageImages = ({ modelTypes }) => {
	const dispatch = useDispatch();

	const { assetTypes } = useSelector((state) => state.dictionary);

	useEffect(() => {
		// Dispatch  action in channels_files
		dictionaryAssetTypes()(dispatch);
		// eslint-disable-next-line
	}, []);

	const filteredProviders = Object.entries(assetTypes)
		.filter(([type]) => modelTypes.includes(type))
		.sort((a, b) => a[0].localeCompare(b[0]));

	return { filteredProviders };
};

export default usePageImages;
