import React from 'react';
import Tabs from 'components/utilities/tabs/Tabs';
import { useSelector } from 'react-redux';

// Import helrpes
import { mainTabs } from './helpers/index';

const MainTabs = () => {
	const { languages: contentTranslationLanguages } = useSelector(
		({ content_translations }) => content_translations.language_list
	);
	return <Tabs tabs={mainTabs(contentTranslationLanguages)} />;
};

export default MainTabs;
