import React from 'react';
import PropTypes from 'prop-types';
import { Field } from 'react-final-form';
import Select from 'antd/es/select';

// Import translation
import { useTranslation } from 'react-i18next';

// Import components
import { InputLabel, MultiSelectLabel } from 'components/common/inputs';

const { Option } = Select;

const Fields = ({ submitting, roles, isEdit }) => {
	const { t } = useTranslation();

	return (
		<React.Fragment>
			<Field
				disabled={submitting}
				name="email"
				type="email"
				placeholder={t('operators:fields.email_placeholder')}
				label={t('operators:fields.email_label')}
				id="email"
				required={true}
				component={InputLabel}
			/>
			<div className="hr-line-dashed">&nbsp;</div>
			<Field
				disabled={submitting}
				name="firstName"
				type="text"
				placeholder={t('operators:fields.first_name_placeholder')}
				label={t('operators:fields.first_name_label')}
				id="firstName"
				required={true}
				component={InputLabel}
			/>
			<div className="hr-line-dashed">&nbsp;</div>
			<Field
				disabled={submitting}
				name="lastName"
				type="text"
				placeholder={t('operators:fields.last_name_placeholder')}
				label={t('operators:fields.last_name_label')}
				id="lastName"
				required={true}
				component={InputLabel}
			/>
			<div className="hr-line-dashed">&nbsp;</div>
			<Field
				disabled={submitting}
				name="password"
				type="password"
				autoComplete="off"
				placeholder={t('operators:fields.password_placeholder')}
				label={t('operators:fields.password_label')}
				id="password"
				required={isEdit}
				component={InputLabel}
			/>
			<div className="hr-line-dashed">&nbsp;</div>
			<Field
				disabled={submitting}
				name="password_confirmation"
				type="password"
				autoComplete="off"
				placeholder={t('operators:fields.password_confirmation_placeholder')}
				label={t('operators:fields.password_confirmation_label')}
				id="password_confirmation"
				required={isEdit}
				component={InputLabel}
			/>
			<div className="hr-line-dashed">&nbsp;</div>
			<Field
				disabled={submitting}
				name="roles"
				type="select"
				placeholder={t('operators:fields.role_placeholder')}
				label={t('operators:fields.role_label')}
				id="roles"
				required={true}
				component={MultiSelectLabel}
			>
				{roles.map((role) => (
					<Option key={role.id} value={role.name}>
						{role.name}
					</Option>
				))}
			</Field>
			<div className="hr-line-dashed">&nbsp;</div>
		</React.Fragment>
	);
};

Fields.propTypes = {
	submitting: PropTypes.bool.isRequired,
	roles: PropTypes.array.isRequired,
	isEdit: PropTypes.bool.isRequired
};

export default Fields;
