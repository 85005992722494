// Import translation
import i18n from 'i18next';

export default ({ start_time_in_seconds, end_time_in_seconds, type }) => {
	const errors = {};

	// ------------------ Start time ------------------
	if (!start_time_in_seconds) {
		errors.start_time_in_seconds = i18n.t(
			'vod_segment:validation.start_time_in_seconds'
		);
	}

	// ------------------ End time ------------------
	if (!end_time_in_seconds) {
		errors.end_time_in_seconds = i18n.t(
			'vod_segment:validation.end_time_in_seconds'
		);
	}

	// ------------------ Type ------------------
	if (!type) {
		errors.type = i18n.t('vod_segment:validation.type');
	}

	return errors;
};
