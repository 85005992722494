import i18n from 'i18next';

export const defaultFieldValues = {
	period: '1',
	period_unit: 'month'
};

export const BASE_FIELDS = [
	{
		name: 'period',
		label: i18n.t('subscribers:fields.period'),
		type: 'number',
		required: true
	},
	{
		name: 'period_unit',
		label: i18n.t('subscribers:fields.period_unit'),
		type: 'select',
		required: true
	},
	{
		name: 'packet_uuid',
		label: i18n.t('subscribers:fields.packet_uuid'),
		type: 'select',
		required: true
	}
];
