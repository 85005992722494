import i18n from 'i18next';

export const BASE_FIELDS = [
	{
		name: 'range',
		label: i18n.t('availability:fields.range'),
		type: 'range',
		placeholder: [
			i18n.t('availability:fields.since'),
			i18n.t('availability:fields.till')
		],
		required: true,
		labelColumn: 2,
		inputColumn: 10,
		showTime: true,
		rangeFormat: 'YYYY-MM-DD HH:mm:ss'
	},
	{
		name: 'price',
		label: i18n.t('prices:fields.price'),
		type: 'number',
		required: true,
		labelColumn: 2,
		inputColumn: 10,
		step: 0.01,
		min: 0
	},
	{
		name: 'external_id',
		label: i18n.t('prices:fields.external_id'),
		type: 'text',
		required: false,
		labelColumn: 2,
		inputColumn: 10
	},
	{
		name: 'period',
		label: i18n.t('prices:fields.period'),
		min: -1,
		type: 'number',
		required: true,
		labelColumn: 2,
		inputColumn: 10
	},
	{
		name: 'periodUnit',
		label: i18n.t('prices:fields.periodUnit'),
		type: 'select',
		required: false,
		labelColumn: 2,
		inputColumn: 10
	},
	{
		name: 'paymentMethod',
		label: i18n.t('prices:fields.payment_method'),
		type: 'select',
		required: true,
		labelColumn: 2,
		inputColumn: 10
	}
];

export const GEOBLOCK_FIELDS = [
	{
		name: 'geoblock_group_ids',
		label: i18n.t('prices:fields.geoblock_group_ids'),
		type: 'custom-names',
		mode: 'multiple',
		showSearch: true,
		labelColumn: 2,
		inputColumn: 10
	}
];
