import React from 'react';
import {
	string,
	number,
	object,
	func,
	oneOfType,
	node,
	arrayOf,
	shape,
	bool
} from 'prop-types';
import { Col, FormGroup, Label, Input } from 'reactstrap';

const InputFileLabel = ({
	children,
	input: { value, onChange, ...input },
	id,
	label,
	labelColumn = 2,
	inputColumn = 10,
	meta: { touched, error, submitError },
	...inputProps
}) => {
	const handleOnChange = ({ target }) => onChange(target.files);

	return (
		<FormGroup
			data-testid={`form-group-${input.name}`}
			row
			className={`${touched && error && 'has-error'}`}
		>
			<Label
				for={id}
				sm={labelColumn}
				className="col-form-label col-form-label-lg"
			>
				{label}
			</Label>
			<Col sm={inputColumn}>
				<Input
					invalid={touched && (error || submitError) ? true : false}
					id={id}
					{...input}
					{...inputProps}
					onChange={handleOnChange}
				/>
				{touched && (error || submitError) && (
					<span
						data-testid={`input-error-${input.name}`}
						className="input-error"
					>
						{error || submitError}
					</span>
				)}
			</Col>
			{children}
		</FormGroup>
	);
};

InputFileLabel.propTypes = {
	input: shape({
		value: oneOfType([string, object]).isRequired,
		onChange: func.isRequired
	}),
	label: string.isRequired,
	id: string.isRequired,
	labelColumn: number,
	inputColumn: number,
	children: oneOfType([node, arrayOf(node)]),
	meta: shape({
		touched: bool.isRequired,
		error: string,
		submitError: bool
	})
};

export default InputFileLabel;
