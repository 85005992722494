import React, { useContext } from 'react';
import CardsList from '../cards_list/CardsList';

// Import translation
import { useTranslation } from 'react-i18next';

// Import context
import { ProductsContext } from '../ProductsProvider';

const ProvidersList = () => {
	const { t } = useTranslation();
	const { selectedData, handleRemoveItem } = useContext(ProductsContext);

	const providers = selectedData.filter(({ type }) => type === 'provider');

	return (
		providers.length > 0 && (
			<>
				<h5>{t('limits:provider')}</h5>
				<CardsList
					data={providers}
					isDelete={true}
					deleteFunction={handleRemoveItem}
				/>
			</>
		)
	);
};

export default ProvidersList;
