// Import translation
import i18n from 'i18next';

export default ({ name, countries }) => {
	const errors = {};

	// ------------------ name ------------------
	if (!name) {
		errors.name = i18n.t('geoblock_groups:validation.name');
	}

	// ------------------ countries ------------------
	if (!countries.length) {
		errors.countries = i18n.t('geoblock_groups:validation.countries');
	}

	return errors;
};
