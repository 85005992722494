import React from 'react';
import { string } from 'prop-types';

/** Functional component responsible for displaying info title
 * @param {string} title - title to display
 * @returns {*}
 */
function InfoTitle({ title }) {
	return (
		<h4 className="info-title__header">
			<i className="fa fa-info info-title__header__icon m-r-md"></i>
			{title}
		</h4>
	);
}

InfoTitle.propTypes = {
	title: string.isRequired
};

export default InfoTitle;
