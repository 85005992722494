// Import translation
import i18n from 'i18next';

export const convertErrors = (response) =>
	Object.keys(response).reduce((prev, next, index) => {
		prev[next] = Object.values(response)[index][0];
		return prev;
	}, {});

export const checkFetchErrors = (error) => {
	if (error.response) {
		return error.response.status === 401 ? false : true;
	}
};

// Translate error messages
// This is temporary solution to translate errors.
// In future create error handling api and translate messages based on proper error codes
export const translateError = (message) => {
	switch (message) {
		case 'Cannot activate vod':
			return i18n.t('errors:406.cannot_activate_vod');

		default:
			return message;
	}
};
