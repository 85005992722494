import React, { useEffect } from 'react';
import { func, object, bool, array } from 'prop-types';
import { connect } from 'react-redux';
import { Form } from 'react-final-form';

// Import actions
import { fetchSoftwareDetails, dictionaryPlatformsTypes } from 'store/actions';

// Import translation
import { useTranslation } from 'react-i18next';

// Validate
import validate from './validation';

// Import utilities
import { submitForm } from 'components/utilities/form';

// Import components
import { FormTemplate } from 'components/common/templates';
import { FormButtons } from 'components/common/buttons';
import Fields from './fields/Fields';
import moment from 'moment';

// Import helpers
import { formatSNList } from 'components/helpers/systems_platforms';

function SoftwareForm({
	history,
	fetchSoftwareDetails,
	dictionaryPlatformsTypes,
	initialValues,
	error,
	isLoaded,
	platformList
}) {
	useEffect(() => {
		dictionaryPlatformsTypes();
		// eslint-disable-next-line
	}, []);

	const { t } = useTranslation();

	// reload form after editing software
	const reloadForm = (id) => {
		if (id) fetchSoftwareDetails(id);
	};

	const handleOnSubmit = ({ isEdit, itemID }) => async (values) => {
		// if there's not sn_value add empty string as value to form
		// otherwise form append does not see data (sn_list key does not exist)
		const { sn_list } = values;
		if (!sn_list) {
			values = { ...values, sn_list: '' };
		}

		// appdend values data to FormData. Required to properly send uploaded file
		let resources = new FormData();
		Object.keys(values).forEach((key) => {
			switch (key) {
				case 'file':
					if (typeof values[key] === 'string') break;
					resources.append(key, values.file[0]);
					break;
				case 'force':
					resources.append(key, values[key] === false ? 0 : 1);
					break;
				case 'start_date':
					resources.append(
						key,
						moment(values[key]).format('YYYY-MM-DD HH:mm:ss')
					);
					break;
				case 'sn_list':
					resources.append(key, formatSNList(values[key]));
					break;
				default:
					resources.append(key, values[key]);
					break;
			}
		});

		return await submitForm({
			history,
			isEdit,
			itemID,
			resources,
			api: 'device/software',
			apiSlug: 'update',
			notificationName: 'software',
			callback: () => {
				reloadForm(itemID);
			}
		});
	};

	const formInitialValues = {
		...initialValues
	};

	return (
		<FormTemplate
			fetchResourceToEdit={fetchSoftwareDetails}
			error={error}
			isLoaded={isLoaded}
		>
			{({ isEdit, itemID }) => (
				<Form
					initialValues={formInitialValues}
					validate={validate}
					onSubmit={handleOnSubmit({ isEdit, itemID })}
					render={({ handleSubmit, submitting }) => (
						<form onSubmit={handleSubmit}>
							<Fields
								submitting={submitting}
								platforms={platformList}
								isEdit={isEdit}
							/>
							<FormButtons
								isButtonDisabled={submitting || error}
								path="software"
								buttonText={t('common:buttons.submit')}
							/>
						</form>
					)}
				/>
			)}
		</FormTemplate>
	);
}

SoftwareForm.propTypes = {
	history: object.isRequired,
	fetchSoftwareDetails: func.isRequired,
	dictionaryPlatformsTypes: func.isRequired,
	initialValues: object.isRequired,
	error: bool.isRequired,
	isLoaded: bool.isRequired,
	platformList: array.isRequired
};

const mapStateToProps = ({
	software: { form },
	dictionary: { platformTypes }
}) => ({
	initialValues: form.edit,
	error: form.error,
	isLoaded: form.isLoaded,
	platformList: platformTypes
});

export default connect(mapStateToProps, {
	fetchSoftwareDetails,
	dictionaryPlatformsTypes
})(SoftwareForm);
