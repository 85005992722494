// Import translation

import i18n from 'i18next';

// Import variables
import { SUBSCRIBER_TYPES } from 'components/helpers/variables';

const {
	ALL,
	ACTIVE,
	NEW,
	RECURRING,
	INACTIVE,
	CANCELLED,
	UNABLE
} = SUBSCRIBER_TYPES;

// list of subscribers types
export const SUBSCRIBERS_TYPES = [
	{ name: i18n.t('subscribers:select.all'), value: ALL },
	{ name: i18n.t('subscribers:select.active'), value: ACTIVE },
	{
		name: i18n.t('subscribers:select.new_subscribers'),
		value: NEW
	},
	{
		name: i18n.t('subscribers:select.recurring_subscribers'),
		value: RECURRING
	},
	{ name: i18n.t('subscribers:select.inactive'), value: INACTIVE },
	{
		name: i18n.t('subscribers:select.cancelled_subscribers'),
		value: CANCELLED
	},
	{
		name: i18n.t('subscribers:select.unable_subscribers'),
		value: UNABLE
	}
];

export const SUBSCRIBER_SOURCE_TYPES = [
	{ name: 'FIREBASE', value: 'firebase' },
	{ name: 'ANONYMOUS', value: 'anonymous' }
];
