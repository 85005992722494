import React from 'react';
import { useDispatch } from 'react-redux';

// Import actions
import { fetchBannerCover } from 'store/actions';

// Import components
import Image from 'components/utilities/images/Image';
import InfoTitle from 'components/utilities/info/InfoTitle';

// Import translation
import { useTranslation } from 'react-i18next';

// Import helpers
import { IMAGE_SIZE_INFO } from 'components/helpers/info_helpers/info_texts';

const BannersImages = () => {
	const { t } = useTranslation();
	const dispatch = useDispatch();
	return (
		<>
			<InfoTitle title={IMAGE_SIZE_INFO} />
			<Image
				title={t('common:images.cover')}
				fetchResourcesAction={(id) => fetchBannerCover(id)(dispatch)}
				collectionType="cover"
				mediaType="banner"
				storePathSelector="banners"
			/>
		</>
	);
};

export default BannersImages;
