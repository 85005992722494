import React from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { compose } from 'redux';
import { withRouter } from 'react-router-dom';
import {
	fetchAssignedPackets,
	setAssignedPacketsQuery,
	dictionaryPaymentMethods,
	createFiltersSuggestions,
	fetchProviders
} from 'store/actions';

// Import hooks
import useFilterLogic from 'hooks/useFilterLogic';

// Import columns
import { createColumns } from './assigned_packets_columns';

// Import utilities
import Search from 'components/utilities/search/Search';

// Import components
import { PanelPageTemplate } from 'components/common/templates';
import FilterButton from 'components/common/buttons/FilterButton';

const AssignedPackets = ({
	error,
	resources,
	fetchAssignedPackets,
	setAssignedPacketsQuery,
	dictionaryPaymentMethods,
	createFiltersSuggestions,
	fetchProviders,
	filters,
	match: {
		params: { id }
	}
}) => {
	// Actions to fetch data for filters
	const filterActions = {
		dictionaryPaymentMethods,
		fetchProviders
	};

	// execute filters logic
	useFilterLogic({
		filterActions,
		createFiltersSuggestions,
		filters
	});

	const { columns } = resources;

	return (
		<PanelPageTemplate
			itemId={id}
			error={error}
			type="assigned_packets"
			resources={resources}
			columns={createColumns}
			fetchResourcesAction={fetchAssignedPackets}
			defaultSorted={[{ id: 'created_at', desc: true }]}
		>
			<div className="d-flex justify-content-end">
				<Search setQueryValue={setAssignedPacketsQuery} />
				<FilterButton tableColumns={columns} />
			</div>
		</PanelPageTemplate>
	);
};

AssignedPackets.propTypes = {
	error: PropTypes.bool.isRequired,
	resources: PropTypes.object.isRequired,
	fetchAssignedPackets: PropTypes.func.isRequired,
	setAssignedPacketsQuery: PropTypes.func.isRequired,
	dictionaryPaymentMethods: PropTypes.func.isRequired,
	createFiltersSuggestions: PropTypes.func.isRequired,
	location: PropTypes.object.isRequired,
	filters: PropTypes.object,
	match: PropTypes.shape({
		params: PropTypes.shape({ id: PropTypes.string })
	})
};

const mapStateToProps = ({
	assigned_packets: { table },
	dictionary: { paymentMethods },
	providers
}) => ({
	error: table.error,
	resources: table,
	filters: { payment_method: paymentMethods, providers: providers.table.data }
});

export default compose(
	connect(mapStateToProps, {
		fetchAssignedPackets,
		setAssignedPacketsQuery,
		dictionaryPaymentMethods,
		createFiltersSuggestions,
		fetchProviders
	}),
	withRouter
)(AssignedPackets);
