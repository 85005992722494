import React from 'react';
import { Link } from 'react-router-dom';

// Import translation
import i18n from 'i18next';

export const createColumns = () => [
	{
		Header: i18n.t('sections_groups:table_columns.name'),
		accessor: 'name',
		Cell: ({
			row: {
				_original: { name, id }
			}
		}) => (
			<Link to={`/panel/sections_groups/edit/${id}/sections-assigned`}>
				{name}
			</Link>
		)
	},
	{
		Header: i18n.t('sections_groups:table_columns.label'),
		accessor: 'label'
	}
];
