import React from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { fetchDevices } from 'store/actions';

// Import translation
import { useTranslation } from 'react-i18next';

// Import columns
import { createColumns } from './devices_columns';

// Import components
import { PanelPageTemplate } from 'components/common/templates';

const Devices = ({ error, fetchDevices, resources }) => {
	const { t } = useTranslation();
	const breadcrumbs = [t('common:breadcrumbs.devices')];

	return (
		// Dispatch fetchDevices, devices_panel
		<PanelPageTemplate
			title={t('common:list')}
			error={error}
			breadcrumbs={breadcrumbs}
			type="devices"
			resources={resources}
			columns={createColumns}
			fetchResourcesAction={fetchDevices}
		/>
	);
};

Devices.propTypes = {
	error: PropTypes.bool.isRequired,
	resources: PropTypes.object.isRequired,
	fetchDevices: PropTypes.func.isRequired
};

const mapStateToProps = ({ devices }) => ({
	error: devices.table.error,
	resources: devices.table
});

export default connect(mapStateToProps, { fetchDevices })(Devices);
