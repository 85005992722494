import React from 'react';
import { useDispatch } from 'react-redux';
import { clearUploadedMaterialsTable } from 'store/actions';

// Import components
import { Button } from 'reactstrap';

// Import translation
import { useTranslation } from 'react-i18next';

const RefreshUploadTableButton = () => {
	const { t } = useTranslation();
	const dispatch = useDispatch();

	const handleRefresh = () => clearUploadedMaterialsTable()(dispatch);

	return (
		<Button onClick={handleRefresh}>
			{<i className="fa fa-refresh m-r-sm" />}
			{t('common:buttons.refresh')}
		</Button>
	);
};

export default RefreshUploadTableButton;
