import { BLUE_ONLINE_ROLES } from 'components/helpers/roles_variables';

const { ADMINISTRATOR, REDACTOR, EDITOR, HELP_DESK } = BLUE_ONLINE_ROLES;

export const AVAILABILITY = {
	allowedRoles: [ADMINISTRATOR, REDACTOR, EDITOR, HELP_DESK],
	canActivate: [ADMINISTRATOR, REDACTOR]
};
export const VISIBILITY = {
	allowedRoles: [ADMINISTRATOR, REDACTOR, EDITOR, HELP_DESK],
	canActivate: [ADMINISTRATOR, REDACTOR]
};
export const PRICES = {
	allowedRoles: [ADMINISTRATOR, REDACTOR, EDITOR, HELP_DESK],
	canActivate: [ADMINISTRATOR, REDACTOR]
};
export const PROMOTIONS = {
	allowedRoles: [ADMINISTRATOR, REDACTOR],
	canActivate: [ADMINISTRATOR, REDACTOR]
};
export const CHANNELS = {
	allowedRoles: [ADMINISTRATOR],
	canActivate: [ADMINISTRATOR, REDACTOR]
};
export const PROVISIONING = {
	allowedRoles: [ADMINISTRATOR],
	canActivate: [ADMINISTRATOR, REDACTOR]
};
export const COLLECTIONS = {
	allowedRoles: [ADMINISTRATOR, REDACTOR, EDITOR],
	canActivate: [ADMINISTRATOR, REDACTOR]
};
export const PACKETS = {
	allowedRoles: [ADMINISTRATOR, REDACTOR],
	canActivate: [ADMINISTRATOR, REDACTOR]
};
export const LIMITS = {
	allowedRoles: [ADMINISTRATOR],
	canActivate: [ADMINISTRATOR, REDACTOR]
};
export const PAYMENTS = {
	allowedRoles: [ADMINISTRATOR, REDACTOR],
	canActivate: [ADMINISTRATOR, REDACTOR]
};
export const ASSIGNED_PACKETS = {
	allowedRoles: [ADMINISTRATOR, REDACTOR],
	canActivate: [ADMINISTRATOR, REDACTOR]
};
export const REPORTS = {
	allowedRoles: [ADMINISTRATOR, REDACTOR],
	canActivate: [ADMINISTRATOR, REDACTOR]
};
export const SUBSCRIBERS = {
	allowedRoles: [ADMINISTRATOR, HELP_DESK],
	canActivate: [ADMINISTRATOR, REDACTOR]
};
export const DEVICES = {
	allowedRoles: [ADMINISTRATOR, HELP_DESK],
	canActivate: [ADMINISTRATOR, REDACTOR]
};
export const CATEGORIES = {
	allowedRoles: [ADMINISTRATOR, REDACTOR],
	canActivate: [ADMINISTRATOR, REDACTOR]
};
export const DOCUMENTS = {
	allowedRoles: [ADMINISTRATOR, HELP_DESK],
	canActivate: [ADMINISTRATOR, REDACTOR]
};
export const AGREEMENTS = {
	allowedRoles: [ADMINISTRATOR, HELP_DESK],
	canActivate: [ADMINISTRATOR, REDACTOR]
};
export const VOD_SERIES = {
	allowedRoles: [ADMINISTRATOR, REDACTOR, EDITOR],
	canActivate: [ADMINISTRATOR, REDACTOR]
};
export const VOD_SEASONS = {
	allowedRoles: [ADMINISTRATOR, REDACTOR, EDITOR],
	canActivate: [ADMINISTRATOR, REDACTOR]
};
export const VOD_EPISODES = {
	allowedRoles: [ADMINISTRATOR, REDACTOR, EDITOR],
	canActivate: [ADMINISTRATOR, REDACTOR]
};
export const VOD = {
	allowedRoles: [ADMINISTRATOR, REDACTOR, EDITOR],
	canActivate: [ADMINISTRATOR, REDACTOR]
};
export const EVENTS_LIVE = {
	allowedRoles: [ADMINISTRATOR, REDACTOR, EDITOR],
	canActivate: [ADMINISTRATOR, REDACTOR]
};
export const SECTION_GROUPS = {
	allowedRoles: [ADMINISTRATOR, REDACTOR],
	canActivate: [ADMINISTRATOR, REDACTOR]
};
export const SECTIONS = {
	allowedRoles: [ADMINISTRATOR, REDACTOR],
	canActivate: [ADMINISTRATOR, REDACTOR]
};
export const BANNERS = {
	allowedRoles: [ADMINISTRATOR, REDACTOR],
	canActivate: [ADMINISTRATOR, REDACTOR]
};
export const MASS_CHANGES = {
	allowedRoles: [ADMINISTRATOR, REDACTOR, EDITOR],
	canActivate: [ADMINISTRATOR, REDACTOR]
};
export const INGESTER_ASSETS = {
	allowedRoles: [ADMINISTRATOR, REDACTOR, EDITOR],
	canActivate: [ADMINISTRATOR, REDACTOR]
};
export const INGESTER_ENCODING = {
	allowedRoles: [ADMINISTRATOR, REDACTOR, EDITOR],
	canActivate: [ADMINISTRATOR, REDACTOR]
};
export const VIDEO_ASSETS_PARAMS = {
	allowedRoles: [ADMINISTRATOR],
	canActivate: [ADMINISTRATOR, REDACTOR]
};
export const OPERATORS = {
	allowedRoles: [ADMINISTRATOR],
	canActivate: [ADMINISTRATOR, REDACTOR]
};
export const GEOLOCK = {
	allowedRoles: [ADMINISTRATOR, REDACTOR, EDITOR],
	canActivate: [ADMINISTRATOR, REDACTOR]
};
export const TRACKINGS = {
	allowedRoles: [ADMINISTRATOR, REDACTOR],
	canActivate: [ADMINISTRATOR, REDACTOR]
};
export const SOFTWARE = {
	allowedRoles: [ADMINISTRATOR],
	canActivate: [ADMINISTRATOR, REDACTOR]
};
export const SETTINGS = {
	allowedRoles: [ADMINISTRATOR],
	canActivate: [ADMINISTRATOR, REDACTOR]
};
export const NOTIFICATIONS = {
	allowedRoles: [ADMINISTRATOR],
	canActivate: [ADMINISTRATOR, REDACTOR]
};
