import React from 'react';

// Import Authorization
import PermissionsAuthorization from 'components/HOC/PermissionsAuthorization';

// Import permissions
import * as permissions from 'components/routes/helpers/permissions';

// ***************** Products *****************

// --- Promotions ---
import Promotions from 'components/views/promotions/Promotions';
import PromotionsTabs from 'components/views/promotions/promotions_tabs/PromotionsTabs';

// --- Channels ---
import Channels from 'components/views/channels/Channels';
import ChannelsTabs from 'components/views/channels/channels_tabs/ChannelsTabs';
import EPGTabs from 'components/views/channels/channels_tabs/epg/epg_tabs/EPGTabs';

// --- Provisioning ---
import Provisioning from 'components/views/provisioning/Provisioning';
import ProvisioningTabs from 'components/views/provisioning/provisioning_tabs/ProvisioningTabs';

// --- Packets ---
import Packets from 'components/views/packets/Packets';
import PacketsTabs from 'components/views/packets/packets_tabs/PacketsTabs';

// --- Main ---
import Main from 'components/views/main/Main';
import MainTabs from 'components/views/main/main_tabs/MainTabs';

// --- Availability ---
import AvailabilityFormTemplate from 'components/common/templates/availability_form_template/AvailabilityFormTemplate';

// --- Visibility ---
import VisibilityFormTemplate from 'components/common/templates/visibility_form_template/VisibilityFormTemplate';

// --- Price ---
import PriceFormTemplate from 'components/common/templates/price_form_template/PriceFormTemplate';

// --- Limits ---
import Limits from 'components/views/limits/Limits';
import LimitsTabs from 'components/views/limits/limits_tabs/LimitsTabs';

// --- Payments ---
import Payments from 'components/views/payments/Payments';

// --- Assigned packets ---
import AssignedPackets from 'components/views/assigned_packets/AssignedPackets';

// --- Reports ---
import Reports from 'components/views/reports/Reports';

// ***************** Users *****************
// --- Subscribers ---
import Subscribers from 'components/views/subscribers/Subscribers';
import SubscribersTabs from 'components/views/subscribers/subscribers_tabs/SubscribersTabs';
// --- Devices ---
import Devices from 'components/views/devices/Devices';

// ***************** CONTENT *****************
// --- Categories ---
import Categories from 'components/views/categories/Categories';
import CategoriesTabs from 'components/views/categories/categories_tabs/CategoriesTabs';
// --- Documents ---
import Documents from 'components/views/documents/Documents';
import DocumentsTabs from 'components/views/documents/documents_tabs/DocumentsTabs';
// --- Agreements ---
import Agreements from 'components/views/agreements/Agreements';
import AgreementsTabs from 'components/views/agreements/agreements_tabs/AgreementsTabs';
import AgreementsVersionsTabs from 'components/views/agreements/agreements_versions/agreements_versions_tabs/AgreementsVersionsTabs';
// --- VOD ---
import Vod from 'components/views/vod/Vod';
import VodTabs from 'components/views/vod/vod_tabs/VodTabs';
import VodSubtitlesForm from 'components/views/vod/vod_subtitles/vod_subtitles_form/VodSubtitlesForm';
import VodSegmentsForm from 'components/views/vod/vod_segments/vod_segments_form/VodSegmentsForm';
// --- VOD Series---
import SeriesTabs from 'components/views/vod/vod_series/series_tabs/SeriesTabs';
// --- VOD Seasons---
import SeasonsTabs from 'components/views/vod/vod_seasons/seasons_tabs/SeasonsTabs';
// --- VOD Episodes---
import EpisodesTabs from 'components/views/vod/vod_episodes/episodes_tabs/EpisodesTabs';
// ***************** Events live *****************
import EventsLive from 'components/views/events_live/EventsLive';
import EventsLiveTabs from 'components/views/events_live/events_live_tabs/EventsLiveTabs';
// --- Sections groups ---
import SectionsGroups from 'components/views/sections_groups/SectionsGroups';
import SectionsGroupsTabs from 'components/views/sections_groups/sections_groups_tabs/SectionsGroupsTabs';
// --- Section ---
import Sections from 'components/views/sections/Sections';
import SectionsTabs from 'components/views/sections/sections_tabs/SectionsTabs';
// --- Banners ---
import Banners from 'components/views/banners/Banners';
import BannersTabs from 'components/views/banners/banners_tabs/BannersTabs';
// --- Mass changes ---
import MassChanges from 'components/views/mass_changes/MassChanges';

// ***************** Administration *****************
// --- Operators ---
import Operators from 'components/views/operators/Operators';
import OperatorsTabs from 'components/views/operators/operators_tabs/OperatorsTabs';
// --- Geoblock Groups  ---
import GeoblockGroups from 'components/views/geoblock_groups/GeoblockGroups';
import GeoblockGroupsTabs from 'components/views/geoblock_groups/geoblock_groups_tabs/GeoblockGroupsTabs';
// --- Trackings ---
import Trackings from 'components/views/trackings/Trackings';
// --- Software ---
import SoftwareForm from 'components/views/software/software_form/SoftwareForm';
import Software from 'components/views/software/Software';
// --- Notifications ---
import NotificationsForm from 'components/views/notifications/notifications_form/NotificationsForm';

// ***************** Settings *****************
import SettingsTabs from 'components/views/settings/settings_tabs/SettingsTabs';

const ROUTES_PATHS = [
	// ------ Availability ------
	{
		path: '/panel/:type/:id/availability/create',
		component: (routerProps) => (
			<PermissionsAuthorization
				{...permissions.AVAILABILITY}
				component={AvailabilityFormTemplate}
				routerProps={routerProps}
			/>
		),
		permissions: permissions.AVAILABILITY
	},
	{
		path: '/panel/:type/:productID/availability/edit/:id',
		component: (routerProps) => (
			<PermissionsAuthorization
				{...permissions.AVAILABILITY}
				component={AvailabilityFormTemplate}
				routerProps={routerProps}
			/>
		),
		permissions: permissions.AVAILABILITY
	},
	// ------ Visibility ------
	{
		path: '/panel/:type/:id/visibility/create',
		component: (routerProps) => (
			<PermissionsAuthorization
				{...permissions.VISIBILITY}
				component={VisibilityFormTemplate}
				routerProps={routerProps}
			/>
		),
		permissions: permissions.VISIBILITY
	},
	{
		path: '/panel/:type/:productID/visibility/edit/:id',
		component: (routerProps) => (
			<PermissionsAuthorization
				{...permissions.VISIBILITY}
				component={VisibilityFormTemplate}
				routerProps={routerProps}
			/>
		),
		permissions: permissions.VISIBILITY
	},

	// ------ Prices ------
	{
		path: '/panel/:type/:id/price/create',
		component: (routerProps) => (
			<PermissionsAuthorization
				{...permissions.PRICES}
				component={PriceFormTemplate}
				routerProps={routerProps}
			/>
		),
		permissions: permissions.PRICES
	},

	// ***************** Products *****************
	// ------ Promotions ------
	{
		path: '/panel/promotions/edit/:id',
		component: (routerProps) => (
			<PermissionsAuthorization
				{...permissions.PROMOTIONS}
				component={PromotionsTabs}
				routerProps={routerProps}
			/>
		),
		permissions: permissions.PROMOTIONS
	},
	{
		path: '/panel/promotions/create',
		component: (routerProps) => (
			<PermissionsAuthorization
				{...permissions.PROMOTIONS}
				component={PromotionsTabs}
				routerProps={routerProps}
			/>
		),
		permissions: permissions.PROMOTIONS
	},
	{
		path: '/panel/promotions',
		component: (routerProps) => (
			<PermissionsAuthorization
				{...permissions.PROMOTIONS}
				component={Promotions}
				routerProps={routerProps}
			/>
		),
		permissions: permissions.PROMOTIONS
	},
	// ------ Channels ------
	{
		path: '/panel/channels/:channelId/epg/edit/:id',
		component: (routerProps) => (
			<PermissionsAuthorization
				{...permissions.CHANNELS}
				component={EPGTabs}
				routerProps={routerProps}
			/>
		),
		permissions: permissions.CHANNELS
	},
	{
		path: '/panel/channels/edit/:id',
		component: (routerProps) => (
			<PermissionsAuthorization
				{...permissions.CHANNELS}
				component={ChannelsTabs}
				routerProps={routerProps}
			/>
		),
		permissions: permissions.CHANNELS
	},
	{
		path: '/panel/channels/create',
		component: (routerProps) => (
			<PermissionsAuthorization
				{...permissions.CHANNELS}
				component={ChannelsTabs}
				routerProps={routerProps}
			/>
		),
		permissions: permissions.CHANNELS
	},
	{
		path: '/panel/channels',
		component: (routerProps) => (
			<PermissionsAuthorization
				{...permissions.CHANNELS}
				component={Channels}
				routerProps={routerProps}
			/>
		),
		permissions: permissions.CHANNELS
	},

	// ------ Provisioning ------
	{
		path: '/panel/provisioning/edit/:id',
		component: (routerProps) => (
			<PermissionsAuthorization
				{...permissions.PROVISIONING}
				component={ProvisioningTabs}
				routerProps={routerProps}
			/>
		),
		permissions: permissions.PROVISIONING
	},
	{
		path: '/panel/provisioning/create',
		component: (routerProps) => (
			<PermissionsAuthorization
				{...permissions.PROVISIONING}
				component={ProvisioningTabs}
				routerProps={routerProps}
			/>
		),
		permissions: permissions.PROVISIONING
	},
	{
		path: '/panel/provisioning',
		component: (routerProps) => (
			<PermissionsAuthorization
				{...permissions.PROVISIONING}
				component={Provisioning}
				routerProps={routerProps}
			/>
		),
		permissions: permissions.PROVISIONING
	},

	// ------ Collections (old packets) ------
	{
		path: '/panel/packets/edit/:id',
		component: (routerProps) => (
			<PermissionsAuthorization
				{...permissions.COLLECTIONS}
				component={PacketsTabs}
				routerProps={routerProps}
			/>
		),
		permissions: permissions.COLLECTIONS
	},
	{
		path: '/panel/packets/create',
		component: (routerProps) => (
			<PermissionsAuthorization
				{...permissions.COLLECTIONS}
				component={PacketsTabs}
				routerProps={routerProps}
			/>
		),
		permissions: permissions.COLLECTIONS
	},
	{
		path: '/panel/packets',
		component: (routerProps) => (
			<PermissionsAuthorization
				{...permissions.COLLECTIONS}
				component={Packets}
				routerProps={routerProps}
			/>
		),
		permissions: permissions.COLLECTIONS
	},

	// ------ Packets (old Main) ------
	{
		path: '/panel/main/edit/:id',
		component: (routerProps) => (
			<PermissionsAuthorization
				{...permissions.PACKETS}
				component={MainTabs}
				routerProps={routerProps}
			/>
		),
		permissions: permissions.PACKETS
	},
	{
		path: '/panel/main/create',
		component: (routerProps) => (
			<PermissionsAuthorization
				{...permissions.PACKETS}
				component={MainTabs}
				routerProps={routerProps}
			/>
		),
		permissions: permissions.PACKETS
	},
	{
		path: '/panel/main',
		component: (routerProps) => (
			<PermissionsAuthorization
				{...permissions.PACKETS}
				component={Main}
				routerProps={routerProps}
			/>
		),
		permissions: permissions.PACKETS
	},

	// ------ Limits ------
	{
		path: '/panel/limits/edit/:id',
		component: (routerProps) => (
			<PermissionsAuthorization
				{...permissions.LIMITS}
				component={LimitsTabs}
				routerProps={routerProps}
			/>
		),
		permissions: permissions.LIMITS
	},
	{
		path: '/panel/limits/create',
		component: (routerProps) => (
			<PermissionsAuthorization
				{...permissions.LIMITS}
				component={LimitsTabs}
				routerProps={routerProps}
			/>
		),
		permissions: permissions.LIMITS
	},
	{
		path: '/panel/limits',
		component: (routerProps) => (
			<PermissionsAuthorization
				{...permissions.LIMITS}
				component={Limits}
				routerProps={routerProps}
			/>
		),
		permissions: permissions.LIMITS
	},

	// ------ Payments ------
	{
		path: '/panel/payments',
		component: (routerProps) => (
			<PermissionsAuthorization
				{...permissions.PAYMENTS}
				component={Payments}
				routerProps={routerProps}
			/>
		),
		permissions: permissions.PAYMENTS
	},

	// ------ Assigned packets ------
	{
		path: '/panel/assigned_packets',
		component: (routerProps) => (
			<PermissionsAuthorization
				{...permissions.ASSIGNED_PACKETS}
				component={AssignedPackets}
				routerProps={routerProps}
			/>
		),
		permissions: permissions.ASSIGNED_PACKETS
	},

	// ------ Data Studio Reports ------
	{
		path: '/panel/reports',
		component: (routerProps) => (
			<PermissionsAuthorization
				{...permissions.REPORTS}
				component={Reports}
				routerProps={routerProps}
			/>
		),
		permissions: permissions.REPORTS
	},

	// ***************** Users *****************
	// ------ Subscribers ------
	{
		path: '/panel/subscribers/edit/:id',
		component: (routerProps) => (
			<PermissionsAuthorization
				{...permissions.SUBSCRIBERS}
				component={SubscribersTabs}
				routerProps={routerProps}
			/>
		),
		permissions: permissions.SUBSCRIBERS
	},
	{
		path: '/panel/subscribers',
		component: (routerProps) => (
			<PermissionsAuthorization
				{...permissions.SUBSCRIBERS}
				component={Subscribers}
				routerProps={routerProps}
			/>
		),
		permissions: permissions.SUBSCRIBERS
	},
	{
		path: '/panel/devices',
		component: (routerProps) => (
			<PermissionsAuthorization
				{...permissions.DEVICES}
				component={Devices}
				routerProps={routerProps}
			/>
		),
		permissions: permissions.DEVICES
	},

	// ***************** CONTENT *****************
	// ------ Categories -----
	{
		path: '/panel/categories/edit/:id',
		component: (routerProps) => (
			<PermissionsAuthorization
				{...permissions.CATEGORIES}
				component={CategoriesTabs}
				routerProps={routerProps}
			/>
		),
		permissions: permissions.CATEGORIES
	},
	{
		path: '/panel/categories/:type/create',
		component: (routerProps) => (
			<PermissionsAuthorization
				{...permissions.CATEGORIES}
				component={CategoriesTabs}
				routerProps={routerProps}
			/>
		),
		permissions: permissions.CATEGORIES
	},
	{
		path: '/panel/categories',
		component: (routerProps) => (
			<PermissionsAuthorization
				{...permissions.CATEGORIES}
				component={Categories}
				routerProps={routerProps}
			/>
		),
		permissions: permissions.CATEGORIES
	},
	// ------ Documents ------
	{
		path: '/panel/documents/edit/:id',
		component: (routerProps) => (
			<PermissionsAuthorization
				{...permissions.DOCUMENTS}
				component={DocumentsTabs}
				routerProps={routerProps}
			/>
		),
		permissions: permissions.DOCUMENTS
	},
	{
		path: '/panel/documents/create',
		component: (routerProps) => (
			<PermissionsAuthorization
				{...permissions.DOCUMENTS}
				component={DocumentsTabs}
				routerProps={routerProps}
			/>
		),
		permissions: permissions.DOCUMENTS
	},
	{
		path: '/panel/documents',
		component: (routerProps) => (
			<PermissionsAuthorization
				{...permissions.DOCUMENTS}
				component={Documents}
				routerProps={routerProps}
			/>
		),
		permissions: permissions.DOCUMENTS
	},
	// ------ Agreements ------
	{
		path: '/panel/agreements/:agreementId/versions/edit/:id',
		component: (routerProps) => (
			<PermissionsAuthorization
				{...permissions.AGREEMENTS}
				component={AgreementsVersionsTabs}
				routerProps={routerProps}
			/>
		),
		permissions: permissions.AGREEMENTS
	},
	{
		path: '/panel/agreements/:agreementId/versions/create',
		component: (routerProps) => (
			<PermissionsAuthorization
				{...permissions.AGREEMENTS}
				component={AgreementsVersionsTabs}
				routerProps={routerProps}
			/>
		),
		permissions: permissions.AGREEMENTS
	},
	{
		path: '/panel/agreements/edit/:id',
		component: (routerProps) => (
			<PermissionsAuthorization
				{...permissions.AGREEMENTS}
				component={AgreementsTabs}
				routerProps={routerProps}
			/>
		),
		permissions: permissions.AGREEMENTS
	},
	{
		path: '/panel/agreements/create',
		component: (routerProps) => (
			<PermissionsAuthorization
				{...permissions.AGREEMENTS}
				component={AgreementsTabs}
				routerProps={routerProps}
			/>
		),
		permissions: permissions.AGREEMENTS
	},
	{
		path: '/panel/agreements',
		component: (routerProps) => (
			<PermissionsAuthorization
				{...permissions.AGREEMENTS}
				component={Agreements}
				routerProps={routerProps}
			/>
		),
		permissions: permissions.AGREEMENTS
	},
	// ------ VOD Series------
	{
		path: '/panel/vod/series/edit/:id',
		component: (routerProps) => (
			<PermissionsAuthorization
				{...permissions.VOD_SERIES}
				component={SeriesTabs}
				routerProps={routerProps}
			/>
		),
		permissions: permissions.VOD_SERIES
	},
	{
		path: '/panel/vod/series/create',
		component: (routerProps) => (
			<PermissionsAuthorization
				{...permissions.VOD_SERIES}
				component={SeriesTabs}
				routerProps={routerProps}
			/>
		),
		permissions: permissions.VOD_SERIES
	},
	// ------ VOD Seasons------
	{
		path: '/panel/vod/season/edit/:id',
		component: (routerProps) => (
			<PermissionsAuthorization
				{...permissions.VOD_SEASONS}
				component={SeasonsTabs}
				routerProps={routerProps}
			/>
		),
		permissions: permissions.VOD_SEASONS
	},
	{
		path: '/panel/vod/season/create',
		component: (routerProps) => (
			<PermissionsAuthorization
				{...permissions.VOD_SEASONS}
				component={SeasonsTabs}
				routerProps={routerProps}
			/>
		),
		permissions: permissions.VOD_SEASONS
	},
	// ------ VOD Episodes------
	{
		path: '/panel/vod/episode/edit/:id',
		component: (routerProps) => (
			<PermissionsAuthorization
				{...permissions.VOD_EPISODES}
				component={EpisodesTabs}
				routerProps={routerProps}
			/>
		),
		permissions: permissions.VOD_EPISODES
	},
	// ------ VOD ------
	{
		path: '/panel/vod/:vodId/materials/:id/subtitles/create',
		component: (routerProps) => (
			<PermissionsAuthorization
				{...permissions.VOD}
				component={VodSubtitlesForm}
				routerProps={routerProps}
			/>
		),
		permissions: permissions.VOD
	},
	{
		path: '/panel/vod/:vodId/materials/:id/segment/create',
		component: (routerProps) => (
			<PermissionsAuthorization
				{...permissions.VOD}
				component={VodSegmentsForm}
				routerProps={routerProps}
			/>
		),
		permissions: permissions.VOD
	},
	{
		path: '/panel/vod/:vodId/materials/:id/segment/:segmentId/edit',
		component: (routerProps) => (
			<PermissionsAuthorization
				{...permissions.VOD}
				component={VodSegmentsForm}
				routerProps={routerProps}
			/>
		),
		permissions: permissions.VOD
	},
	{
		path: '/panel/vod/vod/edit/:id',
		component: (routerProps) => (
			<PermissionsAuthorization
				{...permissions.VOD}
				component={VodTabs}
				routerProps={routerProps}
			/>
		),
		permissions: permissions.VOD
	},
	{
		path: '/panel/vod',
		component: (routerProps) => (
			<PermissionsAuthorization
				{...permissions.VOD}
				component={Vod}
				routerProps={routerProps}
			/>
		),
		permissions: permissions.VOD
	},
	// ------- Events live --------
	{
		path: '/panel/events_live/edit/:id',
		component: (routerProps) => (
			<PermissionsAuthorization
				{...permissions.EVENTS_LIVE}
				component={EventsLiveTabs}
				routerProps={routerProps}
			/>
		),
		permissions: permissions.EVENTS_LIVE
	},
	{
		path: '/panel/events_live/create',
		component: (routerProps) => (
			<PermissionsAuthorization
				{...permissions.EVENTS_LIVE}
				component={EventsLiveTabs}
				routerProps={routerProps}
			/>
		),
		permissions: permissions.EVENTS_LIVE
	},
	{
		path: '/panel/events_live',
		component: (routerProps) => (
			<PermissionsAuthorization
				{...permissions.EVENTS_LIVE}
				component={EventsLive}
				routerProps={routerProps}
			/>
		),
		permissions: permissions.EVENTS_LIVE
	},

	// ------ Section groups ------
	{
		path: '/panel/sections_groups/edit/:id',
		component: (routerProps) => (
			<PermissionsAuthorization
				{...permissions.SECTION_GROUPS}
				component={SectionsGroupsTabs}
				routerProps={routerProps}
			/>
		),
		permissions: permissions.SECTION_GROUPS
	},
	{
		path: '/panel/sections_groups/create',
		component: (routerProps) => (
			<PermissionsAuthorization
				{...permissions.SECTION_GROUPS}
				component={SectionsGroupsTabs}
				routerProps={routerProps}
			/>
		),
		permissions: permissions.SECTION_GROUPS
	},
	{
		path: '/panel/sections_groups',
		component: (routerProps) => (
			<PermissionsAuthorization
				{...permissions.SECTION_GROUPS}
				component={SectionsGroups}
				routerProps={routerProps}
			/>
		),
		permissions: permissions.SECTION_GROUPS
	},
	// ------ Sections ------
	{
		path: '/panel/sections/edit/:id',
		component: (routerProps) => (
			<PermissionsAuthorization
				{...permissions.SECTIONS}
				component={SectionsTabs}
				routerProps={routerProps}
			/>
		),
		permissions: permissions.SECTIONS
	},
	{
		path: '/panel/sections/create',
		component: (routerProps) => (
			<PermissionsAuthorization
				{...permissions.SECTIONS}
				component={SectionsTabs}
				routerProps={routerProps}
			/>
		),
		permissions: permissions.SECTIONS
	},
	{
		path: '/panel/sections',
		component: (routerProps) => (
			<PermissionsAuthorization
				{...permissions.SECTIONS}
				component={Sections}
				routerProps={routerProps}
			/>
		),
		permissions: permissions.SECTIONS
	},
	// ------ Banners ------
	{
		path: '/panel/banners/edit/:id',
		component: (routerProps) => (
			<PermissionsAuthorization
				{...permissions.BANNERS}
				component={BannersTabs}
				routerProps={routerProps}
			/>
		),
		permissions: permissions.BANNERS
	},
	{
		path: '/panel/banners/create',
		component: (routerProps) => (
			<PermissionsAuthorization
				{...permissions.BANNERS}
				component={BannersTabs}
				routerProps={routerProps}
			/>
		),
		permissions: permissions.BANNERS
	},
	{
		path: '/panel/banners',
		component: (routerProps) => (
			<PermissionsAuthorization
				{...permissions.BANNERS}
				component={Banners}
				routerProps={routerProps}
			/>
		),
		permissions: permissions.BANNERS
	},
	// ------ Mass changes ------
	{
		path: '/panel/mass_changes',
		component: (routerProps) => (
			<PermissionsAuthorization
				{...permissions.MASS_CHANGES}
				component={MassChanges}
				routerProps={routerProps}
			/>
		),
		permissions: permissions.MASS_CHANGES
	},
	// ***************** Administration *****************
	// ------ Operators ------
	{
		path: '/panel/operators/edit/:id',
		component: (routerProps) => (
			<PermissionsAuthorization
				{...permissions.OPERATORS}
				component={OperatorsTabs}
				routerProps={routerProps}
			/>
		),
		permissions: permissions.OPERATORS
	},
	{
		path: '/panel/operators/create',
		component: (routerProps) => (
			<PermissionsAuthorization
				{...permissions.OPERATORS}
				component={OperatorsTabs}
				routerProps={routerProps}
			/>
		),
		permissions: permissions.OPERATORS
	},
	{
		path: '/panel/operators',
		component: (routerProps) => (
			<PermissionsAuthorization
				{...permissions.OPERATORS}
				component={Operators}
				routerProps={routerProps}
			/>
		),
		permissions: permissions.OPERATORS
	},
	// ------ GeoblockGroups ------
	{
		path: '/panel/geoblock_groups/edit/:id',
		component: (routerProps) => (
			<PermissionsAuthorization
				{...permissions.GEOLOCK}
				component={GeoblockGroupsTabs}
				routerProps={routerProps}
			/>
		),
		permissions: permissions.GEOLOCK
	},
	{
		path: '/panel/geoblock_groups/create',
		component: (routerProps) => (
			<PermissionsAuthorization
				{...permissions.GEOLOCK}
				component={GeoblockGroupsTabs}
				routerProps={routerProps}
			/>
		),
		permissions: permissions.GEOLOCK
	},
	{
		path: '/panel/geoblock_groups',
		component: (routerProps) => (
			<PermissionsAuthorization
				{...permissions.GEOLOCK}
				component={GeoblockGroups}
				routerProps={routerProps}
			/>
		),
		permissions: permissions.GEOLOCK
	},
	// ------ Trackings ------
	{
		path: '/panel/trackings',
		component: (routerProps) => (
			<PermissionsAuthorization
				{...permissions.TRACKINGS}
				component={Trackings}
				routerProps={routerProps}
			/>
		),
		permissions: permissions.TRACKINGS
	},
	// ------ Software ------
	{
		path: '/panel/software/edit/:id',
		component: (routerProps) => (
			<PermissionsAuthorization
				{...permissions.SOFTWARE}
				component={SoftwareForm}
				routerProps={routerProps}
			/>
		),
		permissions: permissions.SOFTWARE
	},
	{
		path: '/panel/software/create',
		component: (routerProps) => (
			<PermissionsAuthorization
				{...permissions.SOFTWARE}
				component={SoftwareForm}
				routerProps={routerProps}
			/>
		),
		permissions: permissions.SOFTWARE
	},
	{
		path: '/panel/software',
		component: (routerProps) => (
			<PermissionsAuthorization
				{...permissions.SOFTWARE}
				component={Software}
				routerProps={routerProps}
			/>
		),
		permissions: permissions.SOFTWARE
	},

	// ***************** Settings *****************
	{
		path: '/panel/settings',
		component: (routerProps) => (
			<PermissionsAuthorization
				{...permissions.SETTINGS}
				component={SettingsTabs}
				routerProps={routerProps}
			/>
		),
		permissions: permissions.SETTINGS
	},
	// ***************** Notifications *****************
	{
		path: '/panel/notifications/create',
		component: (routerProps) => (
			<PermissionsAuthorization
				{...permissions.NOTIFICATIONS}
				component={NotificationsForm}
				routerProps={routerProps}
			/>
		),
		permissions: permissions.NOTIFICATIONS
	}
];

export default ROUTES_PATHS;
