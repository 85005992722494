import { useDispatch } from 'react-redux';
import {
	clearUploadedMaterialsTable,
	deleteUploadedAsset
} from 'store/actions';

// Import components
import { Modal } from 'antd';
import message from 'antd/es/message';

// Import translation
import i18n from 'i18next';

const useDeleteAssetButton = (record) => {
	const dispatch = useDispatch();

	const handleClearTable = () => {
		clearUploadedMaterialsTable()(dispatch);
	};

	const handleDeleteAsset = async (id) => {
		await deleteUploadedAsset(id, handleClearTable);
	};

	const confirmModal = () =>
		Modal.confirm({
			title: i18n.t(`common:delete`),
			content: `${i18n.t(`messages:modal.delete_confirm_message`)} ${i18n.t(
				`names:notification_names.asset`
			)}`,
			okText: i18n.t('common:buttons.ok'),
			cancelText: i18n.t('common:buttons.cancel'),
			onOk: () => handleDeleteAsset(record.id),
			onCancel() {
				message.info(i18n.t('messages:messages.asset_delete_canceled'));
			}
		});

	return { confirmModal };
};

export default useDeleteAssetButton;
