import { combineReducers } from 'redux';

// Import reducers
import table from './geoblock_groups_panel_reducer';
import form from './geoblock_groups_form_reducer';

export default combineReducers({
	table,
	form
});
