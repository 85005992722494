import React, { useEffect } from 'react';
import PropTypes from 'prop-types';
import { Form } from 'react-final-form';
import { compose } from 'redux';
import { connect, useDispatch } from 'react-redux';
import { withRouter } from 'react-router-dom';
import moment from 'moment';
import { useTranslation } from 'react-i18next';

// Import convert function
import { convertPriceToCents } from 'components/helpers/convert';

// Import helpers
import { convertProductType } from '../helpers';

// Import utilities
import { submitForm } from 'components/utilities/form';
import validate from './validation';

// Import components
import { FormTemplate } from 'components/common/templates';
import { FormButtons } from 'components/common/buttons';
import Fields from './fields/Fields';

// Import hooks
import usePriceDefaultValues from './usePriceDefaultValues';

// Import redux actions
import { dictionaryPeriodUnit, dictionaryGeoblockGroups } from 'store/actions';

// Import variables
import { PAYMENT_TYPES } from 'components/helpers/variables';

const { SUBSCRIPTION_APPSTORE } = PAYMENT_TYPES;

const PriceFormTemplate = ({
	history,
	match: {
		params: { type, id: itemID }
	},
	prevPath,
	geoblockGroups,
	periodUnits
}) => {
	const { t } = useTranslation();
	const dispatch = useDispatch();

	const productType = convertProductType(type);
	const isPacket = productType === 'packet';
	const dateFormat = 'YYYY-MM-DD HH:mm:ss';

	// prepare initial values for form
	const { initialDates, isLoading } = usePriceDefaultValues({
		itemID,
		productType
	});
	const initialValues = {
		paymentMethod: SUBSCRIPTION_APPSTORE,
		period: '1',
		periodUnit: 'hour'
	};
	if (initialDates) initialValues.range = initialDates;

	// Remove "/panel/" from prevPath;
	const redirectPath = prevPath.slice(7);

	const handleOnSubmit = ({ isEdit, itemID }) => async (values) => {
		const { price, period, paymentMethod, range, ...rest } = values;
		const [since, till] = range;

		const sinceFormated = moment(since).format(dateFormat);
		const tillFormated = moment(till).format(dateFormat);

		const resources = {
			since: sinceFormated,
			till: tillFormated,
			price: convertPriceToCents(price),
			period: parseInt(period, 10),
			paymentMethod,
			productUuid: itemID,
			productType,
			...rest
		};

		// Submit the form with field values
		return await submitForm({
			history,
			isEdit,
			itemID,
			resources,
			api: 'timetables/prices',
			redirectPath,
			redirectToPath: true
		});
	};

	useEffect(() => {
		dictionaryPeriodUnit()(dispatch);
		isPacket && dictionaryGeoblockGroups()(dispatch);
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, []);

	return (
		<FormTemplate isLoaded={!isLoading}>
			{({ isEdit, itemID }) => (
				<Form
					initialValues={initialValues}
					validate={validate}
					onSubmit={handleOnSubmit({ isEdit, itemID })}
					render={({ handleSubmit, submitting }) => (
						<form onSubmit={handleSubmit}>
							<Fields
								submitting={submitting}
								periodUnits={periodUnits}
								geoblockGroups={geoblockGroups}
								isPacket={isPacket}
							/>

							<FormButtons
								isButtonDisabled={submitting}
								path={redirectPath}
								buttonText={t('common:buttons.submit')}
							/>
						</form>
					)}
				/>
			)}
		</FormTemplate>
	);
};

PriceFormTemplate.propTypes = {
	history: PropTypes.object.isRequired,
	match: PropTypes.shape({
		params: PropTypes.shape({ type: PropTypes.string.isRequired })
	}),
	prevPath: PropTypes.string.isRequired
};

const mapStateToProps = ({
	previous_location: { pathname },
	dictionary: { geoblockGroups, period_unit }
}) => ({
	prevPath: pathname,
	geoblockGroups,
	periodUnits: period_unit
});

export default compose(connect(mapStateToProps), withRouter)(PriceFormTemplate);
