import services from 'services/services';
import * as types from '../../types';

// ******************** FETCH MAIN_ITEM COVER FILES TO EDIT ********************
export const fetchMainItemCover = (id, collection) => async (dispatch) => {
	try {
		// Dispatch a loading action
		dispatch({
			type: types.FETCH_MAIN_ITEM_COVER_FILES_LOADING,
			payload: { collection }
		});

		const response = await services.get(
			`/assets/${id}/list?model_type=packet&collection=${collection}`
		);

		// Dispatch an action with data
		dispatch({
			type: types.FETCH_MAIN_ITEM_COVER_FILES_SUCCESS,
			payload: { data: response.data, collection }
		});
	} catch (error) {
		dispatch({
			type: types.FETCH_MAIN_ITEM_COVER_FILES_ERROR,
			payload: { collection }
		});
	}
};
