import React from 'react';
import PropTypes from 'prop-types';

// Import hooks
import useAssetStatus from './useAssetStatus';

const AssetStatus = ({ record }) => {
	const { status } = useAssetStatus(record, 'statusComponent');

	return <div>{status}</div>;
};

AssetStatus.propTypes = {
	record: PropTypes.object
};

export default AssetStatus;
