import * as types from 'store/actions/types';
import produce from 'immer';

export const INITIAL_STATE = {
	main: {
		data: []
	},
	preview: {
		data: []
	},
	isLoaded: false,
	error: false
};

export default (state = INITIAL_STATE, action) =>
	produce(state, (draft) => {
		switch (action.type) {
			// ****************** FETCH EVENT_LIVE'S MATERIALS ******************
			case types.FETCH_EVENT_LIVE_MATERIALS_LOADING:
				draft.main.data = [];
				draft.isLoaded = false;
				draft.error = false;
				return;

			case types.FETCH_EVENT_LIVE_MATERIALS_SUCCESS:
				draft.main.data = action.payload.filter(({ type }) => type === 'main');

				draft.preview.data = action.payload.filter(
					({ type }) => type === 'preview'
				);
				draft.isLoaded = true;
				return;

			case types.FETCH_EVENT_LIVE_MATERIALS_ERROR:
				draft.isLoaded = true;
				draft.error = true;
				return;

			// ****************** UPDATE / DELETE EVENT_LIVE'S MATERIALS ******************
			case types.UPDATE_EVENT_LIVE_MATERIALS:
				draft.error = false;
				// gets type data and productId
				const { data, type, productId } = action.payload;
				const assetId = data.id;
				const assets = draft[type].data.filter(({ id }) => id === productId)[0]
					.assets;

				// select asset which data will be modified
				const selectAsset = assets.find((asset) => asset.id === assetId);

				// if there is an specified asset in product asset then modify its data
				// else add new asset to product
				if (selectAsset) {
					selectAsset.src = data.src;
					selectAsset.drm_key = data.drm_key;
					selectAsset.format = data.format;
					selectAsset.params = data.params;
					selectAsset.platform = data.platform;
				} else {
					assets.push(data);
				}

				return;

			case types.UPDATE_EVENT_LIVE_MATERIALS_ERROR:
				draft.error = true;
				return;

			case types.DELETE_EVENT_LIVE_MATERIALS:
				draft.error = false;
				draft[action.payload.type].data.map(
					(product) =>
						(product.assets = product.assets.filter(
							(asset) => asset.id !== action.payload.id
						))
				);
				return;

			case types.DELETE_EVENT_LIVE_MATERIALS_ERROR:
				draft.error = true;
				return;

			// ******************** ADD VIDEO TO EVENT_LIVE ********************
			case types.ADD_VIDEO_TO_EVENT_LIVE_LOADING:
				draft.isLoaded = false;
				draft.error = false;
				return;

			case types.ADD_VIDEO_TO_EVENT_LIVE_SUCCESS:
				draft.main.data.push(action.payload.data);
				draft.isLoaded = true;
				return;

			case types.ADD_VIDEO_TO_EVENT_LIVE_ERROR:
				draft.main.isLoaded = true;
				draft.error = true;
				return;

			default:
				return state;
		}
	});
