import { useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useParams } from 'react-router-dom';
import { getUploadedMaterials, transcodeAsset } from 'store/actions';
import _ from 'lodash';

const useUploadMaterialsTable = () => {
	const { id } = useParams();

	// REDUX
	const dispatch = useDispatch();
	const { data, error, status } = useSelector(
		({ vod }) => vod.upload_materials.uploaded_materials_table
	);

	const handleDataChange = () => getUploadedMaterials(id)(dispatch);

	const handleAssetTranscode = async (assetId, videoType) => {
		await transcodeAsset(assetId, videoType)(dispatch);
	};

	const shouldUpdate = _.isEmpty(data);
	useEffect(() => {
		shouldUpdate && getUploadedMaterials(id)(dispatch);
		// eslint-disable-next-line
	}, [status]);

	return {
		tableResources: data,
		tableError: error,
		tableStatus: status,
		dataChange: handleDataChange,
		transcodeAsset: handleAssetTranscode
	};
};

export default useUploadMaterialsTable;
