import React from 'react';
import PropTypes from 'prop-types';
import Spin from 'antd/es/spin';

const Loader = ({ children, isLoaded }) => (
	<div className="loading">
		<Spin size="large" className="loading__circle" spinning={!isLoaded}>
			{children}
		</Spin>
	</div>
);

Loader.propTypes = {
	isLoaded: PropTypes.bool.isRequired
};

export default Loader;
