import React from 'react';
import PropTypes from 'prop-types';
import { useParams } from 'react-router-dom';
import { Table } from 'antd';

// Import columns
import { createColumns } from './vod_segments_table/vod_segments_columns';

// Import components
import SegmentsHeader from './vod_segments_table/SegmentsHeader';
import { FetchError } from 'components/common/layout';

// Import helpers
import { locale } from 'components/helpers/table';

// Import custom hook
import useAuthCheck from 'hooks/useAuthCheck';
import useVodSegments from './useVodSegments';

const VodSegments = ({ videoId }) => {
	const { id: vodId } = useParams();

	// check if user have rights to create and edit forms
	const { canEditVideo } = useAuthCheck();

	const {
		deleteVodSegment,
		data,
		isError,
		isAddButtonDisabled
	} = useVodSegments({
		vodId,
		videoId
	});

	const subtitlesHeader = () => (
		<SegmentsHeader
			vodId={vodId}
			videoId={videoId}
			canEditVideo={canEditVideo}
			isAddButtonDisabled={isAddButtonDisabled}
		/>
	);

	return (
		<>
			{isError && <FetchError />}
			<Table
				rowKey={(record) => record.uuid}
				columns={createColumns(deleteVodSegment, canEditVideo)}
				title={subtitlesHeader}
				bordered
				dataSource={data}
				pagination={false}
				size="small"
				className="m-b-lg subtitle-table"
				locale={locale}
			/>
		</>
	);
};

VodSegments.propTypes = {
	videoId: PropTypes.number.isRequired
};

export default VodSegments;
