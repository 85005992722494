import React from 'react';
import { useDispatch } from 'react-redux';
import { useTranslation } from 'react-i18next';
import { fetchLandingPageFiles } from 'store/actions';

// Import components
import InfoTitle from 'components/utilities/info/InfoTitle';
import Image from 'components/utilities/images/Image';

// Import helpers
import { IMAGE_SIZE_INFO } from 'components/helpers/info_helpers/info_texts';
import { LANDING_PAGE_MODEL_TYPES } from './helpers';

// Import hooks
import usePageImages from './usePageImages';

const LandingPageImages = () => {
	const { t } = useTranslation();
	const dispatch = useDispatch();

	const { filteredProviders } = usePageImages({
		modelTypes: LANDING_PAGE_MODEL_TYPES
	});

	return (
		<>
			<InfoTitle title={`${IMAGE_SIZE_INFO}`} />
			{filteredProviders.map(([modelType, id]) => (
				<Image
					key={id}
					title={t(`settings:files.${modelType}`)}
					fetchResourcesAction={(id) =>
						fetchLandingPageFiles(id, modelType)(dispatch)
					}
					collectionType="cover"
					mediaType={modelType}
					storePathSelector="settings"
					customId={id}
					customFileType={modelType}
				/>
			))}
		</>
	);
};

export default LandingPageImages;
