import i18n from 'i18next';

export const CATEGORIES_INPUT_FIELDS_DATA = {
	submitUrl: 'products/genres',
	fetchUrl: 'products/genres',
	fetchUrlSlug: 'details',
	cancelButtonPath: 'categories',
	hasMetadata: false,
	defautlFieldValues: {
		name: ''
	},
	inputFields: [
		{
			name: 'name',
			label: i18n.t('categories:fields.name'),
			type: 'text',
			required: true
		}
	]
};
