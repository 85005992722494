import React from 'react';
import { Col, FormGroup, Label } from 'reactstrap';
import { Select } from 'antd';
import PropTypes from 'prop-types';
import { useSelector } from 'react-redux';

import { convertProvidersToNames } from 'components/views/vod/helpers/helpers';

/**
 * Custom input for single providers field. It can accept dynamic data.
 */
const ProviderFieldMultiSelectLabel = ({
	input,
	id,
	label,
	required = true,
	placeholder = 'Chose Country',
	labelColumn = 2,
	inputColumn = 10,
	isEditDisabled = false,
	mode = 'multiple',
	showSearch = false,
	children,
	meta: { touched, error, submitError },
	onSearch,
	disabled,
	...otherProps
}) => {
	// get data from redux. Data can change dynamically
	const { providersNamesById } = useSelector((state) => state.providers.data);

	// convert data from redux to array of provider names
	const customData = convertProvidersToNames(providersNamesById);

	return (
		<FormGroup row className={`${touched && error && 'has-error'}`}>
			{isEditDisabled && <div className="form-group__disabled">&nbsp;</div>}
			<Label
				for={id}
				sm={labelColumn}
				className="col-form-label col-form-label-lg"
			>
				{label}
			</Label>
			<Col sm={inputColumn}>
				<Select
					{...input}
					{...otherProps}
					showSearch
					value={input.value || []}
					mode={mode}
					style={{ width: '100%' }}
					placeholder={placeholder}
					className="m-b-sm"
					onSearch={onSearch}
					disabled={disabled || !customData.length > 0} // disable when there is no provider_id selected
				>
					{customData.map((item, index) => (
						<Select.Option key={index} value={item}>
							{item}
						</Select.Option>
					))}
				</Select>
				{touched && (error || submitError) && (
					<span
						data-testid={`input-error-${input.name}`}
						className="input-error"
					>
						{error || submitError}
					</span>
				)}
			</Col>
		</FormGroup>
	);
};

ProviderFieldMultiSelectLabel.defaultProps = {
	onSearch: () => {},
	onChangeAction: () => {}
};

ProviderFieldMultiSelectLabel.propTypes = {
	input: PropTypes.object,
	id: PropTypes.string,
	label: PropTypes.string,
	required: PropTypes.bool,
	placeholder: PropTypes.string,
	labelColumn: PropTypes.number,
	inputColumn: PropTypes.number,
	isEditDisabled: PropTypes.bool,
	mode: PropTypes.string,
	children: PropTypes.array,
	meta: PropTypes.object,
	onSearch: PropTypes.func,
	otherProps: PropTypes.object,
	showSearch: PropTypes.bool,
	disabled: PropTypes.bool
};

export default ProviderFieldMultiSelectLabel;
