// Import translation
import i18n from 'i18next';

export const categoriesTranslationValidate = ({ name }) => {
	const errors = {};

	// ------------------ Name ------------------
	if (!name) {
		errors.name = i18n.t('categories:validation.name_required');
	} else if (name.length < 2 || name.length > 200) {
		errors.name = i18n.t('categories:validation.name_length');
	}

	return errors;
};
