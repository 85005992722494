import services from 'services/services';
import * as types from '../types';

// Import helpers
import { checkFetchErrors } from '../helpers_actions/convert_helpers';

// ******************** FETCH SETTINGS VOD LOGO FILES TO EDIT ********************
export const fetchSettingsVodLogo = (id, vodLogoMediaType) => async (
	dispatch
) => {
	try {
		// Dispatch a loading action
		dispatch({
			type: types.FETCH_SETTINGS_VOD_LOGO_FILES_LOADING,
			payload: true
		});

		const response = await services.get(
			`/assets/${id}/list?model_type=${vodLogoMediaType}&collection=logo`
		);

		// Dispatch an action with data
		dispatch({
			type: types.FETCH_SETTINGS_VOD_LOGO_FILES_SUCCESS,
			payload: response.data
		});
	} catch (error) {
		dispatch({
			type: types.FETCH_SETTINGS_VOD_LOGO_FILES_ERROR,
			payload: checkFetchErrors(error)
		});
	}
};
