import { useSelector, useDispatch } from 'react-redux';
import { useLocation } from 'react-router-dom';
import {
	setFilterUrlQuery,
	setFilterFormValues,
	clearFilters
} from 'store/actions';
import moment from 'moment';

// Import helpers
import { getTableType } from 'components/helpers/convert';
import { columnsWithDateValues } from 'components/helpers/filters_helpers/helpers';

/**
 * Custom hook to store logic for FiltersDrawersForm
 * @param {func} toggle - toggle function switches drawer visibility
 */
const useFiltersDrawerFormLogic = ({ toggle }) => {
	const dispatch = useDispatch();
	const { pathname } = useLocation();
	const { tableColumns, filterFormValues, filtersSuggestions } = useSelector(
		(state) => state.filters
	);

	const tableType = getTableType(pathname);

	// generates url query which will be injected to request link
	const generateUrlQuery = (columns, values) => {
		let urlQuery = '';
		// format range dates in payments table and add to urlQuery with proper query string names
		if (values.range && values.range.length) {
			const date_from = moment(values.range[0]).format('YYYY-MM-DD HH:mm:ss');
			const date_to = moment(values.range[1]).format('YYYY-MM-DD HH:mm:ss');
			urlQuery += `date_from=${date_from}&date_to=${date_to}&`;
		}

		columns.forEach((column, index) => {
			if (Object.keys(values).indexOf(column.name) !== -1) {
				// for payment models there is different query. It is not searched by column number like other filters.
				// However it still must be present in filters form hence this extra if below for 'payment_models'
				if (column.name === 'payment_models') {
					return (urlQuery += `paymentModel=${values.payment_models}&`);
				}
				// here dates are properly formatted
				else if (columnsWithDateValues.includes(column.name)) {
					// if there is valid date return properly formated date otherwise return nothing
					// there can be date values of null (when you clear antd DatePicker component) - that's why this condition is applied here
					return values[column.name]
						? (urlQuery += `columns[${index}][search][value]=${moment(
								values[column.name]
						  ).format('YYYY-MM-DD')}&`)
						: 'null';
					// We need it to searching phone_number, because plus is received as space
				} else if (column.name === 'phone_number') {
					return (urlQuery += `columns[${index}][search][value]=${values[
						column.name
					].replace('+', '%2b')}&`);
				} else {
					return (urlQuery += `columns[${index}][search][value]=${
						convertBool(values)[column.name]
					}&`);
				}
			}
		});

		return urlQuery;
	};

	// converts true and false values to 1 and 0
	const convertBool = (values) => {
		Object.entries(values).forEach((value) => {
			if (value[1] === true) values[value[0]] = 1;
			if (value[1] === false) values[value[0]] = 0;
		});

		return values;
	};

	const handleOnSubmit = (values) => {
		setFilterUrlQuery(generateUrlQuery(tableColumns, values))(dispatch);
		setFilterFormValues(values)(dispatch);
		toggle();
	};

	const handleOnCancel = () => toggle();

	const handleClear = () => {
		clearFilters()(dispatch);
	};

	return {
		handleOnSubmit,
		handleOnCancel,
		handleClear,
		tableType,
		filterFormValues,
		filtersSuggestions
	};
};

export default useFiltersDrawerFormLogic;
