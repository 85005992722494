import i18n from 'i18next';

export const VOD_FILTER_FIELDS = [
	{
		name: 'title',
		label: i18n.t('filters:fields.title'),
		type: 'text',
		required: false,
		labelColumn: 3,
		inputColumn: 9
	},
	{
		name: 'year',
		label: i18n.t('filters:fields.year'),
		type: 'text',
		required: false,
		labelColumn: 3,
		inputColumn: 9
	},
	{
		name: 'country',
		label: i18n.t('filters:fields.country'),
		type: 'text',
		required: false,
		labelColumn: 3,
		inputColumn: 9
	},
	{
		name: 'actors',
		label: i18n.t('filters:fields.actors'),
		type: 'multi-select-no-resources',
		required: false,
		mode: 'tags',
		showSearch: true,
		labelColumn: 3,
		inputColumn: 9
	},
	{
		name: 'writers',
		label: i18n.t('filters:fields.writers'),
		type: 'text',
		required: false,
		labelColumn: 3,
		inputColumn: 9
	},
	{
		name: 'directors',
		label: i18n.t('filters:fields.directors'),
		type: 'text',
		required: false,
		labelColumn: 3,
		inputColumn: 9
	},
	{
		name: 'provider_id',
		label: i18n.t('filters:fields.provider'),
		type: 'custom-names',
		required: false,
		mode: 'multiple',
		showSearch: true,
		labelColumn: 3,
		inputColumn: 9
	},
	{
		name: 'rating',
		label: i18n.t('filters:fields.rating'),
		type: 'multi-select',
		required: false,
		mode: '',
		showSearch: true,
		labelColumn: 3,
		inputColumn: 9
	},
	{
		name: 'genres',
		label: i18n.t('filters:fields.genres'),
		type: 'multi-select',
		required: false,
		mode: '',
		showSearch: true,
		labelColumn: 3,
		inputColumn: 9
	},
	{
		name: 'payment_models',
		label: i18n.t('filters:fields.payment_models'),
		type: 'custom-names',
		required: false,
		mode: '',
		showSearch: true,
		labelColumn: 3,
		inputColumn: 9
	}
	/* ==== Temporarily hidden ==== */

	// {
	// 	name: 'sub_status',
	// 	label: i18n.t('filters:fields.sub_status'),
	// 	type: 'custom-names',
	// 	required: false,
	// 	mode: '',
	// 	showSearch: true,
	// 	labelColumn: 3,
	// 	inputColumn: 9
	// }
];
