import * as types from 'store/actions/types';
import produce from 'immer';

const INITIAL_STATE = {
	isLoaded: false,
	error: false,
	data: {},
	translationsData: {}
};

export default (state = INITIAL_STATE, action) =>
	produce(state, (draft) => {
		switch (action.type) {
			// CLEAR STATE
			case types.CLEAR_STATE:
				return { ...INITIAL_STATE };
			// FETCH TRANSLATIONS
			case types.FETCH_CONTENT_TRANSLATIONS_LOADING:
				draft.isLoaded = false;
				draft.error = false;
				break;
			case types.FETCH_CONTENT_TRANSLATIONS_SUCCESS:
				draft.isLoaded = true;
				draft.data = action.payload;
				draft.translationsData = action.hasMetadata
					? action.payload.metadata
					: action.payload;
				break;
			case types.FETCH_CONTENT_TRANSLATIONS_ERROR:
				draft.isLoaded = true;
				draft.error = action.payload;
				break;

			default:
				break;
		}
	});
