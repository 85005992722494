import i18n from 'i18next';

export const MAIN_INPUT_FIELDS_DATA = {
	submitUrl: 'packet',
	fetchUrl: 'packet',
	cancelButtonPath: '',
	hasMetadata: false,
	defautlFieldValues: {
		title: '',
		description: '',
		short_description: ''
	},
	inputFields: [
		{
			name: 'title',
			label: i18n.t('main:fields.title'),
			type: 'text',
			required: true
		},
		{
			name: 'description',
			label: i18n.t('main:fields.description'),
			type: 'text',
			required: true
		},
		{
			name: 'short_description',
			label: i18n.t('main:fields.short_description'),
			type: 'textarea',
			required: true
		}
	]
};
