import React from 'react';

// Import translation
import i18n from 'i18next';

// Import components
import AssetStatus from './asset_status/AssetStatus';
import TranscodeButton from './upload_buttons/TranscodeButton';
import DeleteAssetButton from './upload_buttons/DeleteAssetButton';

export const createColumns = (transcodeAsset, isReadOnly) => [
	{
		title: i18n.t('vod:upload_materials.table_columns.url'),
		dataIndex: 'url'
	},
	{
		title: i18n.t('vod:upload_materials.table_columns.created_at'),
		dataIndex: 'created_at'
	},
	{
		title: i18n.t('vod:upload_materials.table_columns.status'),
		dataIndex: 'status',
		render: (text, record, index) => <AssetStatus record={record} />
	},
	{
		title: i18n.t('vod:upload_materials.table_columns.transcode_main'),
		dataIndex: 'transcode_main',
		align: 'center',
		width: 100,
		render: (text, record, index) => (
			<TranscodeButton
				record={record}
				transcodeAsset={transcodeAsset}
				videoType={'main'}
			/>
		)
	},
	{
		title: i18n.t('vod:upload_materials.table_columns.transcode_preview'),
		dataIndex: 'transcode_preview',
		align: 'center',
		width: 100,
		render: (text, record, index) => (
			<TranscodeButton
				record={record}
				transcodeAsset={transcodeAsset}
				videoType={'preview'}
			/>
		)
	},
	{
		title: i18n.t('common:materials_table.table_columns.delete'),
		dataIndex: 'delete',
		align: 'center',
		width: 100,
		render: (text, record, index) => <DeleteAssetButton record={record} />
	}
];
