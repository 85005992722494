export const select_vod_types = [
	{
		name: 'VOD',
		value: 'vod'
	}
];

export const select_vod_subtypes = [
	{
		name: 'SERIES',
		value: 'series'
	},
	{
		name: 'VOD',
		value: 'vod'
	}
];
