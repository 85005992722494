import services from 'services/services';
import * as types from '../../types';

// Import helpers
import { checkFetchErrors } from 'store/actions/helpers_actions/convert_helpers';

// ******************** FETCH INGESTER_STATS - TABLE DATA ********************
export const fetchIngesterStats = () => async (dispatch) => {
	try {
		// Dispatch a loading action
		dispatch({
			type: types.FETCH_INGESTER_STATS_LOADING,
			payload: true
		});

		const { data } = await services.get(`/ingester/stats?`);

		// Dispatch an action with data
		dispatch({
			type: types.FETCH_INGESTER_STATS_SUCCESS,
			payload: data
		});
	} catch (error) {
		dispatch({
			type: types.FETCH_INGESTER_STATS_ERROR,
			payload: checkFetchErrors(error)
		});
	}
};
