import services from 'services/services';

// Import helpers
import { checkFetchErrors } from 'store/actions/helpers_actions/convert_helpers';
import { setActionType } from './helpers';

// ******************** FETCH CHANNEL AVAILABILITY DATA TO EDIT ********************
export const fetchVisibilityForm = (id, resources) => async (dispatch) => {
	const { productType } = resources;
	const { formLoading, formSuccess, formError } = setActionType(productType);
	try {
		// Dispatch a loading action
		dispatch({
			type: formLoading,
			payload: true
		});

		const response = await services.get(`/timetables/visibility/${id}`);

		// Dispatch an action with data
		dispatch({
			type: formSuccess,
			payload: response.data
		});
	} catch (error) {
		dispatch({
			type: formError,
			payload: checkFetchErrors(error)
		});
	}
};
