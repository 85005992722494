import React from 'react';
import PropTypes from 'prop-types';
import { Field } from 'react-final-form';

// Import utilites
import { Info } from 'components/utilities';

// Import components
import { InputLabel, TextareaLabel } from 'components/common/inputs';

// Import fields
import { BASE_FIELDS } from './input_fields';

const Fields = ({ submitting, isEdit }) => {
	// this type of renderFields funciton is used instead of generic renderFields because we need to add the same info at the end of field
	// as in sections form algorithm config. Info component is used.
	const renderFields = (item, index) => {
		switch (item.type) {
			case 'textarea':
				return (
					<React.Fragment key={`${item.name}-${index}`}>
						<Info content={item.info} modalWidth={item.modalWidth}>
							<Field
								disabled={submitting}
								name={item.name}
								type="textarea"
								placeholder={item.label}
								label={item.label}
								id={item.name}
								required={item.required}
								fieldInfo={item.fieldInfo}
								component={TextareaLabel}
							/>
						</Info>
						<div className="hr-line-dashed">&nbsp;</div>
					</React.Fragment>
				);
			default:
				return (
					<React.Fragment key={`${item.name}-${index}`}>
						{(isEdit || item.isEdit) && (
							<React.Fragment>
								<Info content={item.info}>
									<Field
										disabled={submitting}
										name={item.name}
										type={item.type}
										placeholder={item.label}
										label={item.label}
										id={item.name}
										required={item.required}
										component={InputLabel}
									/>
								</Info>
								<div className="hr-line-dashed">&nbsp;</div>
							</React.Fragment>
						)}
					</React.Fragment>
				);
		}
	};

	return <React.Fragment>{BASE_FIELDS.map(renderFields)}</React.Fragment>;
};

Fields.propTypes = {
	submitting: PropTypes.bool.isRequired,
	isEdit: PropTypes.bool.isRequired
};

export default Fields;
