import React from 'react';
import i18n from 'i18next';

// Import components
import SectionsGroupsForm from '../../section_groups_form/SectionsGroupsForm';
import SectionsAssigned from '../../sections_assigned/SectionsAssigned';

// Import utilities
import { generateContentTranslationsTabs } from 'components/utilities/content_translation/tabs/content_translations_tabs';

// import content translation fields
import { SECTIONS_GROUPS_INPUT_FIELDS_DATA } from 'components/utilities/content_translation/input_fields';

// Validate
import { sectionsGroupsTranslationValidate } from 'components/utilities/content_translation/validation';

export const sectionsGroupsBasicTabs = [
	{
		name: i18n.t('common:tabs.sections_assigned'),
		path: 'sections-assigned',
		disableOnCreate: true,
		component: <SectionsAssigned />
	},
	{
		name: i18n.t('common:tabs.metadata'),
		path: 'metadata',
		disableOnCreate: false,
		component: <SectionsGroupsForm />
	}
];

export const sectionsGroupsTabs = (contentTranslationLanguages) => [
	...sectionsGroupsBasicTabs,
	...generateContentTranslationsTabs(
		SECTIONS_GROUPS_INPUT_FIELDS_DATA,
		contentTranslationLanguages,
		sectionsGroupsTranslationValidate
	)
];
