import i18n from 'i18next';

export const BANNERS_INPUT_FIELDS_DATA = {
	submitUrl: 'section/banners',
	fetchUrl: 'section/banners',
	cancelButtonPath: 'banners',
	hasMetadata: false,
	defautlFieldValues: {
		title: '',
		description: ''
	},
	inputFields: [
		{
			name: 'title',
			label: i18n.t('banners:fields.title'),
			type: 'text',
			required: true
		},
		{
			name: 'description',
			label: i18n.t('banners:fields.description'),
			type: 'text',
			required: true
		}
	]
};
