import services from 'services/services';
import * as types from '../types';

// Import helpers
import { checkFetchErrors } from '../helpers_actions/convert_helpers';

// ******************** FETCH SUBSCRIBER PACKETS - TABLE DATA ********************
export const fetchSubscriberPackets = (options, itemId) => async (dispatch) => {
	try {
		// Dispatch a loading action
		dispatch({
			type: types.FETCH_SUBSCRIBER_PACKETS_LOADING
		});

		const { data } = await services.get(`subscriber/${itemId}/packets`);

		// Dispatch an action with data
		dispatch({
			type: types.FETCH_SUBSCRIBER_PACKETS_SUCCESS,
			payload: data
		});
	} catch (error) {
		dispatch({
			type: types.FETCH_SUBSCRIBER_PACKETS_ERROR,
			payload: checkFetchErrors(error)
		});
	}
};

// ******************** FETCH SUBSCRIBER SUBSCRIPTIONS (MAIN) - TABLE DATA ********************
export const fetchSubscriberSubscriptions = (options, subscriberId) => async (
	dispatch
) => {
	try {
		dispatch({
			type: types.FETCH_SUBSCRIBER_SUBSCRIPTIONS_LOADING
		});

		const { data } = await services.get(
			`timetables/subscription/list?subscriber_id=${subscriberId}`
		);

		// Dispatch an action with data
		dispatch({
			type: types.FETCH_SUBSCRIBER_SUBSCRIPTIONS_SUCCESS,
			payload: data
		});
	} catch (error) {
		dispatch({
			type: types.FETCH_SUBSCRIBER_SUBSCRIPTIONS_ERROR,
			payload: checkFetchErrors(error)
		});
	}
};
