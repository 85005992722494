// Import translation
import i18n from 'i18next';

// Import helpers
import { LINK_REGEX } from 'components/helpers/regex_expressions';

export default ({ title, description, group, url_pc, url_mobile }) => {
	const errors = {};

	// ------------------ title ------------------
	if (!title) {
		errors.title = i18n.t('banners:validation.title');
	}

	// ------------------ description ------------------
	if (!description) {
		errors.description = i18n.t('banners:validation.description');
	}

	// ------------------ group ------------------
	if (!group) {
		errors.group = i18n.t('banners:validation.group');
	}

	// ------------------ url pc ------------------
	if (!url_pc) {
		errors.url_pc = i18n.t('banners:validation.url_pc');
	} else if (!LINK_REGEX.test(url_pc)) {
		errors.url_pc = i18n.t('banners:validation.url_pc_error');
	}

	// ------------------ url_mobile ------------------
	if (!url_mobile) {
		errors.url_mobile = i18n.t('banners:validation.url_mobile');
	} else if (!LINK_REGEX.test(url_mobile)) {
		errors.url_mobile = i18n.t('banners:validation.url_mobile_error');
	}

	return errors;
};
