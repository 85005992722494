import React from 'react';
import { array, func, bool } from 'prop-types';
import { setFiltersDisable } from 'store/actions';
import { connect } from 'react-redux';

// Import components
import { Link } from 'react-router-dom';

// Import translation
import { useTranslation } from 'react-i18next';

// Import helpers
import { getImgUrl, generateProductLink } from '../helpers/index';

const CardsList = ({
	data,
	isDelete,
	deleteFunction,
	className,
	setFiltersDisable
}) => {
	const { t } = useTranslation();

	const renderDeleteBtn = (item) => (
		<button
			className="item__delete"
			onClick={() => deleteFunction(item.uuid || item.id)}
			type="button"
		>
			<span className="btn-txt">
				{t('common:delete')}{' '}
				<i className="fa fa-trash-o" aria-hidden="true"></i>
			</span>
			<span className="btn-icon">
				<i className="fa fa-times" aria-hidden="true"></i>
			</span>
		</button>
	);

	const renderItem = (item) => {
		const imgUrl = getImgUrl(item.images);
		const pathname = generateProductLink(item);
		const linkParams = {
			pathname,
			state: { provider: `${item.title}` }
		};

		return (
			<li
				className="cards-list__li"
				key={`${item.uuid || item.id || item.product_id}-${item.title ||
					item.name}`}
			>
				<span className="item">
					{imgUrl && (
						<span className="item__img">
							<img className="img-fluid" src={imgUrl} alt={item.title} />
						</span>
					)}
					{isDelete && renderDeleteBtn(item)}
					<div className="item__text-container">
						<Link
							className="item__title item__title__link"
							to={linkParams}
							onClick={() => setFiltersDisable(true)}
						>
							{item.title}
						</Link>
						<span className="item__category">{item.type}</span>
					</div>
				</span>
			</li>
		);
	};

	return <ul className={`cards-list ${className}`}>{data.map(renderItem)}</ul>;
};

CardsList.defaultProps = {
	isDelete: false,
	deleteFunction: null,
	className: null
};

CardsList.propTypes = {
	data: array.isRequired,
	isDelete: bool.isRequired,
	deleteFunction: func,
	setFiltersDisable: func.isRequired
};

export default connect(null, { setFiltersDisable })(CardsList);
