import React from 'react';

// Import translations
import { useTranslation } from 'react-i18next';

// Import hooks
import useUploadMaterialsTable from './useUploadMaterialsTable';

// Import columns
import { createColumns } from './upload_colmuns';

// Import helpers
import { locale } from 'components/helpers/table';

// Import components
import { Table } from 'antd';
import RefreshUploadTableButton from './upload_buttons/RefreshUploadTableButton';

import { STATUS_TYPES } from 'components/helpers/variables';

const { loading } = STATUS_TYPES;

const UploadMaterialsTable = () => {
	const { t } = useTranslation();
	const {
		tableResources,
		tableStatus,
		dataChange,
		transcodeAsset
	} = useUploadMaterialsTable();

	return (
		<div className="pb-4 my-5">
			<div className="d-flex justify-content-between my-3">
				<h5 className="title__upload mb-4">
					{t('vod:upload_materials.upload_table_title')}
				</h5>
				<RefreshUploadTableButton />
			</div>
			<Table
				rowKey={(record) => record.id}
				dataSource={tableResources}
				columns={createColumns(transcodeAsset)}
				bordered
				pagination={false}
				size="small"
				className="m-b-lg material-table"
				locale={locale}
				onChange={dataChange}
				loading={tableStatus === loading}
			/>
		</div>
	);
};

export default UploadMaterialsTable;
