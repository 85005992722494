import * as types from 'store/actions/types';
import produce from 'immer';

export const INITIAL_STATE = {
	loading: true,
	error: false,
	data: [],
	columns: [],
	deleteItem: {
		success: false,
		error: false
	},
	limitInfo: {
		data: {
			current_month: undefined,
			available: undefined,
			limit: undefined
		},
		loading: true,
		error: false
	},
	options: {
		pages: 0,
		page: 0,
		total_results: 0,
		startIndex: 0,
		sorted: undefined,
		filters: {
			query: ''
		}
	}
};

export default (state = INITIAL_STATE, action) =>
	produce(state, (draft) => {
		switch (action.type) {
			// *************** FETCH DEVICES ***************
			case types.FETCH_SUBSCRIBER_DEVICES_LOADING:
				draft.loading = true;
				draft.error = false;
				break;

			case types.FETCH_SUBSCRIBER_DEVICES_SUCCESS:
				draft.loading = false;
				draft.data = action.payload.data;
				draft.columns = action.payload.input.columns;
				draft.options.pages = action.payload.pages;
				draft.options.total_results = action.payload.recordsFiltered;
				draft.options.startIndex = action.payload.startIndex;
				draft.options.page = action.payload.page;
				break;

			case types.FETCH_SUBSCRIBER_DEVICES_ERROR:
				draft.error = action.payload;
				break;

			// *************** SAVE SORTING OPTIONS ***************
			case types.SAVE_SUBSCRIBER_DEVICES_TABLE_SORTING:
				draft.options.sorted = action.payload;
				break;

			// *************** DEACTIVATE DEVICE ***************
			case types.DEACTIVATE_DEVICE_LOADING:
				draft.deleteItem.success = false;
				draft.deleteItem.error = false;
				return;

			case types.DEACTIVATE_DEVICE_SUCCESS:
				draft.deleteItem.success = action.payload;
				draft.deleteItem.error = false;
				return;

			case types.DEACTIVATE_DEVICE_ERROR:
				draft.deleteItem.error = action.payload;
				return;

			// ******************** RESET LIMITS ********************
			case types.RESET_SUBSCRIBER_LIMITS_LOADING:
				draft.loading = true;
				draft.error = false;
				break;

			case types.RESET_SUBSCRIBER_LIMITS_SUCCESS:
				draft.data = [];
				break;

			case types.RESET_SUBSCRIBER_LIMITS_ERROR:
				draft.error = action.payload;
				break;

			// ******************** FETCH LIMITS DATA ********************
			case types.GET_SUBSCRIBER_LIMITS_DATA_LOADING:
				draft.limitInfo.loading = true;
				draft.limitInfo.error = false;
				break;
			case types.GET_SUBSCRIBER_LIMITS_DATA_SUCCESS:
				draft.limitInfo.data = action.payload;
				draft.limitInfo.loading = false;
				draft.limitInfo.error = false;
				break;
			case types.GET_SUBSCRIBER_LIMITS_DATA_ERROR:
				draft.limitInfo.error = action.payload;
				break;

			default:
				return state;
		}
	});
