import { combineReducers } from 'redux';
// Import reducers
import table from './channels_panel_reducer';
import form from './channels_form_reducer';
import files from './channels_files_reducer';
import materials from './channels_materials_reducer';
import availability from './channels_availability/channels_availability_reducer';
import platforms from './channels_platforms_reducer';

export default combineReducers({
	table,
	form,
	files,
	materials,
	availability,
	platforms
});
