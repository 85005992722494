// Import redux
import { createStore, applyMiddleware } from 'redux';
import { composeWithDevTools } from 'redux-devtools-extension';
// Import redux thunk
import reduxThunk from 'redux-thunk';
// Import reducers
import reducers from './reducers';

// Import variables
import { LOCALSTORAGE_USER } from 'components/helpers/variables';

const user = JSON.parse(localStorage.getItem(LOCALSTORAGE_USER));

// Initial state
const INITIAL_STATE = {
	auth: {
		authenticate: user || null,
		errorMessage: false,
		signOutRedirect: false,
		isAuthenticated: user ? user.isAuthenticated : false,
		signOut: {
			loading: false,
			error: false
		},
		resetPassword: {
			checkHash: {
				isHashValid: false,
				loading: true
			},
			sendResetEmail: {
				loading: false,
				isSuccess: false,
				error: false
			},
			setNewPassword: {
				isSuccess: false,
				loading: false
			}
		}
	}
};
// Create middleware with redux thunk
const createStoreWithMiddleware = applyMiddleware(reduxThunk)(createStore);

let store;

if (process.env.NODE_ENV === 'production') {
	// Create store
	store = createStoreWithMiddleware(reducers, INITIAL_STATE);
} else {
	// Create store
	store = createStoreWithMiddleware(
		reducers,
		INITIAL_STATE,
		composeWithDevTools()
	);
}

export default store;
