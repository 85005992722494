// Import translation
import i18n from 'i18next';

export const mainTranslationValidate = ({
	title,
	description,
	short_description
}) => {
	const errors = {};

	// ------------------ Title ------------------
	if (!title) {
		errors.title = i18n.t('main:validation.title');
	} else if (title.length < 2 || title.length > 200) {
		errors.title = i18n.t('main:validation.title_length');
	}
	// ------------------ Description ------------------
	if (!description) {
		errors.description = i18n.t('main:validation.description');
	}
	// ------------------ Description short ------------------
	if (!short_description) {
		errors.short_description = i18n.t('main:validation.short_description');
	}

	return errors;
};
