import React from 'react';
import Tabs from 'components/utilities/tabs/Tabs';
import { useSelector } from 'react-redux';
import { isEmpty } from 'lodash';

// Import helrpes
import { categoriesTabs } from './helpers/index';

const CategoriesTabs = () => {
	const formData = useSelector((state) => state.categories.form.edit);
	const { languages: contentTranslationLanguages } = useSelector(
		({ content_translations }) => content_translations.language_list
	);
	const isFormDataAvailable = !isEmpty(formData);

	return (
		<Tabs
			tabs={categoriesTabs(isFormDataAvailable, contentTranslationLanguages)}
		/>
	);
};

export default CategoriesTabs;
