import React, { useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';

// Import actions
import { fetchSettingsWatermark, dictionaryAssetTypes } from 'store/actions';

// Import components
import Image from 'components/utilities/images/Image';
import InfoTitle from 'components/utilities/info/InfoTitle';
import { Spin } from 'antd';

// Import translation
import { useTranslation } from 'react-i18next';

// Import helpers
import {
	IMAGE_SIZE_INFO,
	WATERMARK_IMAGE_INFO
} from 'components/helpers/info_helpers/info_texts';

const WatermarkSettings = () => {
	const { t } = useTranslation();
	const dispatch = useDispatch();

	const { assetTypes } = useSelector(({ dictionary }) => dictionary);

	const watermarkMediaType = Object.keys(assetTypes)[0];
	const watermarkCustomId = Object.values(assetTypes)[0];

	useEffect(() => {
		// Dispatch  action in channels_files
		dictionaryAssetTypes()(dispatch);
		// eslint-disable-next-line
	}, []);

	return (
		<>
			<InfoTitle title={`${IMAGE_SIZE_INFO} ${WATERMARK_IMAGE_INFO}`} />
			{Object.entries(assetTypes).length ? (
				<Image
					title={t('common:images.watermark')}
					fetchResourcesAction={(id) =>
						fetchSettingsWatermark(id, watermarkMediaType)(dispatch)
					}
					collectionType="logo"
					mediaType={watermarkMediaType}
					storePathSelector="settings"
					customId={watermarkCustomId}
					isDarkBackground={true}
				/>
			) : (
				<div className="d-flex justify-content-center">
					<Spin size="large" />
				</div>
			)}
		</>
	);
};

export default WatermarkSettings;
