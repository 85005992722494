import { isEmpty } from 'lodash';

/**
 * Functions checks if tabs for edtiting promotions should be visible, based on promotion data
 * @param {object} promotionAvailableTabs - promotion data
 * @param {string} tabType - type of the tab that is currently checked
 * @returns {bool} - returns boolean. By default tab is disabled.
 */
export const handleTabVisiblity = (promotionAvailableTabs, tabType) => {
	if (!isEmpty(promotionAvailableTabs)) {
		const {
			productOutSpecification,
			usersSpecification,
			productInSpecification
		} = promotionAvailableTabs;
		if (
			usersSpecification === 'packets_list' &&
			tabType === 'promotions_packets'
		)
			return false;
		if (usersSpecification === 'users_list' && tabType === 'promotions_users')
			return false;
		if (
			productOutSpecification === 'products_list' &&
			tabType === 'promotions_products_out'
		)
			return false;
		if (
			productOutSpecification === 'products_list' &&
			tabType === 'promotions_products_out_preview'
		)
			return false;
		if (
			productInSpecification === 'products_list' &&
			tabType === 'promotions_products_in'
		)
			return false;
		if (
			productInSpecification === 'products_list' &&
			tabType === 'promotions_products_in_preview'
		)
			return false;
	}

	return true;
};
