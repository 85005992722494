import i18n from 'i18next';

export const defaultFieldsValues = {
	start_time_in_seconds: '0',
	end_time_in_seconds: '0',
	type: 'intro'
};

export const BASE_FIELDS = ({ isEndTimeInSecondsVisible }) => [
	{
		name: 'type',
		label: i18n.t('vod_segment:fields.type'),
		type: 'select',
		required: true
	},
	{
		name: 'start_time_in_seconds',
		label: i18n.t('vod_segment:fields.start_time_in_seconds'),
		type: 'number',
		required: true
	},
	{
		name: 'end_time_in_seconds',
		label: i18n.t('vod_segment:fields.end_time_in_seconds'),
		type: 'number',
		required: true,
		isVisible: isEndTimeInSecondsVisible
	}
];
