// Import translation
import i18n from 'i18next';

// Import permissions
import * as permissions from 'components/routes/helpers/permissions';

const NAV_LINKS = [
	{
		title: i18n.t('nav:title.products'),
		path: '/panel/channels',
		icon: 'fa fa-tv',
		name: i18n.t('nav:name.channels'),
		permissions: permissions.CHANNELS.allowedRoles
	},
	{
		title: '',
		path: '/panel/vod',
		icon: 'fa fa-film',
		name: i18n.t('nav:name.vod'),
		permissions: permissions.VOD.allowedRoles
	},
	{
		path: '/panel/events_live',
		icon: 'fa fa-star',
		name: i18n.t('nav:name.events_live'),
		permissions: permissions.EVENTS_LIVE.allowedRoles
	},
	{
		title: '',
		path: '/panel/packets',
		icon: 'fa fa-ticket',
		name: i18n.t('nav:name.packets'),
		permissions: permissions.COLLECTIONS.allowedRoles
	},
	{
		title: '',
		path: '/panel/promotions',
		icon: 'fa fa-dollar',
		name: i18n.t('nav:name.promotions'),
		permissions: permissions.PROMOTIONS.allowedRoles
	},
	{
		title: '',
		path: '/panel/limits',
		icon: 'fa fa-check-square-o',
		name: i18n.t('nav:name.limits'),
		permissions: permissions.LIMITS.allowedRoles
	},
	{
		title: i18n.t('nav:title.users'),
		path: '/panel/subscribers',
		icon: 'fa fa-user',
		name: i18n.t('nav:name.subscribers'),
		permissions: permissions.SUBSCRIBERS.allowedRoles
	},
	{
		title: '',
		path: '/panel/devices',
		icon: 'fa fa-tablet',
		name: i18n.t('nav:name.devices'),
		permissions: permissions.DEVICES.allowedRoles
	},
	{
		title: '',
		path: '/panel/provisioning',
		icon: 'fa fa-ticket',
		name: i18n.t('nav:name.provisioning'),
		permissions: permissions.PROVISIONING.allowedRoles
	},
	{
		title: i18n.t('nav:title.analitics'),
		path: '/panel/payments',
		icon: 'fa fa-money',
		name: i18n.t('nav:name.payments'),
		permissions: permissions.PAYMENTS.allowedRoles
	},
	{
		title: '',
		path: '/panel/assigned_packets',
		icon: 'fa fa-check-square-o',
		name: i18n.t('nav:name.assigned_packets'),
		permissions: permissions.ASSIGNED_PACKETS.allowedRoles
	},
	{
		title: '',
		path: '/panel/trackings',
		icon: 'fa fa-clock-o',
		name: i18n.t('nav:name.trackings'),
		permissions: permissions.TRACKINGS.allowedRoles
	},
	{
		title: '',
		path: '/panel/reports',
		icon: 'fa fa-bar-chart',
		name: i18n.t('nav:name.reports'),
		permissions: permissions.REPORTS.allowedRoles
	},
	{
		title: i18n.t('nav:title.content'),
		path: '/panel/categories',
		icon: 'fa fa-check-square-o',
		name: i18n.t('nav:name.categories'),
		permissions: permissions.CATEGORIES.allowedRoles
	},
	{
		title: '',
		path: '/panel/documents',
		icon: 'fa fa-file-text-o',
		name: i18n.t('nav:name.documents'),
		permissions: permissions.DOCUMENTS.allowedRoles
	},
	{
		title: '',
		path: '/panel/agreements',
		icon: 'fa fa-check-square-o',
		name: i18n.t('nav:name.agreements'),
		permissions: permissions.AGREEMENTS.allowedRoles
	},
	{
		title: '',
		path: '/panel/sections_groups',
		icon: 'fa fa-window-maximize',
		name: i18n.t('nav:name.sections_groups'),
		permissions: permissions.SECTION_GROUPS.allowedRoles
	},
	{
		title: '',
		path: '/panel/sections',
		icon: 'fa fa-list-alt',
		name: i18n.t('nav:name.sections'),
		permissions: permissions.SECTIONS.allowedRoles
	},
	{
		title: '',
		path: '/panel/banners',
		icon: 'fa fa-picture-o',
		name: i18n.t('nav:name.banners'),
		permissions: permissions.BANNERS.allowedRoles
	},
	{
		title: '',
		path: '/panel/main',
		icon: 'fa fa-ticket',
		name: i18n.t('nav:name.main'),
		permissions: permissions.PACKETS.allowedRoles
	},
	{
		title: i18n.t('nav:title.administration'),
		path: '/panel/operators',
		icon: 'fa fa-user-plus',
		name: i18n.t('nav:name.operators'),
		permissions: permissions.OPERATORS.allowedRoles
	},
	{
		path: '/panel/geoblock_groups',
		icon: 'fa fa-globe',
		name: i18n.t('nav:name.geolock'),
		permissions: permissions.GEOLOCK.allowedRoles
	},
	{
		title: '',
		path: '/panel/notifications/create',
		icon: 'fa fa-bell',
		name: i18n.t('nav:name.notifications'),
		permissions: permissions.NOTIFICATIONS.allowedRoles
	},
	{
		title: '',
		path: '/panel/software',
		icon: 'fa fa-file-code-o',
		name: i18n.t('nav:name.software'),
		permissions: permissions.SOFTWARE.allowedRoles
	},
	{
		title: '',
		path: '/panel/settings/metadata',
		icon: 'fa fa-cogs',
		name: i18n.t('nav:name.settings'),
		permissions: permissions.SETTINGS.allowedRoles
	}
];

export default NAV_LINKS;
