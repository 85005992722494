import React from 'react';
import { Col, FormGroup, Label } from 'reactstrap';
import { Select } from 'antd';
import PropTypes from 'prop-types';

/**
 * MultiSelect Input with custom onChange function. OnChange function can clear value of other field in the form.
 * Input designed for purpose of fetching data for providers_id field in series and seasons form, but can be used in other locations. USE WITH CAUTION!
 * When value is changed additional data is fetched to use in another field. Another field value is cleared (optionally)
 */
const MultiSelectLabelCustomChange = ({
	input,
	id,
	label,
	required = true,
	placeholder = 'Chose Country',
	labelColumn = 2,
	inputColumn = 10,
	isEditDisabled = false,
	mode = 'multiple',
	showSearch = false,
	children,
	meta: { touched, error, submitError },
	onSearch,
	onChangeAction, // action that will be called on onChange
	currentValues, //current form values
	clearFieldName = null, // clear value of field with given name
	...otherProps
}) => {
	const handleOnChange = (value) => {
		onChangeAction(value);
		if (clearFieldName) {
			currentValues[clearFieldName] = null;
		}
		return input.onChange(value);
	};

	return (
		<FormGroup row className={`${touched && error && 'has-error'}`}>
			{isEditDisabled && <div className="form-group__disabled">&nbsp;</div>}
			<Label
				for={id}
				sm={labelColumn}
				className="col-form-label col-form-label-lg"
			>
				{label}
			</Label>
			<Col sm={inputColumn}>
				<Select
					{...input}
					{...otherProps}
					showSearch
					value={input.value || []}
					mode={mode}
					style={{ width: '100%' }}
					placeholder={placeholder}
					className="m-b-sm"
					onSearch={onSearch}
					onChange={handleOnChange}
				>
					{children}
				</Select>
				{touched && (error || submitError) && (
					<span
						data-testid={`input-error-${input.name}`}
						className="input-error"
					>
						{error || submitError}
					</span>
				)}
			</Col>
		</FormGroup>
	);
};

MultiSelectLabelCustomChange.defaultProps = {
	onSearch: () => {},
	onChangeAction: () => {}
};

MultiSelectLabelCustomChange.propTypes = {
	input: PropTypes.object,
	id: PropTypes.string,
	label: PropTypes.string,
	required: PropTypes.bool,
	placeholder: PropTypes.string,
	labelColumn: PropTypes.number,
	inputColumn: PropTypes.number,
	isEditDisabled: PropTypes.bool,
	mode: PropTypes.string,
	children: PropTypes.array,
	meta: PropTypes.object,
	onSearch: PropTypes.func,
	otherProps: PropTypes.object,
	showSearch: PropTypes.bool
};

export default MultiSelectLabelCustomChange;
