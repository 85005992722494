import React from 'react';
import i18n from 'i18next';

// Import components
import GeoblockGroupForm from 'components/views/geoblock_groups/geoblock_group_form/GeoblockGroupForm';

export const geoblockBasicTabs = [
	{
		name: i18n.t('common:tabs.metadata'),
		path: 'metadata',
		disableOnCreate: false,
		component: <GeoblockGroupForm />
	}
];
