// ******************** EPG DATA TABLE ********************
export const FETCH_EPG_LOADING = 'FETCH_EPG_LOADING';
export const FETCH_EPG_SUCCESS = 'FETCH_EPG_SUCCESS';
export const FETCH_EPG_ERROR = 'FETCH_EPG_ERROR';

export const SEND_EPG_TO_BLACKLIST_LOADING = 'SEND_EPG_TO_BLACKLIST_LOADING';
export const SEND_EPG_TO_BLACKLIST_SUCCESS = 'SEND_EPG_TO_BLACKLIST_SUCCESS';
export const SEND_EPG_TO_BLACKLIST_ERROR = 'SEND_EPG_TO_BLACKLIST_ERROR';

// ******************** SET SEARCH QUERY ********************
export const SET_EPG_SEARCH_QUERY = 'SET_EPG_SEARCH_QUERY';

// ******************** SAVE SORTING OPTIONS  ********************
export const SAVE_EPG_TABLE_SORTING = 'SAVE_EPG_TABLE_SORTING';

// ******************** SET DATE ********************
export const SET_EPG_DATE = 'SET_EPG_DATE';

// ******************** EPG DATA FORM ********************
export const FETCH_EPG_ITEM_LOADING = 'FETCH_EPG_ITEM_LOADING';
export const FETCH_EPG_ITEM_SUCCESS = 'FETCH_EPG_ITEM_SUCCESS';
export const FETCH_EPG_ITEM_ERROR = 'FETCH_EPG_ITEM_ERROR';
