import React from 'react';
import { bool, func, object } from 'prop-types';
import { connect } from 'react-redux';
import { useTranslation } from 'react-i18next';

// Import redux actions
import {
	setGeoblockGroupsQuery,
	fetchGeoblockGroups,
	deleteGeoblockGroup,
	createFiltersSuggestions
} from 'store/actions';

// Import columns
import { createColumns } from './geoblock_groups_columns';

// Import utilities
import Search from 'components/utilities/search/Search';

// Import components
import { PanelPageTemplate } from 'components/common/templates';
import FilterButton from 'components/common/buttons/FilterButton';

const GeoblockGroups = ({
	error,
	setGeoblockGroupsQuery,
	fetchGeoblockGroups,
	deleteGeoblockGroup,
	resources
}) => {
	const { t } = useTranslation();

	const { columns } = resources;

	return (
		// Dispatch fetchGeoblockGroups, deleteGeoblockGroup action in geoblock_groups
		<PanelPageTemplate
			title="Lista"
			error={error}
			type="geoblock_groups"
			addLinkText={t('geoblock_groups:buttons.add')}
			resources={resources}
			columns={createColumns}
			fetchResourcesAction={fetchGeoblockGroups}
			deleteItemAction={deleteGeoblockGroup}
			notificationDeleteSuccessTxt={t(
				'geoblock_groups:notification.delete_success'
			)}
			notificationDeleteErrorTxt={t(
				'geoblock_groups:notification.delete_error'
			)}
		>
			<div className="d-flex justify-content-end">
				<Search
					setQueryValue={setGeoblockGroupsQuery}
					value={resources.options.filters.query}
				/>
				<FilterButton tableColumns={columns} />
			</div>
		</PanelPageTemplate>
	);
};

GeoblockGroups.propTypes = {
	error: bool.isRequired,
	setGeoblockGroupsQuery: func.isRequired,
	resources: object.isRequired,
	fetchGeoblockGroups: func.isRequired,
	deleteGeoblockGroup: func.isRequired
};

const mapStateToProps = ({ geoblock_groups: { table } }) => ({
	error: table.error,
	resources: table
});

export default connect(mapStateToProps, {
	setGeoblockGroupsQuery,
	fetchGeoblockGroups,
	deleteGeoblockGroup,
	createFiltersSuggestions
})(GeoblockGroups);
