import React from 'react';
import PropTypes from 'prop-types';
import Loader from 'components/common/loaders/Loader';

const IboxContent = ({ children, isLoaded = true, className = '' }) => (
	<div className="ibox">
		<div className={`ibox-content ${className} ${!isLoaded && 'sk-loading'}`}>
			<Loader isLoaded={isLoaded}>{children}</Loader>
		</div>
	</div>
);

IboxContent.propTypes = {
	children: PropTypes.object,
	className: PropTypes.string,
	isLoaded: PropTypes.bool
};

export default IboxContent;
