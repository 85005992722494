import React from 'react';
import PropTypes from 'prop-types';
import { Field } from 'react-final-form';

// Import translation
import { useTranslation } from 'react-i18next';

// Import components
import { InputLabel } from 'components/common/inputs';

const Fields = ({ submitting }) => {
	const { t } = useTranslation();

	return (
		<React.Fragment>
			<Field
				disabled={submitting}
				name="group_name"
				type="text"
				placeholder={t('limits:fields.group_name')}
				label={t('limits:fields.group_name')}
				id="group_name"
				required={true}
				component={InputLabel}
			/>
			<div className="hr-line-dashed">&nbsp;</div>
			<Field
				disabled={submitting}
				name="limit"
				type="number"
				placeholder={t('limits:fields.limit')}
				label={t('limits:fields.limit')}
				id="limit"
				required={true}
				component={InputLabel}
			/>
		</React.Fragment>
	);
};

Fields.propTypes = {
	submitting: PropTypes.bool.isRequired
};

export default Fields;
