// -----------------  FETCH SETTINGS -----------------
export const FETCH_SETTINGS_LOADING = 'FETCH_SETTINGS_LOADING';
export const FETCH_SETTINGS_SUCCESS = 'FETCH_SETTINGS_SUCCESS';
export const FETCH_SETTINGS_ERROR = 'AUTH_USER_ERROR';

// ******************** FILES ********************
// --------------------  LOGO --------------------
export const FETCH_SETTINGS_LOGO_FILES_LOADING =
	'FETCH_SETTINGS_LOGO_FILES_LOADING';
export const FETCH_SETTINGS_LOGO_FILES_SUCCESS =
	'FETCH_SETTINGS_LOGO_FILES_SUCCESS';
export const FETCH_SETTINGS_LOGO_FILES_ERROR =
	'FETCH_SETTINGS_LOGO_FILES_ERROR';

// --------------------  LANDING PAGE --------------------
export const FETCH_LANDING_PAGE_FILES_LOADING =
	'FETCH_LANDING_PAGE_FILES_LOADING';
export const FETCH_LANDING_PAGE_FILES_SUCCESS =
	'FETCH_LANDING_PAGE_FILES_SUCCESS';
export const FETCH_LANDING_PAGE_FILES_ERROR = 'FETCH_LANDING_PAGE_FILES_ERROR';

// --------------------  NO SUBSCRIPTION PAGE --------------------
export const FETCH_NO_SUBSCRIPTION_PAGE_FILES_LOADING =
	'FETCH_NO_SUBSCRIPTION_PAGE_FILES_LOADING';
export const FETCH_NO_SUBSCRIPTION_PAGE_FILES_SUCCESS =
	'FETCH_NO_SUBSCRIPTION_PAGE_FILES_SUCCESS';
export const FETCH_NO_SUBSCRIPTION_PAGE_FILES_ERROR =
	'FETCH_NO_SUBSCRIPTION_PAGE_FILES_ERROR';

// --------------------  VOD LOGO --------------------
export const FETCH_SETTINGS_VOD_LOGO_FILES_LOADING =
	'FETCH_SETTINGS_VOD_LOGO_FILES_LOADING';
export const FETCH_SETTINGS_VOD_LOGO_FILES_SUCCESS =
	'FETCH_SETTINGS_VOD_LOGO_FILES_SUCCESS';
export const FETCH_SETTINGS_VOD_LOGO_FILES_ERROR =
	'FETCH_SETTINGS_VOD_LOGO_FILES_ERROR';
