// Import translation
import i18n from 'i18next';

export const agreementsVersionsTranslationValidate = ({ description }) => {
	const errors = {};

	// ------------------ Description ------------------
	if (!description) {
		errors.description = i18n.t(
			'agreements_versions:validation.description_required'
		);
	}

	return errors;
};
