// Import translation
import i18n from 'i18next';

export default ({ alias, title, content }) => {
	const errors = {};

	// ------------------ Alias ------------------
	if (!alias) {
		errors.alias = i18n.t('documents:validation.alias_required');
	}
	// ------------------ Tytuł ------------------
	if (!title) {
		errors.title = i18n.t('documents:validation.title_required');
	}
	// ------------------ Content ------------------
	if (!content) {
		errors.content = i18n.t('documents:validation.content_required');
	}

	return errors;
};
