import React from 'react';
import Tabs from 'components/utilities/tabs/Tabs';
import { useSelector } from 'react-redux';
import { useParams } from 'react-router-dom';

// Import helrpes
import { agreementsVersionsTabs } from './helpers/index';

const AgreementsVersionsTabs = () => {
	const { languages: contentTranslationLanguages } = useSelector(
		({ content_translations }) => content_translations.language_list
	);
	const { agreementId } = useParams();

	return (
		<Tabs
			tabs={agreementsVersionsTabs(contentTranslationLanguages, agreementId)}
		/>
	);
};

export default AgreementsVersionsTabs;
