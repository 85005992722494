import React from 'react';
import { connect } from 'react-redux';
import PropTypes from 'prop-types';

// Import actions
import { fetchSectionProducts } from 'store/actions';

// Import translation
import { useTranslation } from 'react-i18next';

// Import components
import ProductsSelect from 'components/utilities/products_select/ProductsSelect';

import { withRouter } from 'react-router';
import useSectionsProductsLogic from './useSectionsProductsLogic';

const SectionsProducts = ({ data, error, isLoaded, fetchSectionProducts }) => {
	const { t } = useTranslation();

	const { tabs, createSubmitData } = useSectionsProductsLogic();

	return (
		<>
			{tabs.length && (
				<ProductsSelect
					api="section"
					title={t('common:products.title')}
					tabs={tabs}
					openModalText={t('common:products.add_products')}
					buttonSubmitText={t('common:buttons.submit')}
					notificationName="products"
					initialData={data}
					error={error}
					isLoaded={isLoaded}
					fetchResourcesAction={fetchSectionProducts}
					createSubmitData={createSubmitData}
				/>
			)}
		</>
	);
};

SectionsProducts.propTypes = {
	data: PropTypes.array.isRequired,
	sectionType: PropTypes.string.isRequired,
	isLoaded: PropTypes.bool.isRequired,
	error: PropTypes.bool.isRequired,
	fetchSectionProducts: PropTypes.func.isRequired
};

const mapStateToProps = ({ sections: { products } }) => {
	return {
		data: products.data,
		isLoaded: products.isLoaded,
		error: products.error
	};
};

export default connect(mapStateToProps, { fetchSectionProducts })(
	withRouter(SectionsProducts)
);
