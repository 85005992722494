import i18n from 'i18next';

export const createAgreementsVersionsInputData = (agreementId) => ({
	submitUrl: 'agreements/versions',
	fetchUrl: 'agreements/versions',
	cancelButtonPath: `agreements/edit/${agreementId}/versions`,
	hasMetadata: false,
	defautlFieldValues: {
		description: ''
	},
	inputFields: [
		{
			name: 'description',
			label: i18n.t('vod:form:fields.title_org'),
			type: 'editor',
			required: true
		}
	]
});
