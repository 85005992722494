import * as types from 'store/actions/types';
import produce from 'immer';

const INITIAL_STATE = {
	isLoaded: false,
	error: false,
	languages: []
};

export default (state = INITIAL_STATE, action) =>
	produce(state, (draft) => {
		switch (action.type) {
			// FETCH TRANSLATIONS
			case types.FETCH_CONTENT_TRANSLATIONS_LANGUAGES_LOADING:
				draft.isLoaded = false;
				draft.error = false;
				break;
			case types.FETCH_CONTENT_TRANSLATIONS_LANGUAGES_SUCCESS:
				draft.isLoaded = true;
				draft.languages = action.payload.languages;
				break;
			case types.FETCH_CONTENT_TRANSLATIONS_LANGUAGES_ERROR:
				draft.isLoaded = true;
				draft.error = action.payload;
				break;

			default:
				break;
		}
	});
