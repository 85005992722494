import React from 'react';
import i18n from 'i18next';

// Import components
import MassChangesPermanentDelete from '../../mass_changes_permanent_delete/MassChangesPermanentDelete';
import MassChangesAddTo from '../../mass_changes_add_to/MassChangesAddTo';
import MassChangesDeleteFrom from '../../mass_changes_delete_from/MassChangesDeleteFrom';

export const massChangesTabs = [
	{
		name: i18n.t(`mass_changes:tabs.permanent_delete`),
		path: 'delete',
		disableOnCreate: false,
		component: <MassChangesPermanentDelete />
	},
	{
		name: i18n.t(`mass_changes:tabs.add_to`),
		path: 'add_to',
		disableOnCreate: false,
		component: <MassChangesAddTo />
	},
	{
		name: i18n.t(`mass_changes:tabs.delete_from`),
		path: 'delete_from',
		disableOnCreate: false,
		component: <MassChangesDeleteFrom />
	}
];
