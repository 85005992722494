import React from 'react';
import { useSelector } from 'react-redux';

import Tabs from 'components/utilities/tabs/Tabs';

// Import helrpes
import { episodesTabs } from './helpers/index';

const EpisodesTabs = () => {
	const { languages } = useSelector(
		({ content_translations }) => content_translations.language_list
	);

	return <Tabs tabs={episodesTabs(languages)} />;
};

export default EpisodesTabs;
