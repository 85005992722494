import React from 'react';
import { Route, Redirect } from 'react-router-dom';
import { connect } from 'react-redux';

const UserLogged = ({ authenticate, component: Component, ...rest }) => (
	<Route
		{...rest}
		render={(props) =>
			authenticate ? <Redirect to="/panel" /> : <Component {...props} />
		}
	/>
);

const mapStateToProps = (state) => {
	return { authenticate: state.auth.isAuthenticated };
};

export default connect(mapStateToProps)(UserLogged);
