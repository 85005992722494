import React from 'react';
import PropTypes from 'prop-types';
import { Field } from 'react-final-form';

// Import translation
import { useTranslation } from 'react-i18next';

// Import components
import { InputLabel } from 'components/common/inputs';

const Fields = ({ submitting }) => {
	const { t } = useTranslation();

	return (
		<React.Fragment>
			<Field
				disabled={submitting}
				name="name"
				type="text"
				placeholder={t('categories:fields.name')}
				label={t('categories:fields.name')}
				id="name"
				required={true}
				labelColumn={2}
				inputColumn={10}
				component={InputLabel}
			/>
			<Field
				disabled={submitting}
				name="slug"
				type="text"
				placeholder={t('categories:fields.slug')}
				label={t('categories:fields.slug')}
				id="slug"
				required={true}
				labelColumn={2}
				inputColumn={10}
				component={InputLabel}
			/>
			<Field
				disabled={submitting}
				name="order"
				type="number"
				placeholder={t('categories:fields.order')}
				label={t('categories:fields.order')}
				id="order"
				required={false}
				labelColumn={2}
				inputColumn={10}
				component={InputLabel}
				step={1}
			/>
		</React.Fragment>
	);
};

Fields.propTypes = {
	submitting: PropTypes.bool.isRequired
};

export default Fields;
