import * as types from 'store/actions/types';
import produce from 'immer';

// Import helpers
import { PROVISIONING_STATUS } from 'components/helpers/variables';
import { translateSubStatuses } from 'components/helpers/translation_helpers';
import {
	convertEntriesToValuePairs,
	convertPacketsToValuePairs
} from 'store/actions/helpers_actions/convert_helpers';

export const INITIAL_STATE = {
	videoFormats: {},
	productTypes: [],
	videoTypes: {},
	assetsCollectionTypes: {},
	counterTypes: {},
	ipRangeTypes: {},
	packetsTypes: {},
	parentalControlRating: {},
	paymentMethods: {},
	playModes: {},
	reminderTypes: {},
	sectionTypes: {},
	agreementTypes: {},
	platformTypes: [],
	paymentModels: [],
	castTypes: [],
	assetTypes: {},
	// status is hardcoded for now - more statuses will be added in future
	status: PROVISIONING_STATUS,
	VODSubStatus: [],
	subscriberSource: [],
	period_unit: [],
	main: [],
	provision: [],
	segmentsTypes: [],
	geoblockGroups: [],
	countriesWithCodes: {}
};

export default (state = INITIAL_STATE, action) =>
	produce(state, (draft) => {
		switch (action.type) {
			// ******************** VIDEO FORMATS ********************
			case types.DICTIONARY_VIDEO_FORMATS:
				draft.videoFormats = Object.values(action.payload);
				break;

			// ******************** PRODUCT TYPES ********************
			case types.DICTIONARY_PRODUCT_TYPES:
				draft.productTypes = Object.values(action.payload);
				break;

			// ******************** VIDEO TYPES ********************
			case types.DICTIONARY_VIDEO_TYPES:
				draft.videoTypes = Object.values(action.payload);
				break;

			// ******************** ASSETS COLLECTION TYPES ********************
			case types.DICTIONARY_ASSETS_COLLECTION_TYPES:
				draft.assetsCollectionTypes = action.payload;
				break;

			// ******************** COUNTER TYPES ********************
			case types.DICTIONARY_COUNTER_TYPES:
				draft.counterTypes = Object.values(action.payload);
				break;

			// ******************** IP RANGE TYPES ********************
			case types.DICTIONARY_IP_RANGE_TYPES:
				draft.ipRangeTypes = Object.values(action.payload);
				break;

			// ******************** PACKETS TYPES ********************
			case types.DICTIONARY_PACKETS_TYPES:
				draft.packetsTypes = Object.values(action.payload);
				break;

			// ******************** PARENTAL CONTROL RATING ********************
			case types.DICTIONARY_PARTENTAL_CONTROL_RATING:
				draft.parentalControlRating = Object.values(action.payload);
				break;

			// ******************** PAYMENT METHODS ********************
			case types.DICTIONARY_PAYMENT_METHODS:
				draft.paymentMethods = Object.values(action.payload);
				break;

			// ******************** PLAY MODES ********************
			case types.DICTIONARY_PLAY_MODES:
				draft.playModes = Object.values(action.payload);
				break;

			// ******************** REMINDER TYPES ********************
			case types.DICTIONARY_REMINDER_TYPES:
				draft.reminderTypes = Object.values(action.payload);
				break;

			// ******************** SECTION TYPES ********************
			case types.DICTIONARY_SECTION_TYPES:
				draft.sectionTypes = Object.values(action.payload);
				break;

			// ******************** AGREEMENTS TYPES ********************
			case types.DICTIONARY_AGREEMENTS_TYPES:
				draft.agreementTypes = Object.values(action.payload);
				break;

			// ******************** PLATFORMS TYPES ********************
			case types.DICTIONARY_PLATFORMS_TYPES:
				draft.platformTypes = Object.values(action.payload);
				break;

			// ******************** PAYMENT MODELS ********************
			case types.DICTIONARY_PAYMENT_MODELS:
				draft.paymentModels = Object.values(action.payload);
				break;

			// ******************** CAST TYPES ********************
			case types.DICTIONARY_CAST_TYPES:
				draft.castTypes = Object.values(action.payload);
				break;

			// ******************** ASSET TYPES ********************
			case types.DICTIONARY_ASSET_TYPES:
				draft.assetTypes = action.payload;
				break;

			// ******************** VOD SUBSTATUS ********************
			case types.DICTIONARY_VOD_SUBSTATUS:
				draft.VODSubStatus = translateSubStatuses(action.payload);
				break;

			// ******************** SUBSCRIBER SOURCE ********************
			case types.DICTIONARY_SUBSCRIBER_SOURCE:
				draft.subscriberSource = Object.entries(action.payload);
				break;

			// ******************** PERIOD UNIT ********************
			case types.DICTIONARY_PERIOD_UNIT:
				draft.period_unit = convertEntriesToValuePairs(action.payload);
				break;

			// ******************** MAIN ********************
			case types.DICTIONARY_MAIN:
				draft.main = convertPacketsToValuePairs(action.payload);
				break;

			// ******************** PROVISION ********************
			case types.DICTIONARY_PROVISION:
				draft.provision = convertPacketsToValuePairs(action.payload);
				break;

			// ******************** SEGMENTS TYPES ********************
			case types.DICTIONARY_SEGMENTS_TYPES:
				draft.segmentsTypes = convertEntriesToValuePairs(action.payload);
				break;

			// ******************** GEOBLOCK_GROUPS SOURCE ********************
			case types.DICTIONARY_GEOBLOCK_GROUPS_LIST:
				draft.geoblockGroups = action.payload;
				break;

			// ******************** COUNTRIES WITH CODES ********************
			case types.DICTIONARY_COUNTRIES_WITH_CODES:
				draft.countriesWithCodes = action.payload;
				break;

			default:
				return state;
		}
	});
