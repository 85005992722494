import React from 'react';
import { func, string, array } from 'prop-types';
import { useTranslation } from 'react-i18next';
import { Radio } from 'antd';

const SectionsPlatformsFilters = ({
	onChangePlatformType,
	currentPlatformType,
	platformTypes
}) => {
	const { t } = useTranslation();

	return (
		<div className="m-r-md">
			<p>{t('sections:radio_buttons.platform_type')}</p>
			<Radio.Group
				className="m-b-md"
				onChange={onChangePlatformType}
				value={currentPlatformType}
			>
				{platformTypes.map((platform) => (
					<Radio value={platform} key={platform}>
						{platform}
					</Radio>
				))}
			</Radio.Group>
		</div>
	);
};

SectionsPlatformsFilters.propTypes = {
	onChangePlatformType: func.isRequired,
	currentPlatformType: string.isRequired,
	platformTypes: array.isRequired
};

export default SectionsPlatformsFilters;
