import React, { useContext } from 'react';
import { sortableContainer } from 'react-sortable-hoc';

// Import context
import { ProductsContext } from '../ProductsProvider';

// Import components
import SortableItem from './SortableItem';

const SortableCardsProduct = () => {
	const {
		selectedData,
		handleSortEnd,
		handleRemoveItem,
		productsTitle
	} = useContext(ProductsContext);

	const sortStart = () => {
		const sortContainer = document.querySelectorAll('.sortable-list');
		sortContainer.forEach((item) => {
			item.classList.add('drag');
		});
	};

	const SortableContainer = sortableContainer(({ handleRemoveItem }) => (
		<ul className="sortable-list">
			{selectedData.map((item, index) => (
				<React.Fragment key={item.uuid}>
					{item.type !== 'provider' && (
						<SortableItem
							index={index}
							value={item.title}
							type={item.type}
							img={item.images}
							handleRemoveItem={handleRemoveItem}
							id={item.uuid}
						/>
					)}
				</React.Fragment>
			))}
		</ul>
	));

	return (
		<>
			{productsTitle && selectedData.length > 0 && <h5>{productsTitle}</h5>}
			<SortableContainer
				onSortEnd={handleSortEnd}
				onSortStart={sortStart}
				axis="xy"
				distance={3}
				helperClass="sortable-list__li"
				handleRemoveItem={handleRemoveItem}
			/>
		</>
	);
};
export default SortableCardsProduct;
