import { SECTION_TYPES } from 'components/helpers/variables';

const { MAIN, PROVISIONING } = SECTION_TYPES;

export const getPacketSubtypePath = (subtype) => {
	switch (subtype) {
		case 'provision':
			return PROVISIONING;

		default:
			return MAIN;
	}
};
