// state update type
const UPDATE_STATE = 'UPDATE_STATE';

// mass changes providers initial state
export const initialState = {
	selectedType: '',
	apiUrl: '',
	selectedItemData: {},
	selectedItem: '',
	fetching: false,
	data: [],
	error: false,
	lastFetchId: 0,
	api: '',
	submitApiSlug: '',
	fetchProductsApiSlug: '',
	idSelector: 'uuid',
	titleSelector: 'title',
	selection: []
};

// updateState action
export const updateState = (payload) => (dispatch) => {
	dispatch({ type: UPDATE_STATE, payload });
};

// mass changes providers reducer
export const massChangesReducer = (state, action) => {
	switch (action.type) {
		case UPDATE_STATE:
			return { ...state, ...action.payload };
		default:
			return state;
	}
};
