import React from 'react';
import PropTypes from 'prop-types';
import { Field } from 'react-final-form';
import Select from 'antd/es/select';

// Import fields
import { BASE_FIELDS } from './input_fields';

// Import components
import {
	InputLabel,
	DatePickerLabel,
	TextareaLabel,
	MultiSelectLabel,
	CheckboxLabel
} from 'components/common/inputs';

const { Option } = Select;

const Fields = ({
	actors,
	writers,
	directors,
	submitting,
	genres,
	status,
	subtype,
	providersNames,
	providersId,
	paymentsModelsList,
	checkedPermissions,
	platformsList
}) => {
	const selectResources = {
		actors,
		writers,
		directors,
		genres,
		provider: providersNames,
		provider_id: providersId,
		payment_models: paymentsModelsList,
		platforms: platformsList
	};

	const renderFields = (item, index) => {
		// some fields have isVisible flag. If it's undefined, create and set flag to true
		if (!item.hasOwnProperty('isVisible')) {
			item.isVisible = true;
		}
		switch (item.type) {
			case 'textarea':
				return (
					item.isVisible && (
						<Field
							key={`${item.name}-${index}`}
							disabled={submitting}
							name={item.name}
							type="textarea"
							placeholder={item.label}
							label={item.label}
							id={item.name}
							required={item.required}
							component={TextareaLabel}
						/>
					)
				);

			case 'date':
				return (
					item.isVisible && (
						<Field
							key={`${item.name}-${index}`}
							disabled={submitting}
							name={item.name}
							type="number"
							placeholder={item.label}
							label={item.label}
							id={item.name}
							required={item.required}
							component={DatePickerLabel}
						/>
					)
				);

			case 'select':
				return (
					item.isVisible && (
						<Field
							key={`${item.name}-${index}`}
							disabled={submitting || item.disabled}
							name={item.name}
							type="select"
							mode={item.mode}
							placeholder={item.label}
							label={item.label}
							id={item.name}
							required={item.required}
							component={MultiSelectLabel}
						>
							{selectResources[item.name]?.map((item, index) => (
								<Option key={index} value={item}>
									{item}
								</Option>
							))}
						</Field>
					)
				);

			case 'checkbox':
				return (
					item.isVisible && (
						<Field
							key={`${item.name}-${index}`}
							disabled={submitting || item.disabled}
							name={item.name}
							type="checkbox"
							placeholder={item.label}
							label={item.label}
							id={item.name}
							required={item.required}
							component={CheckboxLabel}
						/>
					)
				);

			default:
				return (
					item.isVisible && (
						<Field
							key={`${item.name}-${index}`}
							disabled={submitting}
							name={item.name}
							type={item.type}
							placeholder={item.label}
							label={item.label}
							id={item.name}
							required={item.required}
							component={InputLabel}
						/>
					)
				);
		}
	};

	return (
		<React.Fragment>
			<div className="hr-line-solid">&nbsp;</div>
			{BASE_FIELDS({ status, subtype, checkedPermissions }).map(renderFields)}
		</React.Fragment>
	);
};

Fields.propTypes = {
	actors: PropTypes.array,
	writers: PropTypes.array,
	directors: PropTypes.array,
	genres: PropTypes.array,
	submitting: PropTypes.bool.isRequired,
	status: PropTypes.string,
	subtype: PropTypes.string,
	providersNames: PropTypes.array,
	providersId: PropTypes.array,
	paymentsModelsList: PropTypes.array,
	checkedPermissions: PropTypes.object.isRequired,
	platformsList: PropTypes.array.isRequired
};

export default Fields;
