import i18n from 'i18next';

export const DOCUMENTS_INPUT_FIELDS_DATA = {
	submitUrl: 'documents',
	fetchUrl: 'documents',
	cancelButtonPath: 'documents',
	hasMetadata: false,
	defautlFieldValues: {
		title: '',
		content: ''
	},
	inputFields: [
		{
			name: 'title',
			label: i18n.t('documents:fields.title_label'),
			type: 'text',
			required: true
		},
		{
			name: 'content',
			label: i18n.t('vod:form:fields.title_org'),
			type: 'editor',
			required: false
		}
	]
};
