import React from 'react';
import i18n from 'i18next';

// Import components
import PromotionsForm from '../../promotions_form/PromotionsForm';
import PromotionsUsersList from '../../promotions_users_list/PromotionsUsersList';
import PromotionsPacketsList from '../../promotions_packets_list/PromotionsPacketsList';
import PromotionsProductsList from '../../promotions_products/promotion_products_list/PromotionsProductsList';
import PromotionsProductsForm from '../../promotions_products/promotions_products_form/PromotionsProductsForm';
import PromotionProductsWrapper from '../../promotions_products/PromotionProductsWrapper';
import PromotionsProductsPreview from '../../promotions_products/promotions_products_preview/PromotionsProductsPreview';

// Import helpers
import { handleTabVisiblity } from './helpers';
import {
	PROMOTION_PRODUCTS_IN,
	PROMOTION_PRODUCTS_OUT
} from 'components/helpers/variables';

const renderPromotionProducts = (productTypeVariable) => (
	<PromotionProductsWrapper productType={productTypeVariable}>
		{(
			{
				childActions: { fetchListAction, fetchFormAction },
				childStore: { productListStore, productFormStore }
			},
			productType
		) => (
			<>
				<PromotionsProductsForm
					fetchFormAction={fetchFormAction}
					productFormStore={productFormStore}
					productType={productType}
				/>
				<PromotionsProductsList
					fetchListAction={fetchListAction}
					productListStore={productListStore}
					productType={productType}
				/>
			</>
		)}
	</PromotionProductsWrapper>
);

export const promotionsTabs = (promotionAvailableTabs, isCreateTab) => {
	const promotionsTabsList = [
		{
			name: i18n.t('common:tabs.metadata'),
			path: 'metadata',
			disableOnCreate: false,
			component: <PromotionsForm />
		}
	];
	const promotionsEditTabs = [
		{
			name: i18n.t('promotions:tabs.promotions_users'),
			path: 'promotions_users',
			disableOnCreate: true,
			isDisabled: handleTabVisiblity(
				promotionAvailableTabs,
				'promotions_users'
			),
			component: <PromotionsUsersList />
		},
		{
			name: i18n.t('promotions:tabs.promotions_packets'),
			path: 'promotions_packets',
			disableOnCreate: true,
			isDisabled: handleTabVisiblity(
				promotionAvailableTabs,
				'promotions_packets'
			),
			component: <PromotionsPacketsList />
		},

		{
			name: i18n.t('promotions:tabs.promotions_products_out'),
			path: 'promotions_products_out',
			disableOnCreate: true,
			isDisabled: handleTabVisiblity(
				promotionAvailableTabs,
				'promotions_products_out'
			),
			component: renderPromotionProducts(PROMOTION_PRODUCTS_OUT)
		},
		{
			name: i18n.t('promotions:tabs.promotions_products_out_preview'),
			path: 'promotions_products_out_preview',
			disableOnCreate: true,
			isDisabled: handleTabVisiblity(
				promotionAvailableTabs,
				'promotions_products_out_preview'
			),
			component: (
				<PromotionsProductsPreview productType={PROMOTION_PRODUCTS_OUT} />
			)
		},
		{
			name: i18n.t('promotions:tabs.promotions_products_in'),
			path: 'promotions_products_in',
			disableOnCreate: true,
			isDisabled: handleTabVisiblity(
				promotionAvailableTabs,
				'promotions_products_in'
			),
			component: renderPromotionProducts(PROMOTION_PRODUCTS_IN)
		},
		{
			name: i18n.t('promotions:tabs.promotions_products_in_preview'),
			path: 'promotions_products_in_preview',
			disableOnCreate: true,
			isDisabled: handleTabVisiblity(
				promotionAvailableTabs,
				'promotions_products_in_preview'
			),
			component: (
				<PromotionsProductsPreview productType={PROMOTION_PRODUCTS_IN} />
			)
		}
	];

	if (!isCreateTab) {
		return [...promotionsTabsList, ...promotionsEditTabs];
	}

	return promotionsTabsList;
};
