import React from 'react';
import PropTypes from 'prop-types';
import { useHistory, useLocation, useParams } from 'react-router-dom';

// Import helpers
import { replaceStringChar } from 'components/helpers/convert';
import { EDIT_SEGMENT_PATH } from './helpers';

const EditCell = ({ videoId, segmentId }) => {
	const history = useHistory();
	const { pathname } = useLocation();
	const { id: vodId } = useParams();

	const handleButtonClick = () =>
		history.push({
			pathname: replaceStringChar(EDIT_SEGMENT_PATH, {
				':vodId': vodId,
				':videoId': videoId,
				':segmentId': segmentId
			}),
			state: { fromPath: pathname }
		});

	return (
		<button onClick={handleButtonClick} className="btn btn-xs btn-primary">
			<i className="fa fa-edit" />
		</button>
	);
};

EditCell.propTypes = {
	videoId: PropTypes.number.isRequired,
	segmentId: PropTypes.number.isRequired
};

export default EditCell;
