import React from 'react';
import UploadMaterialsTable from './table/UploadMaterialsTable';

// Import components
import Upload from './upload/Upload';

const VodUpload = () => {
	return (
		<>
			<Upload />
			<UploadMaterialsTable />
		</>
	);
};

export default VodUpload;
