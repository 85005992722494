import services from 'services/services';
import * as types from '../types';

// Import helpers
import { checkFetchErrors } from '../helpers_actions/convert_helpers';

// ******************** FETCH EVENT_LIVE MATERIALS ********************
export const fetchEventLiveMaterials = (uuid) => async (dispatch, getState) => {
	try {
		// Dispatch a loading action
		dispatch({
			type: types.FETCH_EVENT_LIVE_MATERIALS_LOADING,
			payload: true
		});

		const response = await services.get(
			`/player/${uuid}/list?with_assets=true`
		);

		// Dispatch an action with data
		dispatch({
			type: types.FETCH_EVENT_LIVE_MATERIALS_SUCCESS,
			payload: response.data
		});
	} catch (error) {
		dispatch({
			type: types.FETCH_EVENT_LIVE_MATERIALS_ERROR,
			payload: checkFetchErrors(error)
		});
	}
};
// ******************** UPDATE / DELETE EVENT_LIVE MATERIALS ********************
export const updateEventLiveMaterials = (data, type, productId) => ({
	type: types.UPDATE_EVENT_LIVE_MATERIALS,
	payload: { data, type, productId }
});

export const updateEventLiveMaterialsError = (error) => ({
	type: types.UPDATE_EVENT_LIVE_MATERIALS_ERROR,
	payload: error
});

export const deleteEventLiveMaterials = (id, type) => ({
	type: types.DELETE_EVENT_LIVE_MATERIALS,
	payload: { id, type }
});

export const deleteEventLiveMaterialsError = (error) => ({
	type: types.DELETE_EVENT_LIVE_MATERIALS_ERROR,
	payload: error
});

// ******************** ADD VIDEO TO EVENT_LIVE ********************
export const addVideoToEventLive = (uuid) => async (dispatch) => {
	try {
		// Dispatch a loading action
		dispatch({
			type: types.ADD_VIDEO_TO_EVENT_LIVE_LOADING,
			payload: true
		});

		const resources = {
			product_uuid: uuid,
			product_type: 'live_event',
			video_type: 'main'
		};

		const response = await services.post(`/player/video/create`, resources);

		// add empty asset array to response data
		response.data.assets = [];

		// Dispatch an action with data
		dispatch({
			type: types.ADD_VIDEO_TO_EVENT_LIVE_SUCCESS,
			payload: { data: response.data }
		});
	} catch (error) {
		dispatch({
			type: types.ADD_VIDEO_TO_EVENT_LIVE_ERROR,
			payload: checkFetchErrors(error)
		});
	}
};
