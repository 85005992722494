import i18n from 'i18next';

// Import helpers text
import { label_info, type_info, algorithm_info } from './helpers';

export const defaultFieldValues = {
	active: false,
	live_epg: false,
	shuffle: false,
	show_free_products_first: false,
	main_limit: 0,
	config: {
		shuffle: false,
		live_epg: false,
		main_limit: 0,
		show_free_products_first: false
	}
};

export const BASE_FIELDS = (checkedPermissions) => [
	{
		name: 'name',
		label: i18n.t('sections:fields.name'),
		type: 'text',
		isEdit: true
	},
	{
		name: 'label',
		label: i18n.t('sections:fields.label'),
		type: 'text',
		required: true,
		isEdit: true,
		info: label_info
	},
	{
		name: 'active',
		label: i18n.t('sections:fields.active'),
		type: 'checkbox',
		required: false,
		disabled: !checkedPermissions.canActivate
	},
	{
		name: 'algorithm_id',
		label: i18n.t('sections:fields.algorithm_id.title'),
		type: 'select',
		required: true
	},
	{
		name: 'config.main_limit',
		label: i18n.t('sections:fields.main_limit'),
		type: 'number',
		required: true
	},
	{
		name: 'type',
		label: i18n.t('sections:fields.type'),
		type: 'select',
		required: true,
		isEdit: true,
		info: type_info
	},
	{
		name: 'algorithm_config',
		label: i18n.t('sections:fields.algorithm_config'),
		type: 'textarea',
		required: false,
		info: algorithm_info,
		modalWidth: 700
	},
	{
		name: 'config.live_epg',
		label: i18n.t('sections:fields.live_epg'),
		type: 'checkbox',
		required: false
	},
	{
		name: 'config.shuffle',
		label: i18n.t('sections:fields.shuffle'),
		type: 'checkbox',
		required: false
	},
	{
		name: 'config.show_free_products_first',
		label: i18n.t('sections:fields.show_free_products_first'),
		type: 'checkbox',
		required: false
	}
];
