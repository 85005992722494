import moment from 'moment';
import { DATE_FORMATS } from './variables';

// convert Date to  yyyy-mm-dd format
export const convertDateFormat = (date) =>
	`${date.getFullYear()}-${date.getMonth() + 1}-${date.getDate()}`;

// Convert names to ids
export const convertNameToIDs = (resources, values) =>
	values.map((item) => resources.find((resource) => resource.name === item).id);

// Capitalize letter
export const capitalizeText = (text) =>
	text.charAt(0).toUpperCase() + text.slice(1).replace('_', ' ');

// Convert files
export const getBase64 = (file) =>
	new Promise((resolve, reject) => {
		const reader = new FileReader();
		reader.readAsDataURL(file);
		reader.onload = () => resolve(reader.result);
		reader.onerror = (error) => reject(error);
	});

// Convert from grosz to zloty
export const convertPrice = (value) => {
	return value ? (parseFloat(value) * 0.01).toFixed(2) : 0;
};

// Convert from zloty to grosz
export const convertPriceToCents = (value) => {
	// parseInt is used because we need to send a number to API not a string
	return value ? parseInt((parseFloat(value) * 100).toFixed(0), 10) : 0;
};

// get table type based on url. Returns string with type
export const getTableType = (pathname) => {
	const pathnameArray = pathname.split('/');
	const tableType = pathnameArray[pathnameArray.length - 1];
	return tableType;
};

// get location type based on url. Returns string with type
export const getLocationType = (pathname) => {
	const pathnameArray = pathname.split('/');
	const locationType = pathnameArray[2];
	return locationType;
};

// get location subtype based on url. Returns string with type
export const getLocationSubtype = (pathname) => {
	const pathnameArray = pathname.split('/');
	const locationType = pathnameArray[3];
	return locationType;
};

// check if value is an int
export const checkIsInt = (value) => Number.isInteger(Number(value));

// convert external_ids to table
export const convertPacketExternalIds = (value) => [`${value}`];

export const replaceStringChar = (string = '', values = {}) => {
	let newValue = string;

	Object.entries(values).forEach(([key, value]) => {
		newValue = newValue.replace(key, value);
	});

	return newValue;
};

export const convertUTCDateToLocalDate = (date) =>
	moment
		.utc(date)
		.local()
		.format(DATE_FORMATS.LOCALE_DATE_FORMAT);
