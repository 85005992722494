import React from 'react';
import PropTypes from 'prop-types';

// Import fields
import { BASE_FIELDS } from './input_fields';

// Import helpers
import { renderFields } from 'components/utilities/form/renderFields';

// Import variables
import { NEXT_EPISODE } from 'components/helpers/variables';

const Fields = ({ submitting, selectResources, type }) => {
	const isEndTimeInSecondsVisible = type !== NEXT_EPISODE;

	return (
		<>
			{BASE_FIELDS({ isEndTimeInSecondsVisible }).map((item, index) =>
				renderFields({ item, index, submitting, selectResources })
			)}
		</>
	);
};

Fields.propTypes = {
	submitting: PropTypes.bool.isRequired,
	selectResources: PropTypes.object.isRequired,
	type: PropTypes.string.isRequired
};

export default Fields;
