// Import translation
import i18n from 'i18next';

export default ({ title, summary_short, rating, data }) => {
	const errors = { data: {} };

	const duration = data?.duration;
	const release_year = data?.release_year;

	// ------------------ Title ------------------
	if (!title) {
		errors.title = i18n.t('events_live:validation.title');
	}
	// ------------------ Summary short ------------------
	if (!summary_short) {
		errors.summary_short = i18n.t('vod:form:validation.summary_short');
	}
	// ------------------ Rating ------------------
	// rating can be 0
	if (rating === '' || rating === undefined || rating === null) {
		errors.rating = i18n.t('events_live:validation.rating');
	}
	// ------------------ Duration ------------------
	if (duration === '' || duration === undefined || duration === null) {
		errors.data.duration = i18n.t('events_live:validation.duration');
	}
	// ------------------ Release year ------------------
	if (
		release_year === '' ||
		release_year === undefined ||
		release_year === null
	) {
		errors.data.release_year = i18n.t('events_live:validation.release_year');
	}

	return errors;
};
