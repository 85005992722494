import * as types from 'store/actions/types';
import produce from 'immer';

import { STATUS_TYPES } from 'components/helpers/variables';

const { loading, success, error } = STATUS_TYPES;

const INITIAL_STATE = {
	get_url: {
		status: loading,
		error: null,
		assetId: null,
		uploadUrl: null
	},
	uploaded_materials_table: {
		status: loading,
		error: null,
		data: []
	}
};

export default (state = INITIAL_STATE, action) =>
	produce(state, (draft) => {
		switch (action.type) {
			// ****************** CLEAR DATA ******************
			case types.CLEAR_STATE:
				return { ...INITIAL_STATE };

			case types.CLEAR_UPLOAD_MATERIALS_TABLE:
				draft.uploaded_materials_table = INITIAL_STATE.uploaded_materials_table;
				break;

			// ****************** UPLOAD_MATERIALS_URL ******************
			case types.GET_UPLOAD_MATERIALS_URL_LOADING:
				draft.get_url.status = loading;
				draft.get_url.error = null;
				break;

			case types.GET_UPLOAD_MATERIALS_URL_SUCCESS:
				draft.get_url.status = success;
				draft.get_url.assetId = action.payload.asset_id;
				draft.get_url.uploadUrl = action.payload.upload_url;
				break;

			case types.GET_UPLOAD_MATERIALS_URL_ERROR:
				draft.get_url.status = error;
				draft.get_url.error = action.payload;
				break;

			// ****************** GET UPLOADED_MATERIALS ******************
			case types.GET_UPLOADED_MATERIALS_LOADING:
				draft.uploaded_materials_table.loading = loading;
				draft.uploaded_materials_table.error = null;
				break;

			case types.GET_UPLOADED_MATERIALS_SUCCESS:
				draft.uploaded_materials_table.status = success;
				draft.uploaded_materials_table.data = action.payload;
				break;

			case types.GET_UPLOADED_MATERIALS_ERROR:
				draft.uploaded_materials_table.status = error;
				draft.uploaded_materials_table.error = action.payload;
				break;

			default:
				return state;
		}
	});
