import services from 'services/services';
import * as types from '../types';

// Import helpers
import {
	checkFetchErrors,
	queryParams
} from '../helpers_actions/convert_helpers';

// Import variables
import { TABLE_LENGTH } from 'components/helpers/table';

// ******************** FETCH MAINS PRICES ********************
export const fetchMainPrices = (options, itemId) => async (
	dispatch,
	getState
) => {
	try {
		// Dispatch a loading action
		dispatch({
			type: types.FETCH_MAIN_PRICES_LOADING,
			payload: true
		});

		const {
			packets: {
				prices: {
					columns,
					options: { startIndex: startTableIndex, page }
				}
			}
		} = getState();

		const startIndex =
			options.page === page ? startTableIndex : options.startIndex;

		const { data } = await services.get(
			`/timetables/prices/listByProduct/packet/${itemId}?${queryParams(
				options,
				TABLE_LENGTH,
				columns,
				startIndex
			)}`
		);

		// Calculate pages
		const pages = Math.ceil(data.recordsFiltered / TABLE_LENGTH);

		// // Dispatch an action with data
		dispatch({
			type: types.FETCH_MAIN_PRICES_SUCCESS,
			payload: { ...data, pages, page: options.page, startIndex }
		});
	} catch (error) {
		dispatch({
			type: types.FETCH_MAIN_PRICES_ERROR,
			payload: checkFetchErrors(error)
		});
	}
};

// ******************** DELETE MAIN PRICE ********************
export const deleteMainPrice = (id) => async (dispatch) => {
	try {
		// Dispatch a loading action
		dispatch({
			type: types.DELETE_MAIN_PRICE_LOADING,
			payload: true
		});

		await services.delete(`/timetables/prices/${id}`);
		// Dispatch an action with data
		dispatch({
			type: types.DELETE_MAIN_PRICE_SUCCESS,
			payload: true
		});
	} catch (error) {
		// Dispatch an action with error set to true
		dispatch({
			type: types.DELETE_MAIN_PRICE_ERROR,
			payload: checkFetchErrors(error)
		});
	}
};
