import React from 'react';
import PropTypes from 'prop-types';
import { Button } from 'antd';
import { Link } from 'react-router-dom';

const ControlButtons = ({
	selection,
	redirectButtonTxt,
	clearButtonTxt,
	clearAllSelection
}) => {
	const isButtonDisabled = selection.length === 0;

	return (
		<div className="d-flex">
			<Link
				to={{
					pathname: '/panel/mass_changes/delete',
					state: { selection }
				}}
				className={`mr-4 btn__link ${isButtonDisabled &&
					'btn__link--disabled'}`}
			>
				{redirectButtonTxt}
			</Link>
			<Button
				className="btn-table-select"
				onClick={clearAllSelection}
				disabled={isButtonDisabled}
			>
				{clearButtonTxt}
			</Button>
		</div>
	);
};

ControlButtons.propTypes = {
	selection: PropTypes.array.isRequired,
	redirectButtonTxt: PropTypes.string.isRequired,
	clearButtonTxt: PropTypes.string.isRequired,
	clearAllSelection: PropTypes.func.isRequired
};

export default ControlButtons;
