import React from 'react';
import i18n from 'i18next';

// Import components
import DocumentsForm from '../../documents_form/DocumentsForm';

// Import utilities
import { generateContentTranslationsTabs } from 'components/utilities/content_translation/tabs/content_translations_tabs';

// import content translation fields
import { DOCUMENTS_INPUT_FIELDS_DATA } from 'components/utilities/content_translation/input_fields';

// Validate
import { documentsTranslationValidate } from 'components/utilities/content_translation/validation';

const documentsBasicTabs = [
	{
		name: i18n.t('common:tabs.metadata'),
		path: 'metadata',
		disableOnCreate: false,
		component: <DocumentsForm />
	}
];

export const documentsTabs = (contentTranslationLanguages) => [
	...documentsBasicTabs,
	...generateContentTranslationsTabs(
		DOCUMENTS_INPUT_FIELDS_DATA,
		contentTranslationLanguages,
		documentsTranslationValidate
	)
];
