import React from 'react';
import i18n from 'i18next';

// Import utilities
import { generateContentTranslationsTabs } from 'components/utilities/content_translation/tabs/content_translations_tabs';

// import content translation fields
import { CHANNELS_INPUT_FIELDS_DATA } from 'components/utilities/content_translation/input_fields';

// Validate
import { channelsTranslationValidate } from 'components/utilities/content_translation/validation';

// Import components
import ChannelsForm from 'components/views/channels/channels_form/ChannelsForm';
import ChannelsImages from 'components/views/channels/channels_file/ChannelsImages';
import Epg from 'components/views/channels/channels_tabs/epg/EPG';
import ChannelsMaterials from 'components/views/channels/channels_tabs/materials/ChannelsMaterials';
import ChannelsAvailability from 'components/views/channels/channels_availability/ChannelsAvailability';
import ProductGeoblockForm from 'components/views/geoblock_groups/ProductGeoblockForm/ProductGeoblockForm';
import ChannelsPlatformsForm from 'components/views/channels/channels_contexts/channels_platforms_form/ChannelsPlatformsForm';

const basicChannelTabs = [
	{
		name: i18n.t('common:tabs.metadata'),
		path: 'metadata',
		disableOnCreate: false,
		component: <ChannelsForm />
	},
	{
		name: i18n.t('channels:tabs.platforms_settings'),
		path: 'platforms_settings',
		disableOnCreate: true,
		component: <ChannelsPlatformsForm />
	},
	{
		name: i18n.t('common:tabs.epg'),
		path: 'epg',
		disableOnCreate: true,
		component: <Epg />
	},
	{
		name: i18n.t('common:tabs.pictures'),
		path: 'pictures',
		disableOnCreate: true,
		component: <ChannelsImages />
	},
	{
		name: i18n.t('common:tabs.materials'),
		path: 'materials',
		disableOnCreate: true,
		component: <ChannelsMaterials />
	},
	{
		name: i18n.t('common:tabs.availability'),
		path: 'availability',
		disableOnCreate: true,
		component: <ChannelsAvailability />
	},
	{
		name: i18n.t('common:tabs.geoblock'),
		path: 'geoblock',
		disableOnCreate: true,
		component: <ProductGeoblockForm />
	}
];

export const channelsTabs = (contentTranslationLanguages) => [
	...basicChannelTabs,
	...generateContentTranslationsTabs(
		CHANNELS_INPUT_FIELDS_DATA,
		contentTranslationLanguages,
		channelsTranslationValidate
	)
];

export const convertChannelMaterials = ({ main, catchup, preview }) => [
	{ videoType: 'main', materials: main },
	{ videoType: 'catchup', materials: catchup },
	{ videoType: 'preview', materials: preview }
];
