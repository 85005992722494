import React from 'react';
import i18n from 'i18next';

// Import components
import ProvisioningForm from '../../provisioning_form/ProvisioningForm';
// import ProvisioningImages from '../../provisioning_file/ProvisioningImages';
import ProvisioningProductsTable from '../../provisioning_products/ProvisioningProductsTable';

export const provisioningTabs = [
	{
		name: i18n.t('common:tabs.metadata'),
		path: 'metadata',
		disableOnCreate: false,
		component: <ProvisioningForm />
	},
	// {
	// 	name: i18n.t('common:tabs.pictures'),
	// 	path: 'pictures',
	// 	disableOnCreate: true,
	// 	component: <ProvisioningImages />
	// },
	{
		name: i18n.t('common:tabs.products'),
		path: 'provisioning_products',
		disableOnCreate: true,
		component: <ProvisioningProductsTable />
	}
];
