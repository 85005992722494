import React from 'react';
import { connect } from 'react-redux';
import { compose } from 'redux';
import {
	fetchChannelMaterials,
	updateChannelMaterials,
	updateChannelMaterialsError,
	deleteChannelMaterials,
	deleteChannelMaterialsError,
	addVideoToChannel
} from 'store/actions';
import { withRouter } from 'react-router-dom';
import PropTypes from 'prop-types';

// Import helpers
import { CHANNELS_MATERIALS_LIMIT } from 'components/helpers/variables';
import { convertChannelMaterials } from '../helpers';

// Import columns
import { createColumns } from 'components/utilities/table/materials_table/materials_columns';

// Import components
import MaterialsTable from 'components/utilities/table/materials_table/MaterialsTable';
import {
	Ibox,
	IboxTitle,
	IboxContentBase,
	Wraper
} from 'components/common/layout';
import { Empty } from 'antd';
import ChannelsAddMaterialsButton from 'components/common/buttons/add_materials_buttons/ChannelsAddMaterialsButton';

// Import translation
import { useTranslation } from 'react-i18next';

const ChannelsMaterials = ({
	fetchChannelMaterials,
	updateChannelMaterials,
	updateChannelMaterialsError,
	deleteChannelMaterials,
	deleteChannelMaterialsError,
	addVideoToChannel,
	error,
	isLoaded,
	main,
	preview,
	match,
	catchup
}) => {
	// fetch channel materials on mount
	const uuid = match.params.id;
	React.useEffect(() => {
		// Fetch resources
		fetchChannelMaterials(uuid);
		// eslint-disable-next-line
	}, []);

	const { t } = useTranslation();

	const materials = convertChannelMaterials({ main, catchup, preview });

	const hasMaterialsData = main.length || catchup.length || preview.length;

	return (
		<Wraper lg="12" error={error}>
			<Ibox>
				<ChannelsAddMaterialsButton
					isLoaded={isLoaded}
					isDisabled={main.length > 0}
					uuid={uuid}
					addVideoToChannel={addVideoToChannel}
					buttonText={t('channels:buttons.add_video')}
					videoType="main"
				/>
				<ChannelsAddMaterialsButton
					isLoaded={isLoaded}
					isDisabled={catchup.length > 0}
					uuid={uuid}
					addVideoToChannel={addVideoToChannel}
					buttonText={t('channels:buttons.add_catchup')}
					videoType="catchup"
				/>

				{materials.map(({ videoType, materials }) => (
					<React.Fragment key={videoType}>
						{materials.length ? (
							<>
								<IboxTitle
									title={t(`common:materials_table.${videoType}_title`)}
								/>
								<IboxContentBase isLoaded={isLoaded}>
									<div className="h-150">
										{isLoaded &&
											materials.map((resource) => (
												<MaterialsTable
													key={resource.id}
													columns={createColumns}
													resources={resource}
													updateMaterials={updateChannelMaterials}
													updateMaterialsError={updateChannelMaterialsError}
													deleteMaterials={deleteChannelMaterials}
													deleteMaterialsError={deleteChannelMaterialsError}
													materialsLimit={CHANNELS_MATERIALS_LIMIT}
												/>
											))}
									</div>
								</IboxContentBase>
							</>
						) : null}
					</React.Fragment>
				))}

				{!hasMaterialsData && (
					<Empty description={t('common:no_data')} className="m-t-md" />
				)}
			</Ibox>
		</Wraper>
	);
};

const mapStateToProps = ({ channels: { materials } }) => {
	return {
		error: materials.error,
		isLoaded: materials.isLoaded,
		main: materials.main.data,
		preview: materials.preview.data,
		catchup: materials.catchup.data
	};
};

ChannelsMaterials.propTypes = {
	fetchChannelMaterials: PropTypes.func.isRequired,
	updateChannelMaterials: PropTypes.func.isRequired,
	updateChannelMaterialsError: PropTypes.func.isRequired,
	deleteChannelMaterials: PropTypes.func.isRequired,
	deleteChannelMaterialsError: PropTypes.func.isRequired,
	addVideoToChannel: PropTypes.func.isRequired,
	error: PropTypes.bool.isRequired,
	isLoaded: PropTypes.bool.isRequired,
	main: PropTypes.array.isRequired,
	preview: PropTypes.array.isRequired,
	match: PropTypes.object.isRequired,
	catchup: PropTypes.array.isRequired
};

export default compose(
	connect(mapStateToProps, {
		fetchChannelMaterials,
		updateChannelMaterials,
		updateChannelMaterialsError,
		deleteChannelMaterials,
		deleteChannelMaterialsError,
		addVideoToChannel
	}),
	withRouter
)(ChannelsMaterials);
