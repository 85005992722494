import React, { useEffect } from 'react';
import PropTypes from 'prop-types';
import { useSelector, useDispatch } from 'react-redux';
import { withRouter } from 'react-router-dom';
import { dictionaryAssetsCollectionTypes } from 'store/actions/dictionary/dictionary';
// Import components
import { UploadImage } from 'components/common/inputs';
import { IboxContentTitle, Wraper } from 'components/common/layout';

// Import scss
import variables from 'scss/inspinia/inspinia_styles/_variables.scss';

/**
 * Functional generic component that displays image. Designed to use across app.
 * @param {string} title - Title of image card
 * @param {function} fetchResourcesAction - Action to fetch image resources
 * @param {string} collectionType - Image collection type (cover | poster | logo | logo_inverted ...)
 * @param {string} mediaType - Type of media (channel | vod | packet ...)
 * @param {string} storePathSelector - type to define store path to file (channels | cod | categories | packets | banners ...) - check Redux for all types
 * @param {bool} isDarkBackground - is image background supposed to be dark
 * @param {number} imagesLimit - how many images user can upload
 * @param {bool} isImagesLimit - is there limit of images that user can upload
 * @returns {*}
 */

const Image = ({
	title,
	fetchResourcesAction,
	collectionType,
	mediaType,
	storePathSelector,
	isDarkBackground = false,
	imagesLimit,
	isImagesLimit,
	customId = null,
	customFileType = null,
	match: {
		params: { id }
	},
	updateImageTranslations = null
}) => {
	// get id from url or set custom id
	const productId = customId ? customId : id;

	// some collections are named after model type not after collection type
	// i.e landingPageAndroid
	const storeFileSelector = customFileType ?? collectionType;
	const dispatch = useDispatch();

	useEffect(() => {
		// Dispatch  action in channels_files
		fetchResourcesAction(productId);
		// Set assets collection list
		dictionaryAssetsCollectionTypes()(dispatch);
		// eslint-disable-next-line
	}, []);

	const { data: files, isLoaded, error } = useSelector(
		(state) => state[storePathSelector].files[storeFileSelector]
	);

	const collectionMap = useSelector(
		(state) => state.dictionary.assetsCollectionTypes
	);

	//Find current collection id
	const collectionId = Object.keys(collectionMap).find(
		(key) => collectionMap[key] === collectionType
	);
	// Scss variables
	const { blueDark } = variables;

	const modalBodyStyle = isDarkBackground
		? { backgroundColor: blueDark, padding: '50px 40px 40px' }
		: { padding: '50px 40px 40px' };

	return (
		<Wraper lg="12" error={error}>
			<IboxContentTitle
				title={title}
				isLoaded={isLoaded}
				classNameContent={isDarkBackground ? 'dark-background-logo' : ''}
			>
				<div
					className={isDarkBackground ? 'h-150 dark-background-logo' : 'h-150'}
				>
					{isLoaded && (
						<UploadImage
							collection={collectionType}
							mediaType={mediaType}
							files={files}
							modelId={productId}
							modalBodyStyle={modalBodyStyle}
							imagesLimit={imagesLimit}
							isImagesLimit={isImagesLimit}
							updateImageTranslations={updateImageTranslations}
							collectionId={collectionId}
						/>
					)}
				</div>
			</IboxContentTitle>
		</Wraper>
	);
};

Image.propTypes = {
	title: PropTypes.string,
	fetchResourcesAction: PropTypes.func.isRequired,
	collectionType: PropTypes.string.isRequired,
	mediaType: PropTypes.string.isRequired,
	storePathSelector: PropTypes.string.isRequired,
	darkBackground: PropTypes.bool,
	imagesLimit: PropTypes.number,
	isImagesLimit: PropTypes.bool,
	customId: PropTypes.string,
	customFileType: PropTypes.string,
	match: PropTypes.shape({
		params: PropTypes.shape({ id: PropTypes.string })
	}),
	updateImageTranslations: PropTypes.func
};

export default withRouter(Image);
