import * as types from '../../types';

// Import variables
import { EVENT_LIVE } from 'components/helpers/variables';

export const setActionType = (productType) => {
	switch (productType) {
		case EVENT_LIVE:
			return {
				tableLoading: types.FETCH_EVENT_LIVE_VISIBILITY_TABLE_LOADING,
				tableSuccess: types.FETCH_EVENT_LIVE_VISIBILITY_TABLE_SUCCESS,
				tableError: types.FETCH_EVENT_LIVE_VISIBILITY_TABLE_ERROR,
				deleteLoading: types.DELETE_EVENT_LIVE_VISIBILITY_LOADING,
				deleteSuccess: types.DELETE_EVENT_LIVE_VISIBILITY_SUCCESS,
				deleteError: types.DELETE_EVENT_LIVE_VISIBILITY_ERROR,
				formLoading: types.FETCH_EVENT_LIVE_VISIBILITY_FORM_LOADING,
				formSuccess: types.FETCH_EVENT_LIVE_VISIBILITY_FORM_SUCCESS,
				formError: types.FETCH_EVENT_LIVE_VISIBILITY_FORM_ERROR
			};

		default:
			return {
				tableLoading: types.FETCH_EVENT_LIVE_VISIBILITY_TABLE_LOADING,
				tableSuccess: types.FETCH_EVENT_LIVE_VISIBILITY_TABLE_SUCCESS,
				tableError: types.FETCH_EVENT_LIVE_VISIBILITY_TABLE_ERROR,
				deleteLoading: types.DELETE_EVENT_LIVE_VISIBILITY_LOADING,
				deleteSuccess: types.DELETE_EVENT_LIVE_VISIBILITY_SUCCESS,
				deleteError: types.DELETE_EVENT_LIVE_VISIBILITY_ERROR,
				formLoading: types.FETCH_EVENT_LIVE_VISIBILITY_FORM_LOADING,
				formSuccess: types.FETCH_EVENT_LIVE_VISIBILITY_FORM_SUCCESS,
				formError: types.FETCH_EVENT_LIVE_VISIBILITY_FORM_ERROR
			};
	}
};
