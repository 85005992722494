import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { Editor } from '@tinymce/tinymce-react';

import { init_options } from './helpers';

class TinyTextEditor extends Component {
	constructor(props) {
		super(props);

		this.state = { content: '' };
	}

	componentDidUpdate(prevProps) {
		const { input } = this.props;
		if (prevProps.input.value !== input.value) {
			// Check if prev input value is not equal to current input value
			this.setState({ content: input.value });
		}
	}

	handleEditorChange = (content, editor) => {
		const { input } = this.props;
		// Update state
		this.setState({ content });
		// Update input value
		input.onChange(content);
	};

	render() {
		const {
			input,
			meta: { touched, error, submitError }
		} = this.props;

		return (
			<div>
				<Editor
					apiKey={process.env.REACT_APP_TINYMCE_KEY}
					value={this.state.content}
					init={init_options}
					onEditorChange={this.handleEditorChange}
				/>

				<input {...input} type="hidden" />

				{touched && (error || submitError) && (
					<span
						data-testid={`input-error-${input.name}`}
						className="input-error"
					>
						{error || submitError}
					</span>
				)}
			</div>
		);
	}
}

TinyTextEditor.propTypes = {
	input: PropTypes.object.isRequired,
	meta: PropTypes.object
};

export default TinyTextEditor;
