import React from 'react';
import PropTypes from 'prop-types';
import ReactTable from 'react-table';

// Import translation
import { withTranslation } from 'react-i18next';

// Import global helpers
import {
	createTableOptions,
	prepareDefaultSortedData
} from 'components/helpers/table';

// Import utilities
import { notificationHandler } from 'components/utilities/notifications/index';
import CustomPagination from 'components/utilities/table/pagination/CustomPagination';

class ReactServerSideTable extends React.Component {
	static propTypes = {
		fetchResourcesAction: PropTypes.func.isRequired,
		resourcesForFetchAction: PropTypes.any,
		resources: PropTypes.shape({
			data: PropTypes.array,
			deleteItem: PropTypes.object,
			options: PropTypes.shape({
				pages: PropTypes.number,
				filters: PropTypes.object
			}),
			loading: PropTypes.bool
		}).isRequired,
		notificationDeleteSuccessTxt: PropTypes.string,
		notificationDeleteErrorTxt: PropTypes.string,
		itemId: PropTypes.string,
		defaultSorted: PropTypes.array,
		tableType: PropTypes.string
	};

	static defaultProps = {
		selectItemColumns: [],
		notificationDeleteSuccessTxt: '',
		notificationDeleteErrorTxt: '',
		defaultSorted: [],
		resources: {
			deleteItem: {
				success: false,
				error: false
			}
		},
		itemId: null,
		resourcesForFetchAction: null,
		tableType: ''
	};

	state = {
		columns: [...this.props.columns],
		defSorted: prepareDefaultSortedData(
			this.props.defaultSorted,
			this.props.resources?.options?.sorted
		)
	};

	componentDidUpdate(prevProps) {
		const { deleteItem } = this.props.resources;
		const {
			resources: { deleteItem: prevPropsDeleteItem }
		} = prevProps;

		// Compare prevProps success to current prop success
		if (prevPropsDeleteItem.success === false && deleteItem.success === true) {
			this.fetchTableDataHandler();
		}
		// Compare prevProps error to current prop error
		if (prevPropsDeleteItem.error !== deleteItem.error) {
			this.deleteTableItemHandler();
		}
	}

	shouldComponentUpdate(nextProps) {
		if (
			JSON.stringify(nextProps.resources) ===
			JSON.stringify(this.props.resources)
		) {
			return false;
		} else {
			return true;
		}
	}

	fetchTableDataHandler = () => {
		// Fetch table resources
		this.refReactTable.fireFetchData();
		// Add success notification
		notificationHandler(
			this.props.t('messages:notifications.deleted'),
			this.props.notificationDeleteSuccessTxt,
			'success'
		);
	};
	deleteTableItemHandler = () =>
		// Add error notification
		notificationHandler(
			this.props.t('messages:notifications.error'),
			this.props.notificationDeleteErrorTxt,
			'error'
		);

	// Fetch table data
	handleOnFetchData = async (state) => {
		const {
			fetchResourcesAction,
			itemId,
			resourcesForFetchAction,
			tableType,
			resources: {
				options: { sorted: reduxSorted }
			}
		} = this.props;

		// Create options
		const options = createTableOptions(state, reduxSorted, tableType);

		// Fetch resources
		fetchResourcesAction(options, itemId, resourcesForFetchAction);
	};

	render() {
		const {
			columns,
			getTrProps,
			t,
			resources: {
				data,
				options: { pages, filters, page, total_results: totalResults },
				loading
			}
		} = this.props;

		// Define page size
		const pageSize = data.length > 0 ? null : 4;

		return (
			<ReactTable
				PaginationComponent={(props) => (
					<CustomPagination tableProps={props} totalResults={totalResults} />
				)}
				ref={(refReactTable) => (this.refReactTable = refReactTable)}
				manual
				data={data}
				columns={[...columns]}
				pages={pages}
				page={page}
				filtered={filters}
				showPageSizeOptions={false}
				pageSize={pageSize}
				loading={loading}
				defaultSorted={this.state.defSorted}
				noDataText={t('common:no_data')}
				previousText={t('common:table_buttons.prev')}
				nextText={t('common:table_buttons.next')}
				className="-striped"
				onFetchData={this.handleOnFetchData}
				getTrProps={getTrProps}
				showPagination={!!pages}
				showPaginationTop={!!pages}
			/>
		);
	}
}

export default withTranslation()(ReactServerSideTable);
