import React from 'react';
import i18n from 'i18next';

// Import tabs component
import Settings from 'components/views/settings/Settings';
import LandingPageImages from 'components/views/settings/settings_file/LandingPageImages';
import WatermarkSettings from 'components/views/settings/settings_file/WatermarkSettings';
import NoSubscriptionPageImages from 'components/views/settings/settings_file/NoSubscriptionPageImages';
import VodLogoSettings from 'components/views/settings/settings_file/VodLogoSettings';

export const settingsTabs = [
	{
		name: i18n.t('common:tabs.metadata'),
		path: 'metadata',
		disableOnCreate: false,
		component: <Settings />
	},
	{
		name: i18n.t('settings:tabs.landing_page_files'),
		path: 'landing_page_files',
		disableOnCreate: false,
		component: <LandingPageImages />
	},
	{
		name: i18n.t('settings:tabs.no_subscription_page_files'),
		path: 'no_subscription_page_files',
		disableOnCreate: false,
		component: <NoSubscriptionPageImages />
	},
	{
		name: i18n.t('settings:tabs.watermark'),
		path: 'watermark',
		disableOnCreate: false,
		component: <WatermarkSettings />
	},
	{
		name: i18n.t('settings:tabs.vodLogo'),
		path: 'vod_logo',
		disableOnCreate: false,
		component: <VodLogoSettings />
	}
];
