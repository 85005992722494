export const GET_SEGMENTS_API =
	'/player/products/:vodId/videos/:videoId/segments';

export const ADD_SEGMENT_API =
	'/player/products/:vodId/videos/:videoId/segments';

export const UPDATE_SEGMENT_API =
	'/player/products/:vodId/videos/:videoId/segments/:segmentId';

export const DELETE_SEGMENT_API =
	'/player/products/:vodId/videos/:videoId/segments/:segmentId';
