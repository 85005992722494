import { message } from 'antd';
// Import translation
import i18n from 'i18next';

// Import utilities
import { notificationHandler } from 'components/utilities/notifications/index';

import services from 'services/services';

export const addSubscriberPacket = async (values, id) => {
	const resources = { ...values, subscriber_id: id };

	try {
		await services.post('timetables/user/packet/free/attach', resources);

		notificationHandler(
			i18n.t('messages:notifications.created'),
			`${i18n.t('messages:notifications.successfuly_created')} ${i18n.t(
				'names:notification_names.packets'
			)} `
		);
	} catch (error) {
		message.error(i18n.t('common:errors.common_error_message'));
	}
};
