import { useState, useEffect } from 'react';
import { useDispatch } from 'react-redux';
import services from 'services/services';
import { clearUploadedMaterialsTable } from 'store/actions';

const useAssetStatus = (record, componentType) => {
	const [status, setStatus] = useState('');

	const dispatch = useDispatch();

	const isStatusComponent = componentType === 'statusComponent';

	const getAssetStatus = async (record) => {
		try {
			const url = `/ingester/asset/${record.id}`;
			const { data } = await services.get(url);
			setStatus(data.status);
		} catch (error) {
			return '';
		}
	};

	const handleClearTable = () => clearUploadedMaterialsTable()(dispatch);

	useEffect(() => {
		// start useEffect only when hook is loaded in status component not in buttons
		isStatusComponent && getAssetStatus(record);
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, []);

	return {
		status,
		getAssetStatus,
		clearUploadedMaterialsTable: handleClearTable
	};
};

export default useAssetStatus;
