import { useHistory, useParams } from 'react-router-dom';

// Import helpers
import { getContentLanguage, getTranslationsData, getValues } from './helpers';

// Import utilities
import { submitForm } from 'components/utilities/form/submitForm';

const useImageTranslations = () => {
	const history = useHistory();
	const { id } = useParams();

	const handleUpdateImageTranslations = async ({ collection, url = null }) => {
		const contentLanguage = getContentLanguage(collection);

		const translationsData = await getTranslationsData({ id, contentLanguage });

		if (translationsData) {
			const values = getValues({ collection, url });

			const resources = { ...translationsData, ...values };

			const translationSubmitUrl = `packet/translations/${contentLanguage}`;

			return await submitForm({
				history,
				isEdit: false,
				itemID: id,
				resources,
				api: translationSubmitUrl,
				notificationName: 'translations',
				apiSlug: 'update'
			});
		}
	};

	return { updateImageTranslations: handleUpdateImageTranslations };
};

export default useImageTranslations;
