import React from 'react';
import { connect } from 'react-redux';
import PropTypes from 'prop-types';
import { Form } from 'react-final-form';
import { Modal } from 'antd';
import { fetchEpisodes } from 'store/actions';

// Import utilities
import { submitModalForm } from 'components/utilities/form';

// Import components
import { ModalFormButtons } from 'components/common/buttons';
import Fields from './fields/Fields';

// Import helpers
import { fetchEpisodesOptions } from 'components/views/vod/vod_episodes/helpers';

const ModalForm = ({ title, visible, toggle, parentID, fetchEpisodes }) => {
	const handleOnSubmit = async (values) => {
		await submitModalForm({
			isEdit: false,
			itemID: parentID,
			resources: { ...values },
			api: 'vod/season',
			apiSlug: 'attachEpisodes',
			callbackAction: () => fetchEpisodes(fetchEpisodesOptions, parentID) //fetch updated episodes list for episodes table
		});
		toggle(); // close modal
	};

	return (
		<Modal
			title={title}
			visible={visible}
			onOk={toggle}
			onCancel={toggle}
			footer={false}
			destroyOnClose={true}
		>
			<Form
				onSubmit={handleOnSubmit}
				render={({ handleSubmit, submitting }) => (
					<form onSubmit={handleSubmit}>
						<Fields submitting={submitting} />
						<ModalFormButtons
							isButtonDisabled={submitting}
							modalCloseAction={toggle}
						/>
					</form>
				)}
			/>
		</Modal>
	);
};

ModalForm.propTypes = {
	title: PropTypes.string.isRequired,
	visible: PropTypes.bool.isRequired,
	toggle: PropTypes.func.isRequired,
	parentID: PropTypes.string.isRequired,
	fetchEpisodes: PropTypes.func.isRequired
};

export default connect(null, { fetchEpisodes })(ModalForm);
