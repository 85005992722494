import React from 'react';
import PropTypes from 'prop-types';

// Import translation
import { useTranslation } from 'react-i18next';

// Import fields
import { BASE_FIELDS } from './input_fields';

// Import utilities
import { renderFields } from 'components/utilities/form/renderFields';

const Fields = ({
	submitting,
	genres,
	casts,
	platforms,
	checkedPermissions
}) => {
	const { t } = useTranslation();

	const selectResources = {
		'data.casts': casts,
		platforms,
		genres
	};

	return (
		<>
			<h3>{t('common:form.base_info')}</h3>
			<div className="hr-line-solid">&nbsp;</div>

			{BASE_FIELDS(checkedPermissions).map((item, index) =>
				renderFields({ item, index, submitting, selectResources })
			)}
		</>
	);
};

Fields.propTypes = {
	submitting: PropTypes.bool.isRequired,
	checkedPermissions: PropTypes.object.isRequired,
	genres: PropTypes.array.isRequired,
	casts: PropTypes.array.isRequired,
	platforms: PropTypes.array.isRequired
};

export default Fields;
