export const select_types = [
	{
		name: 'hls'
	},
	{
		name: 'dash'
	},
	{
		name: 'ss'
	},
	{
		name: 'multicast'
	}
];

export const security_type = [
	{
		name: 'NONE',
		value: null
	},
	{
		name: 'DRM',
		value: 'DRM'
	},
	{
		name: 'AKAMAI',
		value: 'AKAMAI'
	}
];
