import i18n from 'i18next';

export const VISIBLE_FIELDS = [
	{
		name: 'visible_primary',
		label: i18n.t('provisioning:fields.visible_primary'),
		type: 'checkbox'
	},
	{
		name: 'visible_secondary',
		label: i18n.t('provisioning:fields.visible_secondary'),
		type: 'checkbox'
	}
];
