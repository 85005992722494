// Import translation
import i18n from 'i18next';

// Localstorage
export const LOCALSTORAGE_USER = 'blueonline';

// Ingester stat types (assets | encoding)
export const ASSETS = 'assets';
export const ENCODING = 'encoding';

// list of select elementd to filter VOD table with
export const VOD_SUBTYPES = () => [
	{ name: i18n.t('vod:select:subtypes.all'), value: 'all' },
	{ name: i18n.t('vod:select:subtypes.vod'), value: 'vod' },
	{ name: i18n.t('vod:select:subtypes.series'), value: 'series' },
	{ name: i18n.t('vod:select:subtypes.season'), value: 'season' },
	{ name: i18n.t('vod:select:subtypes.episode'), value: 'episode' }
];
export const VOD_STATUS = () => [
	{ name: i18n.t('vod:select:status.all'), value: 'all' },
	{ name: i18n.t('vod:select:status.new'), value: 'new' },
	{ name: i18n.t('vod:select:status.encoded'), value: 'encoded' },
	{ name: i18n.t('vod:select:status.complete'), value: 'complete' },
	{ name: i18n.t('vod:select:status.active'), value: 'active' },
	{ name: i18n.t('vod:select:status.expired'), value: 'expired' },
	{ name: i18n.t('vod:select:status.deleted'), value: 'deleted' }
];

// provisioning status
export const PROVISIONING_STATUS = [
	'new',
	'encoded',
	'complete',
	'active',
	'expired'
];

// attach episodes modal list length
export const EPISODES_MODAL_SEARCH_LENGTH = 100;

// add products (Product Select) vod list length
export const PRODUCT_SELECT_VOD_LIST_LENGTH = 100;

// products (Default Select) list length
export const PRODUCT_SELECT_DEFAULT_LIST_LENGTH = 30;

// Packets types options for select type field in provisioning, main and collections
export const select_packet_types = [
	{
		name: 'PROVISION',
		value: 'provision'
	},
	{
		name: 'COLLECTION',
		value: 'collection'
	},
	{
		name: 'MAIN',
		value: 'main'
	}
];

// List of table types for which export to csv option is disabled
// Names are based on url types
export const disabledCSVExportList = ['tracking', 'trackings'];

// Products limit in Section Preview
export const SECTION_PREVIEW_LIMIT = 100;

// Products limit in Products Preview
export const PROMOTIONS_PRODUCTS_PREVIEW_LIMIT = 100;

// Products limit in Packets Products List
export const PACKETS_PRODUCTS_LIST_LIMIT = 100;

// Promotion Products types
export const PROMOTION_PRODUCTS_IN = 'in';
export const PROMOTION_PRODUCTS_OUT = 'out';

// materials table limits
export const CHANNELS_MATERIALS_LIMIT = 4;
export const VOD_MATERIALS_LIMIT = 3;
export const EVENTS_LIVE_MATERIALS_LIMIT = 4;

// language local storage value
export const LOCAL_STORAGE_LANGUAGE = 'language';

// status types (mainly used for tracking request status in Redux)
export const STATUS_TYPES = {
	idle: 'idle',
	loading: 'loading',
	success: 'success',
	error: 'error',
	pending: 'pending'
};

// ------------ PLATFORM ------------
// platform types
export const PLATFORM_TYPES = {
	all: 'all',
	platformPrimary: 'platform_primary',
	platformSecondary: 'platform_secondary'
};
// ----------------------------------

// blackout variables for generating EPG table
export const BLACKOUT_TYPES = ['streaming', 'catchup', 'npvr'];
export const BLACKOUT_PLATFORMS = [
	{ name: 'BOX', value: 'primary' },
	{ name: 'Secondary Screens', value: 'secondary' }
];

// ----------------------------------

// ------------ PAYMENT ------------
export const PAYMENT_TYPES = {
	SUBSCRIPTION_APPSTORE: 'subscription_appstore',
	SUBSCRIPTION_GOOGLEPLAY: 'subscription_googleplay',
	SUBSCRIPTION_PAYPAL: 'subscription_paypal',
	// SUBSCRIPTION_CHECKOUT: 'subscription_checkout',
	SUBSCRIPTION_PAYTABS: 'subscription_paytabs'
};
// ----------------------------------

// ------------ SUBSCRIBERS ------------
export const SUBSCRIBER_SOURCE_TYPES = {
	PURE_OTT: 'pureott'
};
// ----------------------------------

// ------------ SECTION_TYPES ------------
export const SECTION_TYPES = {
	VOD: 'vod',
	CHANNEL: 'channel',
	BANNER: 'banner',
	COLLECTIONS: 'collections',
	SECTION_ASSIGNED: 'sections',
	PROVIDER: 'provider',
	PROVISIONING: 'provisioning',
	MAIN: 'main'
};
// ----------------------------------

// ------------ SUBSCRIBER TYPES ------------
export const SUBSCRIBER_TYPES = {
	ALL: 'all',
	ACTIVE: 'active',
	NEW: 'new',
	RECURRING: 'recurring',
	INACTIVE: 'inactive',
	CANCELLED: 'cancelled',
	UNABLE: 'unable'
};

// ----------- PLAYER SEGMENT TYPES ------------
export const NEXT_EPISODE = 'next_episode';

// Event types options for select type field in events_live
export const select_event_live_types = [
	{
		name: 'EVENT LIVE',
		value: 'live_event'
	}
];

// PRODUCT TYPES
export const EVENT_LIVE = 'live_event';
export const CHANNEL = 'channel';
export const VOD = 'vod';

// ------------ DATE FORMATS ------------
export const DATE_FORMATS = {
	LOCALE_DATE_FORMAT: 'YYYY-MM-DD HH:mm:ss'
};
