import React, { useContext } from 'react';
import PropTypes from 'prop-types';

// Import context
import { MaterialsContext } from './materials_context/MaterialsContext';

//  Import components
import { Modal, Button, Checkbox } from 'antd';
import message from 'antd/es/message';
import MaterialsForm from './MaterialsForm';

// Import translation
import { useTranslation } from 'react-i18next';

const MaterialsHeader = ({ resources, canEditVideo }) => {
	const {
		isVisible,
		toggleFormModal,
		toggleEdit,
		isEdit,
		isWatermarkChecked,
		toggleWatermark
	} = useContext(MaterialsContext);

	const { t } = useTranslation();

	const onCancel = () => {
		if (isEdit) {
			toggleEdit();
		}
		toggleFormModal();
		message.info(t('messages:messages.operation_canceled'));
	};

	const title = isEdit
		? t('names:materials.edit_product')
		: t('names:materials.add_product');

	return (
		<div className="d-flex align-items-center justify-content-between">
			<h5 style={{ fontSize: '14px', margin: 0 }}>
				{t('common:materials_table.video_title')}
			</h5>
			<div className="d-flex align-items-center ">
				<div style={{ marginRight: '20px' }}>
					<Checkbox
						name="watermark"
						checked={isWatermarkChecked}
						onChange={toggleWatermark}
					>
						{t('common:materials_table.watermark')}
					</Checkbox>
				</div>

				<Button
					className={'btn__link'}
					onClick={toggleFormModal}
					disabled={!canEditVideo}
				>
					<i className={`fa fa-plus`} />
				</Button>
			</div>
			<Modal
				title={title}
				visible={isVisible}
				footer={null}
				onCancel={onCancel}
				destroyOnClose={true}
				width={700}
			>
				<MaterialsForm resources={resources} />
			</Modal>
		</div>
	);
};

MaterialsHeader.propTypes = {
	resources: PropTypes.shape({
		assets: PropTypes.array.isRequired
	}).isRequired
};

export default MaterialsHeader;
