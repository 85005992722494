import React from 'react';
import PropTypes from 'prop-types';

// Import translation
import { useTranslation } from 'react-i18next';

// Import components
import { Row, Col, Input, Form, Button, FormGroup } from 'reactstrap';

const SearchColumn = ({
	setQueryValue,
	value,
	searchColumnValue,
	selectOptionColumns
}) => {
	const [inputValue, setInputValue] = React.useState(value);
	const [searchColumn, setSearchColumn] = React.useState(searchColumnValue);
	const { t } = useTranslation();

	// Update state with the new selected column
	const handleColumnChange = ({ target: { value } }) => setSearchColumn(value);

	// Update state with the new query
	const handleOnChange = ({ target: { value } }) => setInputValue(value);

	// Clear query
	const handleClearQuery = () => {
		// Clear state
		setInputValue('');
		// Dispatch an action to store
		setQueryValue({ query: '', search_column: searchColumn });
	};

	// Handle submit query
	const handleOnSubmit = (e) => {
		// Prevent default actions
		e.preventDefault();
		// Dispatch an action to store
		setQueryValue({ query: inputValue, search_column: searchColumn });
	};

	return (
		<div className="search m-b-xl">
			<Row>
				<Col sm="12" className="d-flex justify-content-end align-items-center">
					<Form inline onSubmit={handleOnSubmit}>
						<FormGroup className="mb-0 m-r-sm">
							<Input
								data-testid="input-columns"
								name="search_column"
								placeholder={t('common:search_box.search_column')}
								type="select"
								value={searchColumn}
								className="search__select"
								onChange={handleColumnChange}
							>
								{selectOptionColumns.map(({ header, name }, index) => (
									<option key={index} value={name}>
										{header}
									</option>
								))}
							</Input>
						</FormGroup>
						<FormGroup className="mb-0">
							<Input
								data-testid="input-search"
								name="query"
								placeholder={t('common:search_box.placeholder')}
								value={inputValue}
								className="search__input"
								onChange={handleOnChange}
							/>
							{inputValue && (
								<div className="search__clear">
									<Button onClick={handleClearQuery} className="btn__clear">
										<i className="fa fa-times" />
									</Button>
								</div>
							)}
						</FormGroup>
						<Button className="m-l-sm">{t('common:search_box.button')}</Button>
					</Form>
				</Col>
			</Row>
		</div>
	);
};

SearchColumn.defaultProps = {
	value: ''
};

SearchColumn.propTypes = {
	setQueryValue: PropTypes.func.isRequired,
	value: PropTypes.string.isRequired
};

export default SearchColumn;
