import React from 'react';

// Import translation
import i18n from 'i18next';

// Import convert
import { convertPrice } from 'components/helpers/convert';
import { getGeoblockNames } from 'components/helpers/geoblock';

export const createColumns = (
	handleSelectItem,
	isReadOnly,
	geoblockGroups = []
) => [
	{
		Header: i18n.t('prices:table_columns.since'),
		accessor: 'since'
	},
	{
		Header: i18n.t('prices:table_columns.till'),
		accessor: 'till'
	},
	{
		Header: i18n.t('prices:table_columns.price'),
		accessor: 'price',
		Cell: ({
			row: {
				_original: { price }
			}
		}) => <span>{convertPrice(price)}</span>
	},
	{
		Header: i18n.t('prices:table_columns.period'),
		accessor: 'period',
		Cell: ({
			row: {
				_original: { period }
			}
		}) => (
			<span>
				{parseInt(period) === -1 ? i18n.t('prices:recurring_payment') : period}
			</span>
		)
	},
	{
		Header: i18n.t('prices:table_columns.payment_method'),
		accessor: 'payment_method'
	},
	{
		Header: i18n.t('prices:table_columns.external_id'),
		accessor: 'external_id'
	},
	{
		Header: i18n.t('prices:table_columns.created_at'),
		accessor: 'created_at'
	},
	{
		Header: i18n.t('prices:table_columns.price_geoblocking'),
		accessor: 'geoblock_group_ids',
		Cell: ({
			row: {
				_original: { geoblock_group_ids }
			}
		}) =>
			getGeoblockNames({
				geoblockGroups,
				geoblockGroupIds: geoblock_group_ids
			})
	},
	{
		Header: i18n.t('common:table_columns.delete'),
		accessor: 'delete',
		width: 60,
		className: 'text-center',
		sortable: false,
		Cell: ({
			row: {
				_original: { id }
			}
		}) =>
			!isReadOnly && (
				<button
					onClick={() => handleSelectItem(id)}
					className="btn btn-xs btn-primary"
				>
					<i className="fa fa-trash" />
				</button>
			)
	}
];
