import services from 'services/services';
import * as types from '../types';
import { setRequestUrl } from 'store/actions';

// Import helpers
import {
	checkFetchErrors,
	queryParams
} from '../helpers_actions/convert_helpers';

// Import variables
import { TABLE_LENGTH } from 'components/helpers/table';

// ******************** FETCH DEVICES ********************
export const fetchSubscriberDevices = (options, itemId) => async (
	dispatch,
	getState
) => {
	try {
		// Dispatch a loading action
		dispatch({
			type: types.FETCH_SUBSCRIBER_DEVICES_LOADING,
			payload: true
		});

		const {
			subscribers: {
				devices: {
					columns,
					options: { startIndex: startTableIndex, page }
				}
			}
		} = getState();

		const startIndex =
			options.page === page ? startTableIndex : options.startIndex;

		const url = `/subscriber/${itemId}/devices?${queryParams(
			options,
			TABLE_LENGTH,
			columns,
			startIndex
		)}`;

		const { data } = await services.get(url);

		// Calculate pages
		const pages = Math.ceil(data.recordsFiltered / TABLE_LENGTH);

		dispatch({
			type: types.FETCH_SUBSCRIBER_DEVICES_SUCCESS,
			payload: { ...data, pages, page: options.page, startIndex }
		});

		// save request url in redux
		setRequestUrl(url)(dispatch);
	} catch (error) {
		dispatch({
			type: types.FETCH_SUBSCRIBER_DEVICES_ERROR,
			payload: checkFetchErrors(error)
		});
	}
	return 1;
};

// ******************** DEACTIVATE DEVICE ********************
export const deactivateDevice = (subscriberId, deviceId) => async (
	dispatch
) => {
	try {
		// Dispatch a loading action
		dispatch({
			type: types.DEACTIVATE_DEVICE_LOADING,
			payload: true
		});

		const options = deviceId ? { device_id: deviceId } : {};

		await services.put(
			`/subscriber/${subscriberId}/devices/deactivate`,
			options
		);

		// Dispatch an action with data
		dispatch({
			type: types.DEACTIVATE_DEVICE_SUCCESS,
			payload: true
		});
	} catch (error) {
		// Dispatch an action with error set to true
		dispatch({
			type: types.DEACTIVATE_DEVICE_ERROR,
			payload: checkFetchErrors(error)
		});
	}
};

// ******************** RESET LIMITS ********************
export const resetSubscriberLimits = (userId) => async (dispatch) => {
	try {
		dispatch({
			type: types.RESET_SUBSCRIBER_LIMITS_LOADING,
			payload: true
		});

		await services.put(`/subscriber/${userId}/devices/resetChanges`);

		dispatch({
			type: types.RESET_SUBSCRIBER_LIMITS_SUCCESS
		});
	} catch (error) {
		dispatch({
			type: types.RESET_SUBSCRIBER_LIMITS_ERROR,
			payload: checkFetchErrors(error)
		});
	}
};

// ******************** FETCH LIMITS DATA ********************
export const fetchSubscriberDevicesLimits = (userId) => async (dispatch) => {
	try {
		// Dispatch a loading action
		dispatch({
			type: types.GET_SUBSCRIBER_LIMITS_DATA_LOADING,
			payload: true
		});

		const { data } = await services.get(
			`subscriber/${userId}/devices/monthlyStats`
		);

		dispatch({
			type: types.GET_SUBSCRIBER_LIMITS_DATA_SUCCESS,
			payload: data
		});
	} catch (error) {
		dispatch({
			type: types.GET_SUBSCRIBER_LIMITS_DATA_ERROR,
			payload: checkFetchErrors(error)
		});
	}
};
