import React from 'react';

// Import components
import ContentTranslationForm from 'components/utilities/content_translation/ContentTranslationForm';

// Import translations
import i18n from 'i18next';

export const generateContentTranslationsTabs = (
	inputFieldsData,
	contentTranslationLanguages,
	validate = () => {}
) => {
	if (contentTranslationLanguages) {
		return contentTranslationLanguages.map((lang) => {
			return {
				name: `${i18n.t(`common:tabs.translations`)} ${lang.toUpperCase()}`,
				path: `translations/${lang}`,
				disableOnCreate: true,
				component: (
					<ContentTranslationForm
						contentLanguage={lang}
						contentData={inputFieldsData}
						validate={validate}
					/>
				)
			};
		});
	} else {
		return [];
	}
};
