import React from 'react';
import PropTypes from 'prop-types';
import { Field } from 'react-final-form';

// Import translation
import { useTranslation } from 'react-i18next';

// Import components
import {
	InputLabel,
	TextareaLabel,
	CheckboxLabel
} from 'components/common/inputs';

const Fields = ({ submitting, isEdit }) => {
	const { t } = useTranslation();

	return (
		<React.Fragment>
			<Field
				disabled={submitting}
				name="title"
				type="text"
				placeholder={t('banners:fields.title')}
				label={t('banners:fields.title')}
				id="title"
				required={true}
				component={InputLabel}
			/>
			<Field
				disabled={submitting}
				name="description"
				type="textarea"
				placeholder={t('banners:fields.description')}
				label={t('banners:fields.description')}
				id="description"
				required={true}
				component={TextareaLabel}
			/>
			<Field
				disabled={submitting}
				name="active"
				type="checkbox"
				placeholder={t('banners:fields.active')}
				label={t('banners:fields.active')}
				id="active"
				required={false}
				component={CheckboxLabel}
			/>
			<Field
				disabled={submitting}
				name="group"
				type="text"
				placeholder={t('banners:fields.group')}
				label={t('banners:fields.group')}
				id="group"
				required={true}
				component={InputLabel}
			/>
			<Field
				disabled={submitting}
				name="url_pc"
				type="text"
				placeholder={t('banners:fields.url_pc')}
				label={t('banners:fields.url_pc')}
				id="url_pc"
				required={true}
				component={InputLabel}
			/>
			<Field
				disabled={submitting}
				name="url_mobile"
				type="text"
				placeholder={t('banners:fields.url_mobile')}
				label={t('banners:fields.url_mobile')}
				id="url_mobile"
				required={true}
				component={InputLabel}
			/>
			{!isEdit && (
				<>
					<Field
						disabled={true}
						name="created_at"
						type="text"
						placeholder={t('banners:fields.created_at')}
						label={t('banners:fields.created_at')}
						id="created_at"
						required={false}
						component={InputLabel}
					/>
					<Field
						disabled={true}
						name="updated_at"
						type="text"
						placeholder={t('banners:fields.updated_at')}
						label={t('banners:fields.updated_at')}
						id="updated_at"
						required={false}
						component={InputLabel}
					/>
				</>
			)}
		</React.Fragment>
	);
};

Fields.propTypes = {
	submitting: PropTypes.bool.isRequired,
	isEdit: PropTypes.bool.isRequired
};

export default Fields;
