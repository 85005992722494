/**
 * Custom hook checking read/write authorization across app
 */
const useAuthCheck = () => {
	// =================================================================================
	// flags listed below needs refactor. They are not used but are spreaded across app.
	// Because of deadline they are hardcoded for now
	// =================================================================================

	// check if there is specific write role in roles assigned to user
	// used in most cases exept video
	const isReadOnly = false;

	// should material tab (with videos) be visible to user
	const isMaterialTabVisible = true;

	// should encoding tab (with ingestion data) be visible to user
	const isEncodingTabVisible = true;

	// should payments tab (with payments data) be visible to user
	const isPaymentsTabVisible = true;

	// should tracking tab be visible to user
	const isTrackingTabVisible = true;

	// can user edit video materials
	const canEditVideo = true;
	// // can user export csv - support_write cannot export csv
	// same as canClearSubscriberLimits, but new variable is added for clear var names and code readability
	const canExportCSV = true;

	// can user clear subscriber limits - support_write cannot clear subscriber limits
	// same as canExportCSV, but new variable is added for clear var names and code readability
	const canClearSubscriberLimits = true;

	return {
		isReadOnly,
		isMaterialTabVisible,
		isEncodingTabVisible,
		isPaymentsTabVisible,
		isTrackingTabVisible,
		canEditVideo,
		canExportCSV,
		canClearSubscriberLimits
	};
};

export default useAuthCheck;
