import { useSelector } from 'react-redux';

const useProductVisibility = (productType) => {
	const productsVisibility = useSelector(
		({ product_visibility }) => product_visibility
	);

	const tableResources = productsVisibility[productType].table;

	const fetchActionResources = {
		productType,
		columns: tableResources?.columns,
		startIndex: tableResources?.options?.startIndex,
		page: tableResources?.options?.page
	};

	const deleteActionResources = {
		productType
	};

	return {
		fetchActionResources,
		deleteActionResources,
		tableResources,
		error: tableResources.error
	};
};

export default useProductVisibility;
