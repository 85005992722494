import React, { useContext } from 'react';
import PropTypes from 'prop-types';
import { Modal } from 'antd';

// Import context
import { ProductsContext } from '../ProductsProvider';

// Import components
import Tabs from './tabs/Tabs';
import Select from './select/Select';
import { ModalFormButtons } from 'components/common/buttons';

const ProductsModal = ({ toggle, visible }) => {
	const { title, handleModalCancel, handleModalOk } = useContext(
		ProductsContext
	);

	const handleOnCancle = () => {
		toggle();
		handleModalCancel();
	};

	const handleOnSubmit = () => {
		toggle();
		handleModalOk();
	};
	return (
		<Modal
			title={title}
			visible={visible}
			onOk={handleOnSubmit}
			onCancel={handleOnCancle}
			footer={false}
			destroyOnClose={true}
			width={700}
		>
			<Tabs />
			<Select />
			<ModalFormButtons
				isButtonDisabled={false}
				modalCloseAction={handleOnCancle}
				modalOkAction={handleOnSubmit}
			/>
		</Modal>
	);
};

ProductsModal.propTypes = {
	visible: PropTypes.bool.isRequired,
	toggle: PropTypes.func.isRequired
};

export default ProductsModal;
