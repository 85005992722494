// ******************** EVENT_LIVE ********************
// -------------------- TABLE --------------------
// -------------------- FETCH --------------------
export const FETCH_EVENT_LIVE_VISIBILITY_TABLE_LOADING =
	'FETCH_EVENT_LIVE_VISIBILITY_TABLE_LOADING';
export const FETCH_EVENT_LIVE_VISIBILITY_TABLE_SUCCESS =
	'FETCH_EVENT_LIVE_VISIBILITY_TABLE_SUCCESS';
export const FETCH_EVENT_LIVE_VISIBILITY_TABLE_ERROR =
	'FETCH_EVENT_LIVE_VISIBILITY_TABLE_ERROR';
// -------------------- DELETE --------------------
export const DELETE_EVENT_LIVE_VISIBILITY_LOADING =
	'DELETE_EVENT_LIVE_VISIBILITY_LOADING';
export const DELETE_EVENT_LIVE_VISIBILITY_SUCCESS =
	'DELETE_EVENT_LIVE_VISIBILITY_SUCCESS';
export const DELETE_EVENT_LIVE_VISIBILITY_ERROR =
	'DELETE_EVENT_LIVE_VISIBILITY_ERROR';

// -------------------- FORM --------------------
export const FETCH_EVENT_LIVE_VISIBILITY_FORM_LOADING =
	'FETCH_EVENT_LIVE_VISIBILITY_FORM_LOADING';
export const FETCH_EVENT_LIVE_VISIBILITY_FORM_SUCCESS =
	'FETCH_EVENT_LIVE_VISIBILITY_FORM_SUCCESS';
export const FETCH_EVENT_LIVE_VISIBILITY_FORM_ERROR =
	'FETCH_EVENT_LIVE_VISIBILITY_FORM_ERROR';
