import React from 'react';
import { string, element, object, number } from 'prop-types';
import { Modal, Button } from 'antd';

// Import translation
import { useTranslation } from 'react-i18next';

import { Toggle } from 'components/utilities';

const Info = ({ children, content, className, iconStyle, modalWidth }) => {
	const { t } = useTranslation();

	const renderComponent = () => (
		<div className={`info${className && ` ${className}`}`}>
			{children}
			<Toggle>
				{({ on, toggle }) => (
					<>
						<i
							onClick={toggle}
							className="fa fa-info-circle info__icon"
							aria-hidden="true"
							style={iconStyle}
						></i>
						<Modal
							title={t('common:form.info_title')}
							visible={on}
							onCancel={toggle}
							footer={[
								<Button key="ok" type="primary" onClick={toggle}>
									{t('common:buttons.ok')}
								</Button>
							]}
							width={modalWidth}
							destroyOnClose={true}
						>
							{content}
						</Modal>
					</>
				)}
			</Toggle>
		</div>
	);

	return content ? renderComponent() : children;
};

Info.defaultProps = {
	content: null,
	className: '',
	modalWidth: 520
};

Info.propTypes = {
	content: element,
	className: string,
	children: object,
	iconStyle: object,
	modalWidth: number
};

export default Info;
