import services from 'services/services';
import * as types from '../../types';
import {
	setRequestUrl,
	fetchIngesterStats,
	refreshIngesterAssets
} from 'store/actions';

// Import translation
import i18n from 'i18next';

// Import utilities
import { notificationHandler } from 'components/utilities/notifications/index';

// Import helpers
import {
	checkFetchErrors,
	queryParams
} from 'store/actions/helpers_actions/convert_helpers';

// Import variables
import { TABLE_LENGTH } from 'components/helpers/table';

// ******************** FETCH INGESTER_ENCODING - TABLE DATA ********************
export const fetchIngesterEncoding = (options) => async (
	dispatch,
	getState
) => {
	try {
		// Dispatch a loading action
		dispatch({
			type: types.FETCH_INGESTER_ENCODING_LOADING,
			payload: true
		});

		const {
			ingester: {
				encoding_table: {
					columns,
					options: {
						filters: { filterUrlQuery },
						startIndex: startTableIndex,
						page
					}
				}
			}
		} = getState();

		const startIndex =
			options.page === page ? startTableIndex : options.startIndex;

		const url = `/ingester/encodingList?${filterUrlQuery}${queryParams(
			options,
			TABLE_LENGTH,
			columns,
			startIndex
		)}`;

		const { data: ingesterEncoding } = await services.get(url);
		// Calculate pages
		const pages = Math.ceil(ingesterEncoding.recordsFiltered / TABLE_LENGTH);

		// Dispatch an action with data
		dispatch({
			type: types.FETCH_INGESTER_ENCODING_SUCCESS,
			payload: { ...ingesterEncoding, pages }
		});

		// save request url in redux
		setRequestUrl(url)(dispatch);
	} catch (error) {
		dispatch({
			type: types.FETCH_INGESTER_ENCODING_ERROR,
			payload: checkFetchErrors(error)
		});
	}
};

// ******************** FETCH INGESTER ENCODING - VOD DETAILS DATA ********************
export const fetchIngesterEncodingVodDetails = (vodId) => async (dispatch) => {
	try {
		// Dispatch a loading action
		dispatch({
			type: types.FETCH_INGESTER_ENCODING_VOD_DETAILS_LOADING,
			payload: true
		});

		const url = `/ingester/encodingList?order[0][column]=1&order[0][dir]=asc&start=0&uuid=${vodId}`;

		const { data: ingesterEncoding } = await services.get(url);

		// Dispatch an action with data
		dispatch({
			type: types.FETCH_INGESTER_ENCODING_VOD_DETAILS_SUCCESS,
			payload: ingesterEncoding
		});
	} catch (error) {
		dispatch({
			type: types.FETCH_INGESTER_ENCODING_VOD_DETAILS_ERROR,
			payload: checkFetchErrors(error)
		});
	}
};

// ******************** REFRESH INGESTER ENCODING ********************
export const refreshIngesterEncoding = () => (dispatch) =>
	dispatch({
		type: types.REFRESH_INGESTER_ENCODING
	});

// ******************** SET SEARCH QUERY ********************
export const setIngesterEncodingQuery = (query) => ({
	type: types.SET_INGESTER_ENCODING_SEARCH_QUERY,
	payload: query
});

// ******************** REPEAT ENCODING ********************
export const repeatEncoding = (encodingID) => async (dispatch) => {
	try {
		await services.post(`/ingester/encoding/${encodingID}/schedule`);

		notificationHandler(
			i18n.t('messages:notifications.commissioned'),
			i18n.t('ingester:ingester_encoding.notification.repeat_encoding_success')
		);

		dispatch(refreshIngesterEncoding());
		dispatch(refreshIngesterAssets());
		dispatch(fetchIngesterStats());
	} catch (error) {
		notificationHandler(
			i18n.t('messages:notifications.error'),
			error.response.data.message
				? error.response.data.message
				: i18n.t('common:errors.common_error_message'),
			'error'
		);
	}
};

// ******************** CANCEL ENCODING ********************
export const cancelEncoding = (encodingID) => async (dispatch) => {
	try {
		await services.post(`/ingester/encoding/${encodingID}/cancel`);

		notificationHandler(
			i18n.t('messages:notifications.canceled'),
			i18n.t('ingester:ingester_encoding.notification.cancel_encoding_success')
		);

		dispatch(refreshIngesterEncoding());
		dispatch(refreshIngesterAssets());
		dispatch(fetchIngesterStats());
	} catch (error) {
		notificationHandler(
			i18n.t('messages:notifications.error'),
			i18n.t('common:errors.common_error_message'),
			'error'
		);
	}
};
